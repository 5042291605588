import React, { useEffect, useState } from "react";
import loader_image from "../../assets/images/loader.gif";
import { userService } from "../../_services";
import { Field, reduxForm, SubmissionError } from "redux-form";
import { config } from "../../config/config";
import Modal from "react-modal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { Layout } from "../common/Layout";
import close_image from "../../assets/images/close.png";
import profile_image from "../../assets/images/profile_logo.jpg";
import { ProviderDetail } from "../common/ProviderDetail";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
} from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import bid_image from "../../assets/images/bid_image.png";
import slider_image from "../../assets/images/slider_image.png";
import left_arrow from "../../assets/images/left_arrow.png";
import right_arrow from "../../assets/images/right_arrow.png";
import chat_image from "../../assets/images/chat_image.png";
import { jobsService } from "../../_services";
import StarRatings from "react-star-ratings";
import visa from "../../assets/images/visa.png";
import { Elements } from "@stripe/react-stripe-js";
import PaymentComponent from "./PaymentComponent";
import { loadStripe } from "@stripe/stripe-js";
const stripePromise = loadStripe(
  "pk_test_51H54EAB80x5swfvw4xQGhJwMZ2vXqnLHS59kGho6u2Zm4vXHF4KM8CFwVSaxQMqfl2cHJGQ2s7FLZDiRXRPiKWMX00HqaWsbV7"
);
// const stripePromise = loadStripe(
//   "pk_live_51H54EAB80x5swfvwsR108Fog1f668MEBEaaKsbbbky0wVWu9maqjuZhaR3fr3Cvl2NCKA9qkXr0HFJN7ZXyUyqQh00exEoECBZ"
// );
interface fieldInterface {
  input: any;
  label: string;
  type: string;
  meta: {
    touched: boolean;
    error: string;
  };
}

const customStyles1 = {
  overlay: {
    display: "block",
    paddingRight: "15px",
    backgroundColor: "rgba(51,51,51,0.8)",
    zIndex: 99,
  },

  content: {
    position: "",
    top: "",
    right: "",
    bottom: "",
    left: "",
  },
};

const Biddetail = (props: any) => {
  useEffect(() => {
    getData();
    get_review();
    getcards();
  }, []);

  function isEmptyObj(object) {
    for (var key in object) {
      if (object.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }

  function submitReview() {
    let params = {
      other_user_id: jobDetail.hiredBidder._id,
      job_id: jobDetail._id,
      rating: filterRating,
      message: reviewMessage,
    };

    console.log("reviewsss", params);
    jobsService
      .giveReview(params)
      .then(function (response) {
        console.log("responseeeeeeeeeeeeeeeee");
        console.log(response);
        getData();
        closeModal();
      })
      .catch(function (error) {
        userService.handleError(error);
      });
  }

  function open_payment_modal() {}

  function make_milestone_payment() {
    setPaymentLoading(true);
    console.log("---make milestone payment");
    // UpdateMilestone(2, selectedMilestone._id);

    // "milestone_id": "5f84013f91304b27b701cb9c",
    // "status": 2,
    // "reason_id": "",
    // "other_reason": "",
    // "cvv_number": "1222",
    // "stripe_card_id": "card_1HbKsfCd733q7XrJPPELlaA7"

    let params = {
      milestone_id: selectedMilestone._id,
      status: 2,
      reason_id: "",
      other_reason: "",
      cvv_number: cvvPayment,
      stripe_card_id: selectedCard,
    };

    console.log(params);
    jobsService
      .milestonePayment(params)
      .then(function (response) {
        setLoaderstyle({ display: "none" });
        console.log(response);
        getData();
        closeModal();
        setPaymentLoading(false);
      })
      .catch(function (error) {
        console.log("error", error);
        setLoaderstyle({ display: "none" });
        userService.handleError(error);
      });

    // https://api.tendr.live/api/v1/milestones
  }

  function bidDetail(bid_id: any) {
    jobsService
      .bidDetail(bid_id as string)
      .then(function (response) {
        console.log("responseeeeeeeeeeeeeeeee");
        console.log(response);
        setSelBid(response.data.bid);
        setBidPrice(response.data.bid.bid_price);
        setbidModal(true);
      })
      .catch(function (error) {
        userService.handleError(error);
      });
  }

  function acceptRejectBid(status) {
    console.log("accept bid");
    const formData = new URLSearchParams();
    formData.append("bid_id", selBid._id);
    formData.append("job_id", selBid.job_id._id);
    formData.append("status", status);
    formData.append("bid_price", bidPrice);
    formData.append("bidder_id", selBid.user_id._id);

    // console.log(formData.toString());
    jobsService
      .acceptRejectBid(formData.toString())
      .then(function (response) {
        setLoaderstyle({ display: "none" });
        console.log("bid accepted");
        console.log(response);

        if (status == "ACC") {
          alert("Bid Accepted Successfully.");
        } else if (status == "REJ") {
          alert("Bid Rejected Successfully.");
        }

        getData();
        closeModal();
      })
      .catch(function (error) {
        console.log("error", error);
        setLoaderstyle({ display: "none" });
        userService.handleError(error);
      });
  }

  function getData() {
    setLoaderstyle({ display: "block" });
    console.log("getdata");
    console.log(id);
    jobsService
      .getJob(id as string)
      .then(function (response) {
        setLoaderstyle({ display: "none" });
        console.log("responseeeeeeeeeeeeeeeee", response.data);
        setJobdetail(response.data.job);
        console.log(response.data.job.hiredBidder);
        console.log(isEmptyObj(response.data.job.hiredBidder));
        // let isbid:any=false;
        // isbid = is_bid(response.data.job)
        // setLogoutpopup(isbid)
      })
      .catch(function (error) {
        setLoaderstyle({ display: "none" });
        userService.handleError(error);
      });
  }

  const changeFilterRating = (newRating: any, name) => {
    setFilterRating(newRating);
    setreviewRatingError(false);
  };

  const submit = (fields: any, type: String) => {
    if (type == "addCard") {
      if (accountName.length == 0) {
        setAccountNameError("Card Holder Name field can't be empty.");
      } else if (!/^\d+$/.test(accountNumber)) {
        setAccountNumberError("Card Number should only contains numbers");
      } else if (!stratDate) {
        setDOBError(true);
      } else {
        console.log("form submit");

        let params = {
          card_number: accountNumber,
          exp_month: "12",
          exp_year: "2022",
          cvc: Cvv,
        };

        setLoaderstyle({ display: "block" });
        userService
          .save_card(params)
          .then(function (response) {
            setLoaderstyle({ display: "none" });
            alert("Card added Successfully.");
            closeModal();
            getcards();
            // window.location.href = "/invite_user/"+job_id;
          })
          .catch(function (error) {
            console.log("errorrrrrr", error);
            setLoaderstyle({ display: "none" });
            userService.handleError(error);
            // alert("Your card details are incorrect.");
          });
      }
    }
  };

  const handleChange = (date: any) => {
    // formData.dob= date;
    setStartdate(date);
    console.log(stratDate);
    setDOBError(false);
  };

  const [approveModal, setapproveModal] = useState(false);
  const [bidModal, setbidModal] = useState(false);
  const [rejectModal, setrejectModal] = useState(false);
  const [loaderStyle, setLoaderstyle] = useState({ display: "none" });
  const urlParams = new URLSearchParams(window.location.search);
  const myParam = urlParams.get("id");
  const [jobDetail, setJobdetail] = useState() as any;
  const [selBid, setSelBid] = useState() as any;
  const [bidPrice, setBidPrice] = useState() as any;
  const [feedbackModal, setfeedbackModal] = useState(false);
  const [reviewMessageError, setreviewMessageError] = useState(false);
  const [reviewRatingError, setreviewRatingError] = useState(false);
  const [reviewMessage, setreviewMessage] = useState("");
  const [filterRating, setFilterRating] = useState(0) as any;
  const [checkfeedbackModal, setcheckfeedbackModal] = useState(false);
  const [getReview, setgetReview] = useState("") as any;
  const [workerReview, setworkerReview] = useState("") as any;
  const [selectedMilestone, setSelectedMilestone] = useState() as any;
  const [cards, setcards] = useState([]) as any;
  const [selectedCard, setselectedCard] = useState() as any;
  const [addCardmodal, setAddCardmodal] = useState(false);
  const [accountNameError, setAccountNameError] = useState("");
  const [accountNumberError, setAccountNumberError] = useState("");
  const [CvvError, setCvvError] = useState("");

  const [accountName, setAccountName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [Cvv, setCvv] = useState("");
  const [stratDate, setStartdate] = useState("");
  const [showDOBError, setDOBError] = useState(false);
  const [cvvModal, setCvvModal] = useState(false);
  const [selectedCardLast4, setSelectedCardLast4] = useState(false);
  const [cvvPayment, setCvvPayment] = useState("");
  const [isShowProviderDetail, setIsShowProviderDetail] = useState(false);
  const [providerObj, setProviderObj] = useState("") as any;
  const [milestone, setMilestone] = useState() as any;
  const [nameHandler, setNameHandler] = useState("");
  const [clientSecret, setClientSecret] = useState("");
  const [toggleStripe, setToggleStripe] = useState(true);

  const [reasonForcancel, setreasonForcancel] = useState("");

  const [milestonestatus, setMilestonestatus] = useState("");
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [priceDetails, setPriceDetails] = useState({
    totalAmount: 0,
    tendrFee: 0,
    taxes: {
      providerTax: 0,
      tendrTax: 0,
    },
  }) as any;
  function SetAddCardmodal() {
    closeModal();
    setAddCardmodal(true);
  }

  useEffect(() => {
    if (!clientSecret && toggleStripe) {
      const getClientSecretFn = async () => {
        userService
          .getClientSecret()
          .then((result) => {
            setClientSecret(result.data.client_secret);
          })
          .then(() => {
            setToggleStripe(true);
          });
      };

      getClientSecretFn();
    }
  }, [toggleStripe]);
  const options = {
    // passing the client secret obtained from the server
    clientSecret: clientSecret,
  };
  function onAccountNameChanged(event) {
    setAccountName(
      accountName.length == 0
        ? event.target.value.replace(/ /g, "")
        : event.target.value
    );
    console.log(event.target.value);
    setAccountNameError("");
  }

  function onAccountNumberChanged(event) {
    setAccountNumber(
      accountNumber.length == 0
        ? event.target.value.replace(/ /g, "")
        : event.target.value
    );
    setAccountNumberError("");
  }

  function onCvvChanged(event) {
    setCvv(
      Cvv.length == 0
        ? event.target.value.replace(/ /g, "")
        : event.target.value
    );
    setCvvError("");
  }

  function onCvvChangedPayment(event) {
    setCvvPayment(
      cvvPayment.length == 0
        ? event.target.value.replace(/ /g, "")
        : event.target.value
    );
    // setCvvError("")
  }

  function closeModal() {
    setCvvModal(false);
    setAddCardmodal(false);
    setapproveModal(false);
    setrejectModal(false);
    setbidModal(false);
    setfeedbackModal(false);
    setcheckfeedbackModal(false);
    setIsShowProviderDetail(false);
  }
  function checkPreviousPaymentStatus(items, index) {
    // Handle the case where the item is the first in the array
    console.log(items);
    if (index === 0) {
      return true;
    }

    // Ensure the index is within the bounds of the array
    if (index > 0 && index < items.length) {
      // Check if the previous item's payment_status is "2"
      return items[index - 1].payment_status === "6";
    }

    // Return false if the index is out of bounds or other conditions are not met
    return false;
  }

  function getcards() {
    setLoaderstyle({ display: "block" });
    console.log("getdataaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa");
    userService
      .card_list()
      .then(function (response) {
        setLoaderstyle({ display: "none" });
        setcards(response.data?.cards);
        console.log(response.data);
        setselectedCard(response.data?.cards[0]?.stripe_card_id);
        setSelectedCardLast4(response.data?.cards[0]?.last4);
      })
      .catch(function (error) {
        setLoaderstyle({ display: "none" });
        userService.handleError(error);
      });
  }

  function open_cvv() {
    closeModal();
    setCvvModal(true);
  }

  function onReviewMessageChanged(e) {
    setreviewMessage(e.target.value);
    setreviewMessageError(false);
  }

  function select_card(item) {
    setselectedCard(item.stripe_card_id);
    setSelectedCardLast4(item.last4);
  }

  function get_review() {
    jobsService
      .getReview(id as string)
      .then(function (response) {
        console.log(
          "===================================get reviewwwww",
          response.data
        );
        setworkerReview(response.data.worker_review);
        setgetReview(response.data);
        // setBidDetail(response.data.bid)
      })
      .catch(function (error) {
        setLoaderstyle({ display: "none" });
        userService.handleError(error);
      });
  }

  const [reviewModal, setReviewModal] = useState(false);
  const [reviewAry, setReviewAry] = useState("") as any;

  function renderReviewModal(user_id) {
    setLoaderstyle({ display: "block" });
    userService
      .userReviews(user_id)
      .then(function (response) {
        setLoaderstyle({ display: "none" });
        console.log("reviewsss", response.data);
        setReviewAry(response.data);
        setReviewModal(true);
      })
      .catch(function (error) {
        setLoaderstyle({ display: "none" });
        userService.handleError(error);
      });
  }

  function openProviderDetail(provider) {
    console.log("openProviderDetail");
    setProviderObj(provider);
    setIsShowProviderDetail(true);
  }

  function onCancel() {
    let result = window.confirm("Are you sure you want to cancel this Job?");

    if (result) {
      // cancel job
      setLoaderstyle({ display: "block" });
      jobsService
        .cancelJob(id)
        .then(function (response) {
          console.log("response");
          setLoaderstyle({ display: "none" });
          getData();
          window.location.href = "/my_jobs";
        })
        .catch(function (error) {
          setLoaderstyle({ display: "none" });
          userService.handleError(error);
        });

      // inviteApi("REJ")
    }
  }

  function pay_now_milestone(milestone) {
    console.log("-----milestone", milestone);
    userService
      .customerPriceDetail({ data: milestone.price })
      .then((response) => {
        setPriceDetails(response.data);
        console.log("pricing response", response.data);
      });
    setSelectedMilestone(milestone);
    setPaymentLoading(false);
    setapproveModal(true);
  }

  function UpdateMilestone(status: any, id: string) {
    closeModal();

    setLoaderstyle({ display: "none" });
    const formData = new URLSearchParams();
    formData.append("milestone_id", id);
    formData.append("status", status);

    if (status == 5) {
      if (milestonestatus == "") {
        alert("Please select the reason");
        setrejectModal(true);
      } else if (milestonestatus == "3" && reasonForcancel == "") {
        alert("Please add the appropriate reason.");
        setrejectModal(true);
      } else {
        setLoaderstyle({ display: "block" });
        formData.append("reason_id", milestonestatus);
        formData.append("other_reason", reasonForcancel);
        // formData.append('status',"5");

        jobsService
          .milestones(formData.toString())
          .then(function (response) {
            setLoaderstyle({ display: "none" });

            getData();
          })
          .catch(function (error) {
            setLoaderstyle({ display: "none" });
            userService.handleError(error);
          });
      }
    } else {
      setLoaderstyle({ display: "block" });

      jobsService
        .milestones(formData.toString())
        .then(function (response) {
          setLoaderstyle({ display: "none" });

          getData();
        })
        .catch(function (error) {
          setLoaderstyle({ display: "none" });
          userService.handleError(error);
        });
    }
  }
  function ApprovePayment(status: any, id: string) {
    closeModal();

    setLoaderstyle({ display: "none" });
    const formData = new URLSearchParams();
    formData.append("milestone_id", id);
    formData.append("status", status);

    if (status == 5) {
      if (milestonestatus == "") {
        alert("Please select the reason");
        setrejectModal(true);
      } else if (milestonestatus == "3" && reasonForcancel == "") {
        alert("Please add the appropriate reason.");
        setrejectModal(true);
      } else {
        setLoaderstyle({ display: "block" });
        formData.append("reason_id", milestonestatus);
        formData.append("other_reason", reasonForcancel);
        // formData.append('status',"5");

        jobsService
          .approveMilestonePayment({ id, status })
          .then(function (response) {
            setLoaderstyle({ display: "none" });

            getData();
          })
          .catch(function (error) {
            setLoaderstyle({ display: "none" });
            userService.handleError(error);
          });
      }
    } else {
      setLoaderstyle({ display: "block" });
      console.log("herestatus", status);
      console.log(formData);
      jobsService
        .approveMilestonePayment(formData.toString())
        .then(function (response) {
          setLoaderstyle({ display: "none" });
          setPaymentLoading(false);
          getData();
        })
        .catch(function (error) {
          setLoaderstyle({ display: "none" });
          setPaymentLoading(false);
          userService.handleError(error);
        });
    }
  }

  function closemodal() {
    setReviewModal(false);
  }

  type idParams = {
    id: string;
  };

  let { id } = useParams<idParams>();
  let all_bids_url = " /bid_message/" + id;
  let job_url = "/job_detail/" + id;

  const { error, className, handleSubmit, pristine, reset, submitting } = props;
  return (
    <div className="full_body">
      <div className="loader-box" style={loaderStyle}>
        <div className="loader-image">
          <img src={loader_image} />
        </div>
      </div>

      {jobDetail && jobDetail.job_images.length > 0 ? (
        <section
          className="preview_post_banner_area"
          style={{
            backgroundImage: `url(${jobDetail.job_images[0].job_image})`,
          }}
        ></section>
      ) : null}

      {jobDetail && (
        <section className="slider_preview_post">
          <div className="container">
            <div className="row">
              <div className="col-md-1"></div>
              <div
                className={
                  jobDetail && jobDetail.job_images.length > 0
                    ? "col-md-4 slider-box"
                    : "col-md-4 slider-box without_image"
                }
              >
                {jobDetail.job_images.length > 0 ? (
                  <Carousel autoPlay>
                    {jobDetail.job_images.map((item, index) => {
                      return (
                        <div>
                          <img
                            src={item.job_image}
                            alt="slider_image.png"
                            style={{ width: "100%" }}
                          />
                        </div>
                      );
                    })}
                  </Carousel>
                ) : null}
                {/* images end for slider */}
                {jobDetail ? (
                  <div>
                    {jobDetail &&
                    isEmptyObj(jobDetail.hiredBidder) &&
                    jobDetail.bids.length > 0 ? (
                      <div className="all_bid_box">
                        <div className="heading">
                          <h1>
                            All Bids{" "}
                            <span>
                              <a href={all_bids_url}>View All</a>{" "}
                            </span>
                          </h1>
                        </div>
                        <div className="all_bid_content">
                          <ul>
                            {jobDetail.bids.map((item, index) => {
                              return (
                                <li>
                                  <div className="content-box">
                                    <img
                                      src={item.user_id.profile_image}
                                      alt={item.user_id.name}
                                    />
                                    <h1>
                                      {" "}
                                      {item.user_id.name}{" "}
                                      <span>{item.time_ago}</span>
                                    </h1>
                                    <p>
                                      <StarRatings
                                        rating={item.user_id.avgrating}
                                        starRatedColor="#f0bf0c"
                                        starDimension="14px"
                                        starSpacing="1px"
                                        starEmptyColor="#cbd3e4"
                                        numberOfStars={5}
                                        name="rating"
                                      />
                                      <a href="">
                                        ({item.user_id.reviews}{" "}
                                        {item.user_id.reviews < 2
                                          ? "review"
                                          : "reviews"}{" "}
                                        )
                                      </a>
                                      <span>${item.bid_price}</span>
                                    </p>
                                    <a
                                      onClick={() => bidDetail(item._id)}
                                      data-toggle="modal"
                                      data-target="#bid_modal"
                                    >
                                      View Bid Message
                                    </a>
                                  </div>
                                  <div className="clear"></div>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    ) : jobDetail &&
                      !isEmptyObj(jobDetail.hiredBidder) &&
                      jobDetail.showMarkComplete == false ? (
                      <div className="all_bid_box">
                        <div className="heading">
                          <h1>Hired Applicant </h1>
                        </div>
                        <div className="all_bid_content">
                          <ul>
                            <li>
                              <div className="content-box chat-link-content-box">
                                <img
                                  src={jobDetail.hiredBidder.profile_image}
                                  alt={jobDetail.hiredBidder.name}
                                />
                                <h1
                                  onClick={() =>
                                    openProviderDetail(jobDetail.hiredBidder)
                                  }
                                >
                                  {" "}
                                  {jobDetail.hiredBidder.name}{" "}
                                  <span>{jobDetail.hiredBid.time_ago}</span>
                                </h1>
                                <p>
                                  <StarRatings
                                    rating={jobDetail.hiredBidder.avgrating}
                                    starRatedColor="#f0bf0c"
                                    starDimension="14px"
                                    starSpacing="1px"
                                    starEmptyColor="#cbd3e4"
                                    numberOfStars={5}
                                    name="rating"
                                  />
                                  {/* here it is */}
                                  <a
                                    onClick={() =>
                                      renderReviewModal(
                                        jobDetail.hiredBidder._id
                                      )
                                    }
                                  >
                                    ({jobDetail.hiredBidder.reviews}{" "}
                                    {jobDetail.hiredBidder.reviews < 2
                                      ? "review"
                                      : "reviews"}{" "}
                                    )
                                  </a>
                                </p>
                                <a
                                  href={
                                    "/new_message/" +
                                    jobDetail._id +
                                    "/" +
                                    jobDetail.hiredBidder._id
                                  }
                                  className="chat_link"
                                >
                                  <img
                                    src={chat_image}
                                    alt="chat_image"
                                    className="chat_image"
                                  />
                                </a>
                                {/* <a onClick={()=>bidDetail(jobDetail.hiredBid._id) } data-toggle="modal" data-target="#bid_modal">View Bid Message</a> */}
                              </div>
                              <div className="clear"></div>
                            </li>
                          </ul>
                        </div>
                        <div className="progress_bottom">
                          {jobDetail.job_status == "in_progress" ? (
                            <p>This job in progress</p>
                          ) : jobDetail.status == "CAN" ? (
                            <p>This job has been cancelled</p>
                          ) : (
                            <div>
                              <p>This job is waiting to start</p>
                              <div
                                className="main_btn"
                                style={{ marginTop: 12 }}
                              >
                                {jobDetail.paymentStatus == "1" && (
                                  <a onClick={onCancel}> CANCEL JOB </a>
                                )}

                                {/* setfeedbackModal(true) */}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    ) : jobDetail &&
                      !isEmptyObj(jobDetail.hiredBidder) &&
                      jobDetail.showMarkComplete == true &&
                      jobDetail.job_status != "complete" ? (
                      <div className="all_bid_box" style={{ paddingBottom: 0 }}>
                        <div className="heading">
                          <h1>Hired Applicant </h1>
                        </div>
                        <div className="all_bid_content">
                          <ul>
                            <li>
                              <div className="content-box chat-link-content-box">
                                <img
                                  src={jobDetail.hiredBidder.profile_image}
                                  alt={jobDetail.hiredBidder.name}
                                />
                                <h1
                                  onClick={() =>
                                    openProviderDetail(jobDetail.hiredBidder)
                                  }
                                >
                                  {" "}
                                  {jobDetail.hiredBidder.name}{" "}
                                  <span>{jobDetail.hiredBid.time_ago}</span>
                                </h1>
                                <p>
                                  <StarRatings
                                    rating={jobDetail.hiredBidder.avgrating}
                                    starRatedColor="#f0bf0c"
                                    starDimension="14px"
                                    starSpacing="1px"
                                    starEmptyColor="#cbd3e4"
                                    numberOfStars={5}
                                    name="rating"
                                  />
                                  {/* <a href="">({jobDetail.hiredBidder.reviews} reviews)</a><span>$${jobDetail.hiredBid.bid_price}</span> */}
                                </p>
                                <a
                                  href={
                                    "/new_message/" +
                                    jobDetail._id +
                                    "/" +
                                    jobDetail.hiredBidder._id
                                  }
                                  className="chat_link"
                                >
                                  <img
                                    src={chat_image}
                                    alt="chat_image"
                                    className="chat_image"
                                  />
                                </a>
                                {/* <a onClick={()=>bidDetail(jobDetail.hiredBid._id) } data-toggle="modal" data-target="#bid_modal">View Bid Message</a> */}
                              </div>
                              <div className="clear"></div>
                            </li>
                          </ul>
                        </div>
                        <div className="progress_bottom milestone_completed milestone_completed_customer ">
                          <p className="color_blue">
                            All stages has been completed
                          </p>
                          <div className="main_btn" style={{ marginTop: 12 }}>
                            <a onClick={() => setfeedbackModal(true)}>
                              {" "}
                              MARK AS COMPLETED
                            </a>
                            {/* setfeedbackModal(true) */}
                          </div>
                        </div>
                      </div>
                    ) : jobDetail &&
                      !isEmptyObj(jobDetail.hiredBidder) &&
                      jobDetail.showMarkComplete == true &&
                      jobDetail.job_status == "complete" ? (
                      <div className="all_bid_box" style={{ paddingBottom: 0 }}>
                        <div className="heading">
                          <h1>Hired Applicant </h1>
                        </div>
                        <div className="all_bid_content">
                          <ul>
                            <li>
                              <div className="content-box">
                                <img
                                  src={jobDetail.hiredBidder.profile_image}
                                  alt={jobDetail.hiredBidder.name}
                                />
                                <h1
                                  onClick={() =>
                                    openProviderDetail(jobDetail.hiredBidder)
                                  }
                                >
                                  {" "}
                                  {jobDetail.hiredBidder.name}{" "}
                                  <span>{jobDetail.hiredBid.time_ago}</span>
                                </h1>
                                <p>
                                  <StarRatings
                                    rating={jobDetail.hiredBidder.avgrating}
                                    starRatedColor="#f0bf0c"
                                    starDimension="14px"
                                    starSpacing="1px"
                                    starEmptyColor="#cbd3e4"
                                    numberOfStars={5}
                                    name="rating"
                                  />
                                  {/* <a href="">({jobDetail.hiredBidder.reviews} reviews)</a><span>$${jobDetail.hiredBid.bid_price}</span> */}
                                </p>
                                <a
                                  href={
                                    "/new_message/" +
                                    jobDetail._id +
                                    "/" +
                                    jobDetail.hiredBidder._id
                                  }
                                  className="chat_link"
                                >
                                  <img
                                    src={chat_image}
                                    alt="chat_image"
                                    className="chat_image"
                                  />
                                </a>
                                {/* <a onClick={()=>bidDetail(jobDetail.hiredBid._id) } data-toggle="modal" data-target="#bid_modal">View Bid Message</a> */}
                              </div>
                              <div className="clear"></div>
                            </li>
                          </ul>
                        </div>

                        <div className="progress_bottom job_completed milestone_completed_customer text_green">
                          <p style={{ paddingBottom: 12 }}>
                            This job has been completed
                          </p>
                          <div className="main_btn">
                            <a onClick={() => setcheckfeedbackModal(true)}>
                              Check Feedback
                            </a>
                          </div>

                          {/* get review */}
                        </div>
                      </div>
                    ) : (
                      <div className="all_bid_box">
                        <div className="heading">
                          <h1>No Bids</h1>
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
              {jobDetail && (
                <div className="col-md-6">
                  <div className="slider_right_content">
                    <h2 className="posted">
                      Posted <span>: {jobDetail.time_ago}</span>
                    </h2>
                    <h1>{jobDetail.title}</h1>
                    {/* { isEmptyObj(jobDetail.hiredBidder) ? null : (jobDetail.job_status === "in_progress") ? <p className="job-in-progress"> The job is in progress.  </p> : (jobDetail.job_status === "waiting") ? <p className="job-waiting"> The job is waiting to start.  </p> : <p className="job-completed"> The job is completed.  </p> } */}
                    <div>
                      <a
                        href={job_url}
                        className="view_job_details button_borderless"
                      >
                        View Job Details
                      </a>

                      <div className="bid_message_content">
                        <ul>
                          <li>
                            <div className="message">
                              <h4>Bid</h4>
                              <p className="break_word">
                                <pre className="pre_css">
                                  {jobDetail.hiredBid.bid_message}
                                </pre>
                              </p>
                            </div>
                          </li>
                          <li>
                            <div className="message mileston">
                              <h4>Stages</h4>
                              {jobDetail.hiredBid.milestones.length > 0
                                ? jobDetail.hiredBid.milestones.map(
                                    (milestone, index) => {
                                      console.log(milestone, "milestone price");
                                      return (
                                        <div className="mileston_list">
                                          <span className="list_span">
                                            {index + 1}
                                          </span>
                                          <p>
                                            {milestone?.title}
                                            <span>
                                              $
                                              {parseFloat(
                                                milestone?.price
                                              ).toFixed(2)}
                                            </span>
                                          </p>
                                          <p className="break_word">
                                            {milestone?.description}
                                          </p>

                                          {jobDetail.status != "CAN" &&
                                            milestone?.payment_status ===
                                              "1" && (
                                              <p style={{ color: "orange" }}>
                                                Payment is pending
                                              </p>
                                            )}
                                          {jobDetail.status != "CAN" &&
                                            (milestone?.payment_status ===
                                              "2" ||
                                              milestone?.payment_status ==
                                                "5") && (
                                              <p style={{ color: "orange" }}>
                                                Payment in escrow
                                              </p>
                                            )}
                                          {jobDetail.status != "CAN" &&
                                            milestone?.payment_status ===
                                              "3" && (
                                              <p style={{ color: "orange" }}>
                                                {jobDetail.hiredBidder.name}{" "}
                                                sent you a request to approve
                                                payment for this stage
                                              </p>
                                            )}
                                          {jobDetail.status != "CAN" &&
                                            milestone?.payment_status ===
                                              "4" && (
                                              <p style={{ color: "orange" }}>
                                                Payment Approved
                                              </p>
                                            )}
                                          {jobDetail.status != "CAN" &&
                                            milestone?.payment_status ===
                                              "6" && (
                                              <p style={{ color: "orange" }}>
                                                Payment paid to your service
                                                provider on{" "}
                                                {milestone?.paymentDate}.
                                              </p>
                                            )}
                                          {checkPreviousPaymentStatus(
                                            jobDetail.hiredBid.milestones,
                                            index
                                          )
                                            ? jobDetail.status != "CAN" &&
                                              milestone?.payment_status ==
                                                1 && (
                                                <a
                                                  className="btn btn-danger pink_button"
                                                  onClick={() =>
                                                    pay_now_milestone(milestone)
                                                  }
                                                >
                                                  Pay Now
                                                </a>
                                              )
                                            : "First stage must be paid to the service provider before you can pay for the next stage."}
                                          {jobDetail.status != "CAN" &&
                                            milestone?.payment_status ==
                                              "3" && (
                                              <div className="pay_now">
                                                <button
                                                  onClick={() => {
                                                    setPaymentLoading(true);
                                                    ApprovePayment(
                                                      "2",
                                                      milestone._id
                                                    );
                                                  }}
                                                  className="pink_button"
                                                  disabled={paymentLoading}
                                                >
                                                  Accept
                                                </button>
                                                <a
                                                  onClick={() => {
                                                    setMilestone(milestone);
                                                    setrejectModal(true);
                                                  }}
                                                  className="pink_button pink_border"
                                                >
                                                  Reject
                                                </a>
                                              </div>
                                            )}
                                        </div>
                                      );
                                    }
                                  )
                                : null}
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <Modal
            ariaHideApp={false}
            isOpen={bidModal}
            onRequestClose={closeModal}
            className="modal-content new_modal_content modal-dialog makepayment-modal"
            contentLabel="Example Modal"
            style={customStyles1}
          >
            {/* <a onClick={()=>setapproveModal(true)} > Accept</a> */}

            <div className="modal-header">
              <h4 className="modal-title">
                View Bid Message
                <span>
                  <i
                    onClick={closeModal}
                    className="fa fa-times-circle-o"
                    aria-hidden="true"
                    data-dismiss="modal"
                  ></i>
                </span>{" "}
              </h4>
            </div>
            <div className="modal-body">
              {selBid && (
                <div className="payment_method_content bid_message_content">
                  <ul>
                    <li>
                      <span>Cost</span>
                      <span className="span_right_price">
                        ${selBid.bid_price}
                      </span>
                    </li>
                    <li>
                      <h3>Bid Message</h3>
                      <span>{selBid.bid_message}</span>
                    </li>
                    <li>
                      <div className="message mileston">
                        <h4>Stages</h4>

                        {selBid &&
                          selBid.milestones.map((item, index) => {
                            return (
                              <div className="mileston_list">
                                <span className="list_span">1</span>
                                <p>
                                  {item.title} <span>${item.price}</span>
                                </p>
                                <p>{item.description}</p>

                                {item.payment_status === "1" && (
                                  <p style={{ color: "orange" }}>
                                    Payment is pending
                                  </p>
                                )}
                                {(item.payment_status === "2" ||
                                  item.payment_status == "5") && (
                                  <p style={{ color: "orange" }}>
                                    Payment in escrow
                                  </p>
                                )}
                                {item.payment_status === "3" && (
                                  <p style={{ color: "orange" }}>
                                    {selBid.user_id.name} sent you a request to
                                    approve payment for this stage
                                  </p>
                                )}
                                {item.payment_status === "4" && (
                                  <p style={{ color: "orange" }}>
                                    Payment Approved
                                  </p>
                                )}

                                {item.payment_status == 1 && (
                                  <a
                                    className="btn btn-danger"
                                    onClick={() => UpdateMilestone(2, item._id)}
                                  >
                                    Pay Now
                                  </a>
                                )}
                                {item.payment_status == "3" && (
                                  <ul className="make_payment_list">
                                    <li>
                                      <div className="next_bottom">
                                        <div className="main_btn">
                                          <a
                                            onClick={() =>
                                              UpdateMilestone(4, item._id)
                                            }
                                          >
                                            Accept
                                          </a>
                                        </div>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="next_bottom margin-top-15">
                                        <div className="main_btn border_button">
                                          {/* // UpdateMilestone(5, item._id) */}
                                          <a
                                            onClick={() => {
                                              setMilestone(item);
                                              setrejectModal(true);
                                            }}
                                          >
                                            Reject
                                          </a>
                                        </div>
                                      </div>
                                    </li>
                                    <div className="clear"></div>
                                  </ul>
                                )}
                              </div>
                            );
                          })}
                      </div>
                    </li>
                  </ul>
                </div>
              )}

              {/* {( jobDetail && (isEmptyObj(jobDetail.hiredBidder))) &&
                    <ul className="make_payment_list">
                        <li>
                            <div className="next_bottom">
                                <div className="main_btn">
                                    <a onClick={() => setapproveModal(true)} >Accept</a>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="next_bottom margin-top-15">
                                <div className="main_btn border_button">
                                    <a onClick={() => setrejectModal(true)} >Reject</a>
                                </div>
                            </div>
                        </li>
                        <div className="clear"></div>
                    </ul>
} */}
            </div>
          </Modal>

          <Modal
            ariaHideApp={false}
            isOpen={checkfeedbackModal}
            onRequestClose={closeModal}
            className="modal-content  new_modal_content login_modal submint_review_modal"
            contentLabel="Example Modal"
            style={customStyles1}
          >
            <div className="modal-dialog login_modal submint_review_modal">
              <div className="modal-content new_modal_content makepayment-modal">
                <div className="modal-header">
                  <h4 className="modal-title">
                    Check Feedback
                    <span>
                      <i
                        onClick={closeModal}
                        className="fa fa-times-circle-o"
                        aria-hidden="true"
                        data-dismiss="modal"
                      ></i>
                    </span>{" "}
                  </h4>
                </div>
                <div className="modal-body">
                  {getReview && !isEmptyObj(getReview.customer_review) ? (
                    <div className="feedback_customer">
                      <p>Your feedback to Provider:</p>
                      <p>
                        <StarRatings
                          // rating={ getReview.customer_review.rating }
                          rating={parseInt(getReview.customer_review.rating)}
                          starRatedColor="#f0bf0c"
                          starDimension="35px"
                          starSpacing="5px"
                          starEmptyColor="#cbd3e4"
                          numberOfStars={5}
                          name="rating"
                        />
                      </p>

                      <p
                        style={{
                          fontWeight: "bold",
                          paddingBottom: 12,
                          wordBreak: "break-word",
                        }}
                      >
                        {getReview.customer_review.message}
                      </p>
                    </div>
                  ) : (
                    <div>No Review Yet</div>
                  )}
                  {getReview && (
                    <div className="feedback_customer">
                      <br />
                      <p>Provider feedback to you:</p>
                      {getReview && !isEmptyObj(getReview.worker_review) ? (
                        <div>
                          <p>
                            <StarRatings
                              // rating={ getReview.customer_review.rating }
                              rating={parseInt(getReview.worker_review.rating)}
                              starRatedColor="#f0bf0c"
                              starDimension="35px"
                              starSpacing="5px"
                              starEmptyColor="#cbd3e4"
                              numberOfStars={5}
                              name="rating"
                            />
                          </p>

                          <p style={{ fontWeight: "bold" }}>
                            {getReview.worker_review.message}
                          </p>
                        </div>
                      ) : (
                        <p>Sorry No Review Yet</p>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Modal>
          <Elements stripe={stripePromise}>
            <Modal
              ariaHideApp={false}
              isOpen={addCardmodal}
              onRequestClose={closeModal}
              className={"modal-content   new_modal_content add_new_card "}
              contentLabel="Example Modal"
              style={customStyles1}
            >
              <div className="modal-header">
                <h4 className="modal-title">
                  Add New Card
                  <span>
                    <i
                      onClick={closeModal}
                      className="fa fa-times-circle-o"
                      aria-hidden="true"
                      data-dismiss="modal"
                    ></i>
                  </span>{" "}
                </h4>
              </div>
              <div className="modal-body">
                <div className="add_new-card_box">
                  <div className="form_input_box">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">
                        CARD HOLDER NAME
                      </label>
                      {/* <Field
                                        name="name"
                                        type="text"
                                        component={renderField}
                                        maxLength={20}
                                        label="CARD HOLDER NAME"
                                        placeholder="Card Holder Name"
                                    /> */}
                      <input
                        type="text"
                        value={nameHandler}
                        maxLength={20}
                        className="form-control"
                        name="CardHolderName"
                        placeholder="Card Holder Name"
                        onChange={(e) => setNameHandler(e.target.value)}
                      />
                      {nameHandler.length > 0 && (
                        <span style={{ color: "red" }}>{accountNameError}</span>
                      )}
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">CARD NUMBER</label>
                      <PaymentComponent
                        nameHandler={nameHandler}
                        options={options}
                        setLoaderstyle={setLoaderstyle}
                        id={id}
                        getcards={getcards}
                        closemodal={closeModal}
                      />
                      {accountNumberError.length > 0 && (
                        <span style={{ color: "red" }}>
                          {accountNumberError}
                        </span>
                      )}
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        {showDOBError && (
                          <span style={{ color: "red" }}>
                            Please select valid expiry date.
                          </span>
                        )}
                      </div>
                      <div className="col-md-6"></div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal>
          </Elements>
          <Modal
            ariaHideApp={false}
            isOpen={feedbackModal}
            onRequestClose={closeModal}
            className="modal-content  new_modal_content login_modal submint_review_modal"
            contentLabel="Example Modal"
            style={customStyles1}
          >
            {/* <div className="modal fade" id="submit-your-review" role="dialog"> */}
            <div className="modal-dialog login_modal submint_review_modal">
              <div className="modal-content new_modal_content makepayment-modal">
                <div className="modal-header">
                  <h4 className="modal-title">
                    Submit your review
                    <span>
                      <i
                        onClick={closeModal}
                        className="fa fa-times-circle-o"
                        aria-hidden="true"
                        data-dismiss="modal"
                      ></i>
                    </span>{" "}
                  </h4>
                </div>
                <div className="modal-body">
                  <p>
                    <StarRatings
                      rating={filterRating}
                      starRatedColor="#f0bf0c"
                      starDimension="35px"
                      starSpacing="5px"
                      starEmptyColor="#cbd3e4"
                      numberOfStars={5}
                      changeRating={changeFilterRating}
                      name="rating"
                    />
                  </p>
                  {reviewRatingError && (
                    <span style={{ color: "red" }}>
                      Review rating can't be empty.
                    </span>
                  )}
                  <br />

                  <div className="form_input_box">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">REVIEW MESSAGE</label>
                      <textarea
                        value={reviewMessage}
                        onChange={onReviewMessageChanged}
                        placeholder="Message"
                      ></textarea>
                      {reviewMessageError && (
                        <span style={{ color: "red" }}>
                          Review Message field can't be empty.
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="main_btn">
                    <button
                      onClick={() => {
                        if (reviewMessage.length == 0)
                          setreviewMessageError(true);
                        else if (filterRating == 0) setreviewRatingError(true);
                        else submitReview();
                      }}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* </div> */}
          </Modal>

          <Modal
            ariaHideApp={false}
            isOpen={approveModal}
            onRequestClose={closeModal}
            className="modal-content new_modal_content modal-dialog makepayment-modal"
            contentLabel="Example Modal"
            style={customStyles1}
          >
            {/* <a onClick={()=>setapproveModal(true)} > Accept</a> */}

            <div className="modal-header">
              <h4 className="modal-title">
                Make Payment
                <span>
                  <i
                    onClick={closeModal}
                    className="fa fa-times-circle-o"
                    aria-hidden="true"
                    data-dismiss="modal"
                  ></i>
                </span>{" "}
              </h4>
            </div>
            <div className="modal-body">
              {false && selBid && (
                <h3 className="accept">
                  Are you sure you want to accept a bid sent from "
                  {selBid.user_id.name}" on cost "${selBid.bid_price}".{" "}
                </h3>
              )}
              {/* <h4>Escrow Amount</h4> */}
              {selectedMilestone && (
                <div className="radio_button_box radio_margin">
                  <label className="radio_box">
                    Deposit the amount of the stage.
                    <input
                      checked
                      type="radio"
                      value={parseFloat(selectedMilestone?.price).toFixed(2)}
                      name="milestone"
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
              )}
              {selectedMilestone && (
                <>
                  <h5>
                    Sub Total
                    <span>
                      ${parseFloat(selectedMilestone?.price).toFixed(2)}
                    </span>
                  </h5>

                  <h5>
                    Service Fee (5%)
                    <span>${(priceDetails?.tendrFee).toFixed(2)}</span>
                  </h5>
                  <h5>
                    Tax (5% GST)
                    <span>
                      $
                      {(
                        priceDetails?.taxes.providerTax +
                        priceDetails?.taxes?.tendrTax
                      ).toFixed(2)}
                    </span>
                  </h5>
                  <h5>
                    Total Amount Due
                    <span>${(priceDetails?.totalAmount).toFixed(2)}</span>
                  </h5>
                </>
              )}
              <p className="red_text">
                * Your money is safe in escrow, we will release amount to your
                worker only for approved stage by you.
              </p>

              <div className="payment_method_content">
                <h1>Payment Method</h1>
                <ul>
                  {cards &&
                    cards.map((item, index) => {
                      return (
                        <li>
                          <div className="payment_content">
                            <p>
                              <img src={visa} alt="visa" />
                              **** **** **** {item.last4}
                            </p>
                          </div>
                          <div className="payment_chekbox">
                            <div className="radio_button_box">
                              <label className="radio_box">
                                <input
                                  type="radio"
                                  checked={
                                    selectedCard == item.stripe_card_id
                                      ? true
                                      : false
                                  }
                                  name="radio"
                                  onClick={() =>
                                    setselectedCard(item.stripe_card_id)
                                  }
                                />
                                <span
                                  className="checkmark"
                                  onClick={() => select_card(item)}
                                ></span>
                              </label>
                            </div>
                          </div>
                          <div className="clear"></div>
                        </li>
                      );
                    })}
                </ul>
                <a
                  data-toggle="modal"
                  onClick={SetAddCardmodal}
                  data-target="#add_new_card"
                  className="add_new"
                >
                  + Add New Card
                </a>
                {/* <div className="tab_bottom_button"> */}
                {/* <a data-toggle="modal" onClick={SetThanks} data-target="#payment_success">proceed to payment</a> */}
                {/* </div> */}
              </div>

              {selectedMilestone && (
                <ul className="make_payment_list">
                  <li>
                    <div className="next_bottom">
                      <div className="main_btn">
                        <a
                          onClick={() => {
                            if (selectedCard == "") {
                              setAddCardmodal(true);
                            } else {
                              setCvvModal(true);
                            }
                          }}
                        >
                          Make Payment
                        </a>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="next_bottom margin-top-15">
                      <div className="main_btn border_button">
                        <a onClick={closeModal}>Not Now</a>
                      </div>
                    </div>
                  </li>
                  <div className="clear"></div>
                </ul>
              )}
            </div>
          </Modal>

          <Modal
            ariaHideApp={false}
            isOpen={rejectModal}
            onRequestClose={closeModal}
            className="modal-content  new_modal_content makepayment-modal"
            contentLabel="Example Modal"
            style={customStyles1}
          >
            <div className="modal-header">
              <h4 className="modal-title">
                Cancel Stage
                <span>
                  <i
                    onClick={closeModal}
                    className="fa fa-times-circle-o"
                    aria-hidden="true"
                    data-dismiss="modal"
                  ></i>
                </span>{" "}
              </h4>
            </div>
            <div className="modal-body">
              {milestone && (
                <>
                  <h3 className="accept">
                    Are you sure you want to reject a bid sent from "
                    {jobDetail.hiredBidder.name}" on cost "$
                    {parseFloat(milestone?.price).toFixed(2)}".{" "}
                  </h3>

                  <div className="radio_button_box radio_margin radio-box-over">
                    <p>Give us a appropriate reason.</p>
                    <label className="radio_box">
                      Work Not Completed
                      <input
                        type="radio"
                        name="radio"
                        value="1"
                        onChange={() => {
                          setMilestonestatus("1");
                        }}
                      />
                      <span className="checkmark"></span>
                    </label>

                    <label className="radio_box">
                      Not Satisfied With Work
                      <input
                        type="radio"
                        name="radio"
                        value="2"
                        onChange={() => {
                          setMilestonestatus("2");
                        }}
                      />
                      <span className="checkmark"></span>
                    </label>

                    <label className="radio_box">
                      Other Reason
                      <input
                        type="radio"
                        name="radio"
                        value="3"
                        onChange={() => {
                          setMilestonestatus("3");
                        }}
                      />
                      <span className="checkmark"></span>
                    </label>

                    {/* <input type="radio" name="reason_for_change" value="1" onChange={()=>{setMilestonestatus("1")}}></input>
                            <input type="radio" name="reason_for_change" value="2" onChange={()=>{setMilestonestatus("2")}}></input>Not Satisfied With Work
                            <input type="radio" name="reason_for_change" value="3" onChange={()=>{setMilestonestatus("3")}}></input>Other Reason */}
                  </div>

                  <div
                    className="reason_for_cancel"
                    style={{
                      display: milestonestatus == "3" ? "block" : "none",
                    }}
                  >
                    <h4>Other Reason</h4>
                    <textarea
                      value={reasonForcancel}
                      className="form-control"
                      onChange={(e: any) => {
                        setreasonForcancel(e.target.value);
                      }}
                    ></textarea>
                  </div>

                  <div className="pay_now">
                    <button
                      className="pink_button"
                      onClick={() => {
                        UpdateMilestone(5, milestone._id);
                      }}
                    >
                      Submit
                    </button>
                  </div>
                </>
              )}
              {jobDetail && isEmptyObj(jobDetail.hiredBidder) && (
                <ul className="make_payment_list">
                  <li>
                    <div className="next_bottom">
                      <div className="main_btn">
                        <a onClick={() => acceptRejectBid("REJ")}>Reject</a>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="next_bottom margin-top-15">
                      <div className="main_btn border_button">
                        <a onClick={closeModal}>Not Now</a>
                      </div>
                    </div>
                  </li>
                  <div className="clear"></div>
                </ul>
              )}
            </div>
          </Modal>
          {/* cvvModal */}
          <Modal
            ariaHideApp={false}
            isOpen={cvvModal}
            onRequestClose={closeModal}
            className={"modal-content   new_modal_content add_new_card "}
            contentLabel="Example Modal"
            style={customStyles1}
          >
            <div className="modal-header">
              <h4 className="modal-title">
                Enter Cvv
                <span>
                  <i
                    onClick={closeModal}
                    className="fa fa-times-circle-o"
                    aria-hidden="true"
                    data-dismiss="modal"
                  ></i>
                </span>{" "}
              </h4>
            </div>

            <div className="modal-body">
              {selectedMilestone && (
                <div className="milestonePopup">
                  <h3 className="color_blue_text">
                    {" "}
                    Stage Amount: ${priceDetails?.totalAmount}
                  </h3>

                  <p>Pay with credit card ending in: {selectedCardLast4}</p>
                  <button
                    className="btnStyle"
                    onClick={() => make_milestone_payment()}
                    type="submit"
                    disabled={paymentLoading}
                  >
                    {" "}
                    Submit
                  </button>
                </div>
              )}
            </div>
          </Modal>
          <ProviderDetail
            renderReviewModal={renderReviewModal}
            showProviderDetail={isShowProviderDetail}
            closeModal={closeModal}
            customStyles1={customStyles1}
            user={providerObj}
          />

          <Modal
            ariaHideApp={false}
            isOpen={reviewModal}
            onRequestClose={closemodal}
            className={"modal-content   new_modal_content add_new_card "}
            contentLabel="Example Modal"
            style={customStyles1}
          >
            <div className="modal-header">
              <h4 className="modal-title">
                Reviews
                <span>
                  <i
                    onClick={closemodal}
                    className="fa fa-times-circle-o"
                    aria-hidden="true"
                    data-dismiss="modal"
                  ></i>
                </span>{" "}
              </h4>
            </div>
            <div className="modal-body">
              <ul className="review_modal_ul">
                {reviewAry &&
                  reviewAry.reviews.length > 0 &&
                  reviewAry.reviews.map((item, index) => {
                    return (
                      <div className="review_popup">
                        <img src={item.user_id.profile_image} />

                        <div className="review_content">
                          <h3>
                            {item.user_id.name} <span>{item.time_ago}</span>
                          </h3>
                          <StarRatings
                            rating={parseInt(item.rating)}
                            starRatedColor="#f0bf0c"
                            starDimension="20px"
                            starSpacing="1px"
                            starEmptyColor="#cbd3e4"
                            numberOfStars={5}
                            name="rating"
                          />
                          <p>{item.message}</p>
                        </div>
                      </div>
                    );
                  })}
              </ul>
            </div>
          </Modal>
        </section>
      )}
    </div>
  );
};

export default reduxForm({
  form: "loginForm", // a unique identifier for this form
})(Biddetail);
