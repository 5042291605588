import React, { useState } from "react";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import OwlCarousel from "react-owl-carousel";
import logo from "../../assets/images/logo.png";
import { config } from "../../config/config";
import { userService } from "../../_services";
import { useParams } from "react-router-dom";
import checkMark from "../../assets/images/check_green.png";
import Modal from "react-modal";
import logo1 from "../../assets/images/logo-1.png";
import email_img from "../../assets/images/email.png";

import { Field, reduxForm, SubmissionError } from "redux-form";
// Importing Images
import plumber from "../../assets/images/plumber.png";
import carpanter from "../../assets/images/carpanter.png";
import elc from "../../assets/images/elc.png";
import jobposting_phone from "../../assets/images/jobposting_phone.png";
import app_store from "../../assets/images/app_store.png";
import google_play from "../../assets/images/google-play.png";
import abouttender from "../../assets/images/about-tender.png";
import wade_pic from "../../assets/images/wade_pic.png";
import wade_pic2 from "../../assets/images/wade_pic2.png";
import wade_pic3 from "../../assets/images/wade_pic3.png";
import footer_logo from "../../assets/images/footer_logo.png";
import google from "../../assets/images/google.png";

import email from "../../assets/images/email.png";
import lock from "../../assets/images/lock.png";
import date_image from "../../assets/images/date_image.png";
import location_image from "../../assets/images/location_image.png";
import upload_image from "../../assets/images/upload-image.png";

import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login";
import LinkedinLogin from "react-linkedin-login-oauth2";
import { Multiselect } from "multiselect-react-dropdown";
import DatePicker from "react-datepicker";
import moment from "moment";
import select_arrow from "../../assets/images/select_arrow.png";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";
import loader_image from "../../assets/images/loader.gif";
import Geocode from "react-geocode";
import "react-toastify/dist/ReactToastify.css";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import { off } from "process";

type iVerificationState = {
  data: object;
};

const VerifyPassword = (props: any): JSX.Element => {
  const [accountType, setaccountType] = useState(false);
  const [providerproflleModal, setProviderProfileModal] = useState(false);
  const [uploaddocModal, setUploaddocModal] = useState(false);
  const [bankaccountModal, setBankAccountModal] = useState(false);
  const [profileModal, setProfileModal] = useState(false);
  const [custwelcomeModal, setCustwelcome] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const [verificationStatus, setVerificationStatus] = useState("");
  const [expiredText, setExpiredText] = useState(false);
  const [selectedValue, setselectedValue] = useState(1);
  const [loginModal, setloginModal] = useState(false);
  const [forgetPasswordModal, setForgetPasswordModal] = useState(false);
  const [signupModal, setSignup] = useState(false);
  const [providerwelcomeModal, setProviderwelcome] = useState(false);
  const [startDate, setStartdate] = useState() as any;

  const [userrole, setRole] = useState(0);
  const [imagedata, setImagedata] = useState("");

  const [workerdocumentArray, setworkerdocumentArray] = useState([]);

  const [workerdocumentcatArray, setworkerdocumentcatArray] = useState(
    []
  ) as any;

  const [showCatError, setCatError] = useState(false);
  const [showSubCatError, setSubCatError] = useState(false);
  const [showDOBError, setDOBError] = useState(false);

  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);

  const [docPlumber, setDocPlumber] = useState({ name: "" });
  const [docGovt, setDocGovt] = useState("");
  const [docProfile, setDocProfile] = useState("");
  const [docCarpenter, setDocCarpenter] = useState("");

  const [showDocGovtError, setDocGovtError] = useState(false);
  const [showDocProfileError, setDocProfileError] = useState(false);
  const [showDocPlumberError, setDocPlumberError] = useState(false);
  const [showDocCarpenterError, setDocCarpenterError] = useState(false);
  const [showCustDocError, setCustDocError] = useState(false);
  const [showCustProfileImageError, setCustProfileImageError] = useState(false);

  const [profilereview, setProfileReview] = useState(false);
  const [profilereviewcust, setProfileReviewcust] = useState(false);

  const [isTermsAndConditionsAccepted, setTermsAndConditionsAccepted] =
    useState(true);
  const [showTermsError, setTermsError] = useState(false);
  const [nameerror, setNameerror] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [latlng, setLatlng] = useState() as any;
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");

  const [bio, setBio] = useState("");
  const [bioError, setBioError] = useState("");

  const [accountName, setAccountName] = useState("");
  const [accountNameError, setAccountNameError] = useState("");

  const [accountNumber, setAccountNumber] = useState("");
  const [accountNumberError, setAccountNumberError] = useState("");

  const [routingNumber, setRoutingNumber] = useState("");
  const [routingNumberError, setRoutingNumberError] = useState("");

  const [location, setLocation] = useState("");
  const [locationError, setLocationError] = useState("");

  const [customerDoc, setCustomerDoc] = useState("");

  const [profile, setProfile] = useState("");
  const [profiledata, setProfiledata] = useState("");

  const [documentArray, setdocumentArray] = useState([]);
  const [catArray, setCatArray] = useState([]);
  const [catidsArray, setCatidsArray] = useState([]);
  const [subcatidsArray, setsubCatidsArray] = useState([]);

  const [catsubarray, setCatsubarray] = useState([]);
  const [workerdocumentArray2, setworkerdocumentArray2] = useState([]);
  const [doc1, setDoc1] = useState([]) as any;
  const [isrevealPassword, setisrevealPassword] = useState(false);
  const [isrevealConfirmassword, setisrevealConfirmassword] = useState(false);
  const [isrevealNewPassword, setisrevealNewPassword] = useState(false);
  const [loaderStyle, setLoaderstyle] = useState({ display: "none" });
  const [branchNameError, setBranchNameError] = useState("");
  const [branchName, setBranchName] = useState("");
  const [selCategoryAry, setSelCategoryAry] = useState([]) as any;
  const [isReadOnly, setIsReadOnly] = useState(true);

  type idParams = {
    token: string;
  };
  let { token } = useParams<idParams>();
  const access_token = localStorage.getItem("access_token");

  const buttonStyle = {
    backgroundColor: "#f16a6e",
    color: "#fff",
    padding: "11px 40px",
    borderRadius: 50,
    top: "18px",
    textDecoration: "none",
  };
  const buttonStyleHover = {
    backgroundColor: "#d0595c",
    color: "#fff",
    padding: "11px 40px",
    borderRadius: 50,
    top: "18px",
    textDecoration: "none",
  };
  const pText = {
    marginBottom: "20px",
  };

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  function SetAccountModal() {
    setaccountType(true);
  }

  // const validate = values => {
  //     const errors = {}
  //     if (!values["username"]) {
  //         setNameerror(true)
  //         errors["username"] = 'Required'
  //     } else if (values.username.length > 15) {
  //         errors["username"] = 'Must be 15 characters or less'
  //     }

  //     return errors
  // }

  // const renderField = ({ input, label, type, id, image, maxLength, placeholder, meta: { touched, error } }: fieldInterface) => (

  //     <div className="form-group">
  //         <label>{label}</label>
  //         <div className="input_content">
  //             <input {...input} type={type} maxLength={maxLength} className="form-control" id={id} placeholder={placeholder} />
  //             {id == "exampleInputEmail1" && <img src={email} alt="email" />}
  //             {id == "exampleInputPassword1" && <img src={lock} alt="email" />}
  //             {touched && error && nameerror && <span style={{ color: "red" }}>{error}</span>}
  //         </div>
  //     </div>
  // )

  // const renderTextareaField = ({ input, label, type, id, image, maxLength, placeholder, meta: { touched, error } }: fieldInterface) => (

  //     <div className="form-group">
  //         <label>{label}</label>
  //         <div className="">

  //             <textarea {...input} type={type} maxLength={maxLength} className="form-control" id={id} placeholder={placeholder} />

  //             {id == "exampleInputEmail1" && <img src={email} alt="email" />}
  //             {id == "exampleInputPassword1" && <img src={lock} alt="email" />}
  //             {touched && error && <span style={{ color: "red" }}>{error}</span>}
  //         </div>
  //     </div>
  // )

  const verifyPassword = () => {
    // console.log(token);
    userService
      .verifyAccount(token)
      .then((data: any) => {
        console.log(data);
        if (data.data.message == "Your signup token has expired") {
          return setExpiredText(true);
        }
        return setVerificationStatus(data.data.status);
      })
      .then(() => {
        return setTimeout(() => {
          window.location.href = "/";
        }, 2000);
      })
      .catch((err) => console.log(err));
  };

  const { error, handleSubmit, pristine, reset, submitting } = props;

  return (
    <>
      <ToastContainer autoClose={10000} />
      <section className="banner_area">
        <header className="header-area" id="fix-header">
          <div className="container">
            <div className="main_header_box">
              <nav className="navbar">
                <div className="navbar-header">
                  <button
                    type="button"
                    className="navbar-toggle"
                    data-toggle="collapse"
                    data-target="#myNavbar"
                  >
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                  </button>
                  <a className="navbar-brand" href="/">
                    <img src={logo} alt="logo" />
                  </a>
                </div>
                <div className="contact_box" style={{ display: "none" }}>
                  <div className="contact-content">
                    <p>
                      <i className="fa fa-phone" aria-hidden="true"></i> +(234)
                      703 482 2809
                      <br></br>M-S: 07:30 – 19:00{" "}
                    </p>
                  </div>
                </div>
                <div className="collapse navbar-collapse" id="myNavbar">
                  <ul className="nav navbar-nav navbar-right">
                    {/* <li>
                      {!access_token && (
                        <a className="btn_style" onClick={SetAccountModal}>
                          Login
                        </a>
                      )}
                    </li>
                    <li>
                      {access_token && (
                        <a className="btn_style" onClick={SetProfilesModal}>
                          Set Profile
                        </a>
                      )}
                    </li>
                    <li>
                      {access_token && (
                        <a className="btn_style" onClick={Logout}>
                          Logout
                        </a>
                      )}
                    </li> */}
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </header>
        <div className="container">
          <div className="tab-content new_job_main_box textAlignCenter">
            <h3>Account Verification</h3>

            {verificationStatus ? (
              <>
                <p style={pText}>Thank you. Your account has been verified.</p>
                <img src={checkMark} />
                <p>EMAIL VERIFIED</p>
              </>
            ) : expiredText ? (
              <>
                {" "}
                <p style={pText}>Your email token has expired</p>
              </>
            ) : (
              <>
                <p style={pText}>
                  Please click the link below to verify your accounts email
                </p>
                <a
                  style={isHovering ? buttonStyleHover : buttonStyle}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  onClick={verifyPassword}
                >
                  VERIFY EMAIL
                </a>
              </>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default reduxForm({
  form: "loginForm", // a unique identifier for this form
})(VerifyPassword);
