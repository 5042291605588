import React, { useEffect, useState } from "react";
import { userService } from "../../_services";
import { Field, reduxForm, SubmissionError } from "redux-form";
import { Link } from "react-router-dom";
import { config } from "../../config/config";
import Modal from "react-modal";

import close_image from "../../assets/images/close.png";
import profile_image from "../../assets/images/adam_smith.png";
import loader_image from "../../assets/images/loading.gif";
import home_header_logo from "../../assets/images/home_header_logo.png";
import notification from "../../assets/images/notification.png";
import down_arrow from "../../assets/images/down_arrow.png";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

interface fieldInterface {
  input: any;
  label: string;
  type: string;
  meta: {
    touched: boolean;
    error: string;
  };
}
const currentRoute = window.location.pathname;

const ProviderHeader = (props: any) => {
  const [openClass, setOpenClass] = useState(false);
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [logoutpopup, setLogoutpopup] = useState(false);
  const [notificationAry, setnotificationAry] = useState("") as any;

  useEffect(() => {
    getUnreadNotification();
    setInterval(function () {
      // console.log("calledd interval------------------------------------------------------------------------")
      // getUnreadNotification();
    }, 8000);

    // setProfile(JSON.parse(localStorage.getItem("user") as string).image)
  }, []);

  function getUnreadNotification() {
    // console.log("getdata");

    userService
      .getUnreadNotification()
      .then(function (response) {
        // console.log("notification response",response.data);
        // console.log("notification response",response.data.notiifcations);
        setnotificationAry(response.data);
      })
      .catch(function (error) {
        console.log("error in notification", error);
        // userService.handleError(error);
      });
  }

  function Logout() {
    // confirmAlert({
    //     title: 'Confirm to submit',
    //     message: 'Are you sure to do this.',
    //     buttons: [
    //       {
    //         label: 'Yes',
    //         onClick: () => alert('Click Yes')
    //       },
    //       {
    //         label: 'No',
    //         onClick: () => alert('Click No')
    //       }
    //     ]
    //   });
    var r = window.confirm("Are you sure you want to logout?");
    if (r == true) {
      localStorage.clear();
      window.location.href = `${config.appurl}`;
    } else {
    }
  }

  function Logoutpopup() {
    setLogoutpopup(true);
  }

  function closeModal() {
    setLogoutpopup(false);
  }

  function onViewAllClicked() {
    window.location.href = "/notifications";
  }

  function handleNotification(notification) {
    console.log("noti", notification);
    if (
      notification.notification_type == "payment_request" ||
      notification.notification_type == "payment_accept" ||
      notification.notification_type == "payment_reject" ||
      notification.notification_type == "payment_released" ||
      notification.notification_type == "job_start_before" ||
      notification.notification_type == "job_start" ||
      notification.notification_type == "hired"
    ) {
      window.location.href = "/job_in_progress/" + notification.job_id._id;
    } else if (
      notification.notification_type == "offer_send" ||
      notification.notification_type == "offer_accept" ||
      notification.notification_type == "offer_reject"
    ) {
      window.location.href =
        "/new_message/" +
        notification.job_id._id +
        "/" +
        notification.receiverId;
    } else if (
      notification.notification_type == "invitation_send" ||
      notification.notification_type == "invitation_reject" ||
      notification.notification_type == "invitation_accept"
    ) {
      window.location.href = "/job_details?id=/" + notification.job_id._id;
    } else {
      window.location.href = "/job_details?id=/" + notification.job_id._id;
    }
  }

  const { error, className, handleSubmit, pristine, reset, submitting } = props;
  return (
    <header className={"home_header "}>
      <div className="container">
        <nav className="navbar">
          <div className="container-fluid">
            <div className="navbar-header">
              <button
                type="button"
                className="navbar-toggle"
                data-toggle="collapse"
                data-target="#myNavbar"
              >
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
              </button>
              <a className="navbar-brand" href="/">
                <img src={home_header_logo} alt="home_header_logo" />
              </a>
            </div>
            <div className="collapse navbar-collapse" id="myNavbar">
              <ul className="nav navbar-nav navbar-right">
                <li
                  className={
                    currentRoute == "/" ||
                    currentRoute == "/profile" ||
                    currentRoute == "/"
                      ? "active"
                      : ""
                  }
                >
                  <a href="/">Home</a>
                </li>
                <li
                  className={currentRoute == "/provider_jobs" ? "active" : ""}
                >
                  <a href="/provider_jobs">My Jobs </a>
                </li>
                <li className={currentRoute == "/chat" ? "active" : ""}>
                  <a href="/chat">
                    Message
                    {notificationAry &&
                    notificationAry.unreadCountMessage > 0 ? (
                      <span className="message_badge"></span>
                    ) : null}
                  </a>
                </li>
                <li className={currentRoute == "/settings" ? "active" : ""}>
                  <a href="/settings">Settings</a>
                </li>
                <li className="list_hide">
                  <a href="/profile">Profile </a>
                </li>
                <li className="list_hide">
                  <a onClick={Logout}>Logout</a>
                </li>
                <li
                  className={
                    notificationOpen
                      ? "notification_list nav-list_hide dropdown open"
                      : "notification_list nav-list_hide dropdown"
                  }
                >
                  <a
                    onClick={() => setNotificationOpen(!notificationOpen)}
                    className="dropdown-toggle"
                    data-toggle="dropdown"
                  >
                    <img src={notification} alt="notification" />
                    {notificationAry &&
                    notificationAry.noitifcationCount > 0 ? (
                      <span className="notification"></span>
                    ) : null}
                  </a>
                  <ul className="dropdown-menu notificaition-dropdown-menu">
                    <div className="notification-heading">
                      {notificationAry && (
                        <span>
                          {notificationAry.noitifcationCount} New Notifications
                        </span>
                      )}
                      {notificationAry && (
                        <button className="notification-two button_borderless">
                          {" "}
                          {notificationAry.noitifcationCount}{" "}
                        </button>
                      )}
                      <div className="clear"></div>
                    </div>
                    <button
                      onClick={onViewAllClicked}
                      className="view_all_button button_borderless"
                    >
                      View all
                    </button>
                    {notificationAry &&
                      notificationAry.notiifcations
                        .slice(0, 5)
                        .map((item, index) => {
                          return (
                            <li>
                              <a
                                className="cursor_pointer"
                                onClick={() => handleNotification(item)}
                              >
                                <span className="notiprofile">
                                  {/* <img src={profile_image} alt="profile_image" className="pic" /> */}
                                </span>
                                <h6>{item.body}</h6>
                                <p> {item.time_ago}</p>
                              </a>
                            </li>
                          );
                        })}
                  </ul>
                </li>

                <li className="nav-list_hide">
                  <div className="Profile_dropdown">
                    <div className={openClass ? "dropdown open" : "dropdown"}>
                      <span>
                        <img
                          src={
                            localStorage.getItem("user") &&
                            JSON.parse(localStorage.getItem("user") as string)
                              .profile_image
                              ? JSON.parse(
                                  localStorage.getItem("user") as string
                                ).profile_image
                              : profile_image
                          }
                          alt="profile_image"
                          className="profile_image"
                        />
                      </span>
                      <button
                        onClick={() => setOpenClass(!openClass)}
                        className="btn  dropdown-toggle"
                        type="button"
                        data-toggle="dropdown"
                      >
                        {
                          JSON.parse(localStorage.getItem("user") as string)
                            .name
                        }
                        <span className="down-arrow">
                          <img src={down_arrow} alt="down_arrow" />
                        </span>
                      </button>
                      <ul className="dropdown-menu">
                        <li>
                          <a href="/profile">
                            <i
                              className="fa fa-user profile_icon"
                              aria-hidden="true"
                            ></i>{" "}
                            Profile
                          </a>
                        </li>
                        {/* <li><a href="/settings"><i className="fa fa-user profile_icon" aria-hidden="true"></i> Edit Profile</a></li> */}
                        <li>
                          <a onClick={Logout}>
                            <i
                              className="fa fa-sign-out profile_icon"
                              aria-hidden="true"
                            ></i>{" "}
                            Logout
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default ProviderHeader;
