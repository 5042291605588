import React from "react";

const PrivacyPolicy = () => {
  return (
    <div>
      <div id="page_1">
        <div id="id1_1">
          <p className="p0 ft0">TERMS OF SERVICE</p>
          <p className="p1 ft0">----</p>
          <p className="p1 ft0">OVERVIEW</p>
          <p className="p2 ft1">
            This website is operated by Tendr Technologies Inc. Throughout the
            site, the terms “we”, “us” and “our” refer to Tendr Technologies
            Inc. Tendr Technologies Inc offers this website, including all
            information, tools and services available from this site to you, the
            user, conditioned upon your acceptance of all terms, conditions,
            policies and notices stated here.
          </p>
          <p className="p3 ft1">
            By visiting our site and/ or purchasing something from us, you
            engage in our “Service” and agree to be bound by the following terms
            and conditions (“Terms of Service”, “Terms”), including those
            additional terms and conditions and policies referenced herein
            and/or available by hyperlink. These Terms of Service apply to all
            users of the site, including without limitation users who are
            browsers, vendors, customers, merchants, and/ or contributors of
            content.
          </p>
          <p className="p4 ft1">
            Please read these Terms of Service carefully before accessing or
            using our website. By accessing or using any part of the site, you
            agree to be bound by these Terms of Service. If you do not agree to
            all the terms and conditions of this agreement, then you may not
            access the website or use any services. If these Terms of Service
            are considered an offer, acceptance is expressly limited to these
            Terms of Service.
          </p>
          <p className="p5 ft2">
            Any new features or tools which are added to the current store shall
            also be subject to the Terms of Service. You can review the most
            current version of the Terms of Service at any time on this page. We
            reserve the right to update, change or replace any part of these
            Terms of Service by posting updates and/or changes to our website.
            It is your responsibility to check this page periodically for
            changes. Your continued use of or access to the website following
            the posting of any changes constitutes acceptance of those changes.
          </p>
          <p className="p6 ft0">SECTION 1 - ONLINE STORE TERMS</p>
          <p className="p7 ft1">
            By agreeing to these Terms of Service, you represent that you are at
            least the age of majority in your state or province of residence, or
            that you are the age of majority in your state or province of
            residence and you have given us your consent to allow any of your
            minor dependents to use this site.
          </p>
          <p className="p8 ft1">
            You may not use our products for any illegal or unauthorized purpose
            nor may you, in the use of the Service, violate any laws in your
            jurisdiction (including but not limited to copyright laws).
          </p>
          <p className="p9 ft0">
            You must not transmit any worms or viruses or any code of a
            destructive nature.
          </p>
        </div>
        <div id="id1_1">
          <div className="page_1">
            <p className="p34 ft0">CONTACT INFORMATION</p>
            <p className="p1 ft0">
              Questions about the Terms of Service should be sent to us at
              support@tendr.live.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
