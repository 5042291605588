import React, { useEffect, useState } from "react";
import { userService } from "../../../_services";
import { config } from "../../../config/config";
import Modal from "react-modal";

import { Layout } from "../../common/Layout";
import close_image from "../../assets/images/close.png";
import profile_image from "../../assets/images/profile_logo.jpg";
import loader_image from "../../../assets/images/loader.gif";
import chat_image from "../../../assets/images/chat_icon.png";
import Bidsidebar from "../messages/Bidsidebar";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
} from "react-router-dom";

import adam_smith from "../../../assets/images/adam_smith.png";
import kim from "../../../assets/images/kim.png";
import christain from "../../../assets/images/christain.png";
import adam_message_image from "../../../assets/images/adam_message_image.png";
import { jobsService } from "../../../_services";

import StarRatings from "react-star-ratings";

interface fieldInterface {
  input: any;
  label: string;
  type: string;
  image: string;
  id: string;
  placeholder: string;
  meta: {
    touched: boolean;
    error: string;
  };
}

const Bidmessage = (props: any) => {
  useEffect(() => {
    getData();
  }, []);
  const [selBid, setSelBid] = useState() as any;
  let { id, user_id } = useParams<{ id: any; user_id: any }>();

  function acceptRejectBid(status) {
    console.log("accept bid");
    if (status == "ACC") {
      window.location.href = "/job_payment/" + selBid._id;
    } else {
      // const formData = new URLSearchParams();
      // const formData = new FormData();
      // formData.append('bid_id',selBid._id);
      // formData.append('job_id',selBid.job_id._id);
      // formData.append('status',status);
      // formData.append('bid_price', bidPrice);
      // formData.append('bidder_id',selBid.user_id._id);

      let params = {
        bid_id: selBid._id,
        job_id: selBid.job_id._id,
        status: status,
        bid_price: bidPrice,
        bidder_id: selBid.user_id._id,
      };

      jobsService
        .acceptRejectBid(params)
        .then(function (response) {
          setLoaderstyle({ display: "none" });
          console.log("bid accepted");
          console.log(response);
          if (status == "ACC") {
            alert("Bid Accepted Successfully");
            window.location.href = "/my_jobs";
          } else if (status == "REJ") {
            closeModal();
            alert("Bid Rejected Successfully");
            getData();
            window.location.href = "/my_jobs";
          } else {
            window.location.href = "/my_jobs";
          }
        })
        .catch(function (error) {
          console.log("error", error);
          setLoaderstyle({ display: "none" });
          userService.handleError(error);
        });
    }
  }

  function ChangeBidDetail(bid_id: any) {
    if (bid_id == selBid._id) {
      console.log("already active");
    } else {
      console.log("bid detailll");
      bidDetail(bid_id);
    }
  }

  function getData() {
    setLoaderstyle({ display: "block" });
    console.log("getdata");
    console.log(id);
    jobsService
      .getJob(id as string)
      .then(function (response) {
        setJobdetail(response.data.job);
        // setSelBid(response.data.job.bids[0]);
        console.log(response.data.job);
        if (user_id) {
          return bidDetail(user_id);
        }
        bidDetail(response.data.job.bids[0]._id);
        // let isbid:any=false;
        // isbid = is_bid(response.data.job)
        // setLogoutpopup(isbid)
      })
      .catch(function (error) {
        setLoaderstyle({ display: "none" });
        userService.handleError(error);
      });
  }

  function bidDetail(bid_id: any) {
    jobsService
      .bidDetail(bid_id as string)
      .then(function (response) {
        console.log("responseeeeeeeeeeeeeeeee");
        console.log(response);
        setSelBid(response.data.bid);
        setBidPrice(response.data.bid.milestones[0].price);
        setLoaderstyle({ display: "none" });
        console.log("bid detail", response.data.bid.milestones[0].price);
        if (response.data.bid.job_id.hiredBidder != null) {
          window.location.href = `/job_detail/${response.data.bid.job_id._id}`;
        }
        userService
          .customerPriceDetail({ data: response.data.bid.milestones[0].price })
          .then((response) => {
            setPriceDetails(response.data);
            console.log("pricing response", response.data);
          });
      })
      .catch(function (error) {
        setLoaderstyle({ display: "none" });
        userService.handleError(error);
      });
  }

  const [paymentModal, setpaymentModal] = useState(false);
  const [rejectBidModal, setrejectBidModal] = useState(false);
  const [loaderStyle, setLoaderstyle] = useState({ display: "none" });
  const [jobDetail, setJobdetail] = useState() as any;

  const [bidPrice, setBidPrice] = useState() as any;
  const [priceDetails, setPriceDetails] = useState({
    totalAmount: 0,
    tendrFee: 0,
    taxes: {
      providerTax: 0,
      tendrTax: 0,
    },
  }) as any;

  function PaymentModal() {
    setpaymentModal(true);
  }

  function closeModal() {
    setpaymentModal(false);
    setrejectBidModal(false);
  }

  const customStyles1 = {
    overlay: {
      display: "block",
      paddingRight: "15px",
      backgroundColor: "rgba(51,51,51,0.8)",
      zIndex: 99,
    },

    content: {
      position: "",
      top: "",
      right: "",
      bottom: "",
      left: "",
    },
  };

  const { error, className, handleSubmit, pristine, reset, submitting } = props;
  return (
    <section className="view_bid_section">
      <div className="container">
        <div className="all_bids_heading">
          <h1>All Bids({jobDetail ? jobDetail.bids.length : 0} )</h1>
        </div>
        <div className="view_bid_main_box">
          <div className="profile_info_box">
            <ul className="profile-info-List">
              {jobDetail &&
                selBid &&
                jobDetail.bids.map((item, index) => {
                  console.log("=========", item.user_id.name);
                  return (
                    <li
                      onClick={() => ChangeBidDetail(item._id)}
                      className={item._id == selBid._id ? "active" : ""}
                    >
                      <div className="content">
                        <img
                          src={item.user_id.profile_image}
                          alt="adam_smith"
                        />
                        <h4>
                          {item.user_id.name} <span>{item.time_ago}</span>
                        </h4>
                        <div className="span_btn_style  span_btn_style_bid ">
                          {jobDetail &&
                            item.user_id.categories.map((item, index) => {
                              console.log("category ==== ", item.category.name);
                              return <span>{item.category.name}</span>;
                            })}
                        </div>
                      </div>
                    </li>
                  );
                })}
            </ul>
          </div>
          {selBid && (
            <div className="view_bid_message_box">
              <div className="bid_message_content_box">
                <div className="heading">
                  <h1>
                    <span className="mobile_back">
                      <i className="fa fa-arrow-left" aria-hidden="true"></i>
                    </span>
                    View Bid Message
                  </h1>
                </div>
                <div className="bid_message_content">
                  <ul>
                    <li>
                      <div className="left_content">
                        <img
                          src={selBid.user_id.profile_image}
                          alt={selBid.user_id.name}
                          className="view-bid-img"
                        />
                        <h2>{selBid.user_id.name}</h2>
                        <p>
                          <StarRatings
                            rating={selBid.user_id.avgrating}
                            starRatedColor="#f0bf0c"
                            starDimension="14px"
                            starSpacing="1px"
                            starEmptyColor="#cbd3e4"
                            numberOfStars={5}
                            name="rating"
                          />
                          <a href="">
                            ({selBid.user_id.reviews}{" "}
                            {selBid.user_id.reviews < 2 ? "review" : "reviews"}{" "}
                            )
                          </a>
                        </p>
                        <div className="span_btn_style">
                          {jobDetail &&
                            selBid &&
                            selBid.user_id.categories.map((item, index) => {
                              console.log(item.category.name);
                              return <span>{item.category.name}</span>;
                            })}
                        </div>
                      </div>
                      <div className="right_content">
                        <div className="button_box">
                          <a
                            data-toggle="modal"
                            onClick={PaymentModal}
                            data-target="#makepayment"
                            className="accept"
                          >
                            Accept Tender
                          </a>
                          <a
                            onClick={() => setrejectBidModal(true)}
                            className="decline"
                          >
                            Reject
                          </a>
                          <a
                            href={
                              "/new_message/" + id + "/" + selBid.user_id._id
                            }
                            className="chat"
                          >
                            <span className="chat_icon">
                              {" "}
                              <img
                                src={chat_image}
                                alt="chat_image"
                                className="chat_image"
                              />{" "}
                            </span>
                            Negotiate/Chat
                            {/* <img src={chat_image} alt="chat_image" className="chat_image" /> */}
                          </a>
                        </div>
                      </div>
                      <div className="clear"></div>
                    </li>
                    <li>
                      <div className="message mileston">
                        <h4>Bid</h4>
                        <p className="break_word">{selBid.bid_message}</p>
                      </div>
                    </li>
                    <li>
                      <div className="message mileston">
                        <h4>Stages</h4>

                        {selBid &&
                          selBid.milestones.slice(0).map((item, index) => {
                            return (
                              <div className="mileston_list">
                                <span className="list_span">{index + 1}</span>
                                <p>
                                  {item.title} <span>${item.price}</span>
                                </p>
                                <p className="break_word">{item.description}</p>
                                <br />
                              </div>
                            );
                          })}
                      </div>
                    </li>
                    <li>
                      <div className="message cost">
                        <h4>Cost</h4>
                        <p>${selBid.bid_price}</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <Modal
        ariaHideApp={false}
        isOpen={paymentModal}
        onRequestClose={closeModal}
        className={"modal-content  new_modal_content makepayment-modal"}
        contentLabel="Example Modal"
        style={customStyles1}
      >
        <div className="modal-header">
          <h4 className="modal-title">
            Make Payment
            <span>
              <i
                onClick={closeModal}
                className="fa fa-times-circle-o"
                aria-hidden="true"
                data-dismiss="modal"
              ></i>
            </span>{" "}
          </h4>
        </div>
        <div className="modal-body">
          {selBid && (
            <h3 className="accept">
              Are you sure you want to accept a bid sent from "
              {selBid.user_id.name}" on cost "${selBid.bid_price}".{" "}
            </h3>
          )}

          <h4>Escrow Amount</h4>
          {selBid && (
            <div className="radio_button_box radio_margin">
              <label className="radio_box">
                Deposit the amount of the first stage.
                <input
                  checked
                  type="radio"
                  value={selBid.milestones[0].price}
                  onClick={() => setBidPrice(selBid.milestones[0].price)}
                  name="radio"
                />
                <span className="checkmark"></span>
              </label>
            </div>
          )}
          <h5>
            {selBid ? selBid.milestones[0].title : ""}
            <span>${selBid ? selBid.milestones[0].price : 0}</span>
          </h5>
          <h5>
            Tendr Fee (5%)
            <span>${priceDetails?.tendrFee}</span>
          </h5>
          <h5>
            Tax
            <span>
              $
              {(
                priceDetails?.taxes.providerTax + priceDetails?.taxes?.tendrTax
              ).toFixed(2)}
            </span>
          </h5>
          <h5>
            Total Amount Due
            <span>${priceDetails?.totalAmount}</span>
          </h5>

          <p className="red_text">
            * Your money is safe in escrow, we will release amount to your
            worker only for approved stage by you.
          </p>
          <ul className="make_payment_list">
            <li>
              <div className="next_bottom">
                <div className="main_btn">
                  <a onClick={() => acceptRejectBid("ACC")}>Make Payment</a>
                </div>
              </div>
            </li>
            <li>
              <div className="next_bottom margin-top-15">
                <div className="main_btn border_button">
                  <a onClick={closeModal}>Not Now</a>
                </div>
              </div>
            </li>
            <div className="clear"></div>
          </ul>
        </div>
      </Modal>

      <Modal
        ariaHideApp={false}
        isOpen={rejectBidModal}
        onRequestClose={closeModal}
        className={"modal-content  new_modal_content makepayment-modal"}
        contentLabel="Example Modal"
        style={customStyles1}
      >
        <div className="modal-header">
          <h4 className="modal-title">
            Decline Bid
            <span>
              <i
                onClick={closeModal}
                className="fa fa-times-circle-o"
                aria-hidden="true"
                data-dismiss="modal"
              ></i>
            </span>{" "}
          </h4>
        </div>
        <div className="modal-body">
          {selBid && (
            <h3 className="accept">
              Are you sure you want to reject a bid sent from "
              {selBid.user_id.name}" on cost "${selBid.bid_price}".{" "}
            </h3>
          )}
          <ul className="make_payment_list">
            <li>
              <div className="next_bottom">
                <div className="main_btn">
                  <a onClick={() => acceptRejectBid("REJ")}>Yes, Sure</a>
                </div>
              </div>
            </li>
            <li>
              <div className="next_bottom margin-top-15">
                <div className="main_btn border_button">
                  <a onClick={closeModal}>Not Now</a>
                </div>
              </div>
            </li>
            <div className="clear"></div>
          </ul>
        </div>
      </Modal>

      <div className="loader-box" style={loaderStyle}>
        <div className="loader-image">
          <img src={loader_image} />
        </div>
      </div>
    </section>
  );
};

export default Bidmessage;
