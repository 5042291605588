import React, { useEffect, useState } from "react";
import { reduxForm } from "redux-form";
import calender_image from "../../assets/images/calender_image.png";
import fillter from "../../assets/images/fillter-image.png";
import left_arrow from "../../assets/images/left_arrow.png";
import right_arrow from "../../assets/images/right_arrow.png";
import { config } from "../../config/config";
import date_image from "../../assets/images/date_image.png";

import { jobsService, userService } from "../../_services";
import Pagination from "react-js-pagination";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";
import loader_image from "../../assets/images/loader.gif";
import StarRatings from "react-star-ratings";
import Modal from "react-modal";

import location_image from "../../assets/images/location_image.png";
import DatePicker from "react-datepicker";
import { Multiselect } from "multiselect-react-dropdown";
import moment from "moment";
import Geocode from "react-geocode";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import BackUpImage from "../common/BackUpImage";
interface fieldInterface {
  input: any;
  label: string;
  type: string;
  meta: {
    touched: boolean;
    error: string;
  };
}

const ProviderHome = (props: any) => {
  const [loader, setLoader] = useState(0);
  const [logoutpopup, setLogoutpopup] = useState(false);
  const [currentpage, setCurrentpage] = useState(0);
  const [jobs, setJobs] = useState([]) as any;
  const [totaljobs, setTotalJobs] = useState(0);
  const [loaderStyle, setLoaderstyle] = useState({ display: "none" });
  const [filterStartDate, setFilterStartdate] = useState() as any;
  const [filterdateselected, setFilterdateselected] = useState(false);
  const [filterCustomerName, setFilterCustomerName] = useState("");
  const [filterSelectedCatId, setFilterSelectedCatId] = useState("");
  const [filterCategoriesAry, setFilterCategoriesAry] = useState([]);
  const [filterCatorySelectedValue, setFilterCatorySelectedValue] = useState(
    ""
  ) as any;

  const handleDateChange = (date: any) => {
    // formData.dob= date;
    // setDOBError(false)
    setFilterdateselected(true);
    setFilterStartdate(date);
    console.log(filterStartDate);
  };

  useEffect(() => {
    // providerSelectedCategories
    get_provider_categories();
    getData(false);
  }, []);

  function get_provider_categories() {
    jobsService
      .providerSelectedCategories()
      .then(function (response) {
        console.log("categoriessss", response.data.categories);
        setFilterCategoriesAry(response.data.categories);
        // setJobs(response.data.jobs)
      })
      .catch(function (error) {
        // setLoaderstyle({display:"none"})
        userService.handleError(error);
      });
  }

  function getData(is_filter) {
    setLoaderstyle({ display: "block" });
    let params;
    if (is_filter) {
      console.log("filterStartDate", filterStartDate);
      let formated_filter_date: any =
        moment(filterStartDate).format("YYYY-MM-D");
      // console.log(formated_filter_date);
      params = {
        category: filterSelectedCatId,
        latitude: latlng.lat == undefined ? "" : latlng.lat,
        longitude: latlng.lng == undefined ? "" : latlng.lng,
        date: filterdateselected
          ? formated_filter_date == "Invalid date"
            ? ""
            : formated_filter_date
          : "",
        name: filterCustomerName,
      };
    } else {
      params = {
        category: "",
        latitude: "",
        longitude: "",
        date: "",
        name: "",
      };
    }
    console.log("params----", params);
    jobsService
      .getProviderhome(params)
      .then(function (response) {
        setLoaderstyle({ display: "none" });
        setJobs(response.data.jobs);
        setTotalJobs(response.data.perPageRecords * response.data.totalPages);
      })
      .catch(function (error) {
        setLoaderstyle({ display: "none" });
        userService.handleError(error);
      });
  }

  const [setfilter, setFilterModal] = useState(false);

  function handlePageChange(pagenumber: any) {
    setCurrentpage(pagenumber - 1);
    getData(false);
  }

  function SetFilter() {
    setFilterModal(true);
  }

  function closemodal() {
    setFilterModal(false);
  }

  function Logout() {
    localStorage.clear();
    window.location.href = `${config.appurl}`;
  }
  function goToHomeDetail(item: any) {
    window.location.href = "job_details?id=" + item._id;
  }

  function Logoutpopup() {
    setLogoutpopup(true);
  }

  function closeModal() {
    setLogoutpopup(false);
  }
  const [noofstarts, setnoofstarts] = useState(5);
  const [filterRating, setFilterRating] = useState(1) as any;
  const [latlng, setLatlng] = useState({}) as any;
  const [location, setLocation] = useState() as any;
  function clearall() {
    // setnoofstarts(5)
    // setFilterRating(1)
    // setLocation("")
    setFilterCustomerName("");
    setLatlng({});
    setFilterStartdate("");
    setFilterCatorySelectedValue([]);
    setFilterSelectedCatId("");
    setLocation("");
    getData(false);
    setFilterdateselected(false);
  }

  const get_listing = () => {
    closemodal();
    getData(true);
  };

  const handleChangeadd = (e: any) => {
    geocodeByAddress(e.description)
      .then((results: any[]) => getLatLng(results[0]))
      .then(({ lat, lng }: any) => setLatlng({ lat, lng }));
    setLocation(e.description);
  };

  function setCurrentLocation() {
    // setLocation(JSON.parse(localStorage.getItem("current_location_name") as string))

    // setLatlng({ "lat": JSON.parse(localStorage.getItem("current_location") as string).lat, "lng": JSON.parse(localStorage.getItem("current_location") as string).lat})
    // setLatlng(JSON.parse(localStorage.getItem("current_location") as any))
    console.log("get_current_location");
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition);
    } else {
      alert("Issue in getting current location.");
    }
  }
  function showPosition(position) {
    console.log("postionnnn", position);
    var lat = position.coords.latitude;
    var lng = position.coords.longitude;
    let current_location = { lat: lat, lng: lng };
    Geocode.setApiKey("AIzaSyBsv-OafO1eNJncye_hAAAlAvE--OjmmJ8");
    Geocode.fromLatLng(lat, lng).then(
      (response) => {
        const address = response.results[0].formatted_address;
        console.log(address);
        setLocation("");
        setLocation(address);
        console.log(location);
        setLatlng({
          lat: lat,
          lng: lng,
        });
      },
      (error) => {
        console.error(error);
      }
    );
  }

  const changeFilterRating = (newRating: any, name) => {
    setFilterRating(newRating);
  };

  const customStyles1 = {
    overlay: {
      display: "block",
      paddingRight: "15px",
      backgroundColor: "rgba(51,51,51,0.8)",
      zIndex: 99,
    },

    content: {
      position: "",
      top: "",
      right: "",
      bottom: "",
      left: "",
    },
  };

  // let htmltemplate: any = [];
  // if (jobs) {
  //   htmltemplate = jobs.map((item, index1) => {
  //     return (
  //       <a
  //         className="tab_content_box grid-item "
  //         onClick={() => goToHomeDetail(item)}
  //       >
  //         {item.job_images.length > 0 ? (
  //           <Carousel>
  //             {item.job_images.map((items, index) => {
  //               return (
  //                 <div
  //                   style={{
  //                     height: "200px",
  //                     borderRadius: "20px",
  //                     overflow: "hidden",
  //                   }}
  //                 >
  //                   <img
  //                     src={items.job_image}
  //                     alt="slider_image.png"
  //                     style={{ width: "100%" }}
  //                   />
  //                 </div>
  //               );
  //             })}
  //           </Carousel>
  //         ) : null}
  //         <h1>
  //           <button
  //             className="button_borderless"
  //             onClick={() => goToHomeDetail(item)}
  //           >
  //             {(item as any).title
  //               ? (item as any).title.slice(0, 70) +
  //                 ((item as any).title.length > 70 ? "..." : "")
  //               : ""}
  //           </button>
  //           {/* <span className="In-progress">
  //                           {(item as any).no_of_bids} {(item as any).no_of_bids > 1 ? "bids" : "bid"}
  //                       </span> */}
  //         </h1>
  //         <h5 className="recent_postt">
  //           <p>
  //             <span className="calender_image">
  //               <img src={calender_image} alt="calender_image" />
  //             </span>{" "}
  //             {(item as any).start_date} &amp; {(item as any).start_time},
  //           </p>
  //           <p>Posted: {(item as any).time_ago},</p>
  //           <p>Est Budget $ {(item as any).budget}</p>
  //         </h5>
  //         {/* <p className="more_content">
  //           {(item as any).description
  //             ? (item as any).description.slice(0, 150) +
  //               ((item as any).description.length > 150 ? "..." : "")
  //             : ""}

  //         </p> */}
  //         <span className="plumber"> {(item as any).category.name}</span>
  //       </a>
  //     );
  //   }, []);
  // }
  function identifyString(s) {
    // Check if the string is only numbers
    if (/^[0-9]+$/.test(s)) {
      return "$";
    }
    // Check if the string is only letters
    else if (/^[A-Za-z]+$/.test(s)) {
      return "";
    }
    // For strings containing both or neither
    else {
      return "";
    }
  }
  let htmltemplate: any = [];
  if (jobs) {
    htmltemplate = jobs.map((value, index) => {
      // const myMoment = moment(activeJobs[key]["createdAt"]).format("D MMMM, YYYY");

      let url = " /job_detail/" + value._id;
      let edit_url = " /edit_job/" + value._id;

      //    let category = Object.keys(value.category).map(function(key: any) {
      //        alert("cat")
      //     //    console.log(value.category.name)
      //     return  (<span className="plumber">{value.category.name}</span> )
      //    })
      console.log(value);

      return (
        <div className="tab_content_box">
          {value.job_images.length > 0 ? (
            <div
              style={{
                borderRadius: "20px",
                backgroundColor: "rgba(0,0,0,0)",
              }}
            >
              <Carousel>
                {value.job_images.map((item, index) => {
                  console.log("item", item?.job_image);
                  return (
                    <div
                      style={{
                        height: "300px",
                        width: "300px",
                        borderRadius: "20px",
                        backgroundColor: "#7aa2f3",
                      }}
                    >
                      <img
                        src={item.job_image}
                        alt="slider_image.png"
                        style={{ width: "100%" }}
                      />
                    </div>
                  );
                })}
              </Carousel>
            </div>
          ) : (
            <BackUpImage height="300" width="300" />
          )}
          <a onClick={() => goToHomeDetail(value)}>
            <h5 style={{ paddingLeft: "8px" }}>
              <span
                style={{
                  fontWeight: 800,
                  fontSize: "1.4em",
                  paddingTop: "10px",
                }}
              >
                {" "}
                {/* {value.title} */}
                {(value as any).title
                  ? (value as any).title.slice(0, 80) +
                    ((value as any).title.length > 80 ? "..." : "")
                  : ""}
              </span>
              <br />
              <span
                style={{ fontWeight: 800, fontSize: "1.1em", color: "#7AA2F3" }}
              >
                {value.category.name}
              </span>
              <br />

              <span>
                {/* <span className="calender_image">
                <img src={calender_image} alt="calender_image" />
              </span> */}
                {value.start_date} @{value.start_time}
              </span>
              <br />
              <span>Posted: {value.time_ago}</span>
              <br />
              <span
                style={{
                  fontWeight: 800,
                  fontSize: "1.1em",
                  marginBottom: "25px",
                }}
              >
                Budget:{" "}
                <span className="color_blue_text">
                  {" "}
                  {identifyString(value.budget)}
                  {value.budget}
                </span>
              </span>
              <br />
            </h5>

            <p className="more_content">
              {/* {value.description
              ? value.description.slice(0, 100) +
                (value.description.length > 150 ? "..." : "")
              : ""} */}
              {/* <span className="more">
              <a onClick={() => goToJobDetail(value._id)}> View More</a>
            </span> */}
            </p>
          </a>
        </div>
      );
    }, []);
  }

  const { error, className, handleSubmit, pristine, reset, submitting } = props;
  return (
    <section className="top-services-area top-service-provider-area">
      <div className="container">
        <div className="top_servies_heading">
          <div className="left_content width100">
            <h1 className="pull-left">Recent Post</h1>
            <a
              className="pull-right"
              data-toggle="modal"
              onClick={SetFilter}
              data-target="#filter"
              data-dismiss="modal"
            >
              <img src={fillter} alt="fillter-image" />
            </a>
            <div className="clearfix"></div>
          </div>
          {/* <div className="right_content">
                        <ul>
                            <li className="image-list"  onClick={SetFilter}>
                                <img src={fillter} alt="fillter-image" />
                            </li>
                            <div className="clear"></div>
                        </ul>
                    </div> */}
          <div className="clear"></div>
        </div>

        <div className="my_job_content my-job-content-two  ">
          <div className="listing-grid-container">
            {jobs.length == 0 && <h4>No Jobs Found</h4>}
            {jobs.length > 0 && <>{htmltemplate}</>}
          </div>
        </div>
        <div className="loader-box" style={loaderStyle}>
          <div className="loader-image">
            <img src={require("../../assets/images/loader.gif")} />
          </div>
        </div>

        {/* <div className="pagination_box"> */}
        {/*
                <Pagination
                     activePage={currentpage==0?currentpage+1:currentpage+1 }
                     itemsCountPerPage={20}
                     totalItemsCount={totaljobs}
                     pageRangeDisplayed={5}
                     onChange={(e:any) => handlePageChange(e)} 
                  />
              */}
        {/* <nav aria-label="Page navigation example">
                        <ul className="pagination">
                            <li className="page-item">
                                <a className="page-link" href="#"><img src={left_arrow} alt="left_arrow" /></a>
                            </li>
                            <li className="page-item"><a className="page-link" href="#">1</a></li>
                            <li className="page-item active"><a className="page-link" href="#">2</a></li>
                            <li className="page-item"><a className="page-link" href="#">3</a></li>
                            <li className="page-item"><a className="page-link" href="#">4</a></li>
                            <li className="page-item"><a className="page-link" href="#">5</a></li>
                            <li className="page-item">
                                <a className="page-link" href="#"><img src={right_arrow} alt="right_arrow" /></a>
                            </li>
                        </ul>
                    </nav> */}
      </div>

      {/* </div> */}

      <Modal
        ariaHideApp={false}
        isOpen={setfilter}
        onRequestClose={closemodal}
        className={"modal-content  new_modal_content   "}
        portalClassName={""}
        contentLabel="Example Modal"
        style={customStyles1}
      >
        <div className="modal-header">
          <h4 className="modal-title">
            Filter
            <span data-dismiss="modal" className="cross">
              <i
                onClick={closemodal}
                className="fa fa-times-circle-o"
                aria-hidden="true"
              ></i>
            </span>
          </h4>
        </div>
        <div className="modal-body">
          <div className="input_form_box form_input_box">
            <form>
              <div className="form-group">
                <label>LOCATION</label>
                <div className="input_content">
                  <GooglePlacesAutocomplete
                    onSelect={(e: any) => handleChangeadd(e)}
                    inputClassName="form-control"
                    initialValue={location}
                  />
                  <img
                    src={location_image}
                    onClick={setCurrentLocation}
                    alt="location_image"
                  />
                </div>
              </div>
              {/* <h2 className="use_current_location" onClick={setCurrentLocation}> */}
              {/* <img src={location_image} alt="location_image" /> */}
              {/* use current location */}
              {/* </h2> */}
              <div className="form-group">
                <label>DATE</label>
                <div className="input_content">
                  <DatePicker
                    selected={filterStartDate}
                    onChange={handleDateChange}
                    value={filterStartDate}
                    // minDate={new Date()}
                    relativeSize={true}
                    showYearDropdown
                    autoComplete="off"
                    placeholderText="Select Date"
                    onKeyDown={(e: { preventDefault: () => any }) =>
                      e.preventDefault()
                    }
                    className="form-control form-control-two"
                  />
                  <img src={date_image} alt="date_image" />
                </div>
              </div>
              <div className="form-group">
                <label>SERVICE CATEGORY</label>
                <div className="input_content">
                  <Multiselect
                    options={filterCategoriesAry}
                    selectedValues={filterCatorySelectedValue}
                    displayValue="name"
                    singleSelect={true}
                    placeholder="Select Service Category"
                    onSelect={(e) => {
                      console.log("on select", e);
                      console.log("selected id", e[0]._id);
                      setFilterSelectedCatId(e[0]._id);
                      setFilterCatorySelectedValue(e);
                    }}
                    onRemove={(e) => {
                      console.log("on remove", e);
                    }}
                    closeIcon="cancel"
                    style={{
                      chips: {
                        background: "#f16a6e",
                        borderRadius: 20,
                      },
                      searchBox: {
                        borderRadius: 5,
                        border: "inherit",
                        padding: 5,
                        minHeight: 22,
                        position: "relative",
                        background: "#f4f4f4",
                        height: 48,
                        fontSize: 18,
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "95%",
                      },
                    }}
                  />
                </div>
              </div>

              <div className="form-group">
                <label>CUSTOMER NAME</label>
                <div className="input_content">
                  <input
                    type="text"
                    maxLength={25}
                    className="form-control"
                    placeholder="Enter Name"
                    value={filterCustomerName}
                    onChange={(e: any) => setFilterCustomerName(e.target.value)}
                  />
                </div>
              </div>
            </form>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="next_bottom filer_buttom-2">
                <div className="main_btn">
                  <span onClick={get_listing}>Apply</span>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="next_bottom">
                <div className="main_btn border_button">
                  <a onClick={clearall}>Clear All</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </section>
  );
};

export default reduxForm({
  form: "loginForm",
})(ProviderHome);
