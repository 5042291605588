import React, { useEffect, useState } from "react";
import { jobsService } from "../../_services";
import { userService } from "../../_services";
import { Field, reduxForm, SubmissionError } from "redux-form";
import { Link } from "react-router-dom";
import { config } from "../../config/config";
import Modal from "react-modal";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { Layout } from "../common/Layout";
import close_image from "../../assets/images/close.png";
import profile_image from "../../assets/images/profile_logo.jpg";
import loader_image from "../../assets/images/loader.gif";

import add_new_left from "../../assets/images/add_new_left.png";
import calender_image from "../../assets/images/calender_image.png";
import left_arrow from "../../assets/images/left_arrow.png";
import right_arrow from "../../assets/images/right_arrow.png";
import Pagination from "react-js-pagination";
import BackUpImage from "../common/BackUpImage";

interface fieldInterface {
  input: any;
  label: string;
  type: string;
  meta: {
    touched: boolean;
    error: string;
  };
}

const Myjobs = (props: any) => {
  function goToJobDetail(id) {
    window.location.href = "job_detail/" + id;
  }

  const [activeJobs, setActiveJobs] = useState() as any;

  const [currentpage, setCurrentpage] = useState(0);
  const [totalactivejobs, setTotalActiveJobs] = useState(0);
  const [loaderStyle, setLoaderstyle] = useState({ display: "none" });

  const [upcomingJobs, setUpcomingJobs] = useState() as any;
  const [pastJobs, setpastJobs] = useState() as any;

  useEffect(() => {
    get_active_jobs(currentpage);
  }, []);

  function get_active_jobs(page: number) {
    setLoaderstyle({ display: "block" });
    jobsService
      .getActiveJobs(page)
      .then(function (response) {
        console.log(response.data);
        setLoaderstyle({ display: "none" });
        setActiveJobs(response.data.jobs);
        setTotalActiveJobs(
          response.data.perPageRecords * response.data.totalPages
        );
      })
      .catch(function (error) {
        setLoaderstyle({ display: "none" });
        userService.handleError(error);
      });
  }

  const getUpcomingJob = (page: number) => {
    setLoaderstyle({ display: "block" });
    jobsService
      .getUpcomingJobs(page)
      .then(function (response) {
        setLoaderstyle({ display: "none" });
        setUpcomingJobs(response.data.jobs);
        console.log("upcoming jobs---------------", response.data.jobs);
        setTotalActiveJobs(20 * response.data.totalPages);
        // window.location.href = "/my_jobs";
      })
      .catch(function (error) {
        setLoaderstyle({ display: "none" });
        userService.handleError(error);
      });
  };

  const getPastJob = (page: number) => {
    setLoaderstyle({ display: "block" });
    jobsService
      .getPastJobs(page)
      .then(function (response) {
        setLoaderstyle({ display: "none" });
        setpastJobs(response.data.jobs);
        console.log("past jobs---------------", response.data.jobs);
        setTotalActiveJobs(20 * response.data.totalPages);
      })
      .catch(function (error) {
        setLoaderstyle({ display: "none" });
        userService.handleError(error);
      });
  };
  function jobEdit(job_id: any) {
    console.log("jobEdit");
    window.location.href = "/edit_job/" + job_id;
  }

  function onJobDelete(job_id: any) {
    console.log("onJobDelete");
    let result = window.confirm("Are you sure you want to delete this job?");
    if (result) {
      setLoaderstyle({ display: "block" });
      jobsService
        .deleteJobs(job_id)
        .then(function (response) {
          get_active_jobs(currentpage);
          setLoaderstyle({ display: "none" });
          alert("Job deleted successfully");
        })
        .catch(function (error) {
          setLoaderstyle({ display: "none" });
          userService.handleError(error);
        });
    }
    // let result = window.confirm('Are you sure you want to delete this job?')
    // if (result) {
    //     window.location.href = ""
    // }
  }

  function handlePageChange(pagenumber: any) {
    setCurrentpage(pagenumber - 1);
    get_active_jobs(pagenumber - 1);
  }

  function identifyString(s) {
    // Check if the string is only numbers
    if (/^[0-9]+$/.test(s)) {
      return "$";
    }
    // Check if the string is only letters
    else if (/^[A-Za-z]+$/.test(s)) {
      return "";
    }
    // For strings containing both or neither
    else {
      return "";
    }
  }
  let htmltemplate: any = [];
  if (activeJobs) {
    htmltemplate = activeJobs.map((value, index) => {
      // const myMoment = moment(activeJobs[key]["createdAt"]).format("D MMMM, YYYY");

      let url = " /job_detail/" + value._id;
      let edit_url = " /edit_job/" + value._id;

      //    let category = Object.keys(value.category).map(function(key: any) {
      //        alert("cat")
      //     //    console.log(value.category.name)
      //     return  (<span className="plumber">{value.category.name}</span> )
      //    })
      console.log("value", value);
      return (
        <>
          <li
            className="tab_content_box"
            style={{ display: "flex", flexDirection: "row" }}
          >
            {value.job_images.length > 0 ? (
              <div
                style={{
                  borderRadius: "20px",
                  backgroundColor: "rgba(0,0,0,0)",
                }}
              >
                <div
                  style={{
                    height: "200px",
                    width: "300px",
                    backgroundColor: "#7aa2f3",
                    borderRadius: "7.5%",
                    overflow: "hidden",
                  }}
                >
                  <img
                    src={value.job_images[0].job_image}
                    alt="slider_image.png"
                    style={{ width: "100%" }}
                  />
                </div>
              </div>
            ) : (
              <BackUpImage height="200" width="300" />
            )}
            <a
              onClick={() => goToJobDetail(value._id)}
              style={{ textDecoration: "none" }}
            >
              <div style={{ width: "100%" }}>
                <h5 style={{ paddingLeft: "16px" }}>
                  <span
                    style={{
                      fontWeight: 800,
                      fontSize: "1.4em",
                      paddingTop: "10px",
                    }}
                  >
                    {" "}
                    {/* {item.title} */}
                    {(value as any).title
                      ? (value as any).title.slice(0, 80) +
                        ((value as any).title.length > 80 ? "..." : "")
                      : ""}
                  </span>
                  <br />
                  <span
                    style={{
                      fontWeight: 800,
                      fontSize: "1.1em",
                      color: "#7AA2F3",
                    }}
                  >
                    {value.category.name}
                  </span>
                  <br />

                  <span style={{ fontSize: "1.1em" }}>
                    <span style={{ fontWeight: 800 }}>Start Date:</span>
                    {value.start_date} @{value.start_time}
                  </span>
                  <br />
                  <span style={{ fontSize: "1.1em" }}>
                    <span style={{ fontWeight: 800 }}>Posted:</span>
                    {value.time_ago}
                  </span>
                  <br />
                  <span
                    style={{
                      fontSize: "1.1em",
                    }}
                  >
                    <span style={{ fontWeight: 800 }}> Budget: </span>

                    <span className="color_blue_text">
                      {identifyString(value.budget)}
                      {value.budget}
                    </span>
                  </span>
                  <br />
                  <span style={{ fontSize: "1.1em" }}>
                    <span style={{ fontWeight: 800 }}>Bids:</span>
                    <span
                      style={{
                        color: value.no_of_bids > 0 ? "#7AA2F3" : "#585858",
                        fontWeight: value.no_of_bids > 0 ? 800 : 500,
                      }}
                    >
                      {value.no_of_bids}
                    </span>
                  </span>
                  <br />
                  <span
                    style={{
                      fontWeight: 800,
                      fontSize: "1.1em",

                      color: "#585858",
                      textDecoration: "none",
                    }}
                  >
                    Description:{" "}
                  </span>
                  <br />
                  <span>{value.description}</span>
                  <br />
                </h5>
              </div>
            </a>
          </li>
        </>
      );
    }, []);
  }
  let htmltemplate1: any = [];
  if (upcomingJobs) {
    htmltemplate1 = upcomingJobs.map((value, index) => {
      let url = " /bid_detail/" + value._id;

      return (
        <>
          <li
            className="tab_content_box"
            style={{ display: "flex", flexDirection: "row" }}
          >
            {value.job_images.length > 0 ? (
              <div
                style={{
                  borderRadius: "20px",
                  backgroundColor: "rgba(0,0,0,0)",
                }}
              >
                <div
                  style={{
                    height: "200px",
                    width: "300px",
                    backgroundColor: "#7aa2f3",
                    borderRadius: "7.5%",
                    overflow: "hidden",
                  }}
                >
                  <img
                    src={value.job_images[0].job_image}
                    alt="slider_image.png"
                    style={{ width: "100%" }}
                  />
                </div>
              </div>
            ) : (
              <BackUpImage height="200" width="300" />
            )}
            <a href={url} style={{ textDecoration: "none" }}>
              <div style={{ width: "100%" }}>
                <h5 style={{ paddingLeft: "16px" }}>
                  <span
                    style={{
                      fontWeight: 800,
                      fontSize: "1.4em",
                      paddingTop: "10px",
                    }}
                  >
                    {" "}
                    {/* {job.title} */}
                    {(value as any).title
                      ? (value as any).title.slice(0, 80) +
                        ((value as any).title.length > 80 ? "..." : "")
                      : ""}
                  </span>
                  <span className="In-progress">
                    {(value as any).job_status === "in_progress"
                      ? "In-progress"
                      : "Waiting to start"}
                  </span>
                  <br />
                  <span
                    style={{
                      fontWeight: 800,
                      fontSize: "1.1em",
                      color: "#7AA2F3",
                    }}
                  >
                    {value.category.name}
                  </span>
                  <br />

                  <span style={{ fontSize: "1.1em" }}>
                    <span style={{ fontWeight: 800 }}>Start Date:</span>
                    {value.start_date} @{value.start_time}
                  </span>
                  <br />
                  <span style={{ fontSize: "1.1em" }}>
                    <span style={{ fontWeight: 800 }}>Posted:</span>
                    {value.time_ago}
                  </span>
                  <br />
                  <span style={{ fontSize: "1.1em" }}>
                    <span style={{ fontWeight: 800 }}>Budget:</span>
                    {identifyString(value.finalPrice)}
                    {value.finalPrice}
                  </span>
                  <br />
                  <span
                    style={{
                      fontWeight: 800,
                      fontSize: "1.1em",

                      color: "#585858",
                      textDecoration: "none",
                    }}
                  >
                    Description:{" "}
                  </span>
                  <br />
                  <span>{value.description}</span>

                  <br />
                </h5>
              </div>
            </a>
          </li>
        </>
      );
    }, []);
  }
  let htmltemplate2: any = [];
  if (pastJobs) {
    htmltemplate2 = pastJobs.map((value: any, index) => {
      let url = " /bid_detail/" + value._id;

      return (
        <>
          <li
            className="tab_content_box"
            style={{ display: "flex", flexDirection: "row" }}
          >
            {value.job_images.length > 0 ? (
              <div
                style={{
                  borderRadius: "20px",
                  backgroundColor: "rgba(0,0,0,0)",
                }}
              >
                <div
                  style={{
                    height: "200px",
                    width: "300px",
                    backgroundColor: "#7aa2f3",
                    borderRadius: "7.5%",
                    overflow: "hidden",
                  }}
                >
                  <img
                    src={value.job_images[0].job_image}
                    alt="slider_image.png"
                    style={{ width: "100%" }}
                  />
                </div>
              </div>
            ) : (
              <BackUpImage height="200" width="300" />
            )}
            <a href={url}>
              <div style={{ width: "100%" }}>
                <h5 style={{ paddingLeft: "16px" }}>
                  <span
                    style={{
                      fontWeight: 800,
                      fontSize: "1.4em",
                      paddingTop: "10px",
                    }}
                  >
                    {" "}
                    {/* {job.title} */}
                    {(value as any).title
                      ? (value as any).title.slice(0, 80) +
                        ((value as any).title.length > 80 ? "..." : "")
                      : ""}
                  </span>
                  {(value as any).status == "CAN" ? (
                    <span className="In-progress comleted">Cancelled</span>
                  ) : (
                    <span className="In-progress comleted">Completed</span>
                  )}
                  <br />
                  <span
                    style={{
                      fontWeight: 800,
                      fontSize: "1.1em",
                      color: "#7AA2F3",
                    }}
                  >
                    {value.category.name}
                  </span>
                  <br />

                  <span style={{ fontSize: "1.1em" }}>
                    <span style={{ fontWeight: 800 }}>Start Date:</span>
                    {value.start_date} @{value.start_time}
                  </span>
                  <br />
                  <span style={{ fontSize: "1.1em" }}>
                    <span style={{ fontWeight: 800 }}>Posted:</span>
                    {value.time_ago}
                  </span>
                  <br />
                  <span style={{ fontSize: "1.1em" }}>
                    <span style={{ fontWeight: 800 }}>Budget:</span>
                    {identifyString(value.finalPrice)}
                    {value.finalPrice}
                  </span>

                  <br />
                  <span
                    style={{
                      fontWeight: 800,
                      fontSize: "1.1em",

                      color: "#585858",
                      textDecoration: "none",
                    }}
                  >
                    Description:{" "}
                  </span>
                  <br />
                  <span>{value.description}</span>
                  <br />
                </h5>
              </div>
            </a>
          </li>
        </>
      );
    }, []);
  }

  const { error, className, handleSubmit, pristine, reset, submitting } = props;
  return (
    <section className="my_job_tab_box">
      <div className="container">
        <div className="tab_heading_content">
          <ul className="nav nav-tabs">
            <li
              className="active active_list"
              onClick={() => get_active_jobs(0)}
            >
              <a data-toggle="tab" href="#active">
                Post
              </a>
            </li>
            <li className="upcoming_list" onClick={() => getUpcomingJob(0)}>
              <a data-toggle="tab" href="#upcoming">
                In-Progress
              </a>
            </li>
            <li className="past-list" onClick={() => getPastJob(0)}>
              <a data-toggle="tab" href="#past">
                History
              </a>
            </li>
            <div className="add_post button">
              <a href="/add_job">Add New Job</a>
            </div>
          </ul>

          <div className="tab-content">
            <div id="active" className="tab-pane fade in active">
              <div className="active_tab_content">
                <div className="my_job_content">
                  {htmltemplate.length > 0 ? (
                    <>{htmltemplate}</>
                  ) : (
                    <h3>No active jobs found</h3>
                  )}
                </div>
              </div>
            </div>
            <div id="upcoming" className="tab-pane fade">
              <div className="my_job_content">
                {htmltemplate1.length > 0 ? (
                  <ul>{htmltemplate1}</ul>
                ) : (
                  <h3>No upcoming jobs found</h3>
                )}
              </div>
            </div>
            <div id="past" className="tab-pane fade">
              <div className="my_job_content">
                {htmltemplate2.length > 0 ? (
                  <ul>{htmltemplate2}</ul>
                ) : (
                  <h3>No past jobs found</h3>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="pagination_box">
          <Pagination
            activePage={currentpage == 0 ? currentpage + 1 : currentpage + 1}
            itemsCountPerPage={20}
            totalItemsCount={totalactivejobs}
            pageRangeDisplayed={5}
            onChange={(e: any) => handlePageChange(e)}
          />
        </div>
      </div>

      <div className="loader-box" style={loaderStyle}>
        <div className="loader-image">
          <img src={loader_image} />
        </div>
      </div>
    </section>
  );
};

export default reduxForm({
  form: "loginForm", // a unique identifier for this form
})(Myjobs);
