import React, { useEffect, useState } from "react";
import { reduxForm } from "redux-form";
import bid_image from "../../assets/images/bid_image.png";
import chat_image from "../../assets/images/chat_image.png";
import slider_image from "../../assets/images/slider_image.png";
import { config } from "../../config/config";

interface fieldInterface {
  input: any;
  label: string;
  type: string;
  meta: {
    touched: boolean;
    error: string;
  };
}

const CompletedJobDetails = (props: any) => {
  const [loader, setLoader] = useState(0);
  const [logoutpopup, setLogoutpopup] = useState(false);

  useEffect(() => {
    //    setProfile(JSON.parse(localStorage.getItem("user") as string).image)
  }, []);

  function Logout() {
    localStorage.clear();
    window.location.href = `${config.appurl}`;
  }

  function Logoutpopup() {
    setLogoutpopup(true);
  }

  function closeModal() {
    setLogoutpopup(false);
  }

  const {
    error,
    classNameNameName,
    handleSubmit,
    pristine,
    reset,
    submitting,
  } = props;
  return (
    <div>
      <section className="preview_post_banner_area"></section>
      <section
        className="slider_preview_post "
        style={{ background: "#f6f8fa" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-1"></div>
            <div className="col-md-4 slider-box">
              <div
                id="myCarousel"
                className="carousel slide"
                data-ride="carousel"
              >
                <ol className="carousel-indicators">
                  <li
                    data-target="#myCarousel"
                    data-slide-to="0"
                    className="active"
                  ></li>
                  <li data-target="#myCarousel" data-slide-to="1"></li>
                  <li data-target="#myCarousel" data-slide-to="2"></li>
                  <li data-target="#myCarousel" data-slide-to="3"></li>
                </ol>

                <div className="carousel-inner">
                  <div className="item active">
                    <img
                      src={slider_image}
                      alt="slider_image.png"
                      style={{ width: "100%" }}
                    />
                  </div>

                  <div className="item">
                    <img
                      src={slider_image}
                      alt="slider_image.png"
                      style={{ width: "100%" }}
                    />
                  </div>

                  <div className="item">
                    <img
                      src={slider_image}
                      alt="slider_image.png"
                      style={{ width: "100%" }}
                    />
                  </div>

                  <div className="item">
                    <img
                      src={slider_image}
                      alt="slider_image.png"
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>

                <button
                  className="left carousel-control button_borderless"
                  data-slide="prev"
                >
                  <span className="glyphicon glyphicon-chevron-left"></span>
                  <span className="sr-only">Previous</span>
                </button>
                <button
                  className="right carousel-control button_borderless"
                  data-slide="next"
                >
                  <span className="glyphicon glyphicon-chevron-right"></span>
                  <span className="sr-only">Next</span>
                </button>
              </div>
              <div className="all_bid_box hire_applicant">
                <div className="heading">
                  <h1>Hired By</h1>
                </div>
                <div className="all_bid_content">
                  <ul>
                    <li>
                      <div className="content-box">
                        <img src={bid_image} alt="bid_image" />
                        <h1>Ronan Thomas</h1>
                        <p>
                          4.4 <i className="fa fa-star" aria-hidden="true"></i>{" "}
                          <button className="button_borderless">
                            (202 reviews)
                          </button>
                        </p>
                        <button className="chat_link button_borderless">
                          <img
                            src={chat_image}
                            alt="chat_image"
                            className="chat_image"
                          />
                        </button>
                      </div>
                      <div className="clear"></div>
                    </li>
                  </ul>
                </div>
                <div className="progress_bottom job_completed">
                  <p>This job has been completed</p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="slider_right_content">
                <h2 className="posted">
                  Posted <span>: 1 day ago</span>
                </h2>
                <h1>Need a Expert for plumbing work in whole house.</h1>
                <button className="view_job_details button_borderless">
                  View Job Details
                </button>
                <div className="bid_message_content">
                  <ul>
                    <li>
                      <div className="message">
                        <h4>Message</h4>
                        <p>
                          It is a long established fact that a reader will be
                          distracted by readable content of a page when looking
                          at its layout. The point of using Lorem Ipsum is that
                          it has a less normal distribution of letters, as
                          opposed to using Content here, content here', making
                          it look like readable English.
                        </p>
                        <p>
                          All the Lorem Ipsum generators on the Internet tend to
                          repeat predefined chunks as necessary, making this the
                          first true generator on the Internet. It uses a
                          dictionary of over 200 Latin words, combined with a
                          handful model sentence structures, generate Lorem
                          Ipsum which reasonable. The generated Lorem Ipsum is
                          therefore always free from repetition, injected
                          humour, or non characteristic words etc.
                        </p>
                        <p>
                          The first line of Lorem Ipsum, "Lorem ipsum dolor
                          sit", comes from a line in section 1.10.32.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="message mileston">
                        <h4>Stages</h4>
                        <div className="mileston_list">
                          <span className="list_span">1</span>
                          <p>
                            Plumbing work in Kitchen Area. <span>$100</span>
                          </p>
                          <p>
                            It is a long established fact that a reader will be
                            distracted by readable content of a page when
                            looking at its layout.
                          </p>
                          <p className="green-text">
                            Payment paid to your service provider on 10 Feb.
                          </p>
                        </div>
                        <div className="mileston_list">
                          <span className="list_span">2</span>
                          <p>
                            Plumbing work in Rooms. <span>$500</span>
                          </p>
                          <p>
                            All the Lorem Ipsum generators on the Internet tend
                            to repeat predefined chunks as necessary, making
                            this the first true generator on the Internet.
                          </p>
                          <p className="green-text">
                            Payment paid to your service provider on 12 Feb.
                          </p>
                        </div>
                        <div className="mileston_list">
                          <span className="list_span">3</span>
                          <p>
                            Plumbing work in Gardens and others{" "}
                            <span>$300</span>
                          </p>
                          <p>
                            It is a long established fact that a reader will be
                            distracted by readable content of a page when
                            looking at its layout.
                          </p>
                          <p className="green-text">
                            Payment paid to your service provider on 12 Feb.
                          </p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="message feed-back">
                        <h4>Feedback</h4>
                        <p className="feedback-text">
                          Your feedback to service provider.
                        </p>
                        <p className="rating-text">
                          <i className="fa fa-star" aria-hidden="true"></i>
                          <i className="fa fa-star" aria-hidden="true"></i>
                          <i className="fa fa-star" aria-hidden="true"></i>
                          <i className="fa fa-star" aria-hidden="true"></i>
                          <i className="fa fa-star" aria-hidden="true"></i>
                        </p>
                        <p>
                          It is a long established fact that a reader will be
                          distracted by readable content of a page when looking
                          at its layout.
                        </p>
                        <p className="feedback-text">
                          Service provider feedback to you.
                        </p>
                        <p className="rating-text">
                          <i className="fa fa-star" aria-hidden="true"></i>
                          <i className="fa fa-star" aria-hidden="true"></i>
                          <i className="fa fa-star" aria-hidden="true"></i>
                          <i className="fa fa-star" aria-hidden="true"></i>
                          <i className="fa fa-star" aria-hidden="true"></i>
                        </p>
                        <p>
                          It is a long established fact that a reader will be
                          distracted by readable content of a page when looking
                          at its layout.
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-1"></div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default reduxForm({
  form: "loginForm", // a unique identifier for this form
})(CompletedJobDetails);
