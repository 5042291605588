import React, { useEffect, useState } from "react";
import bid_image from "../../assets/images/bid_image.png";
import chat_image from "../../assets/images/chat_image.png";
import slider_image from "../../assets/images/slider_image.png";
import dollar_image from "../../assets/images/dollar_image.png";
import { config } from "../../config/config";
import { Field, reduxForm, SubmissionError } from "redux-form";
import { jobsService } from "../../_services";
import { userService } from "../../_services";
import StarRatings from "react-star-ratings";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { ListItem } from "@material-ui/core";
import Modal from "react-modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
interface fieldInterface {
  input: any;
  label: string;
  placeholder: string;
  type: string;
  maxLength: string;
  meta: {
    touched: boolean;
    error: string;
  };
}

const renderField = ({
  input,
  label,
  type,
  placeholder,
  maxLength,
  meta: { touched, error },
}: fieldInterface) => (
  <div className="form-group">
    {label && <label>{label}</label>}
    <div className="input_content">
      <input
        {...input}
        type={type}
        maxLength={maxLength}
        className="form-control"
        placeholder={placeholder}
      />
      {touched && error && <span style={{ color: "red" }}>{error}</span>}
    </div>
  </div>
);

const renderTextareaField = ({
  input,
  label,
  type,
  placeholder,
  maxLength,
  meta: { touched, error },
}: fieldInterface) => (
  <div className="form-group">
    <label>{label}</label>
    <div className="input_content">
      <input
        {...input}
        type={type}
        maxLength={maxLength}
        className="form-control"
        placeholder={placeholder}
      />
      {touched && error && <span style={{ color: "red" }}>{error}</span>}
    </div>
  </div>
);

const submit = (fields: any, type: String) => {
  if (type == "submitBid") {
    if (!fields["bidMessage"]) {
      throw new SubmissionError({
        bidMessage: "Bid Message field can't be empty.",
        _error: "",
      });
      toast.error(config.errorValidationMsg);
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    } else if (!fields["bidBudget"]) {
      throw new SubmissionError({
        bidBudget: "Budget field can't be empty.",
        _error: "",
      });
      toast.error(config.errorValidationMsg);
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
      // } else if (!fields['bidTitle']) {
      //     throw new SubmissionError({ bidTitle: "Title field can't be empty.", _error: '' })
      // } else if (!fields['bidDescription']) {
      //     throw new SubmissionError({ bidDescription: "Description field can't be empty.", _error: '' })
      // } else if (!fields['bidPrice']) {
      //     throw new SubmissionError({ bidPrice: "Price field can't be empty.", _error: '' })
    } else if (!/^\d+$/.test(fields["bidBudget"])) {
      throw new SubmissionError({
        bidBudget: "Budget should only contains numbers.",
        _error: "",
      });
      toast.error(config.errorValidationMsg);
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    } else {
      window.location.href = "provider_home";
    }
  }
};

const SubmitBid = (props: any) => {
  const [loader, setLoader] = useState(0);
  const [logoutpopup, setLogoutpopup] = useState(false);

  const [message, setMessage] = useState("");
  const [messageError, setMessageError] = useState("");

  const [budget, setBudget] = useState("");
  const [budgetError, setBudgetError] = useState("");

  const [title, setTitle] = useState("");
  const [titleError, setTitleError] = useState("");

  const [desc, setDesc] = useState("");
  const [descError, setDescError] = useState("");

  const urlParams = new URLSearchParams(window.location.search);
  const myParam = urlParams.get("id");
  const [jobDetail, setJobdetail] = useState() as any;
  const [milestonePrice, setMilestonePrice] = useState("");
  const [milestonePriceError, setMilestonePriceError] = useState("");
  const [loaderStyle, setLoaderstyle] = useState({ display: "none" });

  const [inputList, setInputList] = useState([
    {
      title: "",
      description: "",
      price: "",
      title_error: "",
      desc_error: "",
      priceerror: "",
      numbererror: "",
      order: 1,
    },
  ]);
  const invitation_id = urlParams.get("invite");
  const [reviewModal, setReviewModal] = useState(false);
  const [reviewAry, setReviewAry] = useState("") as any;
  useEffect(() => {
    getData();
  }, []);

  function getData() {
    setLoaderstyle({ display: "block" });
    jobsService
      .getJob(myParam as string)
      .then(function (response) {
        setLoaderstyle({ display: "none" });
        console.log("response.data.job", response.data.job);
        setJobdetail(response.data.job);
        let isbid: any = false;
        isbid = is_bid(response.data.job);
        if (isbid) {
          alert("Already bid Submitted");
          window.location.href = "job_details?id=" + myParam;
        }
      })
      .catch(function (error) {
        setLoaderstyle({ display: "none" });
        userService.handleError(error);
      });
  }

  const customStyles1 = {
    overlay: {
      display: "block",
      paddingRight: "15px",
      backgroundColor: "rgba(51,51,51,0.8)",
      zIndex: 99,
    },

    content: {
      position: "",
      top: "",
      right: "",
      bottom: "",
      left: "",
    },
  };

  const downloadFile = (url) => {
    console.log(url);
    const fileUrl = `https://tendr.live:8443/attachments/${url}`;
    const anchor = document.createElement("a");
    anchor.href = fileUrl;
    anchor.download = `${url}`; // Set the desired file name

    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };

  function renderReviewModal(user_id) {
    setLoaderstyle({ display: "block" });
    userService
      .userReviews(user_id)
      .then(function (response) {
        setLoaderstyle({ display: "none" });
        console.log("reviewsss", response.data);
        setReviewAry(response.data);
        setReviewModal(true);
      })
      .catch(function (error) {
        setLoaderstyle({ display: "none" });
        userService.handleError(error);
      });
  }

  function is_bid(job: any) {
    let jobdetail: any = job;
    let is_present = false;
    if (jobdetail && jobdetail.bids.length > 0) {
      jobdetail.bids.map((item, index) => {
        if (
          item.user_id._id ==
          JSON.parse(localStorage.getItem("user") as string)._id
        ) {
          return (is_present = true);
        } else {
          return false;
        }
      });
    }
    return is_present;
  }

  function applyBid() {
    // let list = inputList;
    let list = [...inputList];
    let error = false;
    var i;
    for (i = 0; i < list.length; i++) {
      if (list[i]["title"] == "") {
        list[i]["title_error"] = "Please add Title";
        error = true;
      }
      if (list[i]["description"] == "") {
        list[i]["desc_error"] = "Please add Description";
        error = true;
      }
    }

    if (message.length == 0) {
      setMessageError("Bid Message field can't be empty.");
      toast.error(config.errorValidationMsg);
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    } else if (budget.length == 0) {
      setBudgetError("Budget field can't be empty.");
      toast.error(config.errorValidationMsg);
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    } else if (!/^\d+$/.test(budget)) {
      setBudgetError("Budget should only contains numbers.");
      toast.error(config.errorValidationMsg);
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    } else if (milestonePrice.length != 0 && !/^\d+$/.test(milestonePrice)) {
      setMilestonePriceError("Milestone Price should only contains numbers.");
      toast.error(config.errorValidationMsg);
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    } else if (inputList.length < 2 || inputList.length > 5) {
      alert("Add minimum two and Maximum Five milestones.");
      toast.error(config.errorValidationMsg);
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    } else if (error == true) {
      setInputList(list);
      console.log("error", list);
      toast.error(config.errorValidationMsg);
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    } else {
      setLoaderstyle({ display: "block" });
      let params = {
        bid_message: message,
        bid_price: budget,
        job_id: myParam,
        milestone_list: inputList,
      };
      console.log(inputList);

      jobsService
        .addBid(params)
        .then(function (response) {
          if (invitation_id != "") {
            const formData = new URLSearchParams();
            formData.append("invitation_id", invitation_id as string);
            formData.append("status", "ACC");
            setLoaderstyle({ display: "none" });
            alert("Bid added successfully");
            window.location.href = `/provider_jobs`;
          } else {
            setLoaderstyle({ display: "none" });
            alert("Bid added successfully");
            window.location.href = `/provider_jobs`;
          }
        })
        .catch(function (error) {
          setLoaderstyle({ display: "none" });
        });
    }
  }

  function Logout() {
    localStorage.clear();
    window.location.href = `${config.appurl}`;
  }

  function Logoutpopup() {
    setLogoutpopup(true);
  }

  function goToJobDetails() {
    window.location.href = "job_details?id=" + myParam;
  }

  function closeModal() {
    setLogoutpopup(false);
    setReviewModal(false);
  }

  function onMessageChange(event) {
    setMessage(event.target.value);
    setMessageError("");
  }
  function getFirstThreeLetters(str) {
    return str?.slice(0, 3);
  }

  function onBudgetChange(event) {
    setBudget(event.target.value);
    setBudgetError("");
    let bug_val = parseFloat(event.target.value);
    console.log("budget", event.target.value);
    console.log("item list", inputList);
    const list1 = [...inputList];
    calculate_milstone_price(bug_val, list1);
  }

  function milestoneAddRemove(milestone_type, list1) {
    let bug_val = parseFloat(budget);
    if (milestone_type == "add") {
      console.log("addingg");
      calculate_milstone_price(bug_val, list1);
    } else {
      console.log("remove");
      calculate_milstone_price(bug_val, list1);
    }
  }

  function calculate_milstone_price(bug_val, list1) {
    console.log("bug value", bug_val);
    if (isNaN(bug_val)) {
      return;
    }
    // const list1 = [...inputList]
    let inputlist_length = list1.length;
    if (inputlist_length == 1) {
      list1[0]["price"] = change_to_percentage(bug_val, 100);
      setInputList(list1);
    } else if (inputlist_length == 2) {
      list1[0]["price"] = change_to_percentage(bug_val, 35);
      list1[1]["price"] = change_to_percentage(bug_val, 65);
      setInputList(list1);
    } else if (inputlist_length == 3) {
      list1[0]["price"] = change_to_percentage(bug_val, 35);
      list1[1]["price"] = change_to_percentage(bug_val, 45);
      list1[2]["price"] = change_to_percentage(bug_val, 20);
      setInputList(list1);
    } else if (inputlist_length == 4) {
      list1[0]["price"] = change_to_percentage(bug_val, 35);
      list1[1]["price"] = change_to_percentage(bug_val, 25);
      list1[2]["price"] = change_to_percentage(bug_val, 25);
      list1[3]["price"] = change_to_percentage(bug_val, 15);
      setInputList(list1);
    } else if (inputlist_length == 5) {
      list1[0]["price"] = change_to_percentage(bug_val, 35);
      list1[1]["price"] = change_to_percentage(bug_val, 20);
      list1[2]["price"] = change_to_percentage(bug_val, 20);
      list1[3]["price"] = change_to_percentage(bug_val, 20);
      list1[4]["price"] = change_to_percentage(bug_val, 5);
      setInputList(list1);
    } else {
      alert("some thing went wrong");
    }
  }

  function change_to_percentage(val, per) {
    return ((per / 100) * val).toFixed(2).toString();
  }

  function onTitleChange(event) {
    setTitle(
      title.length == 0
        ? event.target.value.replace(/ /g, "")
        : event.target.value
    );
    setTitleError("");
  }

  function onDescChange(event) {
    setDesc(
      desc.length == 0
        ? event.target.value.replace(/ /g, "")
        : event.target.value
    );
    setDescError("");
  }

  function onMilestonePriceChange(event) {
    setMilestonePrice(
      milestonePrice.length == 0
        ? event.target.value.replace(/ /g, "")
        : event.target.value
    );
    setMilestonePriceError("");
  }

  function calculate_milestone_price() {
    setTimeout(function () {
      let tmp_array: any = [];
      tmp_array = inputList;

      console.log(tmp_array);
    }, 100);
  }
  function open_user_detail_page(job_id: any, user_id: any) {
    window.location.href = "/customerjobdetail/" + job_id + "/" + user_id;
  }

  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;

    if (e.target.name == "price") {
      if (e.target.value == "") {
        list[index]["priceerror"] = "Please fill this field.";
        setInputList(list);
      } else if (e.target.value.length != 0 && !/^\d+$/.test(e.target.value)) {
        setMilestonePrice(e.target.value);
        setMilestonePriceError("Milestone Price should only contains numbers.");
        list[index]["numbererror"] =
          "Milestone Price should only contains numbers.";
      } else {
        setMilestonePrice(e.target.value);
        list[index]["priceerror"] = "";
        list[index]["numbererror"] = "";
        setInputList(list);
      }
    } else {
      setMilestonePriceError("");
      setInputList(list);
    }

    if (e.target.name == "title" && e.target.value.length > 100) {
      list[index]["title_error"] = "Please enter less than 100 characters.";
    } else {
      list[index]["title_error"] = "";
      setInputList(list);
    }
    if (e.target.name == "description" && e.target.value.length > 1000) {
      list[index]["desc_error"] = "Please enter less than 1000 characters.";
    } else {
      list[index]["desc_error"] = "";
      setInputList(list);
    }
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
    var i;
    for (i = 0; i < list.length; i++) {
      list[i]["order"] = i + 1;
    }

    calculate_milestone_price();
    milestoneAddRemove("remove", list);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    const list = [
      ...inputList,
      {
        title: "",
        description: "",
        price: "",
        title_error: "",
        desc_error: "",
        priceerror: "",
        numbererror: "",
        order: inputList.length + 1,
      },
    ];

    //   setInputList([...inputList, { title: "", description: "" , price: "", title_error: "",desc_error: "", priceerror:"", numbererror:""}]);
    setInputList(list);
    calculate_milestone_price();
    milestoneAddRemove("add", list);
  };

  const {
    error,
    classNameNameName,
    handleSubmit,
    pristine,
    reset,
    submitting,
  } = props;
  return (
    <div>
      <ToastContainer autoClose={10000} />
      {jobDetail && jobDetail.job_images.length > 0 ? (
        <section
          className="preview_post_banner_area"
          style={{
            backgroundImage: `url(${jobDetail.job_images[0].job_image})`,
          }}
        ></section>
      ) : null}
      <section className="slider_preview_post">
        <div className="container">
          <div className="row">
            <div className="col-md-1"></div>
            <div
              className={
                jobDetail && jobDetail.job_images.length > 0
                  ? "col-md-5 slider-box"
                  : "col-md-5 slider-box without_image"
              }
            >
              {jobDetail && jobDetail.job_images.length > 0 ? (
                <Carousel autoPlay>
                  {jobDetail.job_images.map((item, index) => {
                    return (
                      <div>
                        <img
                          src={item.job_image}
                          alt="slider_image.png"
                          style={{ width: "100%" }}
                        />
                      </div>
                    );
                  })}
                </Carousel>
              ) : null}
              <div className="all_bid_box">
                <div className="heading">
                  <h1>About Customer </h1>
                </div>
                {jobDetail && (
                  <div className="all_bid_content">
                    <ul>
                      <li>
                        <div className="content-box job-detial-content-box new-job-details no_chat">
                          <div className="left-side-content job-detils-left-box">
                            <img
                              src={
                                jobDetail
                                  ? jobDetail.user_id.profile_image
                                  : bid_image
                              }
                              alt="bid_image"
                            />
                            <h1
                              className="cursor_pointer"
                              onClick={() =>
                                open_user_detail_page(
                                  jobDetail._id,
                                  jobDetail.user_id._id
                                )
                              }
                            >
                              {jobDetail.user_id.name}
                            </h1>
                            <p>
                              {" "}
                              <StarRatings
                                rating={jobDetail.user_id.avgrating}
                                starRatedColor="#f0bf0c"
                                starDimension="14px"
                                starSpacing="1px"
                                starEmptyColor="#cbd3e4"
                                numberOfStars={5}
                                name="rating"
                              />{" "}
                              <button
                                onClick={() =>
                                  renderReviewModal(jobDetail.user_id._id)
                                }
                                className="button_borderless"
                              >
                                ({jobDetail.user_id.reviews}{" "}
                                {jobDetail.user_id.reviews < 2
                                  ? "review"
                                  : "reviews"}{" "}
                                )
                              </button>
                            </p>
                          </div>
                          <div className="left-side-content text-center">
                            <h1>
                              <span className="job-posted">Jobs Posted</span>
                            </h1>
                            <p>
                              <span className="job-posted-10">
                                {jobDetail.jobs_posted}
                              </span>
                            </p>
                          </div>
                        </div>
                        <div className="clear"></div>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
              <div className="all_bid_box">
                <div className="slider_right_content">
                  <h3 className="heading">Details</h3>
                  <div className="all_bid_content">
                    <ul>
                      <li>
                        <p>
                          Service Category
                          <span>{jobDetail?.category.name}</span>
                        </p>
                      </li>
                      <li>
                        <p>
                          Start Date & Time
                          <span>
                            {jobDetail?.start_date} &{jobDetail?.start_time}
                          </span>
                        </p>
                      </li>
                      <li>
                        <p>
                          End Date & Time
                          <span>
                            {jobDetail?.end_date} & {jobDetail?.end_time}
                          </span>
                        </p>
                      </li>
                      <li>
                        <p>
                          Budget<span>${jobDetail?.budget}</span>
                        </p>
                      </li>
                      <li className="location-job">
                        <p className="confirm_post_location">
                          {jobDetail?.isShowLocation
                            ? "Location"
                            : "Postal Code"}
                          <span>
                            {jobDetail?.isShowLocation
                              ? jobDetail?.location
                              : getFirstThreeLetters(jobDetail?.city)}
                          </span>
                        </p>
                      </li>
                      <li>
                        <p>
                          Bids
                          <span>{jobDetail?.bids.length}</span>
                        </p>
                      </li>
                      <li style={{ paddingBottom: 16 }}>
                        <p className="description">Description</p>
                        <p className="description_content">
                          {jobDetail?.description}
                        </p>
                      </li>
                      {jobDetail?.attachment != "null" ? (
                        <li>
                          <p>
                            Attachment
                            <span>
                              {jobDetail?.attachment && (
                                <div>
                                  <button
                                    onClick={() =>
                                      downloadFile(
                                        jobDetail.attachment?.split("/").pop()
                                      )
                                    }
                                  >
                                    {jobDetail.attachment?.split("/").pop()}
                                  </button>
                                </div>
                              )}
                              {/* {jobDetail?.attachment.map((item, index) => {
                              // console.log("item", item);
                              return (
                                <div key={index}>
                                  <a href={item} target="_blank">
                                    {item?.split("/").pop()}
                                  </a>
                                </div>
                              );
                            })} */}
                            </span>
                          </p>
                        </li>
                      ) : null}
                    </ul>
                  </div>
                </div>
              </div>
              {/* <div className="job-details-button-box">
                                <div className="main_btn">
                                    <button onClick={applyBid}>Submit Bid</button>
                                </div>
                            </div> */}
            </div>
            <div className="col-md-6">
              <div className="slider_right_content submit_bid">
                {jobDetail && (
                  <h2 className="posted">
                    Posted <span>: {jobDetail.time_ago}</span>
                  </h2>
                )}
                {jobDetail && <h1>{jobDetail.title}</h1>}
                <button
                  className="view_job_details button_borderless"
                  onClick={goToJobDetails}
                >
                  View Job Details
                </button>

                <div className="submit-bid_form-box">
                  <h1>Submit Bid</h1>
                  <form
                    onSubmit={handleSubmit((val: any) =>
                      submit(val, "submitBid")
                    )}
                  >
                    <div className="form_input_box">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">MESSAGE</label>
                        <textarea
                          placeholder="Bid Messages"
                          onChange={onMessageChange}
                          value={message}
                          className="bid-message-textarea"
                        ></textarea>
                        {/* <Field
                                                    name="bidMessage"
                                                    type="text"
                                                    // maxLength={200}
                                                    component={renderTextareaField}
                                                    label="MESSAGE"
                                                    placeholder="Bid Message"
                                                /> */}
                        {messageError.length > 0 && (
                          <span style={{ color: "red" }}>{messageError}</span>
                        )}
                      </div>
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">BUDGET</label>
                        <div className="input_box">
                          <input
                            type="text"
                            maxLength={7}
                            value={budget}
                            onChange={onBudgetChange}
                            className="form-control"
                            placeholder="Offer Maxium Budget"
                          />
                          {/* <Field
                                                        name="bidBudget"
                                                        type="text"
                                                        maxLength={7}
                                                        component={renderField}
                                                        label="BUDGET"
                                                        placeholder="Offer Maxium Budget"
                                                    /> */}
                          <img src={dollar_image} alt="dollar_image" />
                        </div>
                        {budgetError.length > 0 && (
                          <span style={{ color: "red" }}>{budgetError}</span>
                        )}
                        <p className="commision-area-box">
                          {/* <b>10% commission will be deducted from this total.</b> */}
                          {jobDetail && (
                            <b>
                              {jobDetail.commission ? jobDetail.commission : 10}
                              % Service Fee will be deducted from this total.
                            </b>
                          )}
                        </p>
                      </div>
                      <h1>Stage (Mandatory)</h1>
                      <span>Add minimum two and maximum five of stages</span>
                      {inputList.map((x, i) => {
                        return (
                          <div className="milestone_add_box">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">TITLE</label>
                              <input
                                name="title"
                                value={x.title}
                                className="form-control"
                                placeholder="Title"
                                onChange={(e) => handleInputChange(e, i)}
                              />
                              {inputList && inputList[i].title_error && (
                                <span style={{ color: "red" }}>
                                  {inputList[i].title_error}
                                </span>
                              )}
                            </div>
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">
                                DESCRIPTION
                              </label>
                              <textarea
                                maxLength={300}
                                onChange={(e) => handleInputChange(e, i)}
                                value={x.description}
                                placeholder="Description"
                                name="description"
                              ></textarea>
                              {/* <input
                                                    className="form-control"
                                                    name="description"
                                                    placeholder="Description"
                                                    value={x.description}
                                                    onChange={e => handleInputChange(e, i)}
                                                    /> */}
                              {inputList && inputList[i].desc_error && (
                                <span style={{ color: "red" }}>
                                  {inputList[i].desc_error}
                                </span>
                              )}
                            </div>
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">
                                STAGE PRICE
                              </label>
                              <div className="input_box">
                                <input
                                  className="form-control"
                                  name="price"
                                  placeholder="Price"
                                  readOnly={true}
                                  value={x.price}
                                  onChange={(e) => handleInputChange(e, i)}
                                />
                                <img src={dollar_image} alt="dollar_image" />
                              </div>
                              {inputList && inputList[i].numbererror && (
                                <span style={{ color: "red" }}>
                                  {inputList[i].numbererror}
                                </span>
                              )}
                            </div>
                            <div className="btn-box">
                              {inputList.length !== 1 && (
                                <button
                                  className="mr10"
                                  onClick={() => handleRemoveClick(i)}
                                >
                                  Remove
                                </button>
                              )}
                              {inputList.length - 1 === i &&
                                inputList.length < 5 && (
                                  <button onClick={handleAddClick}>Add</button>
                                )}
                            </div>
                          </div>
                        );
                      })}
                      <div className="job-details-button-box">
                        <div className="main_btn">
                          <button onClick={applyBid}>Submit Bid</button>
                        </div>
                      </div>
                      {/* <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">TITLE</label>
                                                <input type="text" value={title} onChange={onTitleChange} className="form-control" placeholder="Title" />
                                               
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">DESCRIPTION</label>
                                                <textarea placeholder="Description" onChange={onDescChange} value={desc}></textarea>
                                               
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">STAGE PRICE</label>
                                                <div className="input_box">
                                                    <input type="text" maxLength={7} onChange={onMilestonePriceChange} value={milestonePrice} className="form-control" placeholder="Price" />
                                                    
                                                    <img src={dollar_image} alt="dollar_image" />
                                                </div>
                                                {milestonePriceError.length > 0 && <span style={{ color: "red" }}>{milestonePriceError}</span>}
                                            </div> */}
                    </div>
                    {/* <div className="add-new-button-box">
                                            <a><button onClick={applyBid} className="button_borderless">Add New</button></a>
                                        </div> */}
                  </form>
                </div>
              </div>
            </div>
            <div className="col-md-1"></div>
          </div>
          <div className="loader-box" style={loaderStyle}>
            <div className="loader-image">
              <img src={require("../../assets/images/loader.gif")} />
            </div>
          </div>
        </div>
      </section>
      <Modal
        ariaHideApp={false}
        isOpen={reviewModal}
        onRequestClose={closeModal}
        className={"modal-content   new_modal_content add_new_card "}
        contentLabel="Example Modal"
        style={customStyles1}
      >
        <div className="modal-header">
          <h4 className="modal-title">
            Reviews
            <span>
              <i
                onClick={closeModal}
                className="fa fa-times-circle-o"
                aria-hidden="true"
                data-dismiss="modal"
              ></i>
            </span>{" "}
          </h4>
        </div>
        <div className="modal-body">
          <ul className="review_modal_ul">
            {reviewAry &&
              reviewAry.reviews.length > 0 &&
              reviewAry.reviews.map((item, index) => {
                return (
                  <div className="review_popup">
                    <img src={item.user_id.profile_image} />

                    <div className="review_content">
                      <h3>
                        {item.user_id.name} <span>{item.time_ago}</span>
                      </h3>
                      <StarRatings
                        rating={parseInt(item.rating)}
                        starRatedColor="#f0bf0c"
                        starDimension="20px"
                        starSpacing="1px"
                        starEmptyColor="#cbd3e4"
                        numberOfStars={5}
                        name="rating"
                      />
                      <p>{item.message}</p>
                    </div>
                  </div>
                );
              })}
          </ul>
        </div>
      </Modal>
    </div>
  );
};

export default reduxForm({
  form: "loginForm", // a unique identifier for this form
})(SubmitBid);
