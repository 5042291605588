import React, { useEffect, useState } from "react";
import { reduxForm } from "redux-form";
import bid_image from "../../assets/images/bid_image.png";
import chat_image from "../../assets/images/chat_image.png";
import slider_image from "../../assets/images/slider_image.png";
import { config } from "../../config/config";
import Modal from "react-modal";
import { jobsService } from "../../_services";
import { userService } from "../../_services";

import StarRatings from "react-star-ratings";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

interface fieldInterface {
  input: any;
  label: string;
  type: string;
  meta: {
    touched: boolean;
    error: string;
  };
}

const customStyles1 = {
  overlay: {
    display: "block",
    paddingRight: "15px",
    backgroundColor: "rgba(51,51,51,0.8)",
    zIndex: 99,
  },

  content: {
    position: "",
    top: "",
    right: "",
    bottom: "",
    left: "",
  },
};

const JobInvitationDetails = (props: any) => {
  const [loader, setLoader] = useState(0);
  const [logoutpopup, setLogoutpopup] = useState(false);
  const [jobDetail, setJobdetail] = useState() as any;
  const [loaderStyle, setLoaderstyle] = useState({ display: "none" });
  const urlParams = new URLSearchParams(window.location.search);
  const myParam = urlParams.get("id");
  const invitation_id = urlParams.get("invite");

  useEffect(() => {
    getData();
  }, []);

  function getData() {
    setLoaderstyle({ display: "block" });
    jobsService
      .getJob(myParam as string)
      .then(function (response) {
        setLoaderstyle({ display: "none" });
        setJobdetail(response.data.job);
      })
      .catch(function (error) {
        setLoaderstyle({ display: "none" });
        userService.handleError(error);
      });
  }

  function Logout() {
    localStorage.clear();
    window.location.href = `${config.appurl}`;
  }
  const [modal, setModal] = useState(false);

  function goToChat() {
    window.location.href = "chat";
  }

  function goToChatnew(job_id, user_id) {
    window.location.href = "/new_message/" + job_id + "/" + user_id;
  }

  function onInvitationRejected() {
    let result = window.confirm("Are you sure you want to reject this bid?");

    if (result) {
      inviteApi("REJ");
    }
  }
  function inviteApi(data: string) {
    setLoaderstyle({ display: "block" });
    const formData = new URLSearchParams();
    formData.append("invitation_id", invitation_id as string);
    formData.append("status", data);

    if (data == "ACC") {
      window.location.href =
        "/submit_bid?id=" + myParam + "&invitation_id=" + invitation_id;
    } else {
      jobsService
        .acceptRejectInvitation(formData.toString())
        .then(function (response) {
          if (data == "ACC") {
            alert("Invitation accepted successfully");
          } else if (data == "REJ") {
            alert("Invitation rejected successfully");
          }
          setLoaderstyle({ display: "none" });
          window.location.href = "/job_invites";
        })
        .catch(function (error) {
          setLoaderstyle({ display: "none" });
          userService.handleError(error);
        });
    }
  }

  function showModal() {
    setModal(true);
  }

  function closeModal() {
    setModal(false);
  }

  const {
    error,
    classNameNameName,
    handleSubmit,
    pristine,
    reset,
    submitting,
  } = props;
  console.log(localStorage.getItem("current_user_id"));
  const current_user_id = localStorage.getItem("current_user_id");
  const hasUserBidded = jobDetail?.bids.some(
    (bid) => bid.user_id._id === current_user_id
  );
  console.log(hasUserBidded);
  return (
    <div>
      {jobDetail && jobDetail.job_images.length > 0 ? (
        <section
          className="preview_post_banner_area"
          style={{
            backgroundImage: `url(${jobDetail.job_images[0].job_image})`,
          }}
        ></section>
      ) : (
        <section className=""></section>
      )}
      {jobDetail && (
        <section className="slider_preview_post">
          <div className="container">
            <div className="row">
              <div className="col-md-1"></div>
              <div
                className={
                  jobDetail && jobDetail.job_images.length > 0
                    ? "col-md-4 slider-box"
                    : "col-md-4 slider-box without_image"
                }
              >
                {jobDetail && jobDetail.job_images.length > 0 ? (
                  <Carousel autoPlay>
                    {jobDetail.job_images.map((item, index) => {
                      return (
                        <div>
                          <img
                            src={item.job_image}
                            alt="slider_image.png"
                            style={{ width: "100%" }}
                          />
                        </div>
                      );
                    })}
                  </Carousel>
                ) : (
                  <div></div>
                )}
                <div className="all_bid_box hire_applicant">
                  <div className="heading">
                    <h1>About Customer</h1>
                  </div>
                  <div className="all_bid_content">
                    <ul>
                      <li>
                        <div className="content-box">
                          <img
                            src={
                              jobDetail
                                ? jobDetail.user_id.profile_image
                                : bid_image
                            }
                            alt="bid_image"
                          />
                          <h1>{jobDetail.user_id.name}</h1>

                          <p>
                            <StarRatings
                              rating={jobDetail.user_id.avgrating}
                              starRatedColor="#f0bf0c"
                              starDimension="14px"
                              starSpacing="1px"
                              starEmptyColor="#cbd3e4"
                              numberOfStars={5}
                              name="rating"
                            />{" "}
                            <button className="button_borderless">
                              ({jobDetail.user_id.reviews}{" "}
                              {jobDetail.user_id.reviews < 2
                                ? "review"
                                : "reviews"}{" "}
                              )
                            </button>
                          </p>
                          <button
                            className="chat_link button_borderless"
                            onClick={() =>
                              goToChatnew(jobDetail._id, jobDetail.user_id._id)
                            }
                          >
                            <img
                              src={chat_image}
                              alt="chat_image"
                              className="chat_image"
                            />
                          </button>
                        </div>
                        <div className="clear"></div>
                      </li>
                    </ul>
                  </div>
                </div>
                {!hasUserBidded ? (
                  <div className="job-details-button-box">
                    <div className="main_btn">
                      <button onClick={() => showModal()}>
                        Accept invitation
                      </button>
                    </div>
                    <div className="main_btn border_button margin-top15">
                      <button onClick={onInvitationRejected}>Reject</button>
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="col-md-6">
                <div className="slider_right_content">
                  <h2 className="posted">
                    Posted{" "}
                    <span>
                      : {jobDetail.time_ago ? jobDetail.time_ago : "1 day ago"}
                    </span>
                  </h2>
                  <h1>{jobDetail.title}</h1>
                  <ul>
                    <li>
                      <p>
                        Service Category<span>{jobDetail.category.name}</span>
                      </p>
                    </li>
                    <li>
                      <p>
                        Start Date & Time
                        <span>
                          {jobDetail.start_date} &{jobDetail.start_time}
                        </span>
                      </p>
                    </li>
                    <li>
                      <p>
                        End Date & Time
                        <span>
                          {jobDetail.end_date} & {jobDetail.end_time}
                        </span>
                      </p>
                    </li>
                    <li>
                      <p>
                        Budget<span>${jobDetail.budget}</span>
                      </p>
                    </li>
                    <li className="location-job">
                      <p>
                        Location<span>{jobDetail.location}</span>
                      </p>
                    </li>
                    <li>
                      <p className="description">Description</p>
                      <p className="description_content">
                        {jobDetail.description}
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-1"></div>
            </div>
          </div>
        </section>
      )}
      {jobDetail && (
        <Modal
          ariaHideApp={false}
          isOpen={modal}
          onRequestClose={closeModal}
          className="modal-content  new_modal_content login_modal accept_invitaion-modal"
          contentLabel="Example Modal"
          style={customStyles1}
        >
          {jobDetail && (
            <div className="modal-content new_modal_content">
              <div className="modal-body">
                <div className="accept_invitation-modal_box">
                  <h1>
                    Are you sure you want to accept an invitation from{" "}
                    <span>“{jobDetail.user_id.name}”</span>.
                  </h1>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="next_bottom">
                      <div className="main_btn">
                        <button onClick={() => inviteApi("ACC")}>
                          Yes, Sure
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="next_bottom">
                      <div className="main_btn border_button">
                        <button onClick={() => closeModal()}>Not Now</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Modal>
      )}
      <div className="loader-box" style={loaderStyle}>
        <div className="loader-image">
          <img src={require("../../assets/images/loader.gif")} />
        </div>
      </div>
    </div>
  );
};

export default reduxForm({
  form: "loginForm", // a unique identifier for this form
})(JobInvitationDetails);
