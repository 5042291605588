export const config = {
  // apiUrl: "http://35.182.42.102:5000/api/v1",
  // apiUrl: "http://15.222.255.124:5000/api/v1",
  apiUrl: "https://tendr.live:8443/api/v1", //Use this one for production
  // apiUrl: "http://localhost:5000/api/v1", // Use this one for local
  // apiUrl: "https://tendrdev.herokuapp.com/api/v1",
  apiBaseUrl: "https://api.tendr.live/",
  tempapiUrl: "http://3.1.33.205:8762/LinkodesCardManagement/v1/api",

  //  appurl : "http://198.211.110.165:81",
  appurl: "https://www.tendr.live",
  appurlDev: "http://localhost:3000",

  // appurl : "http://198.211.110.165:81",
  // appurl : "http://localhost:3001",

  //  google_clientid :"793530926533-2t2f593mnn0tk402r3clkq0tmqtcm9ag.apps.googleusercontent.com",
  google_clientid:
    "522963890633-6fqmn12r2ishatd815c418p4l3sqi8qd.apps.googleusercontent.com",
  //  fb_appId :"1270563019800996",
  fb_appId: "223663352319055",
  //  linkedin :"86cw5a3ifecvqz",
  //  linkedin_secret :"hFvfMgSKlzTWdQzk",
  // linkedin :"86d2pi471t3di0",
  //  linkedin_secret :"IN0jsK2ovvgjGAC4G",
  linkedin: "866bkeodv0vlb9",
  linkedin_secret: "j8AQoqUpQcjfkgns",
  errorValidationMsg: "Please check the validation errors",
  perImageFileSize: 3558180,
};
