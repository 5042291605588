export function authHeader() {
 
    // return authorization header with basic auth credentials
    let user = localStorage.getItem('access_token')
    let unique_device_id = localStorage.getItem('unique_device_id')
    if(unique_device_id == undefined){
        generate_unique_device_id()
    }
    function generate_unique_device_id(){
        console.log("generate_unique_device_id");
        let tmp = Math.random().toString(36).slice(2);
        unique_device_id = tmp;
        localStorage.setItem('unique_device_id',tmp)
    }

    if (user && user) {
        return {  'deviceType':'w','appVersion' : '1.0',   "Content-Type": "application/x-www-form-urlencoded",'Access-Control-Allow-Origin': '*', 'token': user , 'device' : unique_device_id };
    } else {
        return {};
    }
}