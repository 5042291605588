import React from 'react';

import footer_logo from '../../assets/images/footer_logo.png'
import {config} from '../../config/config'
const Footer = () => {
    
    return (
    <footer>
        <div className="footer">
            <div className="container">
                <div className="content_box">
                    <div className="row">
                        <div className="col-sm-4 left_content">
                            <div className="footer_content_logo_side">
                                <a href="/"><img src={footer_logo} alt="footer_logo"/></a>
                                
                            </div>
                        </div>
                        {/* <div className="col-sm-2 social_links ">
                            <div className="footer_content">
                                <h4>Social Links</h4>
                                <p><a href=""><i className="fa fa-facebook" aria-hidden="true"></i>Facebook</a></p>
                                <p><a href=""><i className="fa fa-twitter" aria-hidden="true"></i>Twitter</a></p>                      
                                <p><a href=""><i className="fa fa-linkedin" aria-hidden="true"></i>Linkedin</a></p>
                            </div>
                        </div> */}
                        {localStorage.getItem('access_token') == null && 
                        <div className="col-sm-4 links_box">
                            <div className="footer_content Quick-links-list">
                                <h4>Quick Links</h4>                                
                                <p><a href="">How It Works</a></p>
                                <p><a href={`${config.apiBaseUrl}/privacy/privacy_policy.html`} target="_blank" >Privacy Policy </a></p>
                                <p><a href="">About Us</a></p>
                                <p><a href="">Login</a></p>
                            </div>
                        </div>
                        }
                        <div className="col-sm-4 contact_box">
                            <div className="footer_content">
                                <h4>Contact Info</h4>
                                <p><span><i className="fa fa-map-marker" aria-hidden="true"></i></span>Vancouver, BC, Canada</p>
                                <p><i className="fa fa-envelope" aria-hidden="true"></i>support@tendr.live</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="footer_copy_right text-center">
            <p><i className="fa fa-copyright" aria-hidden="true"></i> Copyright 2020. All rights reserved.</p>
        </div>

    </footer>
  
    )
}

export default Footer