import React, { useEffect, useState } from "react";
import { reduxForm } from "redux-form";
import calender_image from "../../assets/images/calender_image.png";
import fillter from "../../assets/images/fillter-image.png";
import left_arrow from "../../assets/images/left_arrow.png";
import right_arrow from "../../assets/images/right_arrow.png";
import { config } from "../../config/config";
import { jobsService } from "../../_services";
import { userService } from "../../_services";

import Pagination from "react-js-pagination";
import BackUpImage from "../common/BackUpImage";

interface fieldInterface {
  input: any;
  label: string;
  type: string;
  meta: {
    touched: boolean;
    error: string;
  };
}

const JobInvites = (props: any) => {
  const [loader, setLoader] = useState(0);
  const [logoutpopup, setLogoutpopup] = useState(false);
  const [currentpage, setCurrentpage] = useState(0);
  const [jobs, setJobs] = useState([]);
  const [totaljobs, setTotalJobs] = useState(0);
  const [loaderStyle, setLoaderstyle] = useState({ display: "none" });

  useEffect(() => {
    getData(currentpage);
  }, []);

  function getData(page: number) {
    setLoaderstyle({ display: "block" });
    jobsService
      .getInvitations(page)
      .then(function (response) {
        setLoaderstyle({ display: "none" });
        setJobs(response.data.jobs);
        setTotalJobs(response.data.perPageRecords * response.data.totalPages);
      })
      .catch(function (error) {
        setLoaderstyle({ display: "none" });
        userService.handleError(error);
      });
  }

  function Logout() {
    localStorage.clear();
    window.location.href = `${config.appurl}`;
  }

  function handlePageChange(pagenumber: any) {
    setCurrentpage(pagenumber - 1);
    getData(pagenumber - 1);
  }

  function goToInviteDetails(item: any) {
    window.location.href =
      "job_invitation_details?id=" + item._id + "&invite=" + item.invitation_id;
  }

  function Logoutpopup() {
    setLogoutpopup(true);
  }

  function closeModal() {
    setLogoutpopup(false);
  }

  function identifyString(s) {
    // Check if the string is only numbers
    if (/^[0-9]+$/.test(s)) {
      return "$";
    }
    // Check if the string is only letters
    else if (/^[A-Za-z]+$/.test(s)) {
      return "";
    }
    // For strings containing both or neither
    else {
      return "";
    }
  }

  const { error, className, handleSubmit, pristine, reset, submitting } = props;
  return (
    <section className="top-services-area top-service-provider-area">
      <div className="container">
        <div className="top_servies_heading job-invitation-heading">
          <div className="left_content job_invitation_left_content">
            <h1>Job Invitation ({jobs.length})</h1>
          </div>
          <div className="clear"></div>
        </div>
        <div className="my_job_content my-job-content-two">
          <ul>
            {jobs.length == 0 && (
              <li className="tab_content_box">
                <h1>No Invitation Found</h1>
              </li>
            )}
            {jobs.length > 0 &&
              jobs.map((item: any, index1) => {
                return (
                  <>
                    <li
                      className="tab_content_box"
                      style={{ display: "flex", flexDirection: "row" }}
                    >
                      {item.job_images.length > 0 ? (
                        <div
                          style={{
                            borderRadius: "20px",
                            backgroundColor: "rgba(0,0,0,0)",
                          }}
                        >
                          <div
                            style={{
                              height: "200px",
                              width: "300px",
                              borderRadius: "7.5%",
                              backgroundColor: "#7aa2f3",
                              overflow: "hidden",
                            }}
                          >
                            <img
                              src={item.job_images[0].job_image}
                              alt="slider_image.png"
                              style={{ width: "100%" }}
                            />
                          </div>
                        </div>
                      ) : (
                        <BackUpImage height="200" width="300" />
                      )}
                      <a
                        onClick={() => goToInviteDetails(item)}
                        style={{ textDecoration: "none" }}
                      >
                        <div style={{ width: "100%" }}>
                          <h5 style={{ paddingLeft: "16px" }}>
                            <span
                              style={{
                                fontWeight: 800,
                                fontSize: "1.4em",
                                paddingTop: "10px",
                              }}
                            >
                              {" "}
                              {/* {item.title} */}
                              {(item as any).title
                                ? (item as any).title.slice(0, 80) +
                                  ((item as any).title.length > 80 ? "..." : "")
                                : ""}
                            </span>
                            <br />
                            <span
                              style={{
                                fontWeight: 800,
                                fontSize: "1.1em",
                                color: "#7AA2F3",
                              }}
                            >
                              {item.category.name}
                            </span>
                            <br />

                            <span style={{ fontSize: "1.1em" }}>
                              <span style={{ fontWeight: 800 }}>
                                Start Date:
                              </span>
                              {item.start_date} @{item.start_time}
                            </span>
                            <br />
                            <span style={{ fontSize: "1.1em" }}>
                              <span style={{ fontWeight: 800 }}>Posted:</span>
                              {item.time_ago}
                            </span>
                            <br />
                            <span style={{ fontSize: "1.1em" }}>
                              <span style={{ fontWeight: 800 }}>Budget:</span>
                              <span className="color_blue_text">
                                {" "}
                                {identifyString(item.budget)}
                                {item.budget}
                              </span>
                            </span>
                            <br />
                            <span
                              style={{
                                fontWeight: 800,
                                fontSize: "1.1em",

                                color: "#585858",
                                textDecoration: "none",
                              }}
                            >
                              Description:{" "}
                            </span>
                            <br />
                            <span>{item.description}</span>
                            <br />
                          </h5>
                        </div>
                      </a>
                    </li>
                  </>
                );
              })}
          </ul>
        </div>
        {jobs.length > 0 && (
          <div className="pagination_box">
            <Pagination
              activePage={currentpage == 0 ? currentpage + 1 : currentpage + 1}
              itemsCountPerPage={20}
              totalItemsCount={totaljobs}
              pageRangeDisplayed={5}
              onChange={(e: any) => handlePageChange(e)}
            />
          </div>
        )}
      </div>
    </section>
  );
};

export default reduxForm({
  form: "loginForm", // a unique identifier for this form
})(JobInvites);
