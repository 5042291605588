import React, { useEffect, useState } from "react";
import { reduxForm } from "redux-form";
import { userService } from "../../_services";
const Notifications = (props: any) => {
  const [notificationAry, setnotificationAry] = useState("") as any;

  useEffect(() => {
    getData();
    // setProfile(JSON.parse(localStorage.getItem("user") as string).image)
  }, []);

  function getData() {
    console.log("getdata");

    userService
      .getNotification()
      .then(function (response) {
        console.log("notification response", response.data);
        console.log("notification response", response.data.notiifcations);
        setnotificationAry(response.data);
      })
      .catch(function (error) {
        console.log("error in notification", error);
        userService.handleError(error);
      });
  }

  function handleNotification(notification) {
    userService
      .markReadNotification(notification._id)
      .then(function (response) {
        console.log("notification read", response.data);
        getData();
        if (
          notification.notification_type == "payment_request" ||
          notification.notification_type == "payment_accept" ||
          notification.notification_type == "payment_reject" ||
          notification.notification_type == "payment_released" ||
          notification.notification_type == "job_start_before" ||
          notification.notification_type == "job_start" ||
          notification.notification_type == "hired"
        ) {
          window.location.href = "/job_in_progress/" + notification.job_id._id;
        } else if (
          notification.notification_type == "offer_send" ||
          notification.notification_type == "offer_accept" ||
          notification.notification_type == "offer_reject"
        ) {
          window.location.href =
            "/new_message/" +
            notification.job_id._id +
            "/" +
            notification.receiverId;
        } else if (
          notification.notification_type == "invitation_send" ||
          notification.notification_type == "invitation_reject" ||
          notification.notification_type == "invitation_accept"
        ) {
          window.location.href = "/job_details?id=/" + notification.job_id._id;
        } else {
          window.location.href = "/job_details?id=/" + notification.job_id._id;
        }
      })
      .catch(function (error) {
        console.log("error in notification", error);
        userService.handleError(error);
      });
  }
  return (
    <section className="notification-content">
      <div className="container">
        <div className="notification_content-box">
          <h1>Notifications </h1>
          <ul className="notification_page_list">
            {notificationAry &&
              notificationAry.notiifcations.map((item, index) => {
                console.log("item", item);
                return (
                  <a
                    onClick={() => handleNotification(item)}
                    style={{ textDecoration: "none" }}
                  >
                    <li className="cursor_pointer" style={{ display: "flex" }}>
                      <img
                        src={
                          "https://tendr.live:8443/images/profile/1676517825778_profile.jpg"
                        }
                        alt="user"
                        height={50}
                        width={50}
                        style={{ borderRadius: "50%" }}
                      />
                      <div>
                        <h2
                          style={{
                            textTransform: "capitalize",
                            fontWeight: item.read_status == "R" ? 500 : 800,
                          }}
                        >
                          {item.body}{" "}
                        </h2>
                        <p>{item.time_ago}</p>
                      </div>
                    </li>
                  </a>
                );
              })}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default reduxForm({
  form: "loginForm", // a unique identifier for this form
})(Notifications);
