import React from "react";
import PropTypes from "prop-types";

import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { connect } from "react-redux";
import { userService } from "../../_services";

const PaymentComponent = ({
  clientSecret,
  profile,
  options,
  nameHandler,
  setLoaderstyle,
  id,
  closemodal,
  getcards,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const handleSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }
    console.log({ options });
    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);

    // Use your card Element with other Stripe.js APIs
    // const res = await stripe.confirmCardSetup
    // const response = await stripe.createToken(cardElement);
    if (cardElement) {
      setLoaderstyle({ display: "block" });
      await stripe
        .confirmCardSetup(options.clientSecret, {
          payment_method: {
            card: cardElement,
            billing_details: {
              name: `${nameHandler}`,
            },
          },
        })
        .then((result) => {
          if (result.error) {
            console.log("error log:", result.error.doc_url);
            setLoaderstyle({ display: "none" });
            getcards();
            closemodal();
          }
          if (result) {
            console.log(result);
            userService
              .saveCard(result?.setupIntent?.payment_method)
              .then((res) => {
                console.log("result log:", result.setupIntent);
                setLoaderstyle({ display: "none" });
                getcards();
                closemodal();
              })
              .catch((err) => {
                console.log("error in save card", err.message);
              });
          }
        });
    }
  };
  return (
    <form
      onSubmit={handleSubmit}
      style={{
        background: "#fff",
        width: "350px",
        padding: "20px",
        borderRadius: "5px",
      }}
    >
      <CardElement />
      <div className="next_bottom">
        <div className="main_btn">
          <button type="submit">Submit Card Info</button>
        </div>
      </div>
    </form>
  );
};
const mapStateToProps = (state) => ({
  profile: state.profile,
});

export default connect(mapStateToProps)(PaymentComponent);
