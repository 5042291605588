import { authHeader } from "../_helpers";
import { config } from "../config/config";
import axios from "axios";
export const jobsService = {
  getProviderhome,
  getJob,
  getActiveJobs,

  getMybids,
  getHiredJobs,
  getHistoryJobs,
  getInvitations,
  acceptRejectInvitation,
  addBid,
  getUpcomingJobs,
  getPastJobs,
  deleteJobs,
  edit_job,
  bidDetail,
  acceptRejectBid,
  sendInvite,
  jobInvitationProviders,
  addConnection,
  connectionList,
  bidChatDetail,
  makeOffer,
  milestones,
  acceptRejOffer,
  giveReview,
  giveReviewByWorker,
  getReview,
  cancelJob,
  providerNotification,
  milestonePayment,
  providerSelectedCategories,
  clearBadgeChat,
  approveMilestonePayment,
  customerPriceDetail,
  providerPricingDetail,
  deleteConnection,
};
var configheaders: any = {
  headers: authHeader(),
};

function providerSelectedCategories() {
  // https://api.tendr.live/api/v1/categories/categories_subcategories_user
  let url = `${config.apiUrl}/categories/categories_subcategories_user`;
  configheaders["headers"]["timezone"] = "Asia/kolkata";
  return axios.get(url, configheaders);
}

function getProviderhome(params: any) {
  let url = `${config.apiUrl}/jobs/home/worker`;
  configheaders["headers"]["Content-Type"] = "application/json";
  return axios.post(url, params, configheaders);
}

function getJob(id: string) {
  let url = `${config.apiUrl}/jobs/${id}`;
  configheaders["headers"]["timezone"] = "Asia/kolkata";
  return axios.get(url, configheaders);
}

function getActiveJobs(page: any) {
  let url = `${config.apiUrl}/jobs/active/${page}`;
  configheaders["headers"]["timezone"] = "Asia/kolkata";
  return axios.get(url, configheaders);
}

function jobInvitationProviders(job_id: any, lat: any, lng: any) {
  let url = `${config.apiUrl}/invitations/invitees/${job_id}/${lat}/${lng}`;
  configheaders["headers"]["timezone"] = "Asia/kolkata";
  return axios.get(url, configheaders);
}

function getMybids(page: number) {
  let url = `${config.apiUrl}/bids/mybids/${page}`;
  configheaders["headers"]["timezone"] = "Asia/kolkata";
  return axios.get(url, configheaders);
}

function getHiredJobs(page: number) {
  let url = `${config.apiUrl}/bids/hired/${page}`;
  configheaders["headers"]["timezone"] = "Asia/kolkata";
  return axios.get(url, configheaders);
}

function getHistoryJobs(page: number) {
  let url = `${config.apiUrl}/bids/history/${page}`;
  configheaders["headers"]["timezone"] = "Asia/kolkata";
  return axios.get(url, configheaders);
}

function getInvitations(page: number) {
  let url = `${config.apiUrl}/invitations/${page}`;
  configheaders["headers"]["timezone"] = "Asia/kolkata";
  return axios.get(url, configheaders);
}

function acceptRejectInvitation(params: any) {
  let url = `${config.apiUrl}/invitations`;
  return axios.put(url, params, configheaders);
}

function acceptRejectBid(params: any) {
  let url = `${config.apiUrl}/bids`;
  configheaders["headers"]["Content-Type"] = "application/json";
  configheaders["headers"]["timezone"] = "Asia/kolkata";
  return axios.put(url, params, configheaders);
}

function addBid(params: any) {
  let url = `${config.apiUrl}/bids`;
  configheaders["headers"]["Content-Type"] = "application/json";
  configheaders["headers"]["timezone"] = "Asia/kolkata";
  return axios.post(url, params, configheaders);
}

function sendInvite(params: any) {
  let url = `${config.apiUrl}/invitations`;
  configheaders["headers"]["Content-Type"] = "application/json";
  return axios.post(url, params, configheaders);
}

function getUpcomingJobs(page: any) {
  let url = `${config.apiUrl}/jobs/upcoming/${page}`;
  configheaders["headers"]["timezone"] = "Asia/kolkata";
  return axios.get(url, configheaders);
}
function getPastJobs(page: any) {
  let url = `${config.apiUrl}/jobs/past/${page}`;
  configheaders["headers"]["timezone"] = "Asia/kolkata";
  return axios.get(url, configheaders);
}
function deleteJobs(job_id: any) {
  let url = `${config.apiUrl}/jobs/${job_id}`;
  return axios.delete(url, configheaders);
}
function edit_job(params: any, job_id: any) {
  let url = `${config.apiUrl}/jobs/` + job_id;
  configheaders["headers"]["timezone"] = "Asia/kolkata";
  return axios.put(url, params, configheaders);
  // jobs/5ea293f95169220fef02168c
}

function bidDetail(id: string) {
  let url = `${config.apiUrl}/bids/${id}`;
  configheaders["headers"]["timezone"] = "Asia/kolkata";
  return axios.get(url, configheaders);
}

function addConnection(params: any) {
  let url =
    "https://cors-anywhere.herokuapp.com/" + `${config.apiUrl}/connections`;
  configheaders["headers"]["Content-Type"] = "application/json";
  return axios.post(url, params, configheaders);
}

function providerNotification(params: any) {
  let url = `${config.apiUrl}/connections/sendMessage`;
  configheaders["headers"]["Content-Type"] = "application/json";
  return axios.post(url, params, configheaders);
}

function connectionList(params: any) {
  let url = `${config.apiUrl}/connections/connectionListWeb`;
  configheaders["headers"]["timezone"] = "Asia/kolkata";
  configheaders["headers"]["Content-Type"] = "application/json";
  return axios.post(url, params, configheaders);
}

function bidChatDetail(job_id: any, bidder_id: any, connection_id: any) {
  let url = `${config.apiUrl}/jobs/jobDetailChat/${job_id}/${bidder_id}/${connection_id}`;
  configheaders["headers"]["timezone"] = "Asia/kolkata";
  return axios.get(url, configheaders);
}

function makeOffer(params: any) {
  let url = `${config.apiUrl}/offers`;
  configheaders["headers"]["Content-Type"] = "application/json";
  return axios.post(url, params, configheaders);
}

function milestones(params: any) {
  let url = `${config.apiUrl}/milestones`;
  return axios.put(url, params, configheaders);
}

function approveMilestonePayment(params: any) {
  let url = `${config.apiUrl}/milestones/approvepayment`;
  return axios.put(url, params, configheaders);
}

function acceptRejOffer(params: any) {
  let url = `${config.apiUrl}/offers`;
  configheaders["headers"]["Content-Type"] = "application/json";
  return axios.put(url, params, configheaders);
}

function giveReview(params: any) {
  let url = `${config.apiUrl}/reviews`;
  configheaders["headers"]["Content-Type"] = "application/json";
  return axios.put(url, params, configheaders);
}

function giveReviewByWorker(params: any) {
  let url = `${config.apiUrl}/reviews`;
  configheaders["headers"]["Content-Type"] = "application/json";
  return axios.post(url, params, configheaders);
}

function getReview(job_id: any) {
  let url = `${config.apiUrl}/reviews/${job_id}`;
  configheaders["headers"]["timezone"] = "Asia/kolkata";
  return axios.get(url, configheaders);
}

function cancelJob(job_id: any) {
  let params = {};
  let url = `${config.apiUrl}/jobs/cancelJob/${job_id}`;
  configheaders["headers"]["Content-Type"] = "application/json";
  return axios.put(url, params, configheaders);
}

function milestonePayment(params: any) {
  let url = `${config.apiUrl}/milestones`;
  configheaders["headers"]["Content-Type"] = "application/json";
  return axios.put(url, params, configheaders);
}

function clearBadgeChat(params: any) {
  let url = `${config.apiUrl}/connections/clearCount`;
  configheaders["headers"]["Content-Type"] = "application/json";
  return axios.post(url, params, configheaders);
}

function customerPriceDetail(data: any) {
  console.log("data", data);
  let url = `${config.apiUrl}/stripe/customerPricingDetails/`;
  configheaders["headers"]["Content-Type"] = "application/json";
  return axios.post(url, data, configheaders);
}
function providerPricingDetail(data: any) {
  console.log("data", data);
  let url = `${config.apiUrl}/stripe/providerPricingDetails/`;
  configheaders["headers"]["Content-Type"] = "application/json";
  return axios.post(url, data, configheaders);
}

function deleteConnection(id: string) {
  let url = `${config.apiUrl}/connections/${id}`;
  return axios.delete(url, configheaders);
}
