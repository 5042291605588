
import React , {useEffect, useState} from 'react'
import { userService } from '../../_services';
import { Field, reduxForm, SubmissionError } from 'redux-form'
import { Link } from 'react-router-dom';
import {config} from '../../config/config'
import Modal from 'react-modal';

import {Layout} from '../common/Layout'
import close_image from '../../assets/images/close.png';
import profile_image from '../../assets/images/profile_logo.jpg';
import loader_image from '../../assets/images/loading.gif';

import add_new_left from '../../assets/images/add_new_left.png';
import calender_image from '../../assets/images/calender_image.png';
import left_arrow from '../../assets/images/left_arrow.png';
import right_arrow from '../../assets/images/right_arrow.png';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import bid_image from '../../assets/images/bid_image.png';
import slider_image from '../../assets/images/slider_image.png';
import chat_image from '../../assets/images/chat_image.png';
import Inviteuser from './Inviteuser';

interface fieldInterface {
   input: any;
   label: string;
   type: string;
   meta: {
       touched: boolean;
       error: string;
   };
}

const Previewpost = (props:any) => {


  useEffect(() => {
//    setProfile(JSON.parse(localStorage.getItem("user") as string).image)
 }, []);

 function Inviteusercall(){
   window.location.href="/invite_user"
 }

const { error, className, handleSubmit, pristine, reset, submitting } = props
 return (
  
<div >
<section className="preview_post_banner_area">
    </section>
   <section className="slider_preview_post">
     <div className="container">
       <div className="row">
         <div className="col-md-1">          
         </div>
         <div className="col-md-4 slider-box">
            
         <Carousel>
                <div>
                    <img src={slider_image} style={{width:"100%"}}/>
                    {/* <p className="legend">Legend 1</p> */}
                </div>
                <div>
                    <img src={slider_image} style={{width:"100%"}}/>
                    {/* <p className="legend">Legend 2</p> */}
                </div>
                <div>
                    <img src={slider_image} style={{width:"100%"}}/>
                    {/* <p className="legend">Legend 3</p> */}
                </div>
                <div>
                    <img src={slider_image} style={{width:"100%"}}/>
                    {/* <p className="legend">Legend 4</p> */}
                </div>
            </Carousel>
         <div className="pulish_post-box">
           <div className="pulish_button-box">
             <div className="main_btn">
                <a onClick={Inviteusercall}>Publish Post</a>
              </div>
              <div className="main_btn border_button">
                <a href="">Edit Post</a>
              </div>
           </div>
         </div>
         </div>
         <div className="col-md-6">
           <div className="slider_right_content">
             <h1>Need a Expert for plumbing work in whole house.</h1>
             <ul>
              <li>
                <p>Service Category<span>Plumber</span></p>
              </li>
               <li>
                <p>Start Date & Time<span>05 Feb 2020 & 11:00 AM</span></p>
              </li>
               <li>
                <p>End Date & Time<span>15 Feb 2020 & 06:00 PM</span></p>
              </li>
               <li>
                <p>Budget<span>$1000</span></p>
              </li>
              <li className="location">
                <p>Location<span>202 model town, California, <br/>USA 19001</span></p>
              </li>
               <li>
                <p className="description">Description</p>
                <p className="description_content">It is a long established fact that reader will be distracted by the read able content of a page when looking  layout. The point of using Lorem
Ipsum is that it has a more-or-less normal distribution of letters, as
opposed to using 'Content here, content here', making  it look like
readable english. The point of using Lorem Ipsum is that it has a more
normal distribution of letters, as opposed to using 'Content here,
content here', making  look like readable English</p>
              </li>
             </ul>
           </div>
         </div>
         <div className="col-md-1"></div>
       </div>
     </div>
   </section>
</div>

   )}



export default reduxForm({
   form: 'loginForm' // a unique identifier for this form
 })(Previewpost)