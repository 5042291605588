import React, { useEffect, useState } from "react";
import { userService } from "../../_services";
import { Field, reduxForm, SubmissionError } from "redux-form";
import { Link } from "react-router-dom";
import { config } from "../../config/config";
import Modal from "react-modal";

import { Layout } from "../common/Layout";
import date_img from "../../assets/images/date.png";
import down_arrow from "../../assets/images/down.png";
import loader_image from "../../assets/images/loader.gif";
import location_image from "../../assets/images/location_image.png";

import bank_image from "../../assets/images/bank_image.png";
import calender_image from "../../assets/images/calender_image.png";
import download_invoice from "../../assets/images/download_invoice.png";

import { Multiselect } from "multiselect-react-dropdown";
import DatePicker from "react-datepicker";
import moment from "moment";
import { url } from "inspector";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";
import Geocode from "react-geocode";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { loadStripe } from "@stripe/stripe-js";

// Integrated Form
interface fieldInterface {
  input: any;
  label: string;
  type: string;
  image: string;
  id: string;
  stratDate: any;
  handleChange: any;
  placeholder: string;
  value: string;
  contentEditable: boolean;
  selectedValues: [];
  maxLength: number;
  meta: {
    touched: boolean;
    error: string;
  };
}

const customStyles1 = {
  overlay: {
    display: "block",
    paddingRight: "15px",
    backgroundColor: "rgba(51,51,51,0.8)",
    zIndex: 99,
  },

  content: {
    position: "",
    top: "",
    right: "",
    bottom: "",
    left: "",
  },
};

const privacy_policy = config.apiBaseUrl + "/privacy/privacy_policy.html";
const terms_ondition = config.apiBaseUrl + "/terms/TERMS_Conditions.html";

const renderField = ({
  input,
  label,
  type,
  id,
  value,
  contentEditable,
  placeholder,
  maxLength,
  meta: { touched, error },
}: fieldInterface) => (
  <div className="form-group">
    {label && <label>{label}</label>}
    <div className="input_content">
      <input
        {...input}
        type={type}
        maxLength={maxLength}
        className="form-control"
        id={id}
        placeholder={placeholder}
      />
      {touched && error && <span style={{ color: "red" }}>{error}</span>}
    </div>
  </div>
);

const renderTextareaField = ({
  input,
  label,
  type,
  value,
  id,
  placeholder,
  maxLength,
  meta: { touched, error },
}: fieldInterface) => (
  <div className="form-group">
    <label>{label}</label>
    <div className="input_content">
      <textarea
        {...input}
        value={value}
        maxLength={maxLength}
        className="form-control"
        id={id}
        placeholder={placeholder}
        style={{ height: 90 }}
      />
      {touched && error && <span style={{ color: "red" }}>{error}</span>}
    </div>
  </div>
);

const Settings = (props: any) => {
  let current_date = new Date();

  const [stratDate, setStartdate] = useState() as any;
  const [transactionDate, settransactionDate] = useState(current_date) as any;

  const [selectedValue, setselectedValue] = useState(1);

  const handleChange = (date: any) => {
    // formData.dob= date;
    setDOBError(false);
    setStartdate(date);
    console.log(stratDate);
  };

  const handletransactionDateChange = (date: any) => {
    // formData.dob= date;
    settransactionDate(date);
    setTimeout(function () {
      get_transactions(date);
    }, 100);
  };

  const [catArray, setCatArray] = useState([]);
  const [selectedcatArray, setSelectedCatArray] = useState([]);
  const [catidsArray, setCatidsArray] = useState([]);
  const [subcatidsArray, setsubCatidsArray] = useState([]);
  const [subcatidsArray2, setsubCatidsArray2] = useState([]) as any;
  const [catsubarray, setCatsubarray] = useState([]);
  const initialValue = [{ id: 0, value: " --- Select a State ---" }];
  const [workerdocumentArray, setworkerdocumentArray] = useState([]);
  const [workerdocumentArray2, setworkerdocumentArray2] = useState([]);

  const [workerdocumentcatArray, setworkerdocumentcatArray] = useState([]);
  const [userworkerdocumentcatArray, setUserworkerdocumentcatArray] = useState(
    []
  );
  const [loaderStyle, setLoaderstyle] = useState({ display: "none" });
  const [banks, setbanks] = useState([]) as any;
  const [routingNumber, setRoutingNumber] = useState("");
  const [routingNumberError, setRoutingNumberError] = useState("");
  const [transactions, settransactions] = useState("") as any;
  const [isrevealPassword, setisrevealPassword] = useState(false);
  const [isrevealConfirmassword, setisrevealConfirmassword] = useState(false);
  const [isrevealNewPassword, setisrevealNewPassword] = useState(false);
  const [locErr, setLocErr] = useState("");
  const [location, setLocation] = useState() as any;
  const [subCategoeryError, setSubCategoeryError] = useState(
    "Sub Categories field can't be empty."
  );
  const [catSubCatAry, setCatSubCatAry] = useState([]);
  const [finalSubCatAry, setFinalSubCatAry] = useState([]);

  useEffect(() => {
    getData();
    getBanks();

    let current_date = new Date();
    get_transactions(current_date);
    //    setProfile(JSON.parse(localStorage.getItem("user") as string).image)
  }, []);

  function get_current_location() {
    console.log("get_current_location");
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition);
    } else {
      alert("Issue in getting current location.");
    }
  }

  function change_link_to_name(link_path) {
    if (link_path) {
      let doc_url_ary = link_path.split("/");
      let doc_name = doc_url_ary[doc_url_ary.length - 1];
      return doc_name;
    }

    return link_path;
  }

  function change_to_star_number(number) {
    if (number == "") {
      return number;
    }
    if (number.length < 5) {
      return number;
    }
    let number_length = number.length - 4;
    var text = "";
    var i;
    for (i = 0; i < number_length; i++) {
      text += "*";
    }
    return text + number.substr(number.length - 4);
  }
  function showPosition(position) {
    console.log("postionnnn", position);
    var lat = position.coords.latitude;
    var lng = position.coords.longitude;
    let current_location = { lat: lat, lng: lng };
    Geocode.setApiKey("AIzaSyBsv-OafO1eNJncye_hAAAlAvE--OjmmJ8");
    Geocode.fromLatLng(lat, lng).then(
      (response) => {
        const address = response.results[0].formatted_address;
        console.log(address);
        //   setLocation("");
        setLocation("");
        setLocation(address);
      },
      (error) => {
        console.error(error);
      }
    );
  }

  function get_transactions(date) {
    console.log("monthhh", date.getMonth() + 1);
    console.log("yearrr", date.getFullYear());
    userService
      .getTransactions(date.getMonth() + 1, date.getFullYear())
      .then(function (response) {
        // console.log("transactionsss",response.data);
        settransactions(response.data);
      })
      .catch(function (error) {
        console.log("errorrrrrrrr transaction", error);
        userService.handleError(error);
      });
  }

  function deleteBank(bank_id: any) {
    console.log("delete card");

    let result = window.confirm("Are you sure you want to delete this bank?");

    if (result) {
      userService
        .deleteBank(bank_id)
        .then(function (response) {
          setLoaderstyle({ display: "none" });
          console.log(response.data);
          alert("Bank account details has been deleted successfully");
          getBanks();
        })
        .catch(function (error) {
          setLoaderstyle({ display: "none" });
          userService.handleError(error);
        });
    }
  }

  function getBanks() {
    userService
      .getBankDetails()
      .then(function (response) {
        console.log("banks", response.data.bank);
        setbanks(response.data.bank);
      })
      .catch(function (error) {
        userService.handleError(error);
      });
  }

  function download_invoice_api(transaction_id: any) {
    userService
      .downloadInvoice(transaction_id)
      .then(function (response) {
        console.log("download_invoice_api", response);
        window.location.href = response.data.url;
      })
      .catch(function (error) {
        console.log("errorrrrr download invoice", error);
        userService.handleError(error);
      });
  }

  function togglePassword() {
    var tmp = !isrevealPassword;
    setisrevealPassword(tmp);
  }

  function toggleNewPassword() {
    var tmp = !isrevealNewPassword;
    setisrevealNewPassword(tmp);
  }

  function toggleConfirmPassword() {
    var tmp = !isrevealConfirmassword;
    setisrevealConfirmassword(tmp);
  }

  function getData() {
    userService
      .getCategories()
      .then(function (response) {
        let user_data = JSON.parse(localStorage.getItem("user") as string);
        console.log("------user_dataaaa", user_data);
        setUserName(user_data.name);
        setBio(user_data.bio);
        setCatArray(response.data.categories);
        console.log("--- getting cat", response.data.categories);
        console.log("--- getting data", response.data);
        setSelectedCatArray(user_data.categories);
        setDocGovt(user_data.id_document);
        setDocProfile(user_data.profile_document);
        setUserworkerdocumentcatArray(user_data.documents);
        setEmail(user_data.email);
        setLocation(user_data.location);
        setStartdate(new Date());
        let ids: any = [];
        let catsids: any = [];
        let buffer: any = [];
        console.log("user_data.categories-----", user_data.categories);

        setTimeout(function () {
          const element2 = document.querySelector(
            ".category #multiselectContainerReact"
          );

          if (
            JSON.parse(localStorage.getItem("user") as string).categories
              .length > 0
          ) {
            var has_active =
              element2 != null && element2.classList.contains("has_data");
            if (has_active == false) {
              element2 != null && element2.classList.add("has_data");
            }
          } else {
            element2 != null && element2.classList.remove("has_data");
          }

          const element = document.querySelector(
            ".sub_category #multiselectContainerReact"
          );

          if (
            JSON.parse(localStorage.getItem("user") as string).categories
              .length > 0
          ) {
            var has_active =
              element != null && element.classList.contains("has_data");
            if (has_active == false) {
              element != null && element.classList.add("has_data");
            }
          } else {
            element != null && element.classList.remove("has_data");
          }
        });

        user_data.categories.map((item, index) => {
          catsids = item.sub_categories.map((item1, index1) => {
            buffer.push(item1);
            return (catsids[catsids.length + 1] = item1);
          });

          setsubCatidsArray2(subcatidsArray2.concat(buffer));
        });

        // start
        let ary_sub_cat_buffer_ary: any = [];

        var i;
        for (i = 0; i < user_data.categories.length; i++) {
          if (user_data.categories[i].sub_categories.length > 0) {
            console.log("j----");
            var j;
            for (
              j = 0;
              j < user_data.categories[i].sub_categories.length;
              j++
            ) {
              ary_sub_cat_buffer_ary.push(
                user_data.categories[i].sub_categories[j]
              );
            }
          }
        }

        console.log("ary_sub_cat_buffer_ary-------", ary_sub_cat_buffer_ary);
        setFinalSubCatAry(ary_sub_cat_buffer_ary);
        // let a_tmp:any =  ary_sub_cat_buffer_ary.concat(ary_sub_cat_buffer_ary);
        // console.log("a_tmp",a_tmp);
        // setFinalSubCatAry(a_tmp);
        // end

        ids = user_data.categories.map((item, index) => {
          if (ids.length > 0) {
            return (ids[ids.length] = item._id);
          } else {
            return (ids[index] = item._id);
          }
        });

        setCategories(ids);
        setSubcateascat(user_data.categories, "add");

        if (user_data.birthdate) {
          let dates: any = user_data.birthdate;
          var datecheck = moment(dates);
          let valid = datecheck.isValid();
          if (valid === false) {
            const date = moment(dates, "DD-MM-YYYY").format("D MMMM, YYYY");
            setStartdate(new Date(date));
          } else {
            const date = moment(dates).format("D MMMM, YYYY");
            setStartdate(new Date(date));
          }
        }
      })
      .catch(function (error) {
        console.log(error);
        userService.handleError(error);
      });
  }
  const [addCardModal, setaddCardModal] = useState(false);
  const [docPlumber, setDocPlumber] = useState({ name: "" });
  const [docGovt, setDocGovt] = useState("");
  const [docProfile, setDocProfile] = useState("");
  const [docCarpenter, setDocCarpenter] = useState({ name: "" });
  const [userName, setUserName] = useState("");
  const [bio, setBio] = useState("");

  const [showCatError, setCatError] = useState(false);
  const [showSubCatError, setSubCatError] = useState(false);

  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [showDOBError, setDOBError] = useState(false);

  const [showDocGovtError, setDocGovtError] = useState(false);
  const [showDocProfileError, setDocProfileError] = useState(false);
  const [showDocPlumberError, setDocPlumberError] = useState(false);
  const [showDocCarpenterError, setDocCarpenterError] = useState(false);
  const [showNameError, setNameError] = useState(false);
  const [showBioError, setBioError] = useState(false);

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [oldPasswordError, setOldPasswordError] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  const [accountNameError, setAccountNameError] = useState("");
  const [accountNumberError, setAccountNumberError] = useState("");
  const [branchName, setBranchName] = useState("");
  const [branchNameError, setbranchNameError] = useState("");

  const [CvvError, setCvvError] = useState("");

  const [accountName, setAccountName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [Cvv, setCvv] = useState("");

  const [helpName, setHelpName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSbuject] = useState("");
  const [message, setMessage] = useState("");

  const [helpNameError, setHelpNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [subjectError, setSbujectError] = useState("");
  const [messageError, setMessageError] = useState("");
  const [latlng, setLatlng] = useState() as any;
  const [address, setAddress] = useState("");

  const handleChangeadd = (e: any) => {
    geocodeByAddress(e.description)
      .then((results: any[]) => getLatLng(results[0]))
      .then(({ lat, lng }: any) => setLatlng({ lat, lng }));
    setAddress(e.description);
  };

  const submit = (data: any, type: String) => {
    let fields = data;
    let errors: any = [];
    let formIsValid = true;
    console.log({ submitdata: data });
    if (type == "addCard") {
      if (!stratDate) {
        setDOBError(true);
      } else if (Cvv.length < 3) {
        formIsValid = false;
        setCvvError("CVV must be 3 or 4 digits.");
      } else if (!/^\d+$/.test(Cvv)) {
        formIsValid = false;
        setCvvError("CVV field should only contains numbers.");
      } else {
        window.location.href = "";
      }
    } else if (type == "add_provider_bank_details") {
      if (accountName.length == 0) {
        setAccountNameError("Bank Name field can't be empty.");
      } else if (!/^[a-zA-Z ]*$/.test(accountName)) {
        setAccountNameError("Bank Name only contains characters.");
      }
      // else if (!/[A-Za-z]/.test(accountName)) {
      //     setAccountNameError("Account Name only contains character.")
      // }
      else if (accountNumber.length == 0) {
        setAccountNumberError("Account Number field can't be empty.");
      } else if (!/^\d+$/.test(accountNumber)) {
        setAccountNumberError(
          "Account Number field should only contains numbers."
        );
      } else if (accountNumber.length > 16) {
        setAccountNumberError("Account Number should be less than 16 digits.");
      } else if (accountNumber == "0") {
        setAccountNumberError("Account Number must be valid.");
      } else if (routingNumber.length == 0) {
        setRoutingNumberError("Routing Number field can't be empty.");
      } else if (!/^\d+$/.test(routingNumber)) {
        setRoutingNumberError(
          "Routing Number field should only contains numbers."
        );
      } else if (routingNumber.length > 11) {
        setRoutingNumberError("Routing Number should be less than 11 digits.");
      } else if (routingNumber == "0") {
        setRoutingNumberError("Routing Number must be valid.");
      } else {
        const formData = new URLSearchParams();
        console.log("here");
        formData.append("bank_name", accountName);
        formData.append("account_no", accountNumber);
        formData.append("routing_no", routingNumber);

        userService
          .addBankDetails(formData.toString())
          .then(function (responses) {
            alert(responses.data.message);
            getBanks();
            closeModal();
            setAccountName("");
            setAccountNumber("");
            setRoutingNumber("");
          })
          .catch(function (error) {
            console.log(error);

            userService.handleError(error);
          });
      }
    } else if (type == "changePassword") {
      if (oldPassword.length == 0) {
        setOldPasswordError("Old Password field can't be empty.");
        toast.error(config.errorValidationMsg);
      } else if (newPassword.length == 0) {
        setNewPasswordError("New Password field can't be empty.");
        toast.error(config.errorValidationMsg);
      } else if (newPassword.length < 8) {
        setNewPasswordError("Password should have minimum 8 characters.");
        toast.error(config.errorValidationMsg);
      } else if (confirmPassword.length == 0) {
        setConfirmPasswordError("Confirm Password field can't be empty.");
        toast.error(config.errorValidationMsg);
      } else if (confirmPassword != newPassword) {
        setConfirmPasswordError(
          "Confirm password doesn't match with New Password"
        );
        toast.error(config.errorValidationMsg);
      } else {
        const formData = new URLSearchParams();
        formData.append("old_password", oldPassword);
        formData.append("new_password", newPassword);

        userService
          .changePassword(formData.toString())
          .then(function (response) {
            alert(response.data.message);
            window.location.href = "";
          })
          .catch(function (error) {
            console.log("errorrrrr", error);
            if (error.response) {
              console.log("error resonse");
              alert(error.response.data.message);
              console.log(error.request);
              console.log(error.message);
            } else if (error.request) {
              console.log("error request");
            } else if (error.message) {
              console.log("error message");
            }
          });
      }
    } else if (type == "helpAndSupport") {
      if (helpName.length == 0) {
        setHelpNameError("Name field can't be empty.");
        toast.error(config.errorValidationMsg);
      } else if (email.length == 0) {
        setEmailError("Email field can't be empty.");
        toast.error(config.errorValidationMsg);
      } else if (!/\S+@\S+\.\S+/.test(email)) {
        setEmailError("Entered Email is not valid.");
        toast.error(config.errorValidationMsg);
      } else if (subject.length == 0) {
        setSbujectError("Subject field can't be empty.");
        toast.error(config.errorValidationMsg);
      } else if (message.length == 0) {
        setMessageError("Message field can't be empty.");
        toast.error(config.errorValidationMsg);
      } else {
        let params = {
          name: helpName,
          email: email,
          subject: subject,
          message: message,
        };

        setLoaderstyle({ display: "block" });
        userService
          .helpsupports(params)
          .then(function (response) {
            setLoaderstyle({ display: "none" });
            console.log(response.data.message);
            alert(response.data.message);
            window.location.href = "";
          })
          .catch(function (error) {
            console.log("errorrrrrr", error);
            setLoaderstyle({ display: "none" });
            alert("something went wrongg");
          });
      }
    } else if (type == "updateProfile") {
      console.log("heyyyyyyyy calleddd?", catsubarray);
      console.log("end catsubarray");
      console.log("selectedcatArray", selectedcatArray);
      console.log("finalSubCatAry--------------------", finalSubCatAry);

      // category_id
      let tmp: any;
      let cat_ids = selectedcatArray.map(getcategory);
      let sub_cat_ids = finalSubCatAry.map(getcategoryfromsubcategory);
      var filtered: any = [];
      console.log("cat_ids", cat_ids);
      console.log("sub_cat_ids", sub_cat_ids);

      filtered = cat_ids.filter(notContainedIn(sub_cat_ids));
      console.log("----------------filteredddd", filtered);

      if (userName.length == 0) {
        setNameError(true);
        toast.error(config.errorValidationMsg);
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      } else if (bio.length == 0) {
        setBioError(true);
        toast.error(config.errorValidationMsg);
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      } else if (categories.length == 0 && selectedcatArray.length == 0) {
        setCatError(true);
        toast.error(config.errorValidationMsg);
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }
      // else if (subCategories.length == 0 && subcatidsArray2.length == 0) {
      // setSubCatError(true)
      // setSubCategoeryError("Sub Categories field can't be empty.");
      // }
      // else if(filtered.length > 0){
      // setSubCatError(true)
      // console.log(filtered[0])
      // console.log("nameee",get_category_name_from_id(filtered[0]))
      // setSubCategoeryError("Please select sub category of "+ get_category_name_from_id(filtered[0]) +" or remove this category.");
      // }
      else if (docGovt.length == 0) {
        setDocGovtError(true);
        toast.error(config.errorValidationMsg);
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      } else if (docProfile.length == 0) {
        setDocProfileError(true);
        toast.error(config.errorValidationMsg);
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }
      // else if (JSON.parse(localStorage.getItem("user") as string).categories.length != JSON.parse(localStorage.getItem("user") as string).documents.length
      // ) {
      //     alert("Please Upload all the categories document")
      // }
      else {
        var tmp_push_ary: any = [];

        var i;
        console.log("finalSubCatAry-------", finalSubCatAry);
        for (i = 0; i < selectedcatArray.length; i++) {
          console.log(selectedcatArray[i]);
          var item: any = {};
          item = selectedcatArray[i];
          var tmp_hsh: any = {};
          tmp_hsh.id = item._id;
          tmp_hsh.subcategories = get_sub_category_ary(
            finalSubCatAry,
            item._id
          );
          tmp_push_ary.push(tmp_hsh);
        }
        console.log("tmp_push_ary", tmp_push_ary);

        let new_date = moment(stratDate, "DD-MM-YYYY").format("DD-MM-YYYY");
        console.log("catsubarray", catsubarray);
        let formsData = {
          name: userName,
          birthdate: new_date,
          category: catsubarray.length > 0 ? catsubarray : tmp_push_ary,

          bio: bio,
          profile_step1: true,
        };

        console.log("form data", formsData);
        console.log("catsubarrayyyyy", catsubarray);

        setLoaderstyle({ display: "block" });
        userService
          .updateProfile(formsData)
          .then(function (response) {
            setLoaderstyle({ display: "none" });
            console.log("api called");
            console.log("response", response);

            localStorage.setItem("user", JSON.stringify(response.data.user));
            const formData = new FormData();
            console.log("docGovt", docGovt);
            formData.append("id_document", docGovt);
            formData.append("profile_document", docProfile);
            console.log("after append", formData);
            if (workerdocumentArray2.length > 0) {
              workerdocumentArray2.map((value, index) => {
                formData.append("business_documents", (value as any).file);
              });
              formData.append(
                "document_types",
                workerdocumentcatArray.join() as any
              );
              formData.append("profile_step2", true as any);
              console.log(
                "form data--------------------------------",
                formData
              );
              userService
                .updateProfileimage(formData)
                .then(function (response) {
                  alert("Profile updated successfully");
                  localStorage.setItem(
                    "user",
                    JSON.stringify(response.data.user)
                  );
                  window.location.href = "";
                })
                .catch(function (error) {
                  console.log("error", error);
                });
            } else {
              console.log("----------form data", formData);
              userService
                .updateProfileimage(formData)
                .then(function (response) {
                  alert("Profile updated successfully");
                  localStorage.setItem(
                    "user",
                    JSON.stringify(response.data.user)
                  );
                  window.location.href = "";
                })
                .catch(function (error) {
                  console.log("error", error);
                });

              // alert("Profile updated successfully")
              // localStorage.setItem("user", JSON.stringify(response.data.user))
              // window.location.href = ""
            }
          })
          .catch(function (error) {
            console.log("errorrrr");
            userService.handleError(error);
          });
      }
    }
  };

  function get_sub_category_ary(sub_categgory_ary, category_id) {
    console.log("sub_categgory_ary----", sub_categgory_ary);
    var i;
    var tmp_ary: any = [];
    for (i = 0; i < sub_categgory_ary.length; i++) {
      console.log("sub_categgory_ary[i]----", sub_categgory_ary[i]);

      var item: any = {};

      item = sub_categgory_ary[i];
      console.log("item----", item);
      console.log("------ sub categ", sub_categgory_ary[i]);
      console.log("match_with_category_id", category_id);
      console.log("item.category_id", item.category_id);
      if (item.category_id == category_id) {
        tmp_ary.push(item._id);
      }
    }
    return tmp_ary;
  }

  function get_category_name_from_id(category_id) {
    // let obj = selectedcatArray.find(tmp => tmp._id == category_id);
    console.log("get_category_name_from_id", category_id);

    var i;
    for (i = 0; i < selectedcatArray.length; i++) {
      let item: any = selectedcatArray[i];
      if (item._id == category_id) {
        console.log("matchess", item);
        return item.name;
      }
    }
  }

  function getcategoryfromsubcategory(item) {
    console.log("getcategoryfromsubcategory");
    console.log("item", item);
    console.log("item categ", item.category_id);
    return item.category_id;
  }

  function getcategory(item) {
    return item._id;
  }

  function notContainedIn(arr) {
    return function arrNotContains(element) {
      return arr.indexOf(element) === -1;
    };
  }

  function showModal() {
    setaddCardModal(true);
  }

  function closeModal() {
    setaddCardModal(false);
  }
  let filearray: any = [];

  function onPlumberDocUpload(e) {
    e.preventDefault();
    let data: any = [];

    // if (JSON.parse(localStorage.getItem('user') as string).categories.length > workerdocumentArray2.length) {
    let file = e.target.files[0];
    if (file != undefined) {
      var len = e.length;
      filearray[e.target.id] = file;
      setworkerdocumentcatArray(workerdocumentcatArray.concat(e.target.id));
      setworkerdocumentArray(workerdocumentArray.concat(file));

      if (localStorage.getItem(e.target.id) == null) {
        localStorage.setItem(e.target.id, file.name);
        data[0] = {
          id: e.target.id,
          file: file,
        };

        setworkerdocumentArray2(workerdocumentArray2.concat(data));
        console.log("workerdocumentArray2-----", workerdocumentArray2);
      } else {
        localStorage.setItem(e.target.id, file.name);
        workerdocumentArray2.map((item, index) => {
          if ((item as any).id == e.target.id) {
            (workerdocumentArray2[index] as any).file = file;
          }
        });

        console.log("workerdocumentArray2 ----", workerdocumentArray2);
        console.log(workerdocumentArray2);
      }
    }
    // } else {
    //     setworkerdocumentArray([])
    //     if (JSON.parse(localStorage.getItem('user') as string).categories.length > workerdocumentArray.length) {
    //         let file = e.target.files[0];
    //         if (file != undefined) {
    //             var len = e.length;
    //             filearray[e.target.id] = file
    //             setworkerdocumentArray(workerdocumentArray.concat(file))
    //         }
    //     }
    // }
    // if (event.target.files[0])
    //     setDocPlumber(event.target.files[0])
    // setDocPlumberError(false)
  }
  function onGovtDocUpload(event) {
    console.log(event.target.files[0]);
    if (event.target.files[0]) setDocGovt(event.target.files[0]);
    setDocGovtError(false);
  }
  function onProfileDocUpload(event) {
    if (event.target.files[0]) setDocProfile(event.target.files[0]);
    setDocProfileError(false);
  }
  function onCarpenterDocUpload(event) {
    if (event.target.files[0]) setDocCarpenter(event.target.files[0]);
    setDocCarpenterError(false);
  }
  function onUserNameChanged(event) {
    setNameError(false);
    setUserName(
      userName.length == 0
        ? event.target.value.replace(/ /g, "")
        : event.target.value
    );
  }
  function onBioChanged(event) {
    setBioError(false);
    setBio(
      bio.length == 0
        ? event.target.value.replace(/ /g, "")
        : event.target.value
    );
  }

  function onOldPasswordChanged(event) {
    setOldPassword(event.target.value.replace(/ /g, ""));
    setOldPasswordError("");
  }

  function onNewPasswordChanged(event) {
    setNewPassword(event.target.value.replace(/ /g, ""));
    setNewPasswordError("");
  }

  function onConfirmPasswordChanged(event) {
    setConfirmPassword(event.target.value.replace(/ /g, ""));
    setConfirmPasswordError("");
  }
  function onRoutingChanged(event) {
    setRoutingNumberError("");
    setRoutingNumber(
      routingNumber.length == 0
        ? event.target.value.replace(/ /g, "")
        : event.target.value
    );
  }

  function onBranchNameChanged(event) {
    setBranchName(
      accountName.length == 0
        ? event.target.value.replace(/ /g, "")
        : event.target.value
    );
    setbranchNameError("");
  }

  function onAccountNameChanged(event) {
    setAccountName(
      accountName.length == 0
        ? event.target.value.replace(/ /g, "")
        : event.target.value
    );
    setAccountNameError("");
  }

  function onAccountNumberChanged(event) {
    setAccountNumber(
      accountNumber.length == 0
        ? event.target.value.replace(/ /g, "")
        : event.target.value
    );
    setAccountNumberError("");
  }

  function onCvvChanged(event) {
    setCvv(
      Cvv.length == 0
        ? event.target.value.replace(/ /g, "")
        : event.target.value
    );
    setCvvError("");
  }

  function onHelpNameChanged(event) {
    setHelpName(
      helpName.length == 0
        ? event.target.value.replace(/ /g, "")
        : event.target.value
    );
    setHelpNameError("");
  }
  function onEmailChanged(event) {
    setEmail(event.target.value.replace(/ /g, ""));
    setEmailError("");
  }
  function onSubjectChanged(event) {
    setSbuject(
      subject.length == 0
        ? event.target.value.replace(/ /g, "")
        : event.target.value
    );
    setSbujectError("");
  }
  function onMessageChanged(event) {
    setMessage(
      message.length == 0
        ? event.target.value.replace(/ /g, "")
        : event.target.value
    );
    setMessageError("");
  }

  function getSubcategory(e: any) {
    var ids: any = [];
    var aa: any;

    // ids = e.map((item,index)=>{ return(ids.concat(item._id))})
    ids = e.map((item, index) => {
      return (ids[index] = item._id);
    });
    const data = {
      categoryIds: ids,
    };

    userService
      .getSubCategories(data)
      .then(function (response) {
        setsubCatidsArray(response.data.sub_categories);
      })
      .catch(function (error) {
        userService.handleError(error);
      });
  }

  function setSubcateascat(e: any, type: string) {
    if (type == "add") {
      let cat: any = catidsArray.concat(e[e.length - 1]._id);
      setCatidsArray(cat);
      // setCatsubarray(data)
      console.log("setttttttt", catsubarray);
      getSubcategory(e);

      // category: (3) […]
      // ​​
      // 0: {…}
      // ​​​
      // id: "5fa8e6abba380b68fef9ede4"
      // ​​​
      // subcategories: Array [ "5fa8eb37ba380b68fef9edff", "5fa8eb41ba380b68fef9ee00" ]

      // setCatSubCatAry();
    } else {
      console.log("typeee", type);
      if (e.length > 0) {
        console.log("e.len", e.length);
        console.log("e", e);
        // debugger
        let dd: any = [];
        dd = e.map((item, index) => {
          return (dd[index] = item._id);
        });
        let cat: any = catidsArray.filter(
          (item) => item == e[e.length - 1]._id
        );
        console.log("dddd", dd);
        var dd_unique = dd.filter(onlyUnique);
        var e_unique = e.filter(onlyUnique);
        setCatidsArray(dd_unique);
        getSubcategory(e_unique);
      }
    }
  }

  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  function setsubcat(e: any, type: string) {
    if (type == "add") {
      console.log("eeeeeeee", e);
      var ids: any = [];
      ids = catidsArray;
      var aa: any = [];
      let subads: any = e || [];
      let subadsid: any = [];
      let data: any = [];

      ids.map((cat: any, index1) => {
        aa = subads.map((item, index) => {
          if (cat == item.category_id) {
            return (subadsid[index] = item._id);
          }
        });

        data[index1] = {
          id: cat,
          subcategories: aa.filter(function (item) {
            return item != undefined;
          }),
        };
      });
      console.log("Catsubarray", data);
      setCatsubarray(data);
      const element = document.querySelector(
        ".sub_category #multiselectContainerReact"
      );
      if (ids.length > 0) {
        element != null && element.classList.add("has_data");
      } else {
        element != null && element.classList.remove("has_data");
      }

      // ids = e.map((item,index)=>{ return(ids.concat(item._id))})
      // setCatidsArray(catidsArray.concat(e[e.length-1]._id))
      // getSubcategory(e)
    } else {
      if (e.length > 0) {
        var ids: any = [];
        ids = catidsArray;
        var aa: any = [];
        let subads: any = e || [];
        let subadsid: any = [];
        let data: any = [];

        ids.map((cat: any, index1) => {
          aa = subads.map((item, index) => {
            if (cat == item.category_id) {
              return (subadsid[index] = item._id);
            }
          });

          data[index1] = {
            id: cat,
            subcategories: aa.filter(function (item) {
              return item != undefined;
            }),
          };
        });
        console.log("setCatsubarray", data);
        setCatsubarray(data);
        // setCatidsArray(catidsArray.filter(item => item == e[e.length-1]._id))
        // getSubcategory(e)
        const element = document.querySelector(
          ".sub_category #multiselectContainerReact"
        );
        if (ids.length > 0) {
          element != null && element.classList.add("has_data");
        } else {
          element != null && element.classList.remove("has_data");
        }
      } else {
        setCatsubarray([]);
        setsubCatidsArray2([]);
        const element = document.querySelector(
          ".sub_category #multiselectContainerReact"
        );
        element != null && element.classList.remove("has_data");
      }
    }
  }
  let bb: any = [];
  const { error, classNameName, handleSubmit, pristine, reset, submitting } =
    props;
  return (
    <section className="my_profile_settings">
      <ToastContainer autoClose={10000} />
      <div className="container">
        <div className="row">
          <aside className="col-md-4">
            <div className="profile_tabsarea">
              <h2 className="settings">Settings</h2>

              <ul className="nav nav-tabs" role="tablist">
                <li role="presentation" className="active">
                  <a
                    href="#my_profile"
                    aria-controls="my_profile"
                    role="tab"
                    data-toggle="tab"
                  >
                    My Profile
                  </a>
                </li>
                <li role="presentation">
                  <a
                    href="#change_password"
                    aria-controls="change_password"
                    role="tab"
                    data-toggle="tab"
                  >
                    Change Password
                  </a>
                </li>
                <li role="presentation">
                  <a
                    href="#my_payment_method"
                    aria-controls="my_payment_method"
                    role="tab"
                    data-toggle="tab"
                  >
                    My Payment Method
                  </a>
                </li>
                <li role="presentation">
                  <a
                    href="#my_transaction"
                    aria-controls="my_transaction"
                    role="tab"
                    data-toggle="tab"
                  >
                    My Transaction
                  </a>
                </li>
                <li role="presentation">
                  <a
                    href="#help_support"
                    aria-controls="help_support"
                    role="tab"
                    data-toggle="tab"
                  >
                    Help & Support
                  </a>
                </li>
                <li role="presentation">
                  <a
                    target="_blank"
                    href={"/privacy_policy"}
                    aria-controls="privacy_policy"
                  >
                    Privacy Policy
                  </a>
                </li>
                <li role="presentation">
                  <a
                    target="_blank"
                    href={"/terms_conditions"}
                    aria-controls="tems_conditions"
                  >
                    Terms & Conditions
                  </a>
                </li>
              </ul>
            </div>
          </aside>
          <aside className="col-md-8">
            <div className="tab-content new_job_main_box">
              <div role="tabpanel" className="tab-pane active" id="my_profile">
                <h2>My Profile</h2>
                <p className="head_content_paragraph">
                  Change your basic information.
                </p>
                <form
                  onSubmit={handleSubmit((val: any) =>
                    submit(val, "updateProfile")
                  )}
                >
                  <div className="form_input_box">
                    <h1 className="information_heading">General Information</h1>
                    <div className="form-group">
                      <label>NAME</label>
                      <input
                        type="text"
                        maxLength={25}
                        onChange={onUserNameChanged}
                        className="form-control"
                        placeholder="Name"
                        value={userName}
                      />
                      {showNameError && (
                        <span style={{ color: "red" }}>
                          Name field can't be empty.
                        </span>
                      )}

                      {/* <Field
                                                name="name"
                                                type="text"
                                                maxLength={20}
                                                value={userName}
                                                contentEditable={true}
                                                component={renderField}
                                                label="NAME"
                                                placeholder="Name"
                                            /> */}
                    </div>
                    <div className="form-group">
                      <label>DATE OF BIRTH</label>
                      <div className="input_box">
                        {/* <input type="text" className="form-control form-control-two" placeholder="Select Date" value="20 September 1920" /> */}
                        <DatePicker
                          selected={stratDate}
                          onChange={handleChange}
                          value={stratDate}
                          showYearDropdown
                          maxDate={new Date(2006, 11, 24, 10, 33, 30, 0)}
                          relativeSize={true}
                          autoComplete="off"
                          placeholderText="DD/MM/YY"
                          onKeyDown={(e: { preventDefault: () => any }) =>
                            e.preventDefault()
                          }
                          className="form-control form-control-two"
                        />
                        <img src={date_img} alt="date" />
                      </div>
                    </div>
                    <div className="form-group">
                      <label>BIO</label>
                      <textarea
                        placeholder="Bio"
                        onChange={onBioChanged}
                        value={bio}
                        maxLength={200}
                      ></textarea>
                      {showBioError && (
                        <span style={{ color: "red" }}>
                          Bio field can't be empty.
                        </span>
                      )}
                      {/* <Field
                                                name="bio"
                                                type="text"
                                                maxLength={200}
                                                value="I love my home and trust in hard work."
                                                component={renderTextareaField}
                                                label="BIO"
                                                placeholder="Bio"
                                            /> */}
                    </div>
                    <h1 className="information_heading padding-top">
                      Service Category
                    </h1>
                    <div className="form-group">
                      <label>CATEGORY</label>

                      <div className="selectnew category">
                        <Multiselect
                          options={catArray}
                          selectedValues={selectedcatArray}
                          displayValue="name"
                          placeholder="select category"
                          onSelect={(e) => {
                            console.log("on select", e);
                            setCatError(false);
                            // setCategories(categories.concat(e))
                            let ids: any = [];
                            ids = e.map((item, index) => {
                              return (ids[index] = item._id);
                            });
                            setCategories(ids);
                            setSubcateascat(e, "add");
                            const element2 = document.querySelector(
                              ".category #multiselectContainerReact"
                            );
                            if (ids.length > 0) {
                              console.log("hs data-------");
                              element2 != null &&
                                element2.classList.add("has_data");
                            } else {
                              console.log("remove hs_data class");
                              element2 != null &&
                                element2.classList.remove("has_data");
                            }
                            let tmp_c: any = [];
                            var i;
                            for (i = 0; i < ids.length; i++) {
                              let tmp_h: any = {};
                              tmp_h.id = ids[i];
                              tmp_h.subcategories = get_sub_category_ary(
                                subCategories,
                                ids[i]
                              );
                              tmp_c.push(tmp_h);
                            }
                            console.log("tmp_c----------", tmp_c);
                            setCatSubCatAry(tmp_c);
                          }}
                          onRemove={(e) => {
                            setCatError(false);
                            console.log("on remove", e);
                            let ids: any = [];
                            ids = e.map((item, index) => {
                              return (ids[index] = item._id);
                            });
                            // setCategories(categories.filter(item => item == ids))
                            setCategories(ids);
                            const element2 = document.querySelector(
                              ".category #multiselectContainerReact"
                            );
                            if (ids.length > 0) {
                              console.log("hs data-------");
                              element2 != null &&
                                element2.classList.add("has_data");
                            } else {
                              console.log("remove hs_data class");
                              element2 != null &&
                                element2.classList.remove("has_data");
                            }

                            setSubcateascat(e, "remove");
                            console.log("subCatidsArray2", subcatidsArray2);

                            var tmp_ary: any = [];
                            var i;
                            for (i = 0; i < subcatidsArray2.length; i++) {
                              console.log("i", i);
                              console.log("ids", ids);
                              console.log(
                                "category_id",
                                subcatidsArray2[i].category_id
                              );
                              if (
                                ids.includes(subcatidsArray2[i].category_id)
                              ) {
                                tmp_ary.push(subcatidsArray2[i]);
                              }
                            }
                            setsubCatidsArray2(tmp_ary);

                            const element = document.querySelector(
                              ".sub_category #multiselectContainerReact"
                            );
                            if (tmp_ary.length > 0) {
                              element != null &&
                                element.classList.add("has_data");
                            } else {
                              element != null &&
                                element.classList.remove("has_data");
                            }
                            console.log(
                              "tmp_aryrrrrrrrrrrrrrrrrrrrrrr------------------------",
                              tmp_ary
                            );
                          }}
                          closeIcon="cancel"
                          style={{
                            chips: {
                              background: "#f16a6e",
                              borderRadius: 20,
                            },
                            searchBox: {
                              borderRadius: 5,
                              border: "inherit",
                              padding: 5,
                              minHeight: 22,
                              position: "relative",
                              background: "#f4f4f4",
                              height: 48,
                              fontSize: 18,
                              backgroundImage: `url(${down_arrow})`,
                              backgroundRepeat: "no-repeat",
                              backgroundPosition: "95%",
                            },
                          }}
                        />
                        {/* <img src={date_img} alt="date" /> */}
                      </div>

                      {showCatError && (
                        <span style={{ color: "red" }}>
                          Categories field can't be empty.
                        </span>
                      )}
                    </div>
                    <div className="form-group">
                      <label>SUB-CATEGORY</label>

                      <div className="selectnew sub_category">
                        <Multiselect
                          options={subcatidsArray}
                          selectedValues={subcatidsArray2}
                          displayValue="name"
                          placeholder="select sub category"
                          closeIcon="cancel"
                          groupBy="category_name"
                          onSelect={(e) => {
                            setSubCatError(false);
                            let ids: any = [];
                            ids = e.map((item, index) => {
                              return (ids[index] = item._id);
                            });
                            console.log("SubCategories", ids);
                            console.log("SubCategories---e------ ", e);
                            setFinalSubCatAry(e);
                            setSubCategories(ids);
                            setsubcat(e, "add");
                          }}
                          onRemove={(e) => {
                            setSubCatError(false);
                            let ids: any = [];
                            ids = e.map((item, index) => {
                              return (ids[index] = item._id);
                            });
                            console.log("SubCategories--- ", ids);
                            console.log("SubCategories---e ---- ", e);
                            setFinalSubCatAry(e);
                            setSubCategories(
                              subCategories.filter((item) => item == ids)
                            );
                            setsubcat(e, "remove");
                          }}
                          style={{
                            chips: {
                              background: "#f16a6e",
                              borderRadius: 20,
                            },
                            searchBox: {
                              borderRadius: 5,
                              border: "inherit",
                              padding: 5,
                              minHeight: 22,
                              position: "relative",
                              background: "#f4f4f4",
                              height: 48,
                              fontSize: 18,
                              backgroundImage: `url(${down_arrow})`,
                              backgroundRepeat: "no-repeat",
                              backgroundPosition: "95%",
                            },
                          }}
                        />
                        {showSubCatError && (
                          <span style={{ color: "red" }}>
                            {subCategoeryError}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="form-group">
                      <label>LOCATION</label>
                      <div className="input_box">
                        <GooglePlacesAutocomplete
                          onSelect={(e: any) => handleChangeadd(e)}
                          inputClassName="form-control form-control-two"
                          initialValue={location}
                        />
                        {locErr.length > 0 && (
                          <span style={{ color: "red" }}>{locErr}</span>
                        )}
                        <img
                          src={location_image}
                          alt="Current Location"
                          onClick={() => get_current_location()}
                        />
                      </div>
                    </div>

                    <h1 className="information_heading padding-top">
                      Documents
                    </h1>
                    <div className="input_form_box input_box_second input-upload">
                      <div className="form-group">
                        <div className="input_content upload_content">
                          {docGovt != "" &&
                          (docGovt as any).name == undefined ? (
                            <a
                              target="_blank"
                              href={
                                docGovt != "" &&
                                (docGovt as any).name == undefined
                                  ? docGovt
                                  : (docGovt as any).name
                                  ? "javascript:void(0);"
                                  : "javascript:void(0);"
                              }
                            >
                              {docGovt != "" &&
                              (docGovt as any).name == undefined
                                ? change_link_to_name(docGovt)
                                : (docGovt as any).name
                                ? (docGovt as any).name
                                : ""}
                            </a>
                          ) : (
                            <span>
                              {docGovt != "" &&
                              (docGovt as any).name == undefined
                                ? change_link_to_name(docGovt)
                                : (docGovt as any).name
                                ? (docGovt as any).name
                                : ""}
                            </span>
                          )}

                          <input
                            contentEditable={false}
                            type="hidden"
                            className="form-control"
                            placeholder="Choose document"
                            value={
                              docGovt != "" &&
                              (docGovt as any).name == undefined
                                ? change_link_to_name(docGovt)
                                : (docGovt as any).name
                                ? (docGovt as any).name
                                : ""
                            }
                          />
                          <div className="upload_file-btn-box">
                            <div className="upload-btn-wrapper">
                              <button className="btn">UPLOAD</button>
                              <input
                                type="file"
                                accept="application/pdf"
                                name="myfile"
                                onChange={onGovtDocUpload}
                              />
                            </div>
                          </div>
                        </div>
                        {showDocGovtError && (
                          <span style={{ color: "red" }}>
                            Please upload Id or Document.
                          </span>
                        )}
                      </div>
                      <p className="validation">
                        Please upload your verified Government Id or Document.
                      </p>
                      <div className="form-group">
                        <div className="input_content upload_content">
                          {docProfile != "" &&
                          (docProfile as any).name == undefined ? (
                            <a
                              target="_blank"
                              href={
                                docProfile != "" &&
                                (docProfile as any).name == undefined
                                  ? docProfile
                                  : (docProfile as any).name
                                  ? (docProfile as any).name
                                  : ""
                              }
                            >
                              {docProfile != "" &&
                              (docProfile as any).name == undefined
                                ? change_link_to_name(docProfile)
                                : (docProfile as any).name
                                ? (docProfile as any).name
                                : ""}
                            </a>
                          ) : (
                            <span>
                              {docProfile != "" &&
                              (docProfile as any).name == undefined
                                ? change_link_to_name(docProfile)
                                : (docProfile as any).name
                                ? (docProfile as any).name
                                : ""}
                            </span>
                          )}
                          <input
                            contentEditable={false}
                            type="hidden"
                            className="form-control"
                            placeholder="Choose document"
                            value={
                              docProfile != "" &&
                              (docProfile as any).name == undefined
                                ? change_link_to_name(docProfile)
                                : (docProfile as any).name
                                ? (docProfile as any).name
                                : ""
                            }
                          />
                          <div className="upload_file-btn-box">
                            <div className="upload-btn-wrapper">
                              <button className="btn">UPLOAD</button>
                              <input
                                type="file"
                                accept="application/pdf"
                                name="myfile"
                                onChange={onProfileDocUpload}
                              />
                            </div>
                          </div>
                        </div>
                        {showDocProfileError && (
                          <span style={{ color: "red" }}>
                            Please upload Id or Document.
                          </span>
                        )}
                      </div>
                      <p className="validation">
                        Please upload your other document related to your
                        profile.
                      </p>
                      {userworkerdocumentcatArray.length > 0 &&
                      workerdocumentArray2.length == 0 ? (
                        localStorage.getItem("user") &&
                        JSON.parse(localStorage.getItem("user") as string)
                          .categories.length > 0 &&
                        selectedcatArray.map((item: any, index: any) => {
                          console.log("item", item.name);
                          return (
                            <div key={index}>
                              <div className="form-group">
                                <div className="input_content upload_content">
                                  {userworkerdocumentcatArray.length > 0 &&
                                  workerdocumentArray[index] == undefined &&
                                  userworkerdocumentcatArray[index] !==
                                    undefined ? (
                                    <a
                                      target="_blank"
                                      href={
                                        userworkerdocumentcatArray.length > 0 &&
                                        workerdocumentArray[index] ==
                                          undefined &&
                                        userworkerdocumentcatArray[index] !==
                                          undefined &&
                                        (
                                          userworkerdocumentcatArray[
                                            index
                                          ] as any
                                        ).url !== undefined
                                          ? (
                                              userworkerdocumentcatArray[
                                                index
                                              ] as any
                                            ).url
                                          : workerdocumentArray[index] !==
                                              undefined &&
                                            (workerdocumentArray[index] as any)
                                              .name
                                          ? (workerdocumentArray[index] as any)
                                              .name
                                          : ""
                                      }
                                    >
                                      {userworkerdocumentcatArray.length > 0 &&
                                      workerdocumentArray[index] == undefined &&
                                      userworkerdocumentcatArray[index] !==
                                        undefined &&
                                      (userworkerdocumentcatArray[index] as any)
                                        .url !== undefined
                                        ? change_link_to_name(
                                            (
                                              userworkerdocumentcatArray[
                                                index
                                              ] as any
                                            ).url
                                          )
                                        : workerdocumentArray[index] !==
                                            undefined &&
                                          (workerdocumentArray[index] as any)
                                            .name
                                        ? (workerdocumentArray[index] as any)
                                            .name
                                        : ""}
                                    </a>
                                  ) : (
                                    <span>
                                      {userworkerdocumentcatArray.length > 0 &&
                                      workerdocumentArray[index] == undefined &&
                                      userworkerdocumentcatArray[index] !==
                                        undefined &&
                                      (userworkerdocumentcatArray[index] as any)
                                        .url !== undefined
                                        ? change_link_to_name(
                                            (
                                              userworkerdocumentcatArray[
                                                index
                                              ] as any
                                            ).url
                                          )
                                        : workerdocumentArray[index] !==
                                            undefined &&
                                          (workerdocumentArray[index] as any)
                                            .name
                                        ? (workerdocumentArray[index] as any)
                                            .name
                                        : ""}
                                    </span>
                                  )}
                                  <input
                                    contentEditable={false}
                                    type="hidden"
                                    className="form-control"
                                    placeholder="Upload document"
                                    value={
                                      userworkerdocumentcatArray.length > 0 &&
                                      workerdocumentArray[index] == undefined &&
                                      userworkerdocumentcatArray[index] !==
                                        undefined &&
                                      (userworkerdocumentcatArray[index] as any)
                                        .url !== undefined
                                        ? change_link_to_name(
                                            (
                                              userworkerdocumentcatArray[
                                                index
                                              ] as any
                                            ).url
                                          )
                                        : workerdocumentArray[index] !==
                                            undefined &&
                                          (workerdocumentArray[index] as any)
                                            .name
                                        ? (workerdocumentArray[index] as any)
                                            .name
                                        : ""
                                    }
                                  />
                                  <div className="upload_file-btn-box">
                                    <div className="upload-btn-wrapper">
                                      <button className="btn">UPLOAD</button>
                                      <input
                                        type="file"
                                        accept="application/pdf"
                                        name={item.name}
                                        id={item.category_id}
                                        onChange={onPlumberDocUpload}
                                      />
                                    </div>
                                  </div>
                                </div>
                                {showDocPlumberError && (
                                  <span style={{ color: "red" }}>
                                    Please upload Id or Document.
                                  </span>
                                )}
                              </div>
                              <p className="validation">
                                {" "}
                                Please upload your other document related to
                                your's {item.name} Id.
                              </p>
                            </div>
                          );
                        })
                      ) : (
                        <>
                          {localStorage.getItem("user") &&
                            JSON.parse(localStorage.getItem("user") as string)
                              .categories.length > 0 &&
                            JSON.parse(
                              localStorage.getItem("user") as string
                            ).categories.map((item: any, index: any) => {
                              console.log(
                                localStorage.getItem(item.category_id)
                              );
                              return (
                                <div key={index}>
                                  <div className="form-group">
                                    <div className="input_content upload_content">
                                      {/* <input contentEditable={false} type="text" className="form-control" placeholder="Upload document" value={workerdocumentArray2.length > 0 && workerdocumentArray2[index] != undefined ? ((workerdocumentArray2[index] as any).id) == item.category_id ? (workerdocumentArray2[index] as any).file.name : "" : ""} /> */}
                                      {/* <input contentEditable={false} type="text" className="form-control" placeholder="Upload document" value={localStorage.getItem(item.category_id) as string ? localStorage.getItem(item.category_id) as string:""}  /> */}

                                      {localStorage.getItem(item.category_id) ==
                                      null ? (
                                        <a
                                          target="_blank"
                                          href={
                                            (localStorage.getItem(
                                              item.category_id
                                            ) as string)
                                              ? (localStorage.getItem(
                                                  item.category_id
                                                ) as string)
                                              : ""
                                          }
                                        >
                                          {(localStorage.getItem(
                                            item.category_id
                                          ) as string)
                                            ? (localStorage.getItem(
                                                item.category_id
                                              ) as string)
                                            : ""}
                                        </a>
                                      ) : (
                                        <span>
                                          {
                                            localStorage.getItem(
                                              item.category_id
                                            ) as string
                                          }
                                        </span>
                                      )}

                                      <div className="upload_file-btn-box">
                                        <div className="upload-btn-wrapper">
                                          <button className="btn">
                                            UPLOAD
                                          </button>
                                          <input
                                            type="file"
                                            accept="application/pdf"
                                            name={item.name}
                                            id={item.category_id}
                                            onChange={onPlumberDocUpload}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    {showDocPlumberError && (
                                      <span style={{ color: "red" }}>
                                        Please upload Id or Document.
                                      </span>
                                    )}
                                  </div>
                                  <p className="validation">
                                    {" "}
                                    Please upload your other document related to
                                    your {item.name} Id.
                                  </p>
                                </div>
                              );
                            })}
                        </>
                      )}
                    </div>
                  </div>

                  <div className="post_button_box">
                    <div className="main_btn">
                      <button type="submit" className="disabled">
                        Update
                      </button>
                    </div>
                  </div>
                </form>
              </div>

              <div role="tabpanel" className="tab-pane" id="change_password">
                <h2>Change your password ?</h2>
                {/* <p className="head_content_paragraph">Fill the information below to change password.</p> */}
                <form
                  onSubmit={handleSubmit((val: any) =>
                    submit(val, "changePassword")
                  )}
                >
                  <div className="form_input_box">
                    <div className="form-group">
                      <label>OLD PASSWORD</label>
                      <div className="relative-boxcustomIcon">
                        <input
                          type={isrevealPassword ? "text" : "password"}
                          maxLength={32}
                          value={oldPassword}
                          onChange={onOldPasswordChanged}
                          className="form-control"
                          placeholder="Old Password"
                        />
                        {oldPasswordError.length > 0 && (
                          <span style={{ color: "red" }}>
                            {oldPasswordError}
                          </span>
                        )}
                        {isrevealPassword ? (
                          <i
                            className="fa fa-eye eyeCustomIcon color_blue"
                            aria-hidden="true"
                            onClick={() => togglePassword()}
                          ></i>
                        ) : (
                          <i
                            className="fa fa-eye eyeCustomIcon"
                            aria-hidden="true"
                            onClick={() => togglePassword()}
                          ></i>
                        )}
                      </div>
                    </div>
                    <div className="form-group">
                      <label>NEW PASSWORD</label>
                      <div className="relative-boxcustomIcon">
                        <input
                          type={isrevealNewPassword ? "text" : "password"}
                          maxLength={32}
                          value={newPassword}
                          onChange={onNewPasswordChanged}
                          className="form-control"
                          placeholder="New Password"
                        />
                        {newPasswordError.length > 0 && (
                          <span style={{ color: "red" }}>
                            {newPasswordError}
                          </span>
                        )}
                        {isrevealNewPassword ? (
                          <i
                            className="fa fa-eye eyeCustomIcon color_blue"
                            aria-hidden="true"
                            onClick={() => toggleNewPassword()}
                          ></i>
                        ) : (
                          <i
                            className="fa fa-eye eyeCustomIcon"
                            aria-hidden="true"
                            onClick={() => toggleNewPassword()}
                          ></i>
                        )}
                      </div>
                    </div>
                    <div className="form-group">
                      <label>CONFIRM NEW PASSWORD</label>
                      <div className="relative-boxcustomIcon">
                        <input
                          type={isrevealConfirmassword ? "text" : "password"}
                          maxLength={32}
                          value={confirmPassword}
                          onChange={onConfirmPasswordChanged}
                          className="form-control"
                          placeholder="Confirm Password"
                        />
                        {confirmPasswordError.length > 0 && (
                          <span style={{ color: "red" }}>
                            {confirmPasswordError}
                          </span>
                        )}
                        {isrevealConfirmassword ? (
                          <i
                            className="fa fa-eye eyeCustomIcon color_blue"
                            aria-hidden="true"
                            onClick={() => toggleConfirmPassword()}
                          ></i>
                        ) : (
                          <i
                            className="fa fa-eye eyeCustomIcon"
                            aria-hidden="true"
                            onClick={() => toggleConfirmPassword()}
                          ></i>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="post_button_box">
                    <div className="main_btn">
                      <button type="submit" className="disabled">
                        Update
                      </button>
                    </div>
                  </div>
                </form>
              </div>

              <div role="tabpanel" className="tab-pane" id="my_payment_method">
                <h2>My Payment Method</h2>
                <p className="head_content_paragraph">
                  Change your payment method details.
                </p>
                {/* <form> */}
                <div className="form_input_box">
                  {banks &&
                    banks.length > 0 &&
                    banks.map((item, index) => {
                      return (
                        <div className="payment_method-details">
                          <div className="bank_info_box">
                            <img src={bank_image} alt="bank_image" />
                            <p>{change_to_star_number(item.account_no)}</p>
                            <span>{item.bank_name}</span>

                            <button
                              onClick={() => deleteBank(item._id)}
                              className="bank_dlt button_borderless"
                            >
                              <i
                                className="fa fa-trash-o"
                                aria-hidden="true"
                              ></i>
                            </button>
                          </div>
                        </div>
                      );
                    })}
                  {banks && banks.length == 0 ? (
                    <button
                      onClick={() => showModal()}
                      className="add_new addnew_card button_borderless"
                      style={{ marginTop: 5 }}
                    >
                      + Add New Bank
                    </button>
                  ) : null}
                </div>
                {/* </form> */}
              </div>

              <div role="tabpanel" className="tab-pane" id="my_transaction">
                <h2 className="pull-left">My Transaction</h2>
                <div className="date_picker pull-right inline-datepicker">
                  <div className="form-group">
                    <div className="date">
                      <div className="input-group input-append date">
                        {/* <input type="text" className="form-control" name="date" id="datepicker" /> */}

                        <DatePicker
                          selected={transactionDate}
                          onChange={handletransactionDateChange}
                          value={transactionDate}
                          maxDate={new Date()}
                          relativeSize={true}
                          autoComplete="off"
                          placeholderText="DD/MM/YY"
                          onKeyDown={(e: { preventDefault: () => any }) =>
                            e.preventDefault()
                          }
                          className="form-control"
                          showMonthYearPicker
                          dateFormat="MMMM, yyyy"
                        />
                        <span className="input-group-addon add-on">
                          <span className="glyphicon glyphicon-calendar"></span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="clear"></div>
                <div className="my_job_content my-job-content-two my-transaction_box">
                  <ul>
                    {transactions &&
                      transactions.transactions.length > 0 &&
                      transactions.transactions.map((item, index) => {
                        return (
                          <li className="tab_content_box">
                            <h1>
                              <button className="button_borderless">
                                {item.job_id.title}
                              </button>
                            </h1>
                            <div className="payment-by_box">
                              <h5>
                                <span className="calender_image">
                                  <img
                                    src={calender_image}
                                    alt="calender_image"
                                  />
                                </span>
                                <span>{item.date_of_payment}</span>
                                <p className="payment-by">
                                  Payment By:<span>{item.customer.name}</span>
                                </p>
                              </h5>
                            </div>

                            <p className="more_content">
                              {item.job_id.description}
                              {/* <span className="more">
                                                    <button className="button_borderless">more</button>
                                                </span> */}
                            </p>

                            <div className="milestones_box">
                              <h1 className="heading">Stages</h1>
                              <div className="milestones_box_content">
                                <span className="rouned">1</span>
                                <h3>
                                  {item.milestone_id.title}{" "}
                                  <span>${item.actual_amount}</span>
                                </h3>

                                <p>
                                  After {item.deduction_percent}% dedution
                                  <span>
                                    <a
                                      onClick={() =>
                                        download_invoice_api(item._id)
                                      }
                                      download
                                    >
                                      <img
                                        src={download_invoice}
                                        alt="download_invoice"
                                      />{" "}
                                      Download Invoice
                                    </a>
                                  </span>
                                </p>
                              </div>
                            </div>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </div>

              <div role="tabpanel" className="tab-pane" id="help_support">
                <h2>Help & Support</h2>
                <p className="head_content_paragraph">
                  Feel free to ask questions. We will respond within 24 hours.
                </p>
                <form
                  onSubmit={handleSubmit((val: any) =>
                    submit(val, "helpAndSupport")
                  )}
                >
                  <div className="form_input_box">
                    <div className="form-group">
                      <label>NAME</label>
                      <input
                        type="text"
                        maxLength={20}
                        onChange={onHelpNameChanged}
                        value={helpName}
                        className="form-control"
                        placeholder="Enter Name"
                      />
                      {helpNameError.length > 0 && (
                        <span style={{ color: "red" }}>{helpNameError}</span>
                      )}
                      {/* <Field
                                                name="name"
                                                type="text"
                                                maxLength={20}
                                                component={renderField}
                                                label="NAME"
                                                placeholder="Enter Name"
                                            /> */}
                    </div>
                    <div className="form-group">
                      <label>EMAIL</label>
                      <div className="input_box">
                        <input
                          type="text"
                          maxLength={40}
                          onChange={onEmailChanged}
                          value={email}
                          className="form-control"
                          placeholder="Enter Email Address"
                        />
                      </div>
                      {emailError.length > 0 && (
                        <span style={{ color: "red" }}>{emailError}</span>
                      )}
                      {/* <Field
                                                name="email"
                                                type="text"
                                                maxLength={40}
                                                component={renderField}
                                                label="EMAIL"
                                                placeholder="Enter Email Address"
                                            /> */}
                    </div>
                    <div className="form-group ">
                      <label>SUBJECT</label>
                      <input
                        type="text"
                        maxLength={50}
                        onChange={onSubjectChanged}
                        value={subject}
                        className="form-control "
                        placeholder="Enter Subject "
                      />
                      {subjectError.length > 0 && (
                        <span style={{ color: "red" }}>{subjectError}</span>
                      )}
                      {/* <Field
                                                name="subject"
                                                type="text"
                                                maxLength={50}
                                                component={renderField}
                                                label="SUBJECT"
                                                placeholder="Enter Subject"
                                            /> */}
                    </div>
                    <div className="form-group ">
                      <label>MESSAGE</label>
                      <textarea
                        placeholder="Message"
                        onChange={onMessageChanged}
                        value={message}
                        maxLength={200}
                      ></textarea>
                      {messageError.length > 0 && (
                        <span style={{ color: "red" }}>{messageError}</span>
                      )}
                      {/* <Field
                                                name="message"
                                                type="text"
                                                maxLength={200}
                                                component={renderTextareaField}
                                                label="MESSAGE"
                                                placeholder="Message"
                                            /> */}
                    </div>
                  </div>
                  <div className="post_button_box ">
                    <div className="main_btn ">
                      <button className="button_borderless">Submit</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </aside>
        </div>
      </div>
      <div className="loader-box" style={loaderStyle}>
        <div className="loader-image">
          <img src={loader_image} />
        </div>
      </div>

      <Modal
        ariaHideApp={false}
        isOpen={addCardModal}
        onRequestClose={closeModal}
        className="modal-content new_modal_content login_modal"
        contentLabel="Example Modal"
        style={customStyles1}
      >
        {/* <div className="modal fade" id="add_new_card" role="dialog"> */}
        {/* <div className="modal-dialog login_modal"> */}

        <div className="modal-content new_modal_content add_new_card">
          <div className="modal-header">
            <h4 className="modal-title">
              Add Bank Details
              <span>
                <i
                  onClick={() => closeModal()}
                  className="fa fa-times-circle-o"
                  aria-hidden="true"
                  data-dismiss="modal"
                ></i>
              </span>{" "}
            </h4>
          </div>

          <div className="modal-body">
            <div className="input_form_box form_input_box">
              <form
                onSubmit={handleSubmit((val: any) =>
                  submit(val, "add_provider_bank_details")
                )}
              >
                <div className="form-group">
                  <label>BANK NAME</label>
                  <div className="input_content">
                    {/* <Field
                                        name="accountName"
                                        type="text"
                                        maxLength={20}
                                        component={renderField}
                                        label="ACCOUNT HOLDER NAME"
                                        placeholder="Enter Account Holder Name"
                                    /> */}
                    <input
                      type="text"
                      value={accountName}
                      onChange={onAccountNameChanged}
                      className="form-control"
                      placeholder="Enter Bank Name"
                    />
                  </div>
                  {accountNameError.length > 0 && (
                    <span style={{ color: "red" }}>{accountNameError}</span>
                  )}
                </div>
                <div className="form-group">
                  <label>ACCOUNT NUMBER</label>
                  <div className="input_content">
                    <input
                      type="text"
                      value={accountNumber}
                      onChange={onAccountNumberChanged}
                      className="form-control"
                      placeholder="Enter Account Number"
                    />
                    {/* <Field
                                        name="accountNumber"
                                        type="text"
                                        maxLength={20}
                                        component={renderField}
                                        label="ACCOUNT NUMBER"
                                        placeholder="Enter Account Number"
                                    /> */}
                  </div>
                  {accountNumberError.length > 0 && (
                    <span style={{ color: "red" }}>{accountNumberError}</span>
                  )}
                </div>
                <div className="form-group">
                  <label>Branch Name</label>
                  <div className="input_content">
                    <input
                      type="text"
                      onChange={onBranchNameChanged}
                      value={branchName}
                      className="form-control"
                      maxLength={30}
                      placeholder="Enter Branch Name"
                    />
                  </div>
                  {branchNameError.length > 0 && (
                    <span style={{ color: "red" }}>{branchNameError}</span>
                  )}
                </div>

                <div className="form-group">
                  <label>ROUTING NUMBER</label>
                  <div className="input_content">
                    <input
                      type="text"
                      onChange={onRoutingChanged}
                      value={routingNumber}
                      className="form-control"
                      placeholder="Enter Routing Number"
                    />
                    {/* <Field
                                        name="routingNumber"
                                        type="number"
                                        maxLength={30}
                                        component={renderField}
                                        label="ROUTING NUMBER"
                                        placeholder="Enter Routing Number"
                                    /> */}
                  </div>
                  {routingNumberError.length > 0 && (
                    <span style={{ color: "red" }}>{routingNumberError}</span>
                  )}
                </div>
                <div className="row next_bottom">
                  {/* <div className="col-md-6">
                                    <div className="main_btn border_button">
                                        <a data-toggle="modal" onClick={SetUploaddocModal} data-target="#provider-upload-document" data-dismiss="modal">Back</a>
                                    </div>
                                </div> */}
                  <div className="col-md-12">
                    <div className="main_btn ">
                      <button
                        type="submit"
                        data-toggle="modal"
                        data-target="#provider-add-profile"
                        data-dismiss="modal"
                      >
                        save
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* </div>
                </div> */}
      </Modal>
    </section>
  );
};

export default reduxForm({
  form: "loginForm", // a unique identifier for this form
})(Settings);
