import React, { useEffect, useState } from "react";
import loader_image from "../../assets/images/loader.gif";
import { userService } from "../../_services";
import { Field, reduxForm, SubmissionError } from "redux-form";
import { config } from "../../config/config";
import Modal from "react-modal";

import { Layout } from "../common/Layout";
import close_image from "../../assets/images/close.png";
import profile_image from "../../assets/images/profile_logo.jpg";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
} from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import bid_image from "../../assets/images/bid_image.png";
import slider_image from "../../assets/images/slider_image.png";
import left_arrow from "../../assets/images/left_arrow.png";
import right_arrow from "../../assets/images/right_arrow.png";
import chat_image from "../../assets/images/chat_image.png";
import { jobsService } from "../../_services";
import StarRatings from "react-star-ratings";
interface fieldInterface {
  input: any;
  label: string;
  type: string;
  meta: {
    touched: boolean;
    error: string;
  };
}

const customStyles1 = {
  overlay: {
    display: "block",
    paddingRight: "15px",
    backgroundColor: "rgba(51,51,51,0.8)",
    zIndex: 99,
  },

  content: {
    position: "",
    top: "",
    right: "",
    bottom: "",
    left: "",
  },
};

const Jobdetail = (props: any) => {
  useEffect(() => {
    getData();
  }, []);

  const downloadFile = (url) => {
    console.log(url);
    const fileUrl = `https://tendr.live:8443/attachments/${url}`;
    const anchor = document.createElement("a");
    anchor.href = fileUrl;
    anchor.download = `${url}`; // Set the desired file name

    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };

  function isEmptyObj(object) {
    for (var key in object) {
      if (object.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }

  function bidDetail(bid_id: any) {
    jobsService
      .bidDetail(bid_id as string)
      .then(function (response) {
        console.log("responseeeeeeeeeeeeeeeee");
        console.log(response);
        setSelBid(response.data.bid);
        setBidPrice(response.data.bid.bid_price);
        setbidModal(true);
      })
      .catch(function (error) {
        userService.handleError(error);
      });
  }

  function acceptRejectBid(status) {
    console.log("accept bid");
    const formData = new URLSearchParams();
    formData.append("bid_id", selBid._id);
    formData.append("job_id", selBid.job_id._id);
    formData.append("status", status);
    formData.append("bid_price", bidPrice);
    formData.append("bidder_id", selBid.user_id._id);

    console.log(formData.toString());
    jobsService
      .acceptRejectBid(formData.toString())
      .then(function (response) {
        setLoaderstyle({ display: "none" });
        console.log("bid accepted");
        console.log(response);

        if (status == "ACC") {
          alert("Bid Accepted Successfully.");
        } else if (status == "REJ") {
          alert("Bid Rejected Successfully.");
        }

        getData();
        closeModal();
      })
      .catch(function (error) {
        console.log("error", error);
        setLoaderstyle({ display: "none" });
        userService.handleError(error);
      });
  }

  function getData() {
    setLoaderstyle({ display: "block" });
    console.log("getdata");
    console.log(id);
    jobsService
      .getJob(id as string)
      .then(function (response) {
        setLoaderstyle({ display: "none" });
        setJobdetail(response.data.job);
        console.log("data---", response.data);
        console.log(response.data.job.hiredBidder);
        console.log(isEmptyObj(response.data.job.hiredBidder));
        // let isbid:any=false;
        // isbid = is_bid(response.data.job)
        // setLogoutpopup(isbid)
      })
      .catch(function (error) {
        setLoaderstyle({ display: "none" });
        userService.handleError(error);
      });
  }

  const [approveModal, setapproveModal] = useState(false);
  const [bidModal, setbidModal] = useState(false);
  const [rejectModal, setrejectModal] = useState(false);
  const [loaderStyle, setLoaderstyle] = useState({ display: "none" });
  const urlParams = new URLSearchParams(window.location.search);
  const myParam = urlParams.get("id");
  const [jobDetail, setJobdetail] = useState() as any;
  const [selBid, setSelBid] = useState() as any;
  const [bidPrice, setBidPrice] = useState() as any;

  const [reviewModal, setReviewModal] = useState(false);
  const [reviewAry, setReviewAry] = useState("") as any;

  function closeModal() {
    setapproveModal(false);
    setrejectModal(false);
    setbidModal(false);
    setReviewModal(false);
  }

  function renderReviewModal(user_id) {
    setLoaderstyle({ display: "block" });
    userService
      .userReviews(user_id)
      .then(function (response) {
        setLoaderstyle({ display: "none" });
        console.log("reviewsss", response.data);
        setReviewAry(response.data);
        setReviewModal(true);
      })
      .catch(function (error) {
        setLoaderstyle({ display: "none" });
        userService.handleError(error);
      });
  }

  function UpdateMilestone(status: any, id: string) {
    closeModal();
    setLoaderstyle({ display: "block" });
    const formData = new URLSearchParams();
    formData.append("milestone_id", id);
    formData.append("status", status);

    jobsService
      .milestones(formData.toString())
      .then(function (response) {
        setLoaderstyle({ display: "none" });

        getData();
      })
      .catch(function (error) {
        setLoaderstyle({ display: "none" });
        userService.handleError(error);
      });
  }

  let { id } = useParams<{ id: any }>();
  let all_bids_url = " /bid_message/" + id;

  const { error, className, handleSubmit, pristine, reset, submitting } = props;
  return (
    <div className="full_area">
      <div className="loader-box" style={loaderStyle}>
        <div className="loader-image">
          <img src={loader_image} />
        </div>
      </div>

      {jobDetail && jobDetail.job_images.length > 0 ? (
        <section
          className="preview_post_banner_area"
          style={{
            backgroundImage: `url(${jobDetail.job_images[0].job_image})`,
          }}
        ></section>
      ) : (
        <section className=""></section>
      )}

      {jobDetail && (
        <section className="slider_preview_post">
          <div className="container">
            <div className="row">
              <div className="col-md-1"></div>
              <div
                className={
                  jobDetail && jobDetail.job_images.length > 0
                    ? "col-md-4 slider-box"
                    : "col-md-4 slider-box without_image"
                }
              >
                {jobDetail.job_images.length > 0 ? (
                  <Carousel autoPlay>
                    {jobDetail.job_images.map((item, index) => {
                      return (
                        <div>
                          <img
                            src={item.job_image}
                            alt="slider_image.png"
                            style={{ width: "100%" }}
                          />
                        </div>
                      );
                    })}
                  </Carousel>
                ) : null}
                {/* images end for slider */}
                {jobDetail ? (
                  <div>
                    {jobDetail &&
                    isEmptyObj(jobDetail.hiredBidder) &&
                    jobDetail.bids.length > 0 ? (
                      <div className="all_bid_box">
                        <div className="heading">
                          <h1>
                            All Bids{" "}
                            <span>
                              <a href={all_bids_url}>View All</a>{" "}
                            </span>
                          </h1>
                        </div>
                        <div className="all_bid_content">
                          <ul>
                            {jobDetail.bids.map((item, index) => {
                              return (
                                <li>
                                  <div className="content-box no_chat">
                                    <img
                                      src={item.user_id.profile_image}
                                      alt={item.user_id.name}
                                    />

                                    <h1>
                                      <a
                                        href={`/service_provider_profile/${item.user_id._id}/0/0`}
                                      >
                                        {item.user_id.name}
                                      </a>
                                      <span>{item.time_ago}</span>
                                    </h1>
                                    <p>
                                      <StarRatings
                                        rating={item.user_id.avgrating}
                                        starRatedColor="#f0bf0c"
                                        starDimension="14px"
                                        starSpacing="1px"
                                        starEmptyColor="#cbd3e4"
                                        numberOfStars={5}
                                        name="rating"
                                      />
                                      <a
                                        onClick={() =>
                                          renderReviewModal(item.user_id._id)
                                        }
                                      >
                                        ({item.user_id.reviews}{" "}
                                        {item.user_id.reviews < 2
                                          ? "review"
                                          : "reviews"}
                                        )
                                      </a>
                                      <span>${item.bid_price}</span>
                                    </p>
                                    {/* <a onClick={()=>bidDetail(item._id) } data-toggle="modal" data-target="#bid_modal">View Bid Message</a> */}
                                    <a
                                      href={
                                        "/bid_message/" + id + "/" + item._id
                                      }
                                    >
                                      View Bid Message
                                    </a>
                                  </div>
                                  <div className="clear"></div>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    ) : jobDetail && !isEmptyObj(jobDetail.hiredBidder) ? (
                      <div className="all_bid_box">
                        <div className="heading">
                          <h1>Hired Applicant </h1>
                        </div>
                        <div className="all_bid_content">
                          <ul>
                            <li>
                              <div className="content-box">
                                <img
                                  src={jobDetail.hiredBidder.profile_image}
                                  alt={jobDetail.hiredBidder.name}
                                />
                                <h1>
                                  {" "}
                                  {jobDetail.hiredBidder.name}{" "}
                                  <span>{jobDetail.hiredBid.time_ago}</span>
                                </h1>
                                <p>
                                  <StarRatings
                                    rating={jobDetail.hiredBidder.avgrating}
                                    starRatedColor="#f0bf0c"
                                    starDimension="14px"
                                    starSpacing="1px"
                                    starEmptyColor="#cbd3e4"
                                    numberOfStars={5}
                                    name="rating"
                                  />
                                  <a href="">
                                    ({jobDetail.hiredBidder.reviews} reviews)
                                  </a>
                                  <span>
                                    {jobDetail.status != "CAN" &&
                                      "$ " + jobDetail.hiredBid.bid_price}
                                  </span>
                                </p>
                                {/* <a onClick={()=>bidDetail(jobDetail.hiredBid._id) } data-toggle="modal" data-target="#bid_modal">View Bid Message</a> */}
                              </div>
                              <div className="clear"></div>
                            </li>
                          </ul>
                        </div>
                        {jobDetail.complete_status ? (
                          <div className="progress_bottom job_completed milestone_completed_customer text_green">
                            <p className=""> Completed</p>
                          </div>
                        ) : jobDetail.status == "CAN" ? (
                          <div className="progress_bottom">
                            <p>This job has been cancelled</p>
                          </div>
                        ) : (
                          <div className="progress_bottom">
                            <p>This job is in progress</p>
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className="all_bid_box">
                        <div className="heading">
                          <h1>No Bids</h1>
                        </div>
                      </div>
                    )}
                  </div>
                ) : null}
              </div>
              {jobDetail && (
                <div className="col-md-6">
                  <div className="slider_right_content">
                    <h2 className="posted">
                      Posted <span>: {jobDetail.time_ago}</span>
                    </h2>
                    <h1>{jobDetail.title}</h1>
                    {/* { isEmptyObj(jobDetail.hiredBidder) ? null : (jobDetail.job_status === "in_progress") ? <p className="job-in-progress"> The job is in progress.  </p> : (jobDetail.job_status === "waiting") ? <p className="job-waiting"> The job is waiting to start.  </p> : <p className="job-completed"> The job is completed.  </p> } */}
                    <ul>
                      <li>
                        <p>
                          Service Category<span>{jobDetail.category.name}</span>
                        </p>
                      </li>
                      <li>
                        <p>
                          Start Date & Time
                          <span>
                            {jobDetail.start_date} & {jobDetail.start_time}
                          </span>
                        </p>
                      </li>
                      <li>
                        <p>
                          End Date & Time
                          <span>
                            {jobDetail.end_date} & {jobDetail.end_time}
                          </span>
                        </p>
                      </li>
                      <li>
                        <p>
                          Budget<span>${jobDetail.budget}</span>
                        </p>
                      </li>
                      <li className="location-job">
                        <p className="confirm_post_location">
                          Location<span>{jobDetail.location}</span>
                        </p>
                      </li>
                      <li>
                        <p>
                          Bids
                          <span>{jobDetail.bids.length}</span>
                        </p>
                      </li>
                      <li>
                        <p className="description">Description</p>
                        <p className="description_content">
                          {/* <pre> */}
                          {jobDetail.description}
                          {/* </pre> */}
                        </p>
                      </li>
                      {jobDetail?.attachment != null ||
                      jobDetail.attachment != "null" ? (
                        <li>
                          <p>
                            Attachment
                            <span>
                              {jobDetail?.attachment && (
                                <div>
                                  <button
                                    onClick={() =>
                                      downloadFile(
                                        jobDetail.attachment?.split("/").pop()
                                      )
                                    }
                                  >
                                    {jobDetail.attachment?.split("/").pop()}
                                  </button>
                                </div>
                              )}
                              {/* {jobDetail?.attachment.map((item, index) => {
                              // console.log("item", item);
                              return (
                                <div key={index}>
                                  <a href={item} target="_blank">
                                    {item?.split("/").pop()}
                                  </a>
                                </div>
                              );
                            })} */}
                            </span>
                          </p>
                        </li>
                      ) : (
                        <div></div>
                      )}
                    </ul>
                  </div>
                </div>
              )}
            </div>
          </div>
          <Modal
            ariaHideApp={false}
            isOpen={bidModal}
            onRequestClose={closeModal}
            className="modal-content new_modal_content modal-dialog makepayment-modal"
            contentLabel="Example Modal"
            style={customStyles1}
          >
            {/* <a onClick={()=>setapproveModal(true)} > Accept</a> */}

            <div className="modal-header">
              <h4 className="modal-title">
                View Bid Message
                <span>
                  <i
                    onClick={closeModal}
                    className="fa fa-times-circle-o"
                    aria-hidden="true"
                    data-dismiss="modal"
                  ></i>
                </span>{" "}
              </h4>
            </div>
            <div className="modal-body">
              {selBid && (
                <div className="payment_method_content bid_message_content">
                  <ul>
                    <li>
                      <span>Cost</span>
                      <span className="span_right_price">
                        ${selBid.bid_price}
                      </span>
                    </li>
                    <li>
                      <h3>Bid Message</h3>
                      <span>{selBid.bid_message}</span>
                    </li>
                    <li>
                      <div className="message mileston">
                        <h4>Stages</h4>

                        {selBid &&
                          selBid.milestones.map((item, index) => {
                            return (
                              <div className="mileston_list">
                                <span className="list_span">1</span>
                                <p>
                                  {item.title} <span>${item.price}</span>
                                </p>
                                <p>{item.description}</p>

                                {item.payment_status === "1" && (
                                  <p style={{ color: "orange" }}>
                                    Payment is pending
                                  </p>
                                )}
                                {(item.payment_status === "2" ||
                                  item.payment_status == "5") && (
                                  <p style={{ color: "orange" }}>
                                    Payment in escrow
                                  </p>
                                )}
                                {item.payment_status === "3" && (
                                  <p style={{ color: "orange" }}>
                                    {selBid.user_id.name} sent you a request to
                                    approve payment for this stage
                                  </p>
                                )}
                                {item.payment_status === "4" && (
                                  <p style={{ color: "orange" }}>
                                    Payment Approved
                                  </p>
                                )}

                                {item.payment_status == 1 && (
                                  <a
                                    className="btn btn-danger"
                                    onClick={() => UpdateMilestone(2, item._id)}
                                  >
                                    Pay Now
                                  </a>
                                )}
                                {item.payment_status == "3" && (
                                  <ul className="make_payment_list">
                                    <li>
                                      <div className="next_bottom">
                                        <div className="main_btn">
                                          <a
                                            onClick={() =>
                                              UpdateMilestone(4, item._id)
                                            }
                                          >
                                            Accept
                                          </a>
                                        </div>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="next_bottom margin-top-15">
                                        <div className="main_btn border_button">
                                          <a
                                            onClick={() =>
                                              UpdateMilestone(5, item._id)
                                            }
                                          >
                                            Reject
                                          </a>
                                        </div>
                                      </div>
                                    </li>
                                    <div className="clear"></div>
                                  </ul>
                                )}
                              </div>
                            );
                          })}
                      </div>
                    </li>
                  </ul>
                </div>
              )}

              {/* {( jobDetail && (isEmptyObj(jobDetail.hiredBidder))) &&
                    <ul className="make_payment_list">
                        <li>
                            <div className="next_bottom">
                                <div className="main_btn">
                                    <a onClick={() => setapproveModal(true)} >Accept</a>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="next_bottom margin-top-15">
                                <div className="main_btn border_button">
                                    <a onClick={() => setrejectModal(true)} >Reject</a>
                                </div>
                            </div>
                        </li>
                        <div className="clear"></div>
                    </ul>
} */}
            </div>
          </Modal>

          <Modal
            ariaHideApp={false}
            isOpen={approveModal}
            onRequestClose={closeModal}
            className="modal-content new_modal_content modal-dialog makepayment-modal"
            contentLabel="Example Modal"
            style={customStyles1}
          >
            {/* <a onClick={()=>setapproveModal(true)} > Accept</a> */}

            <div className="modal-header">
              <h4 className="modal-title">
                Make Payment
                <span>
                  <i
                    onClick={closeModal}
                    className="fa fa-times-circle-o"
                    aria-hidden="true"
                    data-dismiss="modal"
                  ></i>
                </span>{" "}
              </h4>
            </div>
            <div className="modal-body">
              {selBid && (
                <h3 className="accept">
                  Are you sure you want to accept a bid sent from "
                  {selBid.user_id.name}" on cost "${selBid.bid_price}".{" "}
                </h3>
              )}

              <h4>Escrow Amount</h4>
              {selBid && (
                <div className="radio_button_box radio_margin">
                  <label className="radio_box">
                    Deposit the entire amount for the whole project.
                    <input
                      type="radio"
                      value={selBid.bid_price}
                      onClick={() => setBidPrice(selBid.bid_price)}
                      checked
                      name="radio"
                    />
                    <span className="checkmark"></span>
                  </label>
                  <label className="radio_box">
                    Deposit the amount for the first stage.
                    <input
                      type="radio"
                      value={selBid.milestones[0].price}
                      onClick={() => setBidPrice(selBid.milestones[0].price)}
                      name="radio"
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
              )}
              <h5>
                {selBid ? selBid.milestones[0].title : ""}
                <span>${selBid ? selBid.milestones[0].price : 0}</span>
              </h5>

              <p className="red_text">
                * Your money is safe in escrow, we will release amount to your
                worker only for approved stage by you.
              </p>

              {jobDetail && isEmptyObj(jobDetail.hiredBidder) && (
                <ul className="make_payment_list">
                  <li>
                    <div className="next_bottom">
                      <div className="main_btn">
                        <a onClick={() => acceptRejectBid("ACC")}>
                          Make Payment
                        </a>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="next_bottom margin-top-15">
                      <div className="main_btn border_button">
                        <a onClick={closeModal}>Not Now</a>
                      </div>
                    </div>
                  </li>
                  <div className="clear"></div>
                </ul>
              )}
            </div>
          </Modal>

          <Modal
            ariaHideApp={false}
            isOpen={rejectModal}
            onRequestClose={closeModal}
            className="modal-content  new_modal_content makepayment-modal"
            contentLabel="Example Modal"
            style={customStyles1}
          >
            <div className="modal-header">
              <h4 className="modal-title">
                Decline Bid
                <span>
                  <i
                    onClick={closeModal}
                    className="fa fa-times-circle-o"
                    aria-hidden="true"
                    data-dismiss="modal"
                  ></i>
                </span>{" "}
              </h4>
            </div>
            <div className="modal-body">
              {selBid && (
                <h3 className="accept">
                  Are you sure you want to reject a bid sent from "
                  {selBid.user_id.name}" on cost "${selBid.bid_price}".{" "}
                </h3>
              )}
              {jobDetail && isEmptyObj(jobDetail.hiredBidder) && (
                <ul className="make_payment_list">
                  <li>
                    <div className="next_bottom">
                      <div className="main_btn">
                        <a onClick={() => acceptRejectBid("REJ")}>Reject</a>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="next_bottom margin-top-15">
                      <div className="main_btn border_button">
                        <a onClick={closeModal}>Not Now</a>
                      </div>
                    </div>
                  </li>
                  <div className="clear"></div>
                </ul>
              )}
            </div>
          </Modal>
          <Modal
            ariaHideApp={false}
            isOpen={reviewModal}
            onRequestClose={closeModal}
            className={"modal-content   new_modal_content add_new_card "}
            contentLabel="Example Modal"
            style={customStyles1}
          >
            <div className="modal-header">
              <h4 className="modal-title">
                Reviews
                <span>
                  <i
                    onClick={closeModal}
                    className="fa fa-times-circle-o"
                    aria-hidden="true"
                    data-dismiss="modal"
                  ></i>
                </span>{" "}
              </h4>
            </div>
            <div className="modal-body">
              <ul className="review_modal_ul">
                {reviewAry &&
                  reviewAry.reviews.length > 0 &&
                  reviewAry.reviews.map((item, index) => {
                    return (
                      <div className="review_popup">
                        <img src={item.user_id.profile_image} />

                        <div className="review_content">
                          <h3>
                            {item.user_id.name} <span>{item.time_ago}</span>
                          </h3>
                          <StarRatings
                            rating={parseInt(item.rating)}
                            starRatedColor="#f0bf0c"
                            starDimension="20px"
                            starSpacing="1px"
                            starEmptyColor="#cbd3e4"
                            numberOfStars={5}
                            name="rating"
                          />
                          {/* <button className="button_borderless">({ jobDetail.user_id.reviews} {jobDetail.user_id.reviews < 2 ? "review" : "reviews"}   )</button> */}
                          <p>{item.message}</p>
                        </div>
                      </div>
                    );
                  })}
              </ul>
            </div>
          </Modal>
        </section>
      )}
    </div>
  );
};

export default reduxForm({
  form: "loginForm", // a unique identifier for this form
})(Jobdetail);
