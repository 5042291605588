import React, { useEffect, useState } from "react";
import { userService } from "../../_services";
import { reduxForm } from "redux-form";
// import { config } from '../../config/config'
import Modal from "react-modal";
import { jobsService } from "../../_services";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
// import {Layout} from '../common/Layout'
// import close_image from '../../assets/images/close.png';
// import profile_image from '../../assets/images/profile_logo.jpg';
// import loader_image from '../../assets/images/loading.gif';
// import gallery_image1 from '../../assets/images/gallery_image1.png';
// import gallery_image2 from '../../assets/images/gallery_image2.png';
// import gallery_image3 from '../../assets/images/gallery_image3.png';
// import gallery_image4 from '../../assets/images/gallery_image4.png';
// import gallery_image5 from '../../assets/images/gallery_image5.png';
// import gallery_image6 from '../../assets/images/gallery_image6.png';
// import gallery_image7 from '../../assets/images/gallery_image7.png';
// import gallery_image8 from '../../assets/images/gallery_image8.png';
// import gallery_image9 from '../../assets/images/gallery_image9.png';
// import gallery_image10 from '../../assets/images/gallery_image10.png';
// import gallery_image11 from '../../assets/images/gallery_image11.png';
import certified from "../../assets/images/certified.png";
import StarRatings from "react-star-ratings";
import fav_heart from "../../assets/images/fav-heart.png";
import unfav_heart from "../../assets/images/unfav-heart.png";

import { ProviderDetail } from "../common/ProviderDetail";
import {
  BrowserRouter as Router,
  // Switch,
  // Route,
  // Link,
  useParams,
} from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
// import { Carousel } from 'react-responsive-carousel';
// import bid_image from '../../assets/images/bid_image.png';
// import background_user_image from '../../assets/images/admin_smith_image.png';
// import left_arrow from '../../assets/images/left_arrow.png';
// import right_arrow from '../../assets/images/right_arrow.png';

import loader_image from "../../assets/images/loader.gif";
// interface fieldInterface {
//    input: any;
//    label: string;
//    type: string;
//    meta: {
//        touched: boolean;
//        error: string;
//    };
// }
//photoIndex: 0,
// isOpen: false,
const ServiceProviderProfile = (props: any) => {
  const [profiledata, setProfileData] = useState() as any;
  const [setinvite, setInvite] = useState(false);
  const [loaderStyle, setLoaderstyle] = useState({ display: "none" });
  const [activeJobs, setActiveJobs] = useState() as any;
  const [inviteJobId, setinviteJobId] = useState() as any;
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setisOpen] = useState(false);
  const [image, setImage] = useState("");
  const [noJobModal, setNoJobModal] = useState(false);
  let category: any;
  function SetInvite() {
    get_active_jobs(0);
  }

  const [reviewModal, setReviewModal] = useState(false);
  const [reviewAry, setReviewAry] = useState("") as any;

  function renderReviewModal(user_id) {
    setLoaderstyle({ display: "block" });
    userService
      .userReviews(user_id)
      .then(function (response) {
        setLoaderstyle({ display: "none" });
        console.log("reviewsss", response.data);
        setReviewAry(response.data);
        setReviewModal(true);
      })
      .catch(function (error) {
        setLoaderstyle({ display: "none" });
        userService.handleError(error);
      });
  }

  function closemodal() {
    setInvite(false);
    setReviewModal(false);
  }

  const customStyles1 = {
    overlay: {
      display: "block",
      paddingRight: "15px",
      backgroundColor: "rgba(51,51,51,0.8)",
      zIndex: 99,
    },

    content: {
      position: "",
      top: "",
      right: "",
      bottom: "",
      left: "",
    },
  };

  useEffect(() => {
    get_data();
    //    setProfile(JSON.parse(localStorage.getItem("user") as string).image)
  }, []);

  function get_data() {
    setLoaderstyle({ display: "block" });
    userService
      .provider_profile(
        props.match.params.id,
        props.match.params.lat,
        props.match.params.lng
      )
      .then(function (response) {
        // console.log(response,"==========profile")
        console.log(response.data);
        setProfileData(response.data.user);
        console.log("------------------", response.data.user);

        category += response.data.user.categories.map((item, key) => (
          <span className={item.name}>{item.name}</span>
        ));
        closemodal();
        setLoaderstyle({ display: "none" });
      })
      .catch(function (error) {
        setLoaderstyle({ display: "none" });
        userService.handleError(error);
      });
  }

  function mark_unmark(provider_id, status, e) {
    e.preventDefault();
    let params = {
      provider_id: provider_id,
      status: status,
    };

    setLoaderstyle({ display: "block" });
    userService
      .favMarkUnMark(params)
      .then(function (response) {
        setLoaderstyle({ display: "none" });
        // if(status == "F"){
        //     alert("Successfully Marked favourite");
        // }else{
        //     alert("Successfully Marked unfavourite");
        // }

        get_data();
        // window.location.href = "/";
      })
      .catch(function (error) {
        console.log("errorrrrrr", error);
        setLoaderstyle({ display: "none" });
        userService.handleError(error);
      });
  }

  function inviteProvider() {
    setLoaderstyle({ display: "block" });
    let provider_id: any = props.match.params.id;
    let provider_ary: any = [];
    provider_ary.push(provider_id);
    let params = {
      job_id: inviteJobId,
      receiver_ids: provider_ary,
    };

    console.log(params);

    jobsService
      .sendInvite(params)
      .then(function (response) {
        setLoaderstyle({ display: "none" });
        console.log("bid accepted");
        console.log(response);
        alert("Invitation Sent Successfully");
        closemodal();
        window.location.href = "/my_jobs";
      })
      .catch(function (error) {
        console.log("error", error);
        setLoaderstyle({ display: "none" });
        userService.handleError(error);
      });
  }

  function get_active_jobs(page: number) {
    setLoaderstyle({ display: "block" });
    jobsService
      .getActiveJobs(page)
      .then(function (response) {
        setLoaderstyle({ display: "none" });
        setActiveJobs(response.data.jobs);
        console.log(response.data.jobs);
        if (response.data.jobs.length > 0) {
          setinviteJobId(response.data.jobs[0]._id);
          setInvite(true);
        } else {
          setNoJobModal(true);
        }
      })
      .catch(function (error) {
        console.log("error", error);
        setLoaderstyle({ display: "none" });
        userService.handleError(error);
      });
  }
  var htmltemplateCat: any = [];
  if (profiledata) {
    htmltemplateCat = Object.keys(profiledata.categories).map(function (
      key: any
    ) {
      return (
        <span className={profiledata.categories[key].name}>
          {profiledata.categories[key].name}
        </span>
      );
    });
  }

  var htmltemplateCatService: any = [];
  if (profiledata) {
    htmltemplateCatService = Object.keys(profiledata.categories).map(function (
      key: any
    ) {
      let subcategory = profiledata.categories[key].sub_categories.map(
        (item, key) => (
          <p>
            <span>{key + 1}</span>
            {item.name}
          </p>
        )
      );

      return (
        <div className="col-md-6">
          <div className="category_content">
            <h2>{profiledata.categories[key].name}</h2>
            {subcategory}
          </div>
        </div>
      );
    });
  }
  var htmltemplateGallery: any = [];
  if (profiledata) {
    if (profiledata.gallery.length > 0) {
      htmltemplateGallery = Object.keys(profiledata.gallery).map(function (
        key: any
      ) {
        // console.log("image", key);

        return (
          <div className="col-sm-3">
            <img
              src={profiledata.gallery[key].image}
              onClick={() => {
                setisOpen(true);
                setImage(profiledata.gallery[key].image);
              }}
            />
            {isOpen && (
              <Lightbox
                mainSrc={image}
                onCloseRequest={() => setisOpen(false)}
              />
            )}
          </div>
        );
      });
    } else {
    }
  }

  const { error, className, handleSubmit, pristine, reset, submitting } = props;
  return (
    <div>
      {profiledata ? (
        <section
          className="preview_post_banner_area"
          style={{
            backgroundImage: `url(${profiledata.profile_image})`,
          }}
        ></section>
      ) : (
        <section className=""></section>
      )}

      {profiledata ? (
        <>
          <section className="service_provider_profile_area">
            <div className="container">
              <div className="profile_main_box text-center">
                <div className="profile_box">
                  <div className="profile_content">
                    <img
                      src={profiledata.profile_image}
                      className="main_profile_image"
                    />

                    <a href="">
                      <span
                        onClick={(e) =>
                          mark_unmark(
                            profiledata._id,
                            profiledata.isFavourite ? "U" : "F",
                            e
                          )
                        }
                        className="heart"
                      >
                        {/* <i className={profiledata.isFavourite ? "fa fa-heart" : "fa fa-heart-o"} aria-hidden="true"></i> */}
                        {profiledata.isFavourite ? (
                          <img src={fav_heart} />
                        ) : (
                          <img src={unfav_heart} />
                        )}
                      </span>
                    </a>
                  </div>
                </div>
                <div className="admin_name ">
                  <h1>{profiledata.name}</h1>
                  <h2>
                    <StarRatings
                      rating={profiledata.avgrating}
                      starRatedColor="#f0bf0c"
                      starDimension="14px"
                      starSpacing="1px"
                      starEmptyColor="#cbd3e4"
                      numberOfStars={5}
                      name="rating"
                    />{" "}
                    {profiledata.reviews > 0 ? (
                      <span
                        className="cursor_pointer"
                        onClick={() => renderReviewModal(profiledata._id)}
                      >
                        {" "}
                        ({profiledata.reviews} reviews)
                      </span>
                    ) : (
                      <span>
                        {" "}
                        ({profiledata.reviews}{" "}
                        {profiledata.reviews < 2 ? "review" : "reviews"} ){" "}
                      </span>
                    )}
                    <h3 className="mtop">
                      {profiledata.certified == true && (
                        <span className="certified_provider">
                          <img src={certified} />
                          Certified
                        </span>
                      )}
                      {profiledata.distance != null ? (
                        <>
                          <i
                            className="fa fa-paper-plane"
                            aria-hidden="true"
                          ></i>
                          {profiledata.distance} mi
                        </>
                      ) : (
                        <></>
                      )}
                    </h3>
                  </h2>
                  <div className="span_box category_name">
                    {htmltemplateCat}

                    {/* <span className="plumber">Plumber</span>
                    <span className="">Carpenter</span> */}
                  </div>
                  <p>{profiledata.bio}</p>
                  <h4>{profiledata.jobCompleted} Job Completed</h4>
                </div>
              </div>
            </div>
          </section>

          <section className="service_category">
            <div className="container">
              <div className="service_category_main_box">
                <div className="tab_box">
                  <ul className="nav nav-tabs">
                    <li className="active">
                      <a data-toggle="tab" href="#home" className="tab_1">
                        Services
                      </a>
                    </li>
                    <li>
                      <a data-toggle="tab" href="#menu1" className="tab_2">
                        Gallery
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="tab-content services_tab_content">
                  <div id="home" className="tab-pane fade in active">
                    <div className="row">
                      <h3 className="heading">Service Category</h3>
                      <div className="service-category-box">
                        {htmltemplateCatService}
                      </div>

                      {/* <div className="col-md-1"></div>
                     {htmltemplateCatService}
                    <div className="col-md-1"></div> */}
                    </div>
                  </div>
                  <div id="menu1" className="tab-pane fade">
                    {profiledata && profiledata.gallery.length > 0 ? (
                      <div className="row">
                        <h3 className="heading">Gallery</h3>
                        <div className="col-sm-1"></div>
                        <div className="col-sm-10 gallary-image">
                          <div className="row gallary-group">
                            {htmltemplateGallery}
                          </div>
                        </div>
                        <div className="col-sm-1"></div>
                      </div>
                    ) : (
                      <div className="row">
                        <h3 className="heading">Gallery</h3>
                        <div className="no-content">No Images Available</div>
                      </div>
                    )}
                  </div>
                  <div className="tab_bottom_button">
                    <a
                      data-toggle="modal"
                      onClick={SetInvite}
                      data-target="#create-profile"
                    >
                      invite for job
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      ) : (
        <></>
      )}
      <Modal
        ariaHideApp={false}
        isOpen={setinvite}
        onRequestClose={closemodal}
        className={"modal-content  new_modal_content   "}
        portalClassName={""}
        contentLabel="Example Modal"
        style={customStyles1}
      >
        <div className="modal-header">
          {profiledata && (
            <h4 className="modal-title">
              Invite {profiledata.name}
              <span data-dismiss="modal" className="cross">
                <i
                  onClick={closemodal}
                  className="fa fa-times-circle-o"
                  aria-hidden="true"
                ></i>
              </span>
            </h4>
          )}
        </div>
        <div className="modal-body">
          <h3 className="select_list">
            Select the job from your current job list.
          </h3>
          <div className="radio_button_box radio_margin radio-box-over">
            {/* activeJobs */}
            {activeJobs &&
              activeJobs.map((item, index) => {
                return (
                  <label className="radio_box">
                    {item.title}
                    <input
                      type="radio"
                      name="radio"
                      value={item._id}
                      onChange={() => setinviteJobId(item._id)}
                      checked={item._id == inviteJobId ? true : false}
                    />

                    <span className="checkmark"></span>
                  </label>
                );
              })}
          </div>
          <div className="next_bottom">
            <div className="main_btn">
              {profiledata && (
                <a onClick={() => inviteProvider()}>
                  Invite {profiledata.name}
                </a>
              )}
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        ariaHideApp={false}
        isOpen={reviewModal}
        onRequestClose={closemodal}
        className={"modal-content   new_modal_content add_new_card "}
        contentLabel="Example Modal"
        style={customStyles1}
      >
        <div className="modal-header">
          <h4 className="modal-title">
            Reviews
            <span>
              <i
                onClick={closemodal}
                className="fa fa-times-circle-o"
                aria-hidden="true"
                data-dismiss="modal"
              ></i>
            </span>{" "}
          </h4>
        </div>
        <div className="modal-body">
          <ul className="review_modal_ul">
            {reviewAry &&
              reviewAry.reviews.length > 0 &&
              reviewAry.reviews.map((item, index) => {
                return (
                  <div className="review_popup">
                    <img src={item.user_id.profile_image} />

                    <div className="review_content">
                      <h3>
                        {item.user_id.name} <span>{item.time_ago}</span>
                      </h3>
                      <StarRatings
                        rating={parseInt(item.rating)}
                        starRatedColor="#f0bf0c"
                        starDimension="20px"
                        starSpacing="1px"
                        starEmptyColor="#cbd3e4"
                        numberOfStars={5}
                        name="rating"
                      />
                      <p>{item.message}</p>
                    </div>
                  </div>
                );
              })}
          </ul>
        </div>
      </Modal>
      <Modal
        ariaHideApp={false}
        isOpen={noJobModal}
        onRequestClose={setNoJobModal}
        className={"modal-content   new_modal_content add_new_card "}
        contentLabel="Example Modal"
        style={customStyles1}
      >
        <div className="modal-header">
          <h4 className="modal-title">
            No Jobs
            <span>
              <i
                onClick={() => {
                  setNoJobModal(false);
                }}
                className="fa fa-times-circle-o"
                aria-hidden="true"
                data-dismiss="modal"
              ></i>
            </span>{" "}
          </h4>
        </div>
        <div className="modal-body">
          <ul className="review_modal_ul" style={{ fontSize: "16px" }}>
            You must have atleast one active job to invite a provider
          </ul>
          <div className="tab_bottom_button">
            <a
              data-toggle="modal"
              onClick={() => {
                setNoJobModal(false);
                window.location.href = "/add_job";
              }}
              data-target="#create-profile"
            >
              Create Job
            </a>
          </div>
        </div>
      </Modal>

      <div className="loader-box" style={loaderStyle}>
        <div className="loader-image">
          <img src={loader_image} />
        </div>
      </div>
    </div>
  );
};

export default reduxForm({
  form: "loginForm", // a unique identifier for this form
})(ServiceProviderProfile);
