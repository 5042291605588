import React, { useEffect, useState } from "react";
import { jobsService } from "../../../_services";
import { userService } from "../../../_services";
import { config } from "../../../config/config";
import Modal from "react-modal";

import { Layout } from "../../common/Layout";
import Msgsidebar from "../messages/Msgsidebar";

import check_green from "../../../assets/images/check_green.png";
import profile_image from "../../assets/images/profile_logo.jpg";
import loader_image from "../../../assets/images/loader.gif";
import no_chat from "../../../assets/images/no-chat.png";
import close_red from "../../../assets/images/close_red.png";
import $ from "jquery";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
} from "react-router-dom";
// import adam_smith from '../../assets/images/adam_smith.png';
import kim from "../../assets/images/kim.png";
import christain from "../../assets/images/christain.png";
import dollar_image from "../../../assets/images/dollar_image.png";

import attach_icon from "../../../assets/images/attach_icon.png";

import firebase from "../../../firebase.js";

import queryString from "query-string";
import { event } from "jquery";
import { IconButton, Tooltip, withStyles } from "@material-ui/core";
import HelpIcon from "@material-ui/icons/Help";

const Messages = (props: any) => {
  const [setmakeoffer, setMakeoffer] = useState(false);
  const [messages, setMessages] = useState(["Great"]);
  const [message, setMessage] = useState("");
  const [price, setPrice] = useState("");
  const [priceError, setPriceError] = useState("");
  const [selectedJobId, setselectedJobId] = useState("");
  const [messagesAry, setmessagesAry] = useState([]) as any;
  const [newConnectionId, setnewConnectionId] = useState() as any;
  const [conversation_id, setconversation_id] = useState() as any;
  const [isNewChat, setisNewChat] = useState(false);
  const [loaderStyle, setLoaderstyle] = useState({ display: "none" });
  const [selConnection, setselConnection] = useState() as any;
  const [selConnectionId, setselConnectionId] = useState() as any;
  const [connectionList, setconnectionList] = useState([]) as any;
  const [selectedUser, setselectedUser] = useState() as any;
  const [bidDetail, setbidDetail] = useState() as any;
  const [bidUrl, setbidUrl] = useState("");
  const [userId, setuserId] = useState("");
  const [otheruserName, setotheruserName] = useState("");
  const [paymentModal, setpaymentModal] = useState(false);
  const [rejectBidModal, setrejectBidModal] = useState(false);
  const [noConnection, setNoConnection] = useState(false);
  const [msgloading, setMsgloading] = useState(0);
  const [lastLisner, setLastLisner] = useState("") as any;

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  let fileUploader;

  function SetInvite() {
    // job_id
    // bid_id
    // bidder_id

    setMakeoffer(true);
  }

  const CustomTooltip = withStyles({
    tooltip: {
      fontSize: 18, // Set your desired font size here,
    },
  })(Tooltip);

  function add_connection(tmp_obj, need_to_sel) {
    jobsService
      .addConnection(tmp_obj)
      .then(function (response) {
        setLoaderstyle({ display: "none" });
        setnewConnectionId(response.data.data.connection_id);
        setselConnectionId(response.data.data.connection_id);
        setselConnection(response.data.data);
        connection_list(true, true);
        // get_connection_messages(response.data.data.connection_id);
        // get_chat_job_detail(job_id,other_user_id , response.data.data.connection_id)

        setuserId(response.data.data.userDetail._id);
        setotheruserName(response.data.data.otherUserDetail.name);
      })
      .catch(function (error) {
        console.log("error", error);
        setLoaderstyle({ display: "none" });
        // userService.handleError(error);
      });
  }

  function acceptRejectBid(status) {
    // console.log(bidDetail);
    // return console.log(bidDetail._id);
    console.log("accept bid");
    if (status == "ACC") {
      console.log("biddetail", bidDetail);
      console.log("biddetail", bidDetail.bidDetail);
      console.log("vss", bidDetail.bidDetail._id);
      let bid_id_s = bidDetail.bidDetail._id;
      window.location.href = "/job_payment/" + bid_id_s;
    } else {
      console.log("bidDetail.bidDetail.user_id._id", bidDetail);
      const formData = new URLSearchParams();
      formData.append("bid_id", bidDetail.bidDetail._id);
      formData.append("job_id", bidDetail._id);
      formData.append("status", status);
      formData.append("bid_price", bidDetail.bidDetail.bid_price);
      formData.append("bidder_id", bidDetail.bidDetail.user_id);

      console.log(formData.toString());

      let params = {
        bid_id: bidDetail.bidDetail._id,
        job_id: bidDetail._id,
        status: status,
        bid_price: bidDetail.bidDetail.bid_price,
        bidder_id: bidDetail.bidDetail.user_id,
      };

      jobsService
        .acceptRejectBid(params)
        .then(function (response) {
          setLoaderstyle({ display: "none" });
          console.log("bid accepted");
          console.log(response);
          if (status == "ACC") {
            alert("Bid Accepted Successfully");
            //   window.location.href = "/my_jobs";
          } else if (status == "REJ") {
            jobsService.deleteConnection(bidDetail._id);
            closemodal();
            alert("Bid Rejected Successfully");
            let tmp_obj: any = {};
            tmp_obj.newConnection = false;
            connection_list(tmp_obj, true);
          } else {
            window.location.href = "/my_jobs";
          }
        })
        .catch(function (error) {
          console.log("error", error);
          setLoaderstyle({ display: "none" });
          //    userService.handleError(error);
        });
    }
  }

  function PaymentModal() {
    setpaymentModal(true);
  }

  function closemodal() {
    setMakeoffer(false);
    setpaymentModal(false);
    setrejectBidModal(false);
  }

  function sendMessage() {
    if (message.length == 0) alert("Type message in enter your message");
    // throw new SubmissionError({ password: "Type message in enter your message", _error: '' })
    else {
      writeconverstationData(message, "T");
      setMessage("");
    }
  }

  function convert_to_only_date_show(unix_timestamp) {
    // console.log("unix_time date showwww", unix_timestamp);
    if (unix_timestamp == "") {
      return "";
    } else if (unix_timestamp == undefined) {
      return "";
    }
    unix_timestamp = unix_timestamp * 1000;
    var date: any = new Date(parseInt(unix_timestamp));
    if (is_today(date)) {
      return "Today";
    } else {
      return (
        date.getDate() +
        "-" +
        monthNames[date.getMonth()] +
        "-" +
        date.getFullYear()
      );
    }
  }

  function convert_to_only_date(unix_timestamp) {
    unix_timestamp = unix_timestamp * 1000;
    // console.log("unix_time",unix_timestamp);
    if (unix_timestamp == "") {
      return "";
    }
    var date: any = new Date(parseInt(unix_timestamp));
    // console.log("unix_time date", date);
    // console.log("dateee",date.getDate());
    return (
      date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
    );
  }

  function is_today(date) {
    var current_date = new Date();
    if (
      current_date.getDate() == date.getDate() &&
      current_date.getMonth() == date.getMonth() &&
      current_date.getFullYear() == date.getFullYear()
    ) {
      // console.log("todate date")
      return true;
    } else {
      return false;
    }
  }

  function convert_to_date_or_time(unix_timestamp) {
    // console.log("unix_time",unix_timestamp);
    if (unix_timestamp == "") {
      return "";
    }
    let timeStamp = unix_timestamp * 1000;

    var date: any = new Date(timeStamp);
    // console.log("unix_time date", date);
    // console.log("dateee",date.getDate());
    var current_date = new Date();
    if (
      current_date.getDate() == date.getDate() &&
      current_date.getMonth() == date.getMonth() &&
      current_date.getFullYear() == date.getFullYear()
    ) {
      // console.log("todate date")
      return convert_to_time(unix_timestamp);
    } else {
      return (
        date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
      );
    }
  }

  function convert_to_time(unix_timestamp) {
    unix_timestamp = unix_timestamp * 1000;
    var date: any = new Date(unix_timestamp);
    // console.log(date.getDate());
    // console.log(date.getMonth());
    // console.log(date.getFullYear());
    // console.log(date.getHours());
    // console.log(date.getMinutes());
    // let minutes: any = date.getMinutes();
    // if (date.getMinutes() < 10) {
    //   minutes = "0" + date.getMinutes();
    // }
    // return date.getHours() + ":" + minutes;
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  }
  // not using this for now
  function make_user_online() {
    console.log("make_user_online");
    let user: any = localStorage.getItem("customer");
    let user_id: any = JSON.parse(user).current_user_id;

    firebase
      .database()
      .ref("OnlineStatus/")
      .once("value", (snapshot) => {
        console.log(snapshot);
        console.log("existssss", snapshot.child(user_id).exists());
        if (snapshot.child(user_id).exists()) {
          const userData = snapshot.child(user_id).val();
          console.log("exists!", userData);
          //   alert("reciever id exist"+ userData.status);
          if (userData.status == 0) {
            // notification sent
            console.log("user is offline make it online");
            userData.ref.update({
              status: 1,
            });

            // notification_sent_provider(params);
          }
        } else {
          console.log("notification sent");
          // notification_sent_provider(params);
        }
      });
  }

  function check_other_user_online_offile(reciever_id, params) {
    console.log("reciever_id", reciever_id);
    firebase
      .database()
      .ref("OnlineStatus/")
      .once("value", (snapshot) => {
        console.log(snapshot);
        console.log("existssss", snapshot.child(reciever_id).exists());
        if (snapshot.child(reciever_id).exists()) {
          const userData = snapshot.child(reciever_id).val();
          console.log("exists!", userData);
          //   alert("reciever id exist"+ userData.status);
          if (userData.status == 0) {
            // notification sent
            console.log("status is 0");
            notification_sent_provider(params);
          }
        } else {
          console.log("notification sent");
          notification_sent_provider(params);
        }
      });
  }

  function notification_sent_provider(params) {
    // alert("notification sentttt");
    jobsService
      .providerNotification(params)
      .then(function (response) {
        console.log("notification to provider");
      })
      .catch(function (error) {
        console.log("errorrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr", error);
      });
  }

  function writeconverstationData(message, type) {
    console.log("write conversation");
    console.log("selected connection", selConnection);
    console.log("other user id", selConnection.other_user_id._id);
    console.log("user id", selConnection.user_id._id);
    let cur_date: any = Date.now() / 1000;
    let cur_date_sec = parseInt(cur_date);

    firebase.database().ref(selConnectionId).push({
      receiverID: selConnection.other_user_id._id,
      senderID: selConnection.user_id._id,
      message: message,
      conversation_id: selConnectionId,
      type: type,
      time: cur_date_sec,
      deletedBy: "",
    });
    // setTimeout(function(){
    // $('.product_chatbox').scrollTop(($('.product_chatbox')[0].scrollHeight)+100);
    // }, 1000);
    let tmp_obj: any = {};
    tmp_obj.newConnection = false;
    // connection_list(tmp_obj,true);
    connection_list_msg_added(tmp_obj, true);
    scrool_to_bottom();

    let params: any = {};
    params.connection_id = selConnectionId;
    params.receiverID = selConnection.other_user_id._id;
    params.senderID = selConnection.user_id._id;
    params.type = type;
    params.message = message;
    check_other_user_online_offile(selConnection.other_user_id._id, params);
  }

  // function onMessageChanged(event) {
  //   console.log("spacess", event.target.value);
  //   // if (message.length > 0) {
  //   setMessage(event.target.value);
  // }

  function attachFile() {
    fileUploader.click();
  }

  function onAttachFileChanged(e) {
    if (e.target.files[0]) console.log(e.target.files[0]);

    if (
      e.target.files[0].type == "image/png" ||
      e.target.files[0].type == "image/jpeg" ||
      e.target.files[0].type == "image/jpg"
    ) {
      // loading start
      setLoaderstyle({ display: "block" });

      // select unique name for everytime when image uploaded
      // Date.now() is function that give current timestamp
      var name = "chat_image" + Date.now();

      // make ref to your firebase storage and select images folder
      var storageRef = firebase.storage().ref("/images/" + name);

      // put file to firebase
      var uploadTask = storageRef.put(e.target.files[0]);

      // all working for progress bar that in html
      // to indicate image uploading... report
      uploadTask.on(
        "state_changed",
        function (snapshot) {
          var progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          //  var uploader :any;
          //  uploader = document.getElementById('uploader');
          //   uploader.value=progress;
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED:
              console.log("Upload is paused");
              break;
            case firebase.storage.TaskState.RUNNING:
              console.log("Upload is running");
              break;
          }
        },
        function (error) {
          console.log(error);
        },
        function () {
          // get the uploaded image url back
          uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
            // You get your url from here
            console.log("File available at", downloadURL);
            setLoaderstyle({ display: "none" });
            writeconverstationData(downloadURL, "I");
            // setlastChatMessage("Image")
            // $(".active .message_content").text("Media")

            // setTimeout(function(){
            //     setmessagesAry(message_ary);
            // }, 3000);

            // print the image url
            console.log(downloadURL);
            //   document.getElementById('submit_link').removeAttribute('disabled');
          });
        }
      );
    } else {
      alert("Please select file with valid format");
    }
  }

  let job_id,
    other_user_id = undefined;
  var chat_timestamp_show: any;
  var is_timestamp_show: any = false;

  useEffect(() => {
    if (props.match) {
      setLoaderstyle({ display: "block" });
      job_id = props.match.params.job_id;
      other_user_id = props.match.params.other_user_id;
      let tmp_obj: any = {};
      tmp_obj.job_id = job_id;
      tmp_obj.other_user_id = other_user_id;
      tmp_obj.newConnection = true;
      // add_connection(tmp_obj,true)
      connection_list(tmp_obj, false);
      // setisNewChat(true);
    } else {
      let tmp_obj: any = {};
      tmp_obj.newConnection = false;
      console.log("chat listing and select first one");
      connection_list(tmp_obj, false);
    }
    setimeout_fn();
  }, []);

  function setimeout_fn() {
    setInterval(function () {
      let tmp_obj: any = {};
      tmp_obj.newConnection = false;
      connection_list_msg_added(tmp_obj, true);
    }, 4000);
  }

  const connectionListDisplay = connectionList.map((connection_obj, index) =>
    connectionListSingle(connection_obj, index)
  );

  const messagesDisplay = messagesAry.map(
    (message_obj, index) => displaymessageSingle(message_obj, index)

    // Only do this if items have no stable IDs
  );

  const customStyles1 = {
    overlay: {
      display: "block",
      paddingRight: "15px",
      backgroundColor: "rgba(51,51,51,0.8)",
      zIndex: 99,
    },

    content: {
      position: "",
      top: "",
      right: "",
      bottom: "",
      left: "",
    },
  };

  function clear_badge_chat() {
    console.log("clear_badge_chat");
    let params = {
      connection_id: selConnectionId,
    };
    jobsService
      .clearBadgeChat(params)
      .then(function (response) {
        console.log("responseeeeeeee");
      })
      .catch(function (error) {
        console.log("errorrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr", error);

        // userService.handleError(error);
      });
  }

  function get_chat_job_detail(
    job_id,
    bidder_id,
    connection_id,
    sel_connection
  ) {
    console.log("get_chat_job_detail");
    console.log(job_id, bidder_id);

    setLoaderstyle({ display: "block" });
    console.log(connection_id);
    setMsgloading(0);
    jobsService
      .bidChatDetail(job_id, bidder_id, connection_id)
      .then(function (response) {
        setLoaderstyle({ display: "none" });
        console.log("bid chat detailttttttttt", response.data);

        console.log(
          "is bid accepted=========================================",
          response.data.job.bidDetail.isBidAccepted
        );
        // console.log(response);
        // console.log(response.data);
        setbidDetail(response.data.job);
        if (response.data.job.bidDetail.isBidAccepted) {
          let bid_url = "/bid_detail/" + response.data.job._id;
          setbidUrl(bid_url);
        } else {
          let bid_url = "/bid_message/" + response.data.job._id;
          setbidUrl(bid_url);
        }
        setLoaderstyle({ display: "none" });
        // alert(sel_connection);
        if (sel_connection == false) {
          get_connection_messages(connection_id, job_id, bidder_id);
        }

        // scroll_to_bottom
        scrool_to_bottom();

        setTimeout(function () {
          setMsgloading(1);
        }, 3000);
      })
      .catch(function (error) {
        console.log("errorrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr", error);
        setLoaderstyle({ display: "none" });
        // userService.handleError(error);
      });
  }

  function scrool_to_bottom() {
    setTimeout(function () {
      console.log(
        "set timeoutttttttttttttttttttttttttttttttttttttt------------------------------------------------"
      );
      // $('.product_chatbox').scrollTop(($('.product_chatbox')[0].scrollHeight)+100);
      // $('.product_chatbox').scrollTop(($('.product_chatbox')[0].scrollHeight)+100);
      $(".product_chatbox").animate(
        { scrollTop: $(".product_chatbox")[0].scrollHeight + 100 },
        "slow"
      );
    }, 2000);
  }

  function connection_list_msg_added(tmp_obj, sel_connection) {
    // console.log("connection_list_msg_added");
    jobsService
      .connectionList(tmp_obj)
      .then(function (response) {
        setconnectionList(response.data.data);
      })
      .catch(function (error) {
        console.log(
          "errorrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr connection_list_msg_added",
          error
        );
        //    userService.handleError(error);
      });
  }

  function connection_list(tmp_obj, sel_connection) {
    console.log("connection_list");
    setLoaderstyle({ display: "block" });
    jobsService
      .connectionList(tmp_obj)
      .then(function (response) {
        setLoaderstyle({ display: "none" });
        console.log("connection_list ---------------", response.data.data);
        setconnectionList(response.data.data);
        // setotheruserName(response.data.data.otherUserDetail.name)
        if (sel_connection) {
          console.log("already selected connection");
          let sel_con = selConnection;
          setotheruserName(sel_con.other_user_id.name);
          get_chat_job_detail(
            sel_con.job_id,
            sel_con.other_user_id._id,
            sel_con._id,
            sel_connection
          );
        } else {
          console.log("we are selecting the connection");
          let sel_con = response.data.data[0];
          if (sel_con) {
            setselConnection(response.data.data[0]);
            setselConnectionId(response.data.data[0]._id);
            setuserId(sel_con.user_id._id);
            setotheruserName(sel_con.other_user_id.name);
            get_chat_job_detail(
              sel_con.job_id,
              sel_con.other_user_id._id,
              sel_con._id,
              sel_connection
            );
          } else {
            setNoConnection(true);
          }
        }
      })
      .catch(function (error) {
        console.log(
          "errorrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr connection_list",
          error
        );
        setLoaderstyle({ display: "none" });
        userService.handleError(error);
      });
  }

  function MessageContent(props) {
    if (props.type == "T") {
      return props.message;
    } else if (props.type == "I") {
      return "Media";
    } else if (props.type == "acceptBid") {
      return "Bid";
    } else {
      return "";
    }
  }

  function connectionListSingle(props, index) {
    // console.log("connsction id----------",props._id   );
    // console.log("selConnectionId----------",selConnectionId   );
    console.log("connectionListSingle", props);
    return (
      <li
        className={props._id == selConnectionId ? "active" : ""}
        onClick={() =>
          changeSelectedconnection(
            props._id,
            props.other_user_id._id,
            props.job_id
          )
        }
      >
        <div className="content">
          <img
            src={props.other_user_id.profile_image}
            alt={props.other_user_id.name}
          />
          <h4>
            {props.other_user_id.name}{" "}
            <span>{convert_to_date_or_time(props.time)} </span>
          </h4>
          <p className="message_content">
            <p>{props.job_title} </p>
            {
              // && (selConnectionId != props._id)
              props.user_read_count > 0 ? (
                <span className="unread_message_count">
                  {props.user_read_count}
                </span>
              ) : null
            }
            <MessageContent type={props.type} message={props.message} />
          </p>
        </div>
      </li>
    );
  }

  function render_date_and_msg(message_obj, index) {
    const messageType = message_obj.type;

    if (messageType == "T") {
      return (
        <div>
          <div className="chat_timestamp">
            {convert_to_only_date_show(message_obj.time)}
          </div>
          <div
            key={index}
            className={
              message_obj.senderID == userId
                ? "right_message text-right"
                : "left_message"
            }
          >
            <span className="sender_name">
              {message_obj.senderID == userId ? "You" : otheruserName}
            </span>
            <div className="chat_bubble">{message_obj.message}</div>
            <span className="message_time">
              {convert_to_time(message_obj.time)}{" "}
            </span>
          </div>
        </div>
      );
    } else if (messageType == "I") {
      return (
        <div>
          <div className="chat_timestamp">
            {convert_to_only_date_show(message_obj.time)}
          </div>

          <div
            key={index}
            className={
              message_obj.senderID == userId
                ? "right_message text-right"
                : "left_message"
            }
          >
            <span className="sender_name">
              {message_obj.senderID == userId ? "You" : otheruserName}
            </span>
            {/* <div className="chat_bubble">{message_obj.message}</div> */}
            <a href={message_obj.message} target="_blank">
              <img
                src={message_obj.message}
                alt="Image"
                className="chat_image"
              />
            </a>

            <span className="message_time">
              {convert_to_time(message_obj.time)}{" "}
            </span>
          </div>
        </div>
      );
    } else if (messageType == "acceptOffer") {
      return (
        <div className="offer_accept text-center">
          <img src={check_green} alt="" />
          <h4>Your offer is accepted</h4>
          <p>
            Congrats, Now you can accept a bid request then job will be start.
            <br />
            Please check the updated stage in bid message.
          </p>
        </div>
      );
    } else if (messageType == "rejectOffer") {
      return (
        <div
          className="offer_accept text-center offer_reject"
          style={{ display: "none" }}
        >
          <img src={close_red} alt="" />
          <h4>Your offer is rejected </h4>
          <p>
            Don’t worry, You can make another offer to your service provider.
          </p>
        </div>
      );
    }

    //   return <GuestGreeting />;
  }

  function render_only_msg(message_obj, index) {
    const messageType = message_obj.type;

    if (messageType == "T") {
      return (
        <div
          key={index}
          className={
            message_obj.senderID == userId
              ? "right_message text-right"
              : "left_message"
          }
        >
          <span className="sender_name">
            {message_obj.senderID == userId ? "You" : otheruserName}
          </span>
          <div className="chat_bubble">{message_obj.message}</div>
          <span className="message_time">
            {convert_to_time(message_obj.time)}{" "}
          </span>
        </div>
      );
    } else if (messageType == "I") {
      return (
        <div
          key={index}
          className={
            message_obj.senderID == userId
              ? "right_message text-right"
              : "left_message"
          }
        >
          <span className="sender_name">
            {message_obj.senderID == userId ? "You" : otheruserName}
          </span>
          {/* <div className="chat_bubble">{message_obj.message}</div> */}
          <a href={message_obj.message} target="_blank">
            <img src={message_obj.message} alt="Image" className="chat_image" />
          </a>

          <span className="message_time">
            {convert_to_time(message_obj.time)}{" "}
          </span>
        </div>
      );
    } else if (messageType == "acceptOffer") {
      return (
        <div className="offer_accept text-center">
          <img src={check_green} alt="" />
          <h4>Your offer is accepted</h4>
          <p>
            Congrats, Now you can accept a bid request then job will be start.
            <br />
            Please check the updated stage in bid message.
          </p>
        </div>
      );
    } else if (messageType == "rejectOffer") {
      return (
        <div
          className="offer_accept text-center offer_reject"
          style={{ display: "none" }}
        >
          <img src={close_red} alt="" />
          <h4>Your offer is rejected </h4>
          <p>
            Don’t worry, You can make another offer to your service provider.
          </p>
        </div>
      );
    }

    //   return <GuestGreeting />;
  }

  function displaymessageSingle(props, index) {
    is_timestamp_show = false;
    let message_obj = props;
    // console.log(message_obj.message);
    if (index == 0) {
      chat_timestamp_show = convert_to_only_date(message_obj.time);
      // console.log("index 0 show with date");
      // date and msg
      return render_date_and_msg(message_obj, index);
    } else {
      if (chat_timestamp_show == convert_to_only_date(message_obj.time)) {
        // only msg
        return render_only_msg(message_obj, index);
      } else {
        chat_timestamp_show = convert_to_only_date(message_obj.time);
        return render_date_and_msg(message_obj, index);
        //  date and msg
      }
    }
  }

  let message_ary: any = [];

  function get_connection_messages(connection_id: string, job_id, bidder_id) {
    console.log(
      "get_connection_messages ---------------------------------------------------------------------------------------"
    );
    // setmessagesAry()
    message_ary = [];

    // setmessagesAry([]);
    if (lastLisner != "") {
      console.log("removing last lisner", lastLisner);
      lastLisner.off("child_added");
    }

    const messages = firebase.database().ref(connection_id);
    setLastLisner(messages);
    messages.on("child_added", function (data, prevChildKey) {
      var newPlayer = data.val();

      console.log("deleted byyyyyyyyyy", newPlayer);
      console.log("current_user_id", localStorage.getItem("current_user_id"));
      console.log("deketed by-------", newPlayer.deletedBy);
      if (localStorage.getItem("current_user_id") == newPlayer.deletedBy) {
        console.log("deleted for this user");
      } else {
        console.log(
          "message----------------------------------------------------",
          messagesAry
        );
        // messagesAry.push(newPlayer);
        message_ary.push(newPlayer);

        // message_ary.push(newPlayer)
        // setTimeout(function(){
        // console.log("setting the message aryyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy---------------------------------------------------------------------------------------------------------")
        //    setmessagesAry(message_ary);
        //    }, 3000);
        setmessagesAry([...message_ary]);

        if (
          newPlayer.message.includes("Offer has been accepted of amount : $") ||
          newPlayer.message.includes("Offer has been rejected of amount: $")
        ) {
          console.log("offerrrr acc / rej");

          get_chat_job_detail(job_id, bidder_id, connection_id, true);

          // get_chat_job_detail(job_id,bidder_id, connection_id,true);
        }
      }
    });
  }

  function delete_connection_messages(connection_id: string) {
    var r = window.confirm("Are you sure you want to delete messages ?");
    if (r == true) {
      console.log("delete connection message", connection_id);
      const messages: any = firebase.database().ref(connection_id);
      let current_user_id = selConnection.user_id._id;
      messages.once("value", function (snapshot) {
        snapshot.forEach(function (child) {
          if (
            child.val().deletedBy == "" ||
            child.val().deletedBy == undefined
          ) {
            console.log("deleteby blank");
            child.ref.update({
              deletedBy: current_user_id,
            });
          } else if (child.val().deletedBy == current_user_id) {
            console.log("already deleted");
          } else {
            console.log("update deleted by", child.val().deletedBy);
            console.log("update current user", current_user_id);
            child.ref.remove();
          }
        });
      });
      setTimeout(function () {
        setmessagesAry([]);
        window.location.reload();
      }, 1000);
    }
  }

  function onPriceChanged(e) {
    setPrice(
      price.length == 0 ? e.target.value.replace(/ /g, "") : e.target.value
    );
    setPriceError("");
  }

  function submitPrice() {
    if (price.length == 0) {
      setPriceError("Price field can't be empty.");
    } else if (!/^\d+$/.test(price)) {
      setPriceError("Price field should only contain numbers.");
    } else {
      console.log("================================", bidDetail);
      let params = {
        job_id: bidDetail._id,
        bid_id: bidDetail.bidDetail._id,
        bidder_id: bidDetail.bidder_detail._id,
        price: price,
      };
      setLoaderstyle({ display: "block" });
      jobsService
        .makeOffer(params)
        .then(function (response) {
          setLoaderstyle({ display: "none" });
          let message = "Made an Offer: $" + price;
          writeconverstationData(message, "T");
          alert("Offer sent successfully.");
          // $('.product_chatbox').scrollTop(($('.product_chatbox')[0].scrollHeight)+100);
          closemodal();

          let tmp_obj: any = {};
          tmp_obj.newConnection = false;
          connection_list(tmp_obj, true);
          setPrice("");
          // get_chat_job_detail(bidDetail._id,bidDetail.bidder_detail._id ,selectedJobId );
          // window.location.href = "/invite_user/"+job_id;
        })
        .catch(function (error) {
          console.log("errorrrrrr", error);
          setLoaderstyle({ display: "none" });
          alert("something went wrong");
        });
    }
  }

  function changeSelectedconnection(connection_id, bidder_id, job_id) {
    console.log(connection_id, selConnectionId);
    if (connection_id == selConnectionId) {
      console.log("already selected");
    } else {
      console.log(
        "set message aryy blankkkkkkkkkkkkkkkkkkkkkk-----------------------------------------------"
      );
      message_ary = [];
      setmessagesAry([]);

      setselConnectionId(connection_id);
      // get_connection_messages(connection_id);
      get_chat_job_detail(job_id, bidder_id, connection_id, false);
    }
  }

  function selectedUserValue(id) {
    // setSelectedUser(id)
  }

  const { error, className, handleSubmit, pristine, reset, submitting } = props;
  return (
    <section className="view_bid_section">
      <div className="container">
        <div
          className="all_bids_heading"
          style={{ display: "flex", alignItems: "center", paddingBottom: 12 }}
        >
          <h1 style={{ marginBottom: 0 }}>Messages</h1>
          {/* <CustomTooltip title="The offer is final and closes off the Posting to other bidders once accepted">
            <IconButton>
              <HelpIcon style={{ fontSize: 24 }} />
            </IconButton>
          </CustomTooltip> */}
        </div>
        {noConnection && (
          <div className="no_data">
            <p>
              <img src={no_chat} alt="No Data" />
            </p>
            No Chat Available
          </div>
        )}
        <div className="view_bid_main_box">
          <div className="profile_info_box">
            <ul className="profile-info-List">
              {isNewChat == true && selConnection && (
                <li
                  className={
                    selConnection.connection_id == selConnectionId
                      ? "active"
                      : ""
                  }
                  onClick={() =>
                    changeSelectedconnection(
                      selConnection.connection_id,
                      selConnection.otherUserDetail._id,
                      selConnection.connection_detail.job_id
                    )
                  }
                >
                  <div className="content">
                    <img
                      src={selConnection.otherUserDetail.profile_image}
                      alt="adam_smith"
                    />
                    <h4>
                      {selConnection.otherUserDetail.name}{" "}
                      <span>
                        {" "}
                        {convert_to_date_or_time(
                          selConnection.connection_detail.time
                        )}{" "}
                      </span>
                    </h4>
                    <p className="message_content">
                      <MessageContent
                        type={selConnection.connection_detail.type}
                        message={selConnection.connection_detail.message}
                      />
                    </p>
                  </div>
                </li>
              )}

              {connectionListDisplay}
            </ul>
          </div>

          <div className="view_bid_message_box">
            {bidDetail && (
              <div
                onClick={() => clear_badge_chat()}
                className="bid_message_content_box"
                id="bid_message_content_box"
              >
                <div className="heading">
                  <h1>
                    {/* delete_connection_messages */}
                    {/* <span className="dlt">
                      <button
                        onClick={() =>
                          delete_connection_messages(selConnectionId)
                        }
                      >
                        <i className="fa fa-trash-o" aria-hidden="true"></i>
                      </button>
                    </span> */}
                    <span className="mobile_back">
                      <i className="fa fa-arrow-left" aria-hidden="true"></i>
                    </span>{" "}
                    {bidDetail.bidder_detail.name}{" "}
                  </h1>
                </div>
                <div className="bid_message_content message_chatbox">
                  {bidDetail && bidDetail.bidder_detail && (
                    <div className="project_short_description">
                      <h4>
                        {bidDetail.title}{" "}
                        <span className="job_price">
                          $
                          {bidDetail?.offerDetail?.price
                            ? bidDetail.offerDetail.price
                            : Object.keys(bidDetail.bidDetail).length === 0
                            ? bidDetail.budget
                            : bidDetail.bidDetail.bid_price}
                        </span>
                      </h4>
                      <p>
                        <b>{bidDetail.bidder_detail.name}</b> sent you a
                        proposal.
                        {bidDetail.hiredBidder != null ? (
                          <div
                            className="view_message"
                            style={{
                              textDecoration: "none",
                              fontWeight: 800,
                              fontSize: 16,
                            }}
                          >
                            A bid has already been accepted for this job
                          </div>
                        ) : (
                          <a href={bidUrl} className="view_message">
                            View Bid Message
                          </a>
                        )}
                      </p>

                      {bidDetail &&
                        bidDetail.offerDetail.status != "PEN" &&
                        bidDetail.isHired == false &&
                        Object.keys(bidDetail.bidDetail).length != 0 && (
                          <div className="button_box">
                            <a
                              onClick={() => setrejectBidModal(true)}
                              className="decline blue_border_btn"
                            >
                              Reject
                            </a>
                            <a onClick={PaymentModal} className="accept">
                              Accept Tender
                            </a>
                            {bidDetail &&
                              bidDetail.offerDetail &&
                              bidDetail.offerDetail.status != "PEN" &&
                              bidDetail.offerDetail.status != "ACC" && (
                                <a onClick={SetInvite} className="decline">
                                  Make Offer
                                </a>
                              )}
                          </div>
                        )}
                    </div>
                  )}
                  <div className="product_chatbox">
                    <div className="product_innerchatbox" id="scroll_chat">
                      {msgloading == 1 &&
                      messagesAry &&
                      messagesAry.length == 0 ? (
                        <div className="no-text-message">
                          <span>No Text Message</span>
                        </div>
                      ) : null}
                      {messagesDisplay}

                      {false &&
                        bidDetail &&
                        bidDetail.offerDetail &&
                        bidDetail.offerDetail.status == "PEN" && (
                          <div className="right_message text-right">
                            <span className="sender_name">You</span>
                            <div className="chat_bubble">
                              Made an Offer: ${bidDetail.offerDetail.price}
                            </div>
                            {/* <span className="message_time">  { convert_to_only_date_show(bidDetail.offerDetail.createdAt) }</span> */}
                          </div>
                        )}
                      {bidDetail &&
                        bidDetail.offerDetail &&
                        bidDetail.offerDetail.status == "ACC" && (
                          <div className="offer_accept text-center">
                            <img src={check_green} alt="" />
                            <h4>Your offer is accepted.</h4>
                            <p>
                              Congrats, Now you can accept a bid request then
                              job will be start.
                              <br />
                              Please check the updated stage in bid message.
                            </p>
                            <div
                              className="next_bottom"
                              style={{ paddingTop: 24 }}
                            >
                              <div className="main_btn">
                                <a onClick={() => acceptRejectBid("ACC")}>
                                  Make Payment
                                </a>
                              </div>
                            </div>
                          </div>
                        )}
                      {bidDetail &&
                        bidDetail.offerDetail &&
                        bidDetail.offerDetail.status == "REJ" && (
                          <div className="offer_accept text-center offer_reject">
                            <img src={close_red} alt="" />
                            <h4>Your offer is rejected </h4>
                            <p>
                              Don’t worry, You can make another offer to your
                              service provider.
                            </p>
                          </div>
                        )}
                    </div>
                  </div>

                  <div className="chat_inputarea">
                    <input
                      type="file"
                      onChange={onAttachFileChanged}
                      className="hidden"
                      ref={(ref) => (fileUploader = ref)}
                      accept="image/*"
                    />
                    <img
                      onClick={attachFile}
                      src={attach_icon}
                      alt="attach_icon"
                      className="attach_icon"
                    />
                    <input
                      type="text"
                      placeholder="Enter Your Message"
                      onChange={(event) => setMessage(event.target.value)}
                      onKeyPress={(event) => {
                        if (event.key === "Enter" || event.key == "") {
                          sendMessage();
                        }
                      }}
                      value={message}
                      className="form-control"
                    />
                    <button className="button_borderless">
                      <i
                        onClick={sendMessage}
                        className="fa fa-send"
                        aria-hidden="true"
                      ></i>
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <Modal
        ariaHideApp={false}
        isOpen={setmakeoffer}
        onRequestClose={closemodal}
        className={"modal-content  new_modal_content   "}
        portalClassName={""}
        contentLabel="Example Modal"
        style={customStyles1}
      >
        <div className="modal-header">
          <h4 className="modal-title">
            Make Offer
            <span data-dismiss="modal" className="cross">
              <i
                onClick={closemodal}
                className="fa fa-times-circle-o"
                aria-hidden="true"
              ></i>
            </span>
          </h4>
        </div>
        <div className="modal-body">
          <div className="input_form_box">
            <form>
              <div className="form-group">
                <label>ENTER YOUR OFFER PRICE</label>
                <div className="input_content">
                  <input
                    type="text"
                    value={price}
                    onChange={onPriceChanged}
                    className="form-control"
                    maxLength={9}
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Enter Your Offer Price"
                  />
                  <img src={dollar_image} alt="dollar_image" />
                </div>
                {priceError.length > 0 && (
                  <span style={{ color: "red" }}>{priceError}</span>
                )}
                <p style={{ color: "red", fontSize: 14, paddingTop: 16 }}>
                  Once an offer is accepted, the Posting will be closed to other
                  bidders.
                </p>
              </div>
            </form>
          </div>
          <div className="sign_up_bottom">
            <div className="main_btn">
              <button
                onClick={submitPrice}
                data-toggle="modal"
                data-target="#create-profile"
                data-dismiss="modal"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        ariaHideApp={false}
        isOpen={paymentModal}
        onRequestClose={closemodal}
        className={"modal-content  new_modal_content makepayment-modal"}
        contentLabel="Example Modal"
        style={customStyles1}
      >
        <div className="modal-header">
          <h4 className="modal-title">
            Make Payment
            <span>
              <i
                onClick={closemodal}
                className="fa fa-times-circle-o"
                aria-hidden="true"
                data-dismiss="modal"
              ></i>
            </span>{" "}
          </h4>
        </div>
        <div className="modal-body">
          {bidDetail && (
            <h3 className="accept">
              Are you sure you want to accept a bid sent from "
              {bidDetail.user_id.name}" on cost "$
              {bidDetail.bidDetail.bid_price}".{" "}
            </h3>
          )}

          <h4>Escrow Amount</h4>
          {bidDetail &&
            bidDetail.bidDetail &&
            bidDetail.bidDetail.milestones && (
              <div className="radio_button_box radio_margin">
                <label className="radio_box">
                  Deposit the amount for the first stage.
                  <input
                    checked
                    type="radio"
                    value={bidDetail.bidDetail.milestones[0].price}
                    name="radio"
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
            )}
          {bidDetail &&
            bidDetail.bidDetail &&
            bidDetail.bidDetail.milestones && (
              <h5>
                {bidDetail.bidDetail.milestones[0].title}
                <span>${bidDetail.bidDetail.milestones[0].price}</span>
              </h5>
            )}

          <p className="red_text">
            * Your money is safe in escrow. We will release the money to your
            worker only for stages approved by you.
          </p>
          <ul className="make_payment_list">
            <li>
              <div className="next_bottom">
                <div className="main_btn">
                  <a onClick={() => acceptRejectBid("ACC")}>Make Payment</a>
                </div>
              </div>
            </li>
            <li>
              <div className="next_bottom margin-top-15">
                <div className="main_btn border_button">
                  <a onClick={closemodal}>Not Now</a>
                </div>
              </div>
            </li>
            <div className="clear"></div>
          </ul>
        </div>
      </Modal>

      <Modal
        ariaHideApp={false}
        isOpen={rejectBidModal}
        onRequestClose={closemodal}
        className={"modal-content  new_modal_content makepayment-modal"}
        contentLabel="Example Modal"
        style={customStyles1}
      >
        <div className="modal-header">
          <h4 className="modal-title">
            Decline Bid
            <span>
              <i
                onClick={closemodal}
                className="fa fa-times-circle-o"
                aria-hidden="true"
                data-dismiss="modal"
              ></i>
            </span>{" "}
          </h4>
        </div>
        <div className="modal-body">
          {bidDetail && bidDetail.bidDetail && (
            <h3 className="accept">
              Are you sure you want to reject a bid sent from "
              {bidDetail.bidder_detail.name}" for "$
              {bidDetail.bidDetail.bid_price}".{" "}
              <p style={{ color: "red", fontSize: 14, paddingTop: 16 }}>
                This will also close off communication with this bidder.
              </p>
            </h3>
          )}
          <ul className="make_payment_list">
            <li>
              <div className="next_bottom">
                <div className="main_btn">
                  <a onClick={() => acceptRejectBid("REJ")}>Reject</a>
                </div>
              </div>
            </li>
            <li>
              <div className="next_bottom margin-top-15">
                <div className="main_btn border_button">
                  <a onClick={closemodal}>Not Now</a>
                </div>
              </div>
            </li>
            <div className="clear"></div>
          </ul>
        </div>
      </Modal>

      <div className="loader-box" style={loaderStyle}>
        <div className="loader-image">
          <img src={loader_image} />
        </div>
      </div>
    </section>
  );
};

export default Messages;
