import React, { useEffect, useState } from "react";
import { Field, reduxForm, SubmissionError } from "redux-form";
import axios from "axios";
import { config } from "../config/config";
// Importing Images
import logo from "../assets/images/logo.png";
import plumber from "../assets/images/plumber.png";
import carpanter from "../assets/images/carpanter.png";
import elc from "../assets/images/elc.png";
import jobposting_phone from "../assets/images/jobposting_phone.png";
import app_store from "../assets/images/app_store.png";
import google_play from "../assets/images/google-play.png";
import abouttender from "../assets/images/about-tender.png";
import wade_pic from "../assets/images/wade_pic.png";
import wade_pic2 from "../assets/images/wade_pic2.png";
import wade_pic3 from "../assets/images/wade_pic3.png";
import footer_logo from "../assets/images/footer_logo.png";
import logo1 from "../assets/images/logo-1.png";
import google from "../assets/images/google.png";
import email_img from "../assets/images/email.png";

import email from "../assets/images/email.png";
import lock from "../assets/images/lock.png";
import date_image from "../assets/images/date_image.png";
import location_image from "../assets/images/location_image.png";
import upload_image from "../assets/images/upload-image.png";

import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login";
import LinkedinLogin from "react-linkedin-login-oauth2";
import { Multiselect } from "multiselect-react-dropdown";
import DatePicker from "react-datepicker";
import moment from "moment";
import select_arrow from "../assets/images/select_arrow.png";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";
import loader_image from "../assets/images/loader.gif";
import Modal from "react-modal";
import Geocode from "react-geocode";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import { userService } from "../_services";
import { off } from "process";

// Integrated Form
interface fieldInterface {
  input: any;
  label: string;
  type: string;
  maxLength: number;
  image: string;
  id: string;
  placeholder: string;
  meta: {
    touched: boolean;
    error: string;
  };
}

const renderField = ({
  input,
  label,
  type,
  id,
  image,
  maxLength,
  placeholder,
  meta: { touched, error },
}: fieldInterface) => (
  <div className="form-group">
    <label>{label}</label>
    <div className="input_content">
      <input
        {...input}
        type={type}
        maxLength={maxLength}
        className="form-control"
        id={id}
        placeholder={placeholder}
      />
      {id == "exampleInputEmail1" && <img src={email} alt="email" />}
      {id == "exampleInputPassword1" && <img src={lock} alt="email" />}
      {touched && error && <span style={{ color: "red" }}>{error}</span>}
    </div>
  </div>
);

const renderTextareaField = ({
  input,
  label,
  type,
  id,
  image,
  maxLength,
  placeholder,
  meta: { touched, error },
}: fieldInterface) => (
  <div className="form-group">
    <label>{label}</label>
    <div className="">
      <textarea
        {...input}
        type={type}
        maxLength={maxLength}
        className="form-control"
        id={id}
        placeholder={placeholder}
        style={{ height: 90 }}
      />

      {id == "exampleInputEmail1" && <img src={email} alt="email" />}
      {id == "exampleInputPassword1" && <img src={lock} alt="email" />}
      {touched && error && <span style={{ color: "red" }}>{error}</span>}
    </div>
  </div>
);

const Home = (props: any) => {
  const [selectedValue, setselectedValue] = useState(1);
  const [accountType, setaccountType] = useState(false);
  const [loginModal, setloginModal] = useState(false);
  const [forgetPasswordModal, setForgetPasswordModal] = useState(false);
  const [signupModal, setSignup] = useState(false);
  const [profileModal, setProfileModal] = useState(false);
  const [providerproflleModal, setProviderProfileModal] = useState(false);
  const [custwelcomeModal, setCustwelcome] = useState(false);
  const [providerwelcomeModal, setProviderwelcome] = useState(false);
  const [uploaddocModal, setUploaddocModal] = useState(false);
  const [bankaccountModal, setBankAccountModal] = useState(false);
  const [startDate, setStartdate] = useState() as any;

  const [userrole, setRole] = useState(0);
  const [imagedata, setImagedata] = useState("");

  const [workerdocumentArray, setworkerdocumentArray] = useState([]);

  const [workerdocumentcatArray, setworkerdocumentcatArray] = useState(
    []
  ) as any;

  const [showCatError, setCatError] = useState(false);
  const [showSubCatError, setSubCatError] = useState(false);
  const [showDOBError, setDOBError] = useState(false);

  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);

  const [docPlumber, setDocPlumber] = useState({ name: "" });
  const [docGovt, setDocGovt] = useState("");
  const [docProfile, setDocProfile] = useState("");
  const [docCarpenter, setDocCarpenter] = useState("");

  const [showDocGovtError, setDocGovtError] = useState(false);
  const [showDocProfileError, setDocProfileError] = useState(false);
  const [showDocPlumberError, setDocPlumberError] = useState(false);
  const [showDocCarpenterError, setDocCarpenterError] = useState(false);
  const [showCustDocError, setCustDocError] = useState(false);
  const [showCustProfileImageError, setCustProfileImageError] = useState(false);

  const [profilereview, setProfileReview] = useState(false);
  const [profilereviewcust, setProfileReviewcust] = useState(false);

  const [isTermsAndConditionsAccepted, setTermsAndConditionsAccepted] =
    useState(true);
  const [showTermsError, setTermsError] = useState(false);
  const [nameerror, setNameerror] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [latlng, setLatlng] = useState() as any;
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");

  const [bio, setBio] = useState("");
  const [bioError, setBioError] = useState("");

  const [accountName, setAccountName] = useState("");
  const [accountNameError, setAccountNameError] = useState("");

  const [accountNumber, setAccountNumber] = useState("");
  const [accountNumberError, setAccountNumberError] = useState("");

  const [routingNumber, setRoutingNumber] = useState("");
  const [routingNumberError, setRoutingNumberError] = useState("");

  const [location, setLocation] = useState("");
  const [locationError, setLocationError] = useState("");

  const [customerDoc, setCustomerDoc] = useState("");

  const [profile, setProfile] = useState("");
  const [profiledata, setProfiledata] = useState("");

  const [documentArray, setdocumentArray] = useState([]);
  const [catArray, setCatArray] = useState([]);
  const [catidsArray, setCatidsArray] = useState([]);
  const [subcatidsArray, setsubCatidsArray] = useState([]);

  const [catsubarray, setCatsubarray] = useState([]);
  const [workerdocumentArray2, setworkerdocumentArray2] = useState([]);
  const [doc1, setDoc1] = useState([]) as any;
  const [isrevealPassword, setisrevealPassword] = useState(false);
  const [isrevealConfirmassword, setisrevealConfirmassword] = useState(false);
  const [isrevealNewPassword, setisrevealNewPassword] = useState(false);
  const [loaderStyle, setLoaderstyle] = useState({ display: "none" });
  const [branchNameError, setBranchNameError] = useState("");
  const [branchName, setBranchName] = useState("");
  const [selCategoryAry, setSelCategoryAry] = useState([]) as any;
  const [isReadOnly, setIsReadOnly] = useState(true);

  let CategoriesNew: {} = [
    {
      electrician: [
        "Emergency Electrician",
        "Electricity Code updates",
        "Electrical service upgrades.",
        "Lighting maintenance and design",
        "Troubleshooting",
        "Adding Lighting",
        "Exterior/Interior Lighting Installs",
        "Energy-saving solutions",
        "Home Renovations",
        "Commercial Renovations",
        "Outlet Device Replacements",
        "Electrical Vehicle Charger Instalment",
      ],
    },
  ];

  function SetAccountModal() {
    setaccountType(true);
  }

  function update_user_details() {
    setdata();
    getData("new");
    closeModal();
  }

  function get_current_location() {
    console.log("get_current_location");
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition);
    } else {
      alert("Issue in getting current location.");
    }
  }
  function showPosition(position) {
    // x.innerHTML = "Latitude: " + position.coords.latitude +
    // "<br>Longitude: " + position.coords.longitude;
    console.log("postionnnn", position);

    // const geocoder = new google.maps.Geocoder();
    // getting location start

    var lat = position.coords.latitude;
    var lng = position.coords.longitude;
    let current_location = { lat: lat, lng: lng };
    Geocode.setApiKey("AIzaSyBsv-OafO1eNJncye_hAAAlAvE--OjmmJ8");
    Geocode.fromLatLng(lat, lng).then(
      (response) => {
        const address = response.results[0].formatted_address;
        console.log(address);
        setLocation("");
        setLocation(address);
        console.log(location);

        setLatlng({
          lat: lat,
          lng: lng,
        });
      },
      (error) => {
        console.error(error);
      }
    );
  }

  useEffect(() => {
    setdata();
    getData("new");
    let location = window.location.href;
    const res = location.split("?code=");
    console.log("location fetchhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh", location);
    if (localStorage.getItem("access_token")) {
      console.log("get access tokennnnnnnnnnnnnnnn");
      userService
        .my_profile()
        .then(function (response) {
          if (response.data.user_type == "C") {
            if (response.data.user.signup_token !== "") {
              if (response.data.user.status == "ACT") {
                localStorage.setItem("is_profile_created", "1");
              }
              localStorage.setItem("user", JSON.stringify(response.data.user));

              // window.location.href= '/'
            } else {
            }
          } else {
            if (response.data.user.status == "ACT") {
              localStorage.setItem("is_profile_created", "1");
            }
            localStorage.setItem("user", JSON.stringify(response.data.user));
            console.log(
              "**************************************************",
              response.data.user
            );
            SetProfilesModal();
          }
        })
        .catch(function (error) {});
    }
    if (res && res[1]) {
      linkedin(res[1]);

      // logininsta(res[1])
    }
  }, []);

  function getData(type: string) {
    userService
      .getCategories()
      .then(function (response) {
        setCatArray(response.data.categories);
        let user_data: any = localStorage.getItem("user")
          ? JSON.parse(localStorage.getItem("user") as string)
          : "";

        if (user_data != "" && type == "new") {
          if (user_data.profile_step1 == true) {
            setName(user_data.name);
            setBio(user_data.bio);
            setSelectedCatArray(user_data.categories);
            setDoc1(user_data.profile_document);

            setLocation(user_data.location);
            setLatlng({
              lat: user_data.latitude,
              lng: user_data.longitude,
            });

            // setCustomerDoc(user_data.)
            if (user_data.birthdate) {
              let dates: any = user_data.birthdate;
              var datecheck = moment(dates);
              let valid = datecheck.isValid();
              if (valid === false) {
                const date = moment(dates, "DD-MM-YYYY").format("D MMMM, YYYY");
                setStartdate(new Date(date));
              } else {
                const date = moment(dates).format("D MMMM, YYYY");
                setStartdate(new Date(date));
              }
            }
            let ids: any = [];
            let catsids: any = [];
            let buffer: any = [];

            setTimeout(function () {
              const element2 = document.querySelector(
                ".category #multiselectContainerReact"
              );

              if (
                JSON.parse(localStorage.getItem("user") as string).categories
                  .length > 0
              ) {
                var has_active =
                  element2 != null && element2.classList.contains("has_data");
                if (has_active == false) {
                  element2 != null && element2.classList.add("has_data");
                }
              } else {
                element2 != null && element2.classList.remove("has_data");
              }

              const element = document.querySelector(
                ".sub_category #multiselectContainerReact"
              );

              if (
                JSON.parse(localStorage.getItem("user") as string).categories
                  .length > 0
              ) {
                var has_active =
                  element != null && element.classList.contains("has_data");
                if (has_active == false) {
                  element != null && element.classList.add("has_data");
                }
              } else {
                element != null && element.classList.remove("has_data");
              }
            });

            user_data.categories.map((item, index) => {
              catsids = item.sub_categories.map((item1, index1) => {
                buffer.push(item1);
                return (catsids[catsids.length + 1] = item1);
              });

              setsubCatidsArray2(subcatidsArray2.concat(buffer));
            });

            ids = user_data.categories.map((item, index) => {
              if (ids.length > 0) {
                return (ids[ids.length] = item._id);
              } else {
                return (ids[index] = item._id);
              }
            });

            setCategories(ids);
            setSubcateascat(user_data.categories, "add");
          }
        }
      })
      .catch(function (error) {});
  }

  // const validate = values => {
  //     const errors = {}
  //     if (!values["username"]) {
  //         setNameerror(true)
  //         errors["username"] = 'Required'
  //     } else if (values.username.length > 15) {
  //         errors["username"] = 'Must be 15 characters or less'
  //     }

  //     return errors
  // }

  // const renderField = ({ input, label, type, id, image, maxLength, placeholder, meta: { touched, error } }: fieldInterface) => (

  //     <div className="form-group">
  //         <label>{label}</label>
  //         <div className="input_content">
  //             <input {...input} type={type} maxLength={maxLength} className="form-control" id={id} placeholder={placeholder} />
  //             {id == "exampleInputEmail1" && <img src={email} alt="email" />}
  //             {id == "exampleInputPassword1" && <img src={lock} alt="email" />}
  //             {touched && error && nameerror && <span style={{ color: "red" }}>{error}</span>}
  //         </div>
  //     </div>
  // )

  // const renderTextareaField = ({ input, label, type, id, image, maxLength, placeholder, meta: { touched, error } }: fieldInterface) => (

  //     <div className="form-group">
  //         <label>{label}</label>
  //         <div className="">

  //             <textarea {...input} type={type} maxLength={maxLength} className="form-control" id={id} placeholder={placeholder} />

  //             {id == "exampleInputEmail1" && <img src={email} alt="email" />}
  //             {id == "exampleInputPassword1" && <img src={lock} alt="email" />}
  //             {touched && error && <span style={{ color: "red" }}>{error}</span>}
  //         </div>
  //     </div>
  // )

  const handleDateChange = (date: any) => {
    // formData.dob= date;
    setDOBError(false);
    setStartdate(date);
    console.log(startDate);
  };

  function togglePassword() {
    var tmp = !isrevealPassword;
    setisrevealPassword(tmp);
  }

  function toggleNewPassword() {
    var tmp = !isrevealNewPassword;
    setisrevealNewPassword(tmp);
  }

  function toggleConfirmPassword() {
    var tmp = !isrevealConfirmassword;
    setisrevealConfirmassword(tmp);
  }

  function onPlumberDocUpload(e) {
    e.preventDefault();
    let data: any = [];

    // if (JSON.parse(localStorage.getItem('user') as string).categories.length > workerdocumentArray2.length) {
    let file = e.target.files[0];
    if (file != undefined) {
      var len = e.length;
      if (file.size > config.perImageFileSize) {
        alert("Please upload max of 3 mb");
        // error = true
        return;
      } else {
        console.log("onPlumberDocUpload 1---");
        setworkerdocumentcatArray(workerdocumentcatArray.concat(e.target.id));
        setworkerdocumentArray(workerdocumentArray.concat(file));

        if (localStorage.getItem(e.target.id) == null) {
          localStorage.setItem(e.target.id, file.name);
          data[0] = {
            id: e.target.id,
            file: file,
          };

          setworkerdocumentArray2(workerdocumentArray2.concat(data));
          console.log(workerdocumentArray2);
        } else {
          console.log("onPlumberDocUpload 2---");
          localStorage.setItem(e.target.id, file.name);
          workerdocumentArray2.map((item, index) => {
            if ((item as any).id == e.target.id) {
              (workerdocumentArray2[index] as any).file = file;
            }
          });
          console.log(workerdocumentArray2);
        }
      }
    }
    // } else {
    //     setworkerdocumentArray([])
    //     if (JSON.parse(localStorage.getItem('user') as string).categories.length > workerdocumentArray.length) {
    //         let file = e.target.files[0];
    //         if (file != undefined) {
    //             var len = e.length;
    //             setworkerdocumentArray(workerdocumentArray.concat(file))
    //         }
    //     }
    // }
    // if (event.target.files[0])
    //     setDocPlumber(event.target.files[0])
    // setDocPlumberError(false)
  }

  // function onPlumberDocUpload(e) {

  //     if(JSON.parse(localStorage.getItem('user') as string).categories.length >workerdocumentArray.length)
  //     {
  //     let file = e.target.files[0];
  //         if (file != undefined) {
  //             var len = e.length;
  //             setworkerdocumentcatArray(workerdocumentcatArray.concat(e.target.id))
  //             setworkerdocumentArray(workerdocumentArray.concat(file as any))
  //         }
  //     }else{
  //         setworkerdocumentArray([])
  //         if(JSON.parse(localStorage.getItem('user') as string).categories.length >workerdocumentArray.length)
  //         {
  //         let file = e.target.files[0];
  //             if (file != undefined) {
  //                 var len = e.length;
  //                 setworkerdocumentArray(workerdocumentArray.concat(file as any))
  //             }
  //         }
  //     }
  // }

  function onGovtDocUpload(event) {
    console.log(event.target.files[0]);
    if (event.target.files[0]) {
      if (event.target.files[0].size > config.perImageFileSize) {
        alert("Please upload max of 3 mb");
        // error = true
        return;
      } else {
        setDocGovt(event.target.files[0]);
        setDocGovtError(false);
      }
    }
  }
  function onProfileDocUpload(event) {
    if (event.target.files[0]) {
      //
      if (event.target.files[0].size > config.perImageFileSize) {
        alert("Please upload max of 3 mb");
        // error = true
        return;
      } else {
        setDocProfile(event.target.files[0]);
        setDocProfileError(false);
      }
    }
  }
  function onCarpenterDocUpload(event) {
    if (event.target.files[0]) setDocCarpenter(event.target.files[0]);
    setDocCarpenterError(false);
  }

  function SetLoginModal(role: number) {
    setRole(role);
    setSignup(false);
    setForgetPasswordModal(false);
    setaccountType(false);
    setloginModal(true);

    setEmail("");
    setPassword("");
    setConfirmPassword("");
    setIsReadOnly(true);
  }

  function SetForgotPasswordModal() {
    setloginModal(false);
    setForgetPasswordModal(true);

    setEmail("");
    setPassword("");
    setConfirmPassword("");
  }

  function SetSignup() {
    setloginModal(false);
    setSignup(true);

    setEmail("");
    setPassword("");
    setConfirmPassword("");
  }

  function setProviderSignup() {
    setRole(1);
    setSignup(true);

    setEmail("");
    setPassword("");
    setConfirmPassword("");
  }

  function SetProfileModal() {
    setSignup(false);
    let role: number =
      localStorage.getItem("role") && localStorage.getItem("role") == "provider"
        ? 1
        : 0;

    setRole(role);
    setdata();
    localStorage.setItem("profile_step1", "false");
    if (role == 0) {
      setProfileModal(true);
    } else {
      let step1 = localStorage.getItem("profile_step1");
      if (step1 == "false") {
        setUploaddocModal(false);
        setProviderProfileModal(true);
      }
    }
  }

  function SetUploaddocModal() {
    localStorage.setItem("profile_step2", "false");
    setProviderProfileModal(false);
    setBankAccountModal(false);
    setUploaddocModal(true);
  }

  function SetBankAccountModal() {
    localStorage.setItem("profile_step3", "false");
    setUploaddocModal(false);
    setProviderwelcome(false);
    setBankAccountModal(true);
  }

  function SetCustwelcome() {
    if (userrole == 0) {
      setProfileModal(false);
      setCustwelcome(true);
    } else {
      setBankAccountModal(false);
      setProviderwelcome(true);
    }
  }

  function onTermsCheckedChange(event) {
    setTermsAndConditionsAccepted(event.target.checked);
    setTermsError(false);
  }

  function SetProfileReview() {
    setProviderwelcome(false);
    setProfileReview(true);
  }

  function closeModal() {
    setaccountType(false);
    setloginModal(false);
    setForgetPasswordModal(false);
    setSignup(false);
    setProfileModal(false);
    setCustwelcome(false);
    setProviderProfileModal(false);
    setUploaddocModal(false);
    setUploaddocModal(false);
    setProviderwelcome(false);
    setBankAccountModal(false);
    setProfileReview(false);
    setProfileReviewcust(false);
  }

  function SetProfilesModal() {
    let accountType = localStorage.getItem("role");
    if (accountType == "customer") {
      let profile_step1 = localStorage.getItem("profile_step1");
      if (profile_step1 == "false") {
        setProfileModal(true);
      } else {
        let profile_step2 = localStorage.getItem("profile_step2");
        if (profile_step2 == "false") {
          setCustwelcome(true);
        } else {
          console.log("---here");
          setdata();
          let profile_created = localStorage.getItem("is_profile_created");
          if (profile_created == "1") {
            window.location.href = "/";
          } else {
            setProfileReviewcust(true);
          }
        }
      }
    } else {
      let profile_step1 = localStorage.getItem("profile_step1");
      if (profile_step1 == "false") {
        setTimeout(function () {
          const element2 = document.querySelector(
            ".category #multiselectContainerReact"
          );

          if (
            JSON.parse(localStorage.getItem("user") as string).categories
              .length > 0
          ) {
            var has_active =
              element2 != null && element2.classList.contains("has_data");
            if (has_active == false) {
              element2 != null && element2.classList.add("has_data");
            }
          } else {
            element2 != null && element2.classList.remove("has_data");
          }

          const element = document.querySelector(
            ".sub_category #multiselectContainerReact"
          );

          if (
            JSON.parse(localStorage.getItem("user") as string).categories
              .length > 0
          ) {
            var has_active =
              element != null && element.classList.contains("has_data");
            if (has_active == false) {
              element != null && element.classList.add("has_data");
            }
          } else {
            element != null && element.classList.remove("has_data");
          }
        });
        setProviderProfileModal(true);
      } else {
        let profile_step2 = localStorage.getItem("profile_step2");
        if (profile_step2 == "false") {
          setProviderProfileModal(false);
          setUploaddocModal(true);
        } else {
          let profile_step3 = localStorage.getItem("profile_step3");
          if (profile_step3 == "false") {
            setUploaddocModal(false);
            setBankAccountModal(true);
          } else {
            let profile_step4 = localStorage.getItem("profile_step4");
            console.log(profile_step4);
            if (profile_step4 == "false") {
              setBankAccountModal(false);
              setProviderwelcome(true);
            } else {
              setProviderwelcome(false);
              setProfileReview(true);
            }
          }
        }
      }
    }
  }
  const responseFacebook = (response: any) => {
    console.log("response fackbook", response);
    var user_type = userrole == 0 ? "C" : "P";

    const formData = new URLSearchParams();
    formData.append("name", response.name);
    formData.append("email", response.email);
    formData.append("user_type", user_type);
    formData.append("social_media_id", response.id);
    formData.append("profile_image", response.picture.data.url);
    socialLogin(formData.toString());
  };
  const socialLogin = (params: any) => {
    userService
      .socialLogin(params)
      .then(function (response) {
        Responsefunction(response);
      })
      .catch(function (error) {
        console.log("social loginn error", error);
        if (error.response) {
          alert(error.response.data.message);
        }
      });
  };
  const handleSuccess = (data: any) => {
    console.log(
      "linkedin handleSuccesssssssssssssssssssssssssssssssssssssssss"
    );
    linkedin(data.code);
    console.log(data.code, "================================linkedin");
  };

  const linkedin = (code: any) => {
    ///   alert(code)
    // let formdata = {
    //   "grant_type":"authorization_code",
    //   "redirect_uri":"http://localhost:3000/profileT",
    //   "code":code,
    //   "client_id":"81xb4tb20s08fq",
    //   "client_secret":"yhXSASeuZSndmBpE",

    // };

    var form = new FormData();
    form.append("grant_type", "authorization_code");
    form.append("redirect_uri", `${config.appurl}`);
    form.append("code", code);
    form.append("client_id", `${config.linkedin}`);
    form.append("client_secret", `${config.linkedin_secret}`);

    const proxyurl = "https://cors-anywhere.herokuapp.com/";
    // let url:any =  "https://www.linkedin.com/uas/oauth2/accessToken?client_id=81xb4tb20s08fq&grant_type=authorization_code&redirect_uri=https://catchmarkets.io/profileT&client_secret=yhXSASeuZSndmBpE&code="+code
    let url1: any =
      proxyurl +
      "https://www.linkedin.com/uas/oauth2/accessToken?client_id=" +
      `${config.linkedin}` +
      "&grant_type=authorization_code&redirect_uri=" +
      `${config.appurl}` +
      "&callback" +
      `${config.appurl}` +
      "&client_secret=" +
      `${config.linkedin_secret}` +
      "&code=" +
      code;
    axios
      .post(url1, form, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then(function (response) {
        console.log(response, "=====response linkedin");
        let accessToken = response.data.access_token;
        //  var user_type = userrole == 0 ? "C" : "P";

        axios
          .get(proxyurl + "https://api.linkedin.com/v2/me", {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "cache-control": "no-cache",
              "X-Restli-Protocol-Version": "2.0.0",
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods": "POST, GET, OPTIONS, DELETE ,PUT",
            },
          })
          .then(function (response1) {
            console.log(response1.data, "=====response1");
            var user_type = userrole == 0 ? "C" : "P";

            console.log(
              userrole,
              "---------------------------------------------->roole"
            );
            console.log(
              user_type,
              "---------------------------------------------->user_typeuser_type"
            );

            const formData = new URLSearchParams();
            formData.append(
              "name",
              response1.data.localizedFirstName +
                " " +
                response1.data.localizedLastName
            );
            //   formData.append('email',response.email);
            formData.append("user_type", user_type);
            formData.append("social_media_id", response1.data.id);
            //   formData.append('profile_image',response1.data.profilePicture.displayImage);
            socialLogin(formData.toString());
          })
          .catch((error) => {
            console.log(error, "====================-----error");
          });
      })
      .catch((error) => {
        console.log(error, "====================-----error");
      });
  };
  const handleFailure = (error: any) => {
    alert("error");
    console.log(error.errorMessage);
  };
  function responseGoogle(response: any) {
    console.log("google response", response);
    var user_type = userrole == 0 ? "C" : "P";
    console.log("user_type", user_type);
    if (response.profileObj) {
      const formData = new URLSearchParams();
      formData.append("name", response.profileObj.name);
      formData.append("email", response.profileObj.email);
      formData.append("user_type", user_type);
      formData.append("social_media_id", response.profileObj.googleId);
      formData.append("profile_image", response.profileObj.imageUrl);
      socialLogin(formData.toString());
    }
  }
  const Responsefunction = (response: any) => {
    localStorage.setItem("access_token", response.data.access_token);
    window.location.href = "/";

    if (response.data.user_type == "C") {
      localStorage.setItem("role", "customer");
      localStorage.setItem("profile_step1", response.data.user.profile_step1);
      localStorage.setItem("profile_step2", response.data.user.profile_step2);
      localStorage.setItem("user", JSON.stringify(response.data.user));
      localStorage.setItem("current_user_id", response.data.user._id);

      if (
        response.data.user.profile_step1 == true &&
        response.data.user.profile_step2 == true
      ) {
        localStorage.setItem("is_profile_created", "1");
        window.location.href = "/";
      } else {
        localStorage.setItem("is_profile_created", "0");
        window.location.href = "/";
      }
    } else {
      localStorage.setItem("role", "provider");
      localStorage.setItem("user", JSON.stringify(response.data.user));
      localStorage.setItem("profile_step1", response.data.user.profile_step1);
      localStorage.setItem("profile_step2", response.data.user.profile_step2);
      localStorage.setItem("profile_step3", response.data.user.profile_step3);
      localStorage.setItem("profile_step4", response.data.user.profile_step4);
      localStorage.setItem("profile_step5", response.data.user.profile_step5);
      localStorage.setItem("current_user_id", response.data.user._id);
      if (
        response.data.user.profile_step1 == true &&
        response.data.user.profile_step2 == true &&
        response.data.user.profile_step2 == true &&
        response.data.user.profile_step2 == true &&
        response.data.user.profile_step2 == true &&
        response.data.user.profile_step2 == true
      ) {
        localStorage.setItem("is_profile_created", "1");
        window.location.href = "/";
      } else {
        localStorage.setItem("is_profile_created", "0");
        window.location.href = "/";
      }
    }
  };
  const submit = (values: any, type: string) => {
    if (validateForm(values, type)) {
      if (type == "login") {
        const formData = new URLSearchParams();
        formData.append("email", email);
        formData.append("password", password);
        var user_type = userrole == 0 ? "C" : "P";

        formData.append("user_type", user_type);

        userService
          .login(formData.toString())
          .then(function (response) {
            // alert(response.data.message)
            localStorage.setItem("access_token", response.data.access_token);
            // alert("Sign In Successfully")

            if (response.data.user_type == "C") {
              // if(response.data.user.status=="ACT"){
              localStorage.setItem("role", "customer");
              localStorage.setItem(
                "profile_step1",
                response.data.user.profile_step1
              );
              localStorage.setItem(
                "profile_step2",
                response.data.user.profile_step2
              );
              localStorage.setItem("user", JSON.stringify(response.data.user));
              localStorage.setItem("current_user_id", response.data.user._id);

              if (response.data.user.status == "ACT") {
                localStorage.setItem("is_profile_created", "1");
                window.location.href = "/";
              } else {
                localStorage.setItem("is_profile_created", "0");
                window.location.href = "/";
              }
              // }else{
              //     alert("Please verify your email first.")
              // }
            } else {
              localStorage.setItem("role", "provider");
              localStorage.setItem("user", JSON.stringify(response.data.user));
              localStorage.setItem(
                "profile_step1",
                response.data.user.profile_step1
              );
              localStorage.setItem(
                "profile_step2",
                response.data.user.profile_step2
              );
              localStorage.setItem(
                "profile_step3",
                response.data.user.profile_step3
              );
              localStorage.setItem(
                "profile_step4",
                response.data.user.profile_step4
              );
              localStorage.setItem(
                "profile_step5",
                response.data.user.profile_step5
              );
              localStorage.setItem("current_user_id", response.data.user._id);
              if (response.data.user.status == "ACT") {
                localStorage.setItem("is_profile_created", "1");
                window.location.href = "/";
              } else {
                localStorage.setItem("is_profile_created", "0");
                window.location.href = "/";
              }
            }
          })
          .catch(function (error) {
            if (error.response) {
              alert(error.response.data.message);
              // window.location.href = '/';
            } else {
              alert(error.message);
              window.location.href = "/";
            }
          });
      } else if (type == "forgot_password") {
        if (email.length == 0) {
          setEmailError("Email field can't be empty.");
        } else {
          const formData = new URLSearchParams();
          formData.append("email", email);
          userService
            .forgotPassword(formData.toString())
            .then(function (response) {
              alert(response.data.message);
              setForgetPasswordModal(false);
            })
            .catch(function (error) {
              if (error.response) {
                alert(error.response.data.message);
              }
              setForgetPasswordModal(false);
            });
          // SetLoginModal(userrole)
        }
      } else if (type == "create_customer_profile") {
        console.log(customerDoc);
        if (name.length == 0) {
          setNameError("Name field can't be empty.");
        } else if (!startDate) {
          setDOBError(true);
        } else if (location.length == 0) {
          setLocationError("Location field can't be empty.");
        } else if (doc1.length == 0) {
          setCustDocError(true);
        } else if (bio.length == 0) {
          setBioError("Bio field can't be empty.");
        } else {
          // setdocumentArray(documentArray.concat(customerDoc as any))

          let new_date = moment(startDate, "DD-MM-YYYY").format("DD-MM-YYYY");
          const formData = new URLSearchParams();
          formData.append("name", name);
          formData.append("birthdate", new_date);
          formData.append("location", location);
          formData.append("latitude", latlng.lat);
          formData.append("longitude", latlng.lng);
          const formData2 = new FormData();
          if (doc1) {
            formData2.append("profile_document", doc1);
          }
          // formData.append('documents',documentArray as any);
          formData.append("bio", bio);
          formData.append("profile_step1", true as any);
          // formData.append('profile_step3',true as any);
          // formData.append('profile_step4',true as any);

          userService
            .updateProfile(formData.toString())
            .then(function (response) {
              userService
                .updateProfileimage(formData2)
                .then(function (response) {
                  alert(response.data.message);

                  localStorage.setItem("is_profile_created", "0");

                  localStorage.setItem(
                    "user",
                    JSON.stringify(response.data.user)
                  );

                  localStorage.setItem(
                    "profile_step1",
                    response.data.user.profile_step1
                  );
                  localStorage.setItem(
                    "profile_step2",
                    response.data.user.profile_step2
                  );
                  SetCustwelcome();
                })
                .catch(function (error) {});
            })
            .catch(function (error) {});
        }
      } else if (type == "create_customer_profile2") {
        if (profile == "") {
          alert("Please Upload your Profile");
          // setCustDocError(true)
          setCustProfileImageError(true);
        } else if (!isTermsAndConditionsAccepted) {
          setTermsError(true);
        } else {
          const formData = new FormData();
          formData.append("profile_image", profile);
          formData.append("profile_step2", true as any);
          formData.append("profile_step3", true as any);
          formData.append("profile_step4", true as any);

          userService
            .updateProfileimage(formData)
            .then(function (response) {
              alert(response.data.message);

              localStorage.setItem("user", JSON.stringify(response.data.user));
              if (
                response.data.user.profile_step1 == true &&
                response.data.user.profile_step2 == true
              ) {
                if (response.data.user.signup_token == "") {
                  localStorage.setItem("is_profile_created", "1");
                }
              }

              localStorage.setItem(
                "profile_step1",
                response.data.user.profile_step1
              );
              localStorage.setItem(
                "profile_step2",
                response.data.user.profile_step2
              );
              SetProfilesModal();
            })
            .catch(function (error) {});
        }
      } else if (type == "sign_up") {
        const formData = new URLSearchParams();
        formData.append("email", email);
        formData.append("password", password);
        var user_type = userrole == 0 ? "C" : "P";

        formData.append("user_type", user_type);
        //    loader start
        setLoaderstyle({ display: "block" });
        userService
          .register(formData.toString())
          .then(function (response) {
            setLoaderstyle({ display: "none" });
            if (response.data.user_type == "C") {
              if (response.data.user.signup_token !== "") {
                alert(response.data.message);
                localStorage.setItem(
                  "access_token",
                  response.data.access_token
                );
                localStorage.setItem("is_profile_created", "0");
                localStorage.setItem("role", "customer");
                localStorage.setItem(
                  "user",
                  JSON.stringify(response.data.user)
                );
                localStorage.setItem(
                  "profile_step1",
                  response.data.user.profile_step1
                );
                localStorage.setItem(
                  "profile_step2",
                  response.data.user.profile_step2
                );
                localStorage.setItem("current_user_id", response.data.user._id);
                window.location.href = "/";
              } else {
                alert(response.data.message);
                window.location.href = "/";
              }
            } else {
              alert("Sign Up Successfully.");
              localStorage.setItem("access_token", response.data.access_token);
              localStorage.setItem("is_profile_created", "0");
              localStorage.setItem("role", "provider");
              localStorage.setItem("user", JSON.stringify(response.data.user));
              localStorage.setItem(
                "profile_step1",
                response.data.user.profile_step1
              );
              localStorage.setItem(
                "profile_step2",
                response.data.user.profile_step2
              );
              localStorage.setItem(
                "profile_step3",
                response.data.user.profile_step3
              );
              localStorage.setItem(
                "profile_step4",
                response.data.user.profile_step4
              );
              localStorage.setItem(
                "profile_step5",
                response.data.user.profile_step5
              );
              localStorage.setItem("current_user_id", response.data.user._id);
              window.location.href = "/";
            }

            // else{
            //     window.location.href= '/'
            // }
          })
          .catch(function (error) {
            setLoaderstyle({ display: "none" });
            if (error.response) {
              alert(error.response.data.message);
              window.location.href = "/";
            } else {
              alert(error.message);
              window.location.href = "/";
            }
          });
      } else if (type == "create_provider_profile") {
        if (name.length == 0) {
          setNameError("Name field can't be empty.");
        } else if (!startDate) {
          setDOBError(true);
        } else if (location.length == 0) {
          setLocationError("Location field can't be empty.");
        } else if (categories.length == 0 && selectedcatArray.length == 0) {
          setCatError(true);
        } else if (subCategories.length == 0 && subcatidsArray2.length == 0) {
          setSubCatError(true);
        } else if (bio.length == 0) {
          setBioError("Bio field can't be empty.");
        } else {
          setdocumentArray(documentArray.concat(customerDoc as any));
          // const formData = new URLSearchParams();
          // const formData = new FormData();
          // formData.append('name',name);
          // formData.append('birthdate',startDate);
          // formData.append('category',catsubarray as any);
          // formData.append('bio',bio);
          // formData.append('profile_step1',true as any);

          let formData = {
            name: name,
            birthdate: startDate,
            category: catsubarray,
            bio: bio,
            profile_step1: true,
            location: location,
            latitude: latlng.lat,
            longitude: latlng.lng,
          };

          console.log(formData);

          userService
            .updateProfile(formData)
            .then(function (response) {
              alert(response.data.message);
              localStorage.setItem("is_profile_created", "0");

              localStorage.setItem("user", JSON.stringify(response.data.user));

              localStorage.setItem(
                "profile_step1",
                response.data.user.profile_step1
              );
              localStorage.setItem(
                "profile_step2",
                response.data.user.profile_step2
              );
              localStorage.setItem(
                "profile_step3",
                response.data.user.profile_step3
              );
              localStorage.setItem(
                "profile_step4",
                response.data.user.profile_step4
              );
              localStorage.setItem(
                "profile_step5",
                response.data.user.profile_step5
              );
              window.location.reload();
              // SetProfilesModal()
            })
            .catch(function (error) {});
        }
      } else if (type == "upload_documents") {
        console.log("upload_documents after validation");
        if (docGovt == "") {
          setDocGovtError(true);
        } else if (docProfile == "") {
          setDocProfileError(true);
        } else {
          console.log("data is valid");
          const formData = new FormData();
          formData.append("id_document", docGovt);
          formData.append("profile_document", docProfile);

          if (workerdocumentArray2.length > 0) {
            workerdocumentArray2.map((value, index) => {
              formData.append("business_documents", (value as any).file);
            });
          }
          formData.append(
            "document_types",
            workerdocumentcatArray.join() as any
          );
          formData.append("profile_step2", true as any);

          userService
            .updateProfileimage(formData)
            .then(function (response) {
              alert(response.data.message);
              localStorage.setItem("user", JSON.stringify(response.data.user));

              localStorage.setItem(
                "profile_step1",
                response.data.user.profile_step1
              );
              localStorage.setItem(
                "profile_step2",
                response.data.user.profile_step2
              );
              localStorage.setItem(
                "profile_step3",
                response.data.user.profile_step3
              );
              localStorage.setItem(
                "profile_step4",
                response.data.user.profile_step4
              );
              localStorage.setItem(
                "profile_step5",
                response.data.user.profile_step5
              );
              SetProfilesModal();
            })
            .catch(function (error) {});

          // userService.updateProfileimage(formData)
          // .then(function (response) {
          //     alert(response.data.message)
          //     localStorage.setItem("user", JSON.stringify(response.data.user))

          //     localStorage.setItem("profile_step1", response.data.user.profile_step1)
          //     localStorage.setItem("profile_step2", response.data.user.profile_step2)
          //     localStorage.setItem("profile_step3", response.data.user.profile_step3)
          //     localStorage.setItem("profile_step4", response.data.user.profile_step4)
          //     localStorage.setItem("profile_step5", response.data.user.profile_step5)
          //     SetProfilesModal()

          // }).catch(function(error){
          // });
          // SetBankAccountModal()
        }
      } else if (type == "add_provider_bank_details") {
        if (accountName.length == 0) {
          setAccountNameError("Bank Name field can't be empty.");
        } else if (!/^[a-zA-Z ]*$/.test(accountName)) {
          setAccountNameError("Account Name only contains characters.");
        }
        // else if (!/[A-Za-z]/.test(accountName)) {
        //     setAccountNameError("Account Name only contains character.")
        // }
        else if (accountNumber.length == 0) {
          setAccountNumberError("Bank Name field can't be empty.");
        } else if (!/^\d+$/.test(accountNumber)) {
          setAccountNumberError(
            "Bank Name field should only contains numbers."
          );
        } else if (accountNumber.length > 16) {
          setAccountNumberError("Bank Name should be less than 16 digits.");
        } else if (accountNumber == "0") {
          setAccountNumberError("Bank Name must be valid.");
        } else if (routingNumber.length == 0) {
          setRoutingNumberError("Routing Number field can't be empty.");
        } else if (!/^\d+$/.test(routingNumber)) {
          setRoutingNumberError(
            "Routing Number field should only contains numbers."
          );
        } else if (routingNumber.length > 11) {
          setRoutingNumberError(
            "Routing Number should be less than 11 digits."
          );
        } else if (routingNumber == "0") {
          setRoutingNumberError("Routing Number must be valid.");
        } else if (branchName.length == 0) {
          setBranchNameError("Branch Name feild can't be empty.");
        } else if (!/^[a-zA-Z ]*$/.test(branchName)) {
          setBranchNameError("Branch Name only contains characters.");
        } else if (branchName.length > 30) {
          setBranchNameError("Branch Name should be less than 30 digits");
        } else {
          const formData = new URLSearchParams();
          const formData2 = new FormData();
          formData.append("bank_name", accountName);
          formData.append("account_no", accountNumber);
          formData.append("routing_no", routingNumber);
          formData2.append("profile_step3", true as any);

          userService
            .addBankDetails(formData.toString())
            .then(function (responses) {
              alert(responses.data.message);
              userService
                .updateProfile(formData2)
                .then(function (response) {
                  localStorage.setItem("is_profile_created", "0");

                  localStorage.setItem(
                    "user",
                    JSON.stringify(response.data.user)
                  );

                  localStorage.setItem(
                    "profile_step1",
                    response.data.user.profile_step1
                  );
                  localStorage.setItem(
                    "profile_step2",
                    response.data.user.profile_step2
                  );
                  localStorage.setItem(
                    "profile_step3",
                    response.data.user.profile_step3
                  );
                  localStorage.setItem(
                    "profile_step4",
                    response.data.user.profile_step4
                  );
                  localStorage.setItem(
                    "profile_step5",
                    response.data.user.profile_step5
                  );

                  SetProfilesModal();
                })
                .catch(function (error) {});
            })
            .catch(function (error) {
              if (error.response) {
                console.log(error);
                console.log(error.response);
                alert(error.response.data.message);
              }
            });
        }
      } else if (type == "create_provider_profile4") {
        if (profile == "") {
          alert("Please Upload your Profile");
        } else if (!isTermsAndConditionsAccepted) {
          setTermsError(true);
        } else {
          const formData = new FormData();
          formData.append("profile_image", profile);
          formData.append("profile_step4", true as any);
          formData.append("profile_step5", true as any);

          userService
            .updateProfileimage(formData)
            .then(function (response) {
              alert(response.data.message);

              localStorage.setItem("user", JSON.stringify(response.data.user));
              localStorage.setItem(
                "profile_step1",
                response.data.user.profile_step1
              );
              localStorage.setItem(
                "profile_step2",
                response.data.user.profile_step2
              );
              localStorage.setItem(
                "profile_step3",
                response.data.user.profile_step3
              );
              localStorage.setItem(
                "profile_step4",
                response.data.user.profile_step4
              );
              localStorage.setItem(
                "profile_step5",
                response.data.user.profile_step5
              );
              SetProfilesModal();
            })
            .catch(function (error) {});
        }
      }
    }
  };

  function profileComplete() {
    console.log(isTermsAndConditionsAccepted);
    if (isTermsAndConditionsAccepted) {
      // localStorage.setItem("access_token", "fghfgfjgfj")
      console.log("userrole", userrole);
      if (userrole == 0) {
        localStorage.setItem("role", "customer");
        window.location.href = "/";
      } else {
        localStorage.setItem("role", "provider");
        window.location.href = "/provider_home";
      }
    } else {
      setTermsError(true);
    }
  }

  function onEmailChanged(event) {
    setEmailError("");
    setEmail(
      email.length == 0
        ? event.target.value.replace(/ /g, "")
        : event.target.value
    );
  }
  function onPasswordChanged(event) {
    setPasswordError("");
    setPassword(event.target.value.replace(/ /g, ""));
  }
  function onConfirmPasswordChanged(event) {
    setConfirmPasswordError("");
    setConfirmPassword(event.target.value.replace(/ /g, ""));
  }

  function onNameChanged(event) {
    setNameError("");
    setName(event.target.value);
  }
  function onBioChanged(event) {
    setBioError("");
    setBio(event.target.value);
  }

  function onLocationChanged(event) {
    setLocationError("");
    setLocation(
      location.length == 0
        ? event.target.value.replace(/ /g, "")
        : event.target.value
    );
  }

  function onAccountNameChanged(event) {
    setAccountNameError("");
    setAccountName(event.target.value);
  }

  function onAccountNumberChanged(event) {
    setAccountNumberError("");
    setAccountNumber(
      accountNumber.length == 0
        ? event.target.value.replace(/ /g, "")
        : event.target.value
    );
  }

  function onRoutingChanged(event) {
    setRoutingNumberError("");
    setRoutingNumber(
      routingNumber.length == 0
        ? event.target.value.replace(/ /g, "")
        : event.target.value
    );
  }

  function OnBranchNameChanged(event) {
    setBranchNameError("");
    setBranchName(
      branchName.length == 0
        ? event.target.value.replace(/ /g, "")
        : event.target.value
    );
  }

  const validateEmail = (data: any) => {
    let fields = data;
    let errors: any = [];
    let formIsValid = true;
    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );

    if (email.length == 0) {
      setEmailError("Email address field can't be empty.");
      formIsValid = false;
    } else if (!pattern.test(email)) {
      formIsValid = false;
      setEmailError("Email address is not valid.");
    }
    return formIsValid;
  };

  const handleChangeadd = (e: any) => {
    console.log("handlechangedddd");
    geocodeByAddress(e.description)
      .then((results: any[]) => getLatLng(results[0]))
      .then(({ lat, lng }: any) => setLatlng({ lat, lng }));
    console.log("----", e.description);
    setLocation(e.description);
    setLocationError("");
  };

  const validateForm = (data: any, type: string) => {
    let fields = data;
    let errors: any = [];
    let formIsValid = true;

    if (type == "login") {
      formIsValid = validateEmail(data);
      if (password.length == 0) {
        setPasswordError("Password field can't be empty.");
        formIsValid = false;
      }
      return formIsValid;
    } else if (type == "forgot_password") {
      let vat = validateEmail(data);
      return vat;
    } else if (type == "sign_up") {
      formIsValid = validateEmail(data);
      if (!formIsValid) {
      } else if (password.length == 0) {
        formIsValid = false;
        setPasswordError("Password field can't be empty.");
      } else if (password.length < 8) {
        formIsValid = false;
        setPasswordError("Password length should be greater than 8 digits");
      } else if (confirmPassword.length == 0) {
        formIsValid = false;
        setConfirmPasswordError("Confirm Password field can't be empty.");
      } else if (password != confirmPassword) {
        formIsValid = false;
        const access_token = localStorage.getItem("access_token");
        setConfirmPasswordError("Password and Confirm Password Not Matched.");
        // formIsValid = true;
      }

      return formIsValid;
    } else if (type == "create_customer_profile") {
      return formIsValid;
    } else if (type == "upload_documents") {
      let user_selected_cat = JSON.parse(
        localStorage.getItem("user") as string
      ).categories;
      console.log("categories", user_selected_cat);
      console.log("workerdocumentArray", workerdocumentArray);
      console.log("workerdocumentcatArray", workerdocumentcatArray);

      let tmp_doc_ary_er: any = selCategoryAry;

      var j;
      for (j = 0; j < tmp_doc_ary_er.length; j++) {
        if (workerdocumentcatArray.includes(tmp_doc_ary_er[j]._id)) {
          tmp_doc_ary_er[j].is_error = false;
        } else if (tmp_doc_ary_er[j].document_url != "") {
          tmp_doc_ary_er[j].is_error = false;
        } else {
          console.log("tmp_doc_ary_er[j] error", tmp_doc_ary_er[j]);
          tmp_doc_ary_er[j].is_error = true;
          formIsValid = false;
        }
      }

      setSelCategoryAry(tmp_doc_ary_er);

      // if(user_selected_cat.length == workerdocumentcatArray.length){
      //     console.log("all doc available")

      // }else{
      //     toast.error(config.errorValidationMsg);
      //     console.log("Not all documents avai");
      //     formIsValid = false;
      //     let tmp_doc_ary_er:any = selCategoryAry;

      //     var j;
      //     for (j = 0; j < tmp_doc_ary_er.length; j++) {
      //         if(workerdocumentcatArray.includes(tmp_doc_ary_er[j]._id)){
      //             tmp_doc_ary_er[j].is_error = false;
      //         }else{
      //             tmp_doc_ary_er[j].is_error = true;
      //         }
      //     }

      //     setSelCategoryAry(tmp_doc_ary_er);

      // }
      console.log("is form valid", formIsValid);

      return formIsValid;
    } else {
      return formIsValid;
    }
  };

  function _handleImageChange(e: any) {
    e.preventDefault();
    if (e.target.files[0]) {
      setCustomerDoc(e.target.files[0]);
      setCustDocError(false);
    }

    let reader = new FileReader();
    let file = e.target.files[0];
    setImagedata(file);
    reader.onloadend = () => {
      console.log("onloadend");

      const csv: string = reader.result as string;

      //   setImage(csv)
    };

    reader.readAsDataURL(file);
  }

  function doc1changeHandle(e: any) {
    // alert("doc1changeHandle")

    if (
      e.target.files[0].type == "video/mp4" ||
      e.target.files[0].type == "video/ogg"
    ) {
      alert("Please enter valid document");
    } else {
      if (e.target.files[0].size > config.perImageFileSize) {
        alert("Please upload max of 3 mb");
        // error = true
        return;
      } else {
        setDoc1(e.target.files[0]);
        setCustDocError(false);
      }
    }
  }

  function _handleProfileImageChange(e: any) {
    e.preventDefault();
    if (e.target.files[0]) {
      console.log("fileeee", e);
      console.log("fileeee ", e.target.files[0]);
      if (
        e.target.files[0].type == "image/png" ||
        e.target.files[0].type == "image/jpg" ||
        e.target.files[0].type == "image/jpeg"
      ) {
        setProfile(e.target.files[0]);
        setCustDocError(false);
        let reader = new FileReader();
        let file = e.target.files[0];
        if (file.size > config.perImageFileSize) {
          alert("Please upload max of 3 mb");

          return;
        } else {
          setImagedata(file);
        }

        reader.onloadend = () => {
          console.log("onloadend");

          const csv: string = reader.result as string;
          setProfiledata(csv);

          //   setImage(csv)
        };

        reader.readAsDataURL(file);
      } else {
        console.log("doc errorrrrrrrrrrrrr");
        // setCustDocError(true)
        setCustProfileImageError(true);
      }
    }
  }

  function Logout() {
    var r = window.confirm("Are you sure you want to logout?");

    if (r == true) {
      console.log("logout home", window.location.origin);
      localStorage.clear();
      window.location.href =
        window.location.host == "localhost:3000"
          ? `${config.appurlDev}`
          : `${config.appurl}`;
    } else {
    }
  }

  const customStyles1 = {
    overlay: {
      display: "block",
      paddingRight: "15px",
      backgroundColor: "rgba(51,51,51,0.8)",
      zIndex: 99,
    },

    content: {
      position: "",
      top: "",
      right: "",
      bottom: "",
      left: "",
    },
  };

  const renderField_test = ({
    input,
    label,
    type,
    meta: { touched, error, warning },
  }) => (
    <div>
      <label>{label}</label>
      <div>
        <input {...input} placeholder={label} type={type} />
        {touched &&
          ((error && <span>{error}</span>) ||
            (warning && <span>{warning}</span>))}
      </div>
    </div>
  );

  function getSubcategory(e: any) {
    var ids: any = [];
    var aa: any;

    // ids = e.map((item,index)=>{ return(ids.concat(item._id))})
    ids = e.map((item, index) => {
      return (ids[index] = item._id);
    });
    const data = {
      categoryIds: ids,
    };
    setCatidsArray(ids);
    userService
      .getSubCategories(data)
      .then(function (response) {
        console.log({ here: response.data });
        setsubCatidsArray(response.data.sub_categories);
      })
      .catch(function (error) {});
  }

  function setSubcateascat(e: any, type: string) {
    if (type == "add") {
      let cat: any = catidsArray.concat(e[e.length - 1]._id);
      setCatidsArray(cat);
      getSubcategory(e);
    } else {
      if (e.length > 0) {
        // setCatidsArray(catidsArray.filter(item => item == e[e.length-1]._id))
        // getSubcategory(e)
        // debugger
        let dd: any = [];
        dd = e.map((item, index) => {
          return (dd[index] = item._id);
        });
        let cat: any = catidsArray.filter(
          (item) => item == e[e.length - 1]._id
        );
        setCatidsArray(dd);
        getSubcategory(e);
      }
    }
  }

  function setsubcat(e: any, type: string) {
    if (type == "add") {
      var ids: any = [];
      ids = catidsArray;
      var aa: any = [];
      let subads: any = e || [];
      let subadsid: any = [];
      let data: any = [];

      ids.map((cat: any, index1) => {
        aa = subads.map((item, index) => {
          if (cat == item.category_id) {
            return (subadsid[index] = item._id);
          }
        });

        data[index1] = {
          id: cat,
          subcategories: aa.filter(function (item) {
            return item != undefined;
          }),
        };
      });
      setCatsubarray(data);
      console.log(data);
      // ids = e.map((item,index)=>{ return(ids.concat(item._id))})
      // setCatidsArray(catidsArray.concat(e[e.length-1]._id))
      // getSubcategory(e)
    } else {
      if (e.length > 0) {
        // setCatidsArray(catidsArray.filter(item => item == e[e.length-1]._id))
        // getSubcategory(e)
      }
    }
  }

  const required = (value) => (value ? undefined : "Required");
  const null_value = (value) =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
      ? "Invalid email address"
      : undefined;

  const [selectedcatArray, setSelectedCatArray] = useState([]);
  const [subcatidsArray2, setsubCatidsArray2] = useState([]);
  const [userworkerdocumentcatArray, setUserworkerdocumentcatArray] = useState(
    []
  );
  function setdata() {
    let user_data: any = localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user") as string)
      : "";

    if (user_data != "") {
      if (user_data.profile_step1 == true) {
        getData("again");
        setTimeout(function () {
          const element2 = document.querySelector(
            ".category #multiselectContainerReact"
          );

          if (
            JSON.parse(localStorage.getItem("user") as string).categories
              .length > 0
          ) {
            var has_active =
              element2 != null && element2.classList.contains("has_data");
            if (has_active == false) {
              element2 != null && element2.classList.add("has_data");
            }
          } else {
            element2 != null && element2.classList.remove("has_data");
          }

          const element = document.querySelector(
            ".sub_category #multiselectContainerReact"
          );

          if (
            JSON.parse(localStorage.getItem("user") as string).categories
              .length > 0
          ) {
            var has_active =
              element != null && element.classList.contains("has_data");
            if (has_active == false) {
              element != null && element.classList.add("has_data");
            }
          } else {
            element != null && element.classList.remove("has_data");
          }
        });
        setName(user_data.name);
        setBio(user_data.bio);
        setSelectedCatArray(user_data.categories);

        setLocation(user_data.location);
        setLatlng({
          lat: user_data.latitude,
          lng: user_data.longitude,
        });

        var i;
        var tmp_ary_cat_er: any = [];
        console.log("user_data.categories", user_data.categories);
        for (i = 0; i < user_data.categories.length; i++) {
          let hsh_cat_er: any = user_data.categories[i];
          hsh_cat_er.is_error = false;
          console.log("hsh_cat_er", hsh_cat_er);
          tmp_ary_cat_er.push(hsh_cat_er);
        }
        console.log("---tmp_ary_cat_er", tmp_ary_cat_er);
        // alert("here");
        ////////////////////////////////////////////
        setSelCategoryAry(tmp_ary_cat_er);

        // setCustomerDoc(user_data.)
        if (user_data.birthdate) {
          let dates: any = user_data.birthdate;
          var datecheck = moment(dates);
          let valid = datecheck.isValid();
          if (valid === false) {
            const date = moment(dates, "DD-MM-YYYY").format("D MMMM, YYYY");
            setStartdate(new Date(date));
          } else {
            const date = moment(dates).format("D MMMM, YYYY");
            setStartdate(new Date(date));
          }
        }
      }
      if (user_data.profile_step1 == true) {
        setDocGovt(user_data.id_document);
        setDocProfile(user_data.profile_document);
        setUserworkerdocumentcatArray(user_data.documents);
      }
      if (user_data.profile_step3 == true) {
        userService
          .getBankDetails()
          .then(function (response) {
            if (response.data.bank) {
              setAccountName(response.data.bank.bank_name);
              setAccountNumber(response.data.bank.account_no);
              setRoutingNumber(response.data.bank.routing_no);
            }
          })
          .catch(function (error) {});
      }
    }
  }

  const access_token = localStorage.getItem("access_token");
  const { error, handleSubmit, pristine, reset, submitting } = props;
  return (
    <div>
      <ToastContainer autoClose={10000} />
      <section className="banner_area">
        <header className="header-area" id="fix-header">
          <div className="container">
            <div className="main_header_box">
              <nav className="navbar">
                <div className="navbar-header">
                  <button
                    type="button"
                    className="navbar-toggle"
                    data-toggle="collapse"
                    data-target="#myNavbar"
                  >
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                  </button>
                  <a className="navbar-brand" href="/">
                    <img src={logo} alt="logo" />
                  </a>
                </div>
                <div className="contact_box" style={{ display: "none" }}>
                  <div className="contact-content">
                    <p>
                      <i className="fa fa-phone" aria-hidden="true"></i> +(234)
                      703 482 2809
                      <br></br>M-S: 07:30 – 19:00{" "}
                    </p>
                  </div>
                </div>
                <div className="collapse navbar-collapse" id="myNavbar">
                  <ul className="nav navbar-nav navbar-right">
                    <li>
                      <a href="#services"> Services</a>
                    </li>
                    <li>
                      <a href="#how_it_works"> How It Works</a>
                    </li>
                    <li>
                      <a href="#about"> About</a>
                    </li>
                    <li>
                      {!access_token && (
                        <a className="btn_style" onClick={SetAccountModal}>
                          Login
                        </a>
                      )}
                    </li>
                    <li>
                      {access_token && (
                        <a className="btn_style" onClick={SetProfilesModal}>
                          Set Profile
                        </a>
                      )}
                    </li>
                    <li>
                      {access_token && (
                        <a className="btn_style" onClick={Logout}>
                          Logout
                        </a>
                      )}
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </header>

        <OwlCarousel
          items={1}
          className="owl-theme"
          autoPlay={true}
          loop
          margin={10}
          nav
          dots={false}
        >
          <div className="item">
            <div className="banner_box one_banner">
              <h1>Post. Accept. Hire! </h1>
              <p>Your Construction Projects, Simplified.</p>
            </div>
          </div>

          <div className="item">
            <div className="banner_box two_banner">
              <h1>
                A New Way to find<br></br> services
              </h1>
              <p>Easiest and simplest way to find work. </p>
            </div>
          </div>
        </OwlCarousel>
      </section>

      <section className="how_it_work padding_90" id="how_it_works">
        <div className="container">
          <OwlCarousel items={1} className="owl-theme" loop margin={10} nav>
            <div className="item">
              <div className="tittle_box">
                <h1 className="tittle_heading">Here's how it works</h1>
                <h3 className="provider">For Customers</h3>
                <p className="tittlep">Find in 3 easy steps</p>
              </div>
              <div className="main_box">
                <div className="row top_margin75">
                  <div className="col-md-4">
                    <div className="content_box">
                      <span>1</span>
                      <h1>Post a project</h1>
                      <p>Quickly post with our user-friendly form.</p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="content_box milddle-box">
                      <span>2</span>
                      <h1>Accept an Offer</h1>
                      <p>
                        Assess bids, check ratings, view photos, and chat to
                        make your choice.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="content_box">
                      <span>3</span>
                      <h1>Hire a Pro</h1>
                      <p>
                        Secure payments with control on release. Ensure project
                        completion.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="item">
              <div className="tittle_box">
                <h1 className="tittle_heading">Here's how it works</h1>
                <h3 className="provider">For Providers</h3>
                <p className="tittlep">Find in 3 easy steps</p>
              </div>
              <div className="main_box">
                <div className="row top_margin75">
                  <div className="col-md-4">
                    <div className="content_box">
                      <span>1</span>
                      <h1> Find a Project</h1>
                      <p>
                        It is long established fact that reader will be
                        distracted the readable content of a page when looking
                        at its layout.{" "}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="content_box milddle-box">
                      <span>2</span>
                      <h1>Send a Bid</h1>
                      <p>
                        Compare prices and read previous customer feedback to
                        pick the right delivery quote for you.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="content_box">
                      <span>3</span>
                      <h1>Get Paid</h1>
                      <p>
                        It is long established fact that reader will be
                        distracted the readable content of a page when looking
                        at its layout.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </OwlCarousel>
        </div>
      </section>

      <section className="our-popular-services padding_90">
        <div className="container">
          <div className="content-box" id="services">
            <h1 className="tittle_heading">Our Popular Services</h1>

            <OwlCarousel items={1} className="owl-theme" loop margin={10} nav>
              <div className="item">
                <div className="row top_margin75 new-owl-crousel">
                  <div className="col-md-4">
                    <div className="our_content">
                      <img src={plumber} alt="plumber" />
                      <h1>Carpenter</h1>
                      <p>
                        Install structures and fixtures like windows. Measure
                        and cut wood and other materials. Build building frames
                        like wall and doors.
                      </p>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="our_content">
                      <img src={carpanter} alt="carpenter" />
                      <h1>Drywall</h1>
                      <p>
                        Install and cut wallboard panels onto wood or metal
                        studs. Finish by protecting corners. Fix existing wall
                        damage.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="our_content">
                      <img src={elc} alt="elc" />
                      <h1>Electrician</h1>
                      <p>
                        Lay out, assemble, install, test, troubleshoot, and
                        repair electrical wiring, fixtures, control devices and
                        related equipment in buildings and other structures
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="row top_margin75 new-owl-crousel">
                  <div className="col-md-4">
                    <div className="our_content">
                      <img src={plumber} alt="plumber" />
                      <h1>HVAC</h1>
                      <p>
                        Heating, ventilation, and Air conditioning. Testing,
                        repair, install, and maintaining equipment and
                        components.
                      </p>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="our_content">
                      <img src={carpanter} alt="carpenter" />
                      <h1>Millwork</h1>
                      <p>
                        Woodwork or building products. Doors, moulding trim,
                        flooring, wall,
                      </p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="our_content">
                      <img src={elc} alt="elc" />
                      <h1>Painter</h1>
                      <p>
                        Repair holes, wash walls, paint new coat, or remove old
                        paint. Mix and match existing paint. Finishing or faux
                        finishes
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="item">
                <div className="row top_margin75 new-owl-crousel">
                  <div className="col-md-4">
                    <div className="our_content">
                      <img src={plumber} alt="plumber" />
                      <h1>Plumbing</h1>
                      <p>
                        Install, maintain, and repair pipes, fixtures and other
                        plumbing equipment or components
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </OwlCarousel>
          </div>
        </div>
      </section>
      <section className="download">
        <div className="container">
          <div className="content_box">
            <div className="row">
              <div className="col-md-5 text-center">
                <div className="download_left_content">
                  <img src={jobposting_phone} alt="jobposting_phone" />
                </div>
              </div>
              <div className="col-md-7">
                <div className="download_right_content">
                  <h1>Download the Tendr App</h1>
                  <h4>Customer:</h4>
                  <p>
                    Ready to reshape your space? Dive into Tendr's vast pool of
                    contractors. Navigate your renovation adventure with endless
                    choices – download the app and kickstart your project today!
                  </p>
                  <br></br>
                  <h4>Service Provider:</h4>
                  <p>
                    Seeking job opportunities? Say goodbye to the hassle of
                    finding clients and upfront payment for leads. Download
                    Tendr now – start working and earning today!
                  </p>
                  <ul className="download_button_list">
                    <li>
                      <a href="">
                        <img src={app_store} alt="app_store" />
                      </a>
                    </li>
                    <li>
                      <a href="">
                        <img src={google_play} alt="google-play" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="about-tender" id="about">
        <div className="about_content_box">
          <img src={abouttender} alt="about-Tendr" />
        </div>
        <div className="about_content_box">
          <div className="about_text-box">
            <h1>About Tendr</h1>
            <p>
              Tendr stands as Canada's premier construction service platform,
              fostering seamless connections and collaborations between
              customers and skilled tradespeople for construction projects, big
              or small.
            </p>
            <p>
              Accessible via desktop, iOS, or Android devices, Tendr ensures
              you're always in sync with your projects, no matter your location.
              Customers can effortlessly receive, review, and accept bids, while
              tradespeople have a streamlined avenue to discover new work
              opportunities that complement their availability.
            </p>
            <p>
              At the heart of Tendr Technologies lies a simple yet profound
              mission: Bridging customers with varied trades specialists
            </p>
          </div>
        </div>
        <div className="clear"></div>
      </section>

      <section className="testimonial">
        {/*
                <div className="container">
                    <div className="content_box ">
                        <h1 className="tittle_heading">Clients Testimonial</h1>
                    </div>
                    <div className=" testimonial_content">
                        <div className="col-md-12">
                            <div className="carousel slide" data-ride="carousel" id="quote-carousel">
                                <div className="carousel-inner text-center">
                                    <div className="item active">
                                        <p className="text-center">Tendr team is the best of the best, and expertly trained team members who take the extra step and go the extra mile, all to fulfill our dedicated promise to deliver innovative and dynamic solutions to our customers to fit the needs of a rapidly changing global environment.</p>

                                    </div>
                                    <div className="item">
                                        <p className="text-center">Tendr team is the best of the best, and expertly trained team members who take the extra step and go the extra mile, all to fulfill our dedicated promise to deliver innovative and dynamic solutions to our customers to fit the needs of a rapidly changing global environment.</p>
                                    </div>
                                    <div className="item">
                                        <p className="text-center">Tendr team is the best of the best, and expertly trained team members who take the extra step and go the extra mile, all to fulfill our dedicated promise to deliver innovative and dynamic solutions to our customers to fit the needs of a rapidly changing global environment.</p>

                                    </div>
                                </div>
                                <ol className="carousel-indicators">
                                    <li data-target="#quote-carousel" data-slide-to="0" className="active">
                                        <img className="img-responsive " src={wade_pic} alt="" />
                                        <h3>Sami wade</h3>
                                        <p>WhiteMov</p>
                                    </li>
                                    <li data-target="#quote-carousel" data-slide-to="1">
                                        <img className="img-responsive" src={wade_pic2} alt="" />
                                        <h3>Sami wade</h3>
                                        <p>WhiteMov</p>
                                    </li>
                                    <li data-target="#quote-carousel" data-slide-to="2">
                                        <img className="img-responsive" src={wade_pic3} alt="" />
                                        <h3>Sami wade</h3>
                                        <p>WhiteMov</p>
                                    </li>
                                </ol>

                                <a data-slide="prev" href="#quote-carousel" className="left carousel-control"><i className="fa fa-chevron-left"></i></a>
                                <a data-slide="next" href="#quote-carousel" className="right carousel-control"><i className="fa fa-chevron-right"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="provider-services">
                <div className="container">
                    <div className="delivery_work_content">
                        <h4>Looking for delivery work?</h4>
                        <h1>Become A Service Provider?</h1>
                        <div>
                            <div className="register-button_box">
                                <a href="javascript:void(0)" onClick={() => setProviderSignup()} className="btn_style">Register Now<span><i className="fa fa-long-arrow-right" aria-hidden="true"></i></span></a>
                            </div>
                        </div>
                    </div>
                </div>
                */}
      </section>

      <footer>
        <div className="footer">
          <div className="container">
            <div className="content_box">
              <div className="row">
                <div className="col-sm-4 left_content">
                  <div className="footer_content_logo_side">
                    <a href="/">
                      <img src={footer_logo} alt="footer_logo" />
                    </a>
                  </div>
                </div>
                {/* <div className="col-sm-2 social_links ">
                                    <div className="footer_content">
                                        <h4>Social Links</h4>
                                        <p><a href=""><i className="fa fa-facebook" aria-hidden="true"></i>Facebook</a></p>
                                        <p><a href=""><i className="fa fa-twitter" aria-hidden="true"></i>Twitter</a></p>
                                        <p><a href=""><i className="fa fa-linkedin" aria-hidden="true"></i>Linkedin</a></p>
                                    </div>
                                </div> */}
                <div className="col-sm-4 links_box">
                  <div className="footer_content Quick-links-list">
                    <h4>Quick Links</h4>
                    <p>
                      <a href="#how_it_works">How It Works</a>
                    </p>
                    <p>
                      <a
                        href={`${config.apiBaseUrl}/privacy/privacy_policy.html`}
                        target="_blank"
                      >
                        Privacy Policy{" "}
                      </a>
                    </p>
                    <p>
                      <a href="#about">About Us</a>
                    </p>
                    <p>{<a href="#">Login</a>}</p>
                  </div>
                </div>
                <div className="col-sm-4 contact_box">
                  <div className="footer_content">
                    <h4>Contact Info</h4>
                    <p>
                      <span>
                        <i className="fa fa-map-marker" aria-hidden="true"></i>
                      </span>
                      Vancouver, BC, Canada
                    </p>

                    <p>
                      <i className="fa fa-envelope" aria-hidden="true"></i>
                      support@tendr.live
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer_copy_right text-center">
          <p>
            <i className="fa fa-copyright" aria-hidden="true"></i> Copyright
            2020. All rights reserved.
          </p>
        </div>
      </footer>

      {/* account Modal */}
      <Modal
        ariaHideApp={false}
        isOpen={accountType}
        onRequestClose={closeModal}
        className={"modal-content  new_modal_content add_profile_modal-content"}
        contentLabel="Example Modal"
        style={customStyles1}
      >
        <div className="modal-header">
          <h4 className="modal-title">
            <span>
              <i
                className="fa fa-times-circle-o"
                onClick={closeModal}
                aria-hidden="true"
                data-dismiss="modal"
              ></i>
            </span>{" "}
          </h4>
        </div>
        <div className="modal-body">
          <div className="logo_area text-center">
            <img src={logo1} alt="logo-1" />
          </div>
          <h1 className="select-accout-heading">
            Please select your account type
          </h1>

          <div className="submit_bottom">
            <div className="main_btn">
              <a
                data-toggle="modal"
                onClick={() => SetLoginModal(0)}
                data-target="#login"
                data-dismiss="modal"
              >
                Customer
              </a>
            </div>
          </div>
          <div className="or">
            <span>or</span>
          </div>
          <div className="submit_bottom">
            <div className="main_btn pink_btn">
              <a
                data-toggle="modal"
                onClick={() => SetLoginModal(1)}
                data-target="#provider-login"
                data-dismiss="modal"
              >
                Service Provider
              </a>
            </div>
          </div>
        </div>
      </Modal>

      {/* LOGIN MODAL */}
      <Modal
        ariaHideApp={false}
        isOpen={loginModal}
        onRequestClose={closeModal}
        className={"modal-content new_modal_content login_modal small_modal"}
        contentLabel="Example Modal"
        style={customStyles1}
      >
        <div className="modal-header">
          <h4 className="modal-title">
            Login
            <span>
              <i
                className="fa fa-times-circle-o"
                onClick={closeModal}
                aria-hidden="true"
                data-dismiss="modal"
              ></i>
            </span>{" "}
          </h4>
        </div>
        <div className="modal-body">
          {/* <div className="button_box">
            <FacebookLogin
              appId={`${config.fb_appId}`}
              fields="name,email,picture"
              callback={responseFacebook}
              icon="fa-facebook"
            />

            <GoogleLogin
              clientId={`${config.google_clientid}`}
              buttonText="LOGIN WITH GOOGLE"
              onSuccess={responseGoogle}
              onFailure={responseGoogle}
              className={"social_button_links google"}
              autoLoad={false}
            />

            <LinkedinLogin
              clientId={`${config.linkedin}`}
              buttonText="LOGIN WITH LINKEDIN"
              onSuccess={handleSuccess}
              onFailure={handleFailure}
              scope="r_liteprofile"
              redirectUri={`${config.appurl}`}
              callback={`${config.appurl}`}
              className={"social_button_links linkedin"}
            >
              <i className="fa fa-linkedin" aria-hidden="true"></i>LOGIN WITH
              LINKEDIN
            </LinkedinLogin>
          </div> */}
          {/* <div className="or">
            <span>or</span>
          </div> */}
          <div className="input_form_box">
            <form
              onSubmit={handleSubmit((val: any) => submit(val, "login"))}
              autoComplete={"off"}
            >
              {/* <Field
                                name="email"
                                type="text"
                                component={renderField}
                                label="EMAIL ADDRESS"
                                id="exampleInputEmail1"
                                img={email}
                                placeholder="Enter Address"
                            /> */}
              <input type="email" name="email" style={{ display: "none" }} />
              <input
                type="password"
                name="password"
                style={{ display: "none" }}
              />

              <div className="form-group">
                <label>EMAIL ADDRESS</label>
                <div className="input_content">
                  <input
                    type="text"
                    onChange={onEmailChanged}
                    className="form-control"
                    placeholder="Email Address"
                    value={email}
                    autoComplete={new Date() + "jahjhk-newwww"}
                    readOnly={isReadOnly}
                    onFocus={(e) => setIsReadOnly(false)}
                  />
                  <img src={email_img} alt="email" />
                  {emailError.length > 0 && (
                    <span style={{ color: "red" }}>{emailError}</span>
                  )}
                </div>
              </div>
              <div className="form-group">
                <label>PASSWORD</label>
                <div className="input_content relative-boxcustomIcon">
                  <input
                    type={isrevealPassword ? "text" : "password"}
                    maxLength={32}
                    onChange={onPasswordChanged}
                    className="form-control"
                    placeholder="Password"
                    value={password}
                    autoComplete={new Date() + "password-newwww"}
                    readOnly={isReadOnly}
                    onFocus={(e) => setIsReadOnly(false)}
                  />
                  {isrevealPassword ? (
                    <i
                      className="fa fa-eye eyeCustomIcon color_blue"
                      aria-hidden="true"
                      onClick={() => togglePassword()}
                    ></i>
                  ) : (
                    <i
                      className="fa fa-eye eyeCustomIcon"
                      aria-hidden="true"
                      onClick={() => togglePassword()}
                    ></i>
                  )}
                  <img src={lock} alt="email" />
                  {passwordError.length > 0 && (
                    <span style={{ color: "red" }}>{passwordError}</span>
                  )}
                </div>
              </div>

              <div className="box_bottom">
                <div className="main_btn">
                  <button
                    type="submit"
                    disabled={submitting}
                    className="login_btn"
                  >
                    Login
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div className="forgot">
            <a
              onClick={SetForgotPasswordModal}
              data-toggle="modal"
              data-target="#provider_forgot_modal"
              data-dismiss="modal"
            >
              Forgot Password?
            </a>
          </div>
        </div>
        <div className="modal-footer">
          <div className="modal_footer-content">
            <p>
              <span>Don’t have an account?</span>{" "}
              <span>
                <a
                  data-toggle="modal"
                  onClick={SetSignup}
                  data-target="#provider-signup-modal"
                  data-dismiss="modal"
                >
                  Sign Up
                </a>
              </span>
            </p>
          </div>
        </div>
      </Modal>

      {/* Forgot Password */}
      <Modal
        ariaHideApp={false}
        isOpen={forgetPasswordModal}
        onRequestClose={closeModal}
        className={"modal-content  new_modal_content "}
        contentLabel="Example Modal"
        style={customStyles1}
      >
        <div className="modal-header">
          <h4 className="modal-title">
            Forgot Password
            <span>
              <i
                className="fa fa-times-circle-o"
                onClick={closeModal}
                aria-hidden="true"
                data-dismiss="modal"
              ></i>
            </span>{" "}
          </h4>
        </div>
        <div className="modal-body">
          <div className="text_box">
            <h1>
              Enter your registered email address in the field below.We will
              send you a link to reset your password.
            </h1>
          </div>
          <div className="input_form_box">
            <form
              onSubmit={handleSubmit((val: any) =>
                submit(val, "forgot_password")
              )}
              autoComplete={"off"}
            >
              <div className="form-group">
                <label>EMAIL ADDRESS</label>
                <div className="input_content">
                  <input
                    type="text"
                    onChange={onEmailChanged}
                    className="form-control"
                    placeholder="Email Address"
                    value={email}
                  />
                  <img src={email_img} alt="email" />
                  {emailError.length > 0 && (
                    <span style={{ color: "red" }}>{emailError}</span>
                  )}
                </div>
              </div>
              {/* <Field
                                name="email"
                                type="email"
                                component={renderField}
                                label="EMAIL ADDRESS"
                                id="exampleInputEmail1"
                                img={email}
                                placeholder="Email address"
                            /> */}

              <div className="reset_bottom">
                <div className="main_btn">
                  <button
                    type="submit"
                    disabled={submitting}
                    className="login_btn"
                  >
                    Reset My Password
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="modal-footer">
          <div className="modal_footer-content">
            <p>
              Back to{" "}
              <span>
                <a
                  data-toggle="modal"
                  onClick={() => SetLoginModal(userrole)}
                  data-target="#login"
                  data-dismiss="modal"
                >
                  Login
                </a>
              </span>
            </p>
          </div>
        </div>
      </Modal>

      {/* Sign Up Modal */}
      <Modal
        ariaHideApp={false}
        isOpen={signupModal}
        onRequestClose={closeModal}
        className={"modal-content  new_modal_content small_modal"}
        contentLabel="Example Modal"
        style={customStyles1}
      >
        <div className="modal-header">
          <h4 className="modal-title">
            Sign Up
            <span>
              <i
                className="fa fa-times-circle-o"
                onClick={closeModal}
                aria-hidden="true"
                data-dismiss="modal"
              ></i>
            </span>{" "}
          </h4>
        </div>
        <div className="modal-body">
          {/* <div className="button_box">
            <FacebookLogin
              appId={`${config.fb_appId}`}
              fields="name,email,picture"
              callback={responseFacebook}
              icon="fa-facebook"
            />

            <GoogleLogin
              clientId={`${config.google_clientid}`}
              buttonText="LOGIN WITH GOOGLE"
              onSuccess={responseGoogle}
              onFailure={responseGoogle}
              className={"social_button_links google"}
              autoLoad={false}
            />
            <LinkedinLogin
              clientId={`${config.linkedin}`}
              buttonText="LOGIN WITH LINKEDIN"
              onSuccess={handleSuccess}
              onFailure={handleFailure}
              scope="r_liteprofile"
              redirectUri={`${config.appurl}`}
              callback={`${config.appurl}`}
              className={"social_button_links linkedin"}
            >
              <i className="fa fa-linkedin" aria-hidden="true"></i>LOGIN WITH
              LINKEDIN
            </LinkedinLogin>

            <LinkedinLogin clientId={`${config.linkedin}`}
                            buttonText="LOGIN WITH LINKEDIN"
                            onSuccess={handleSuccess}
                            onFailure={handleFailure}
                            scope="r_liteprofile+r_emailaddress+w_member_social"
                            redirectUri={`${config.appurl}`}
                            callback={`${config.appurl}`}
                            className={"social_button_links linkedin"}
                        ></LinkedinLogin>
          </div> */}
          {/* <div className="or">
            <span>or</span>
          </div> */}
          <div className="input_form_box">
            <form
              onSubmit={handleSubmit((val: any) => submit(val, "sign_up"))}
              autoComplete={"off"}
            >
              <input type="email" name="email" style={{ display: "none" }} />
              <input
                type="password"
                name="password"
                style={{ display: "none" }}
              />

              <div className="form-group">
                <label>EMAIL ADDRESS</label>
                <div className="input_content">
                  <input
                    type="text"
                    onChange={onEmailChanged}
                    className="form-control"
                    placeholder="Enter Address"
                    value={email}
                  />
                  <img src={email_img} alt="email" />
                  {emailError.length > 0 && (
                    <span style={{ color: "red" }}>{emailError}</span>
                  )}
                </div>
              </div>
              <div className="form-group">
                <label>PASSWORD</label>
                <div className="input_content relative-boxcustomIcon">
                  <input
                    type={isrevealNewPassword ? "text" : "password"}
                    maxLength={32}
                    onChange={onPasswordChanged}
                    className="form-control"
                    placeholder="Password"
                    value={password}
                  />
                  {isrevealNewPassword ? (
                    <i
                      className="fa fa-eye eyeCustomIcon color_blue"
                      aria-hidden="true"
                      onClick={() => toggleNewPassword()}
                    ></i>
                  ) : (
                    <i
                      className="fa fa-eye eyeCustomIcon"
                      aria-hidden="true"
                      onClick={() => toggleNewPassword()}
                    ></i>
                  )}

                  <img src={lock} alt="email" />
                  {passwordError.length > 0 && (
                    <span style={{ color: "red" }}>{passwordError}</span>
                  )}
                </div>
              </div>
              <div className="form-group">
                <label>CONFIRM PASSWORD</label>
                <div className="input_content relative-boxcustomIcon">
                  <input
                    type={isrevealConfirmassword ? "text" : "password"}
                    maxLength={32}
                    onChange={onConfirmPasswordChanged}
                    className="form-control"
                    placeholder="Re Password"
                    value={confirmPassword}
                  />
                  {isrevealConfirmassword ? (
                    <i
                      className="fa fa-eye eyeCustomIcon color_blue"
                      aria-hidden="true"
                      onClick={() => toggleConfirmPassword()}
                    ></i>
                  ) : (
                    <i
                      className="fa fa-eye eyeCustomIcon"
                      aria-hidden="true"
                      onClick={() => toggleConfirmPassword()}
                    ></i>
                  )}

                  <img src={lock} alt="email" />

                  {confirmPasswordError.length > 0 && (
                    <span style={{ color: "red" }}>{confirmPasswordError}</span>
                  )}
                </div>
              </div>
              {/* <Field
                                name="password"
                                type="password"
                                component={renderField}
                                label="PASSWORD"
                                id="exampleInputPassword1"
                                img={email}
                                placeholder="Password"
                            /> */}

              {/* <Field
                                name="confirm_password"
                                type="password"
                                component={renderField}
                                label="CONFIRM PASSWORD"
                                id="exampleInputPassword1"
                                img={email}
                                placeholder="Confirm Password"
                            /> */}
              <div className="sign_up_bottom">
                <div className="main_btn">
                  <button
                    type="submit"
                    disabled={submitting}
                    className="login_btn"
                  >
                    Sign Up
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="modal-footer">
          <div className="modal_footer-content">
            <p>
              <span>Already have an account?</span>{" "}
              <span>
                <a
                  data-toggle="modal"
                  onClick={() => SetLoginModal(userrole)}
                  data-target="#login"
                  data-dismiss="modal"
                >
                  Sign In
                </a>
              </span>
            </p>
          </div>
        </div>
      </Modal>

      {/* Create customer Profile Modal */}
      <Modal
        ariaHideApp={false}
        isOpen={profileModal}
        onRequestClose={closeModal}
        className={"modal-content  new_modal_content small_modal"}
        contentLabel="Example Modal"
        style={customStyles1}
      >
        <div className="modal-header">
          <h4 className="modal-title">
            Create Profile
            <span>
              <i
                className="fa fa-times-circle-o"
                onClick={closeModal}
                aria-hidden="true"
                data-dismiss="modal"
              ></i>
            </span>{" "}
          </h4>
        </div>
        <div className="modal-body">
          <div className="input_form_box form_input_box">
            <form
              onSubmit={handleSubmit((val: any) =>
                submit(val, "create_customer_profile")
              )}
              autoComplete={"off"}
            >
              <div className="form-group">
                <label>NAME</label>
                <div className="input_content">
                  <input
                    type="text"
                    value={name}
                    onChange={onNameChanged}
                    className="form-control"
                    placeholder="Enter Name"
                    maxLength={25}
                  />
                </div>
                {nameError.length > 0 && (
                  <span style={{ color: "red" }}>{nameError}</span>
                )}
              </div>
              <div className="form-group">
                <label>DATE OF BIRTH</label>
                <div className="input_content">
                  {/* <input type="text" className="form-control form-control-two" placeholder="Select Date Of Birth" /> */}
                  <DatePicker
                    selected={startDate}
                    onChange={handleDateChange}
                    value={startDate}
                    maxDate={new Date(2006, 11, 24, 10, 33, 30, 0)}
                    relativeSize={true}
                    showYearDropdown
                    autoComplete="off"
                    placeholderText="Select date of birth"
                    onKeyDown={(e: { preventDefault: () => any }) =>
                      e.preventDefault()
                    }
                    className="form-control form-control-two"
                  />
                  <img src={date_image} alt="date_image" />
                </div>

                {showDOBError && (
                  <span style={{ color: "red" }}>
                    Please select date of birth.
                  </span>
                )}
              </div>
              <div className="form-group">
                <label>LOCATION</label>
                <div className="input_content">
                  <GooglePlacesAutocomplete
                    onSelect={(e: any) => handleChangeadd(e)}
                    inputClassName="form-control"
                    initialValue={location}
                  />
                  {/* <input type="text" value={location} onChange={onLocationChanged} className="form-control form-control-two" placeholder="Enter Location" /> */}
                  {/* 
                                    <Field
                                        name="location"
                                        type="text"
                                        component={renderField}
                                        label="LOCATION"
                                        placeholder="Enter Location"
                                    /> */}
                  <img
                    src={location_image}
                    alt="location_image"
                    onClick={() => get_current_location()}
                  />
                </div>
                {locationError.length > 0 && (
                  <span style={{ color: "red" }}>{locationError}</span>
                )}
              </div>

              <div className="form-group">
                <label>Documents</label>
                <div className="input_content upload_content">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Upload document"
                    value={
                      doc1.length != 0 && (doc1 as any).name == undefined
                        ? doc1
                        : (doc1 as any).name
                        ? (doc1 as any).name
                        : ""
                    }
                  />
                  <div className="upload_file-btn-box">
                    <div className="upload-btn-wrapper">
                      <button className="btn">UPLOAD</button>
                      <input
                        type="file"
                        accept="application/pdf"
                        name="myfile"
                        onChange={(e: any) => doc1changeHandle(e)}
                      />
                    </div>
                  </div>
                </div>
                {showCustDocError && (
                  <span style={{ color: "red" }}>Please upload document.</span>
                )}
              </div>
              <p className="validation validation-two">
                Please upload your verified Government Id or Document.
              </p>

              <label>BIO</label>
              <div className="">
                <textarea
                  onChange={onBioChanged}
                  value={bio}
                  placeholder="Write Something About You"
                ></textarea>
                {/* <Field
                                        name="bio"
                                        type="text"
                                        maxLength={200}
                                        component={renderTextareaField}
                                        label="BIO"
                                        placeholder="Write Something About You"
                                    /> */}
              </div>
              {bioError.length > 0 && (
                <span style={{ color: "red" }}>{bioError}</span>
              )}

              <div className="next_bottom">
                <div className="main_btn">
                  <button
                    type="submit"
                    disabled={submitting}
                    className="login_btn"
                  >
                    Next
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal>

      {/* Create Customer Welcome Modal */}
      <Modal
        ariaHideApp={false}
        isOpen={custwelcomeModal}
        onRequestClose={closeModal}
        className={"modal-content  new_modal_content small_modal"}
        contentLabel="Example Modal"
        style={customStyles1}
      >
        {localStorage.getItem("user") && (
          <div className="modal-body">
            <div className="welcome_heading">
              <h1>Welcome,</h1>
              <h3>{JSON.parse(localStorage.getItem("user") as string).name}</h3>
            </div>
            <form
              onSubmit={handleSubmit((val: any) =>
                submit(val, "create_customer_profile2")
              )}
              autoComplete={"off"}
            >
              <div className="file_upload_box">
                <div className="row">
                  <div className="col-md-12">
                    <div className="upload-btn-wrapper">
                      {profiledata == "" ? (
                        <img src={upload_image} alt="upload-image" />
                      ) : (
                        <img src={profiledata} alt="upload-image" />
                      )}
                      <span>Upload Profile Picture</span>
                      {/* showCustProfileImageError */}
                      {showCustProfileImageError && (
                        <span style={{ color: "red" }}>
                          Please upload profile image.
                        </span>
                      )}
                      <input
                        type="file"
                        name="myfile"
                        onChange={(e: any) => _handleProfileImageChange(e)}
                        accept="image/jpeg, image/png"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="main-check">
                <label className="chek_box_label">
                  Before you join you need to accept our
                  <a
                    href={`${config.apiBaseUrl}/terms/TERMS_Conditions.html`}
                    target="_blank"
                  >
                    {" "}
                    Terms
                  </a>{" "}
                  and{" "}
                  <a
                    href={`${config.apiBaseUrl}/privacy/privacy_policy.html`}
                    target="_blank"
                  >
                    Privacy Policy
                  </a>{" "}
                  .
                  <input
                    type="checkbox"
                    defaultChecked={isTermsAndConditionsAccepted}
                    onChange={onTermsCheckedChange}
                  />
                  <span className="checkmark"></span>
                </label>
                {showTermsError && (
                  <span style={{ color: "red" }}>
                    Please accept our Terms and Privacy Policy.
                  </span>
                )}
              </div>
              <div className="submit_bottom">
                <div className="main_btn">
                  {/* onClick={profileComplete} */}
                  <button disabled={submitting}>Submit</button>
                </div>
              </div>
            </form>
          </div>
        )}
      </Modal>

      {/* Create Provider Profile Modal */}
      <Modal
        ariaHideApp={false}
        isOpen={providerproflleModal}
        onRequestClose={closeModal}
        className={"modal-content  new_modal_content small_modal"}
        contentLabel="Example Modal"
        style={customStyles1}
      >
        <div className="modal-header">
          <h4 className="modal-title">
            Create Profile
            <span>
              <i
                className="fa fa-times-circle-o"
                onClick={closeModal}
                aria-hidden="true"
                data-dismiss="modal"
              ></i>
            </span>{" "}
          </h4>
        </div>
        <div className="modal-body">
          <div className="input_form_box form_input_box">
            <form
              onSubmit={handleSubmit((val: any) =>
                submit(val, "create_provider_profile")
              )}
              autoComplete={"off"}
            >
              <div className="form-group">
                <label>NAME</label>
                <div className="input_content">
                  <input
                    type="text"
                    value={name}
                    onChange={onNameChanged}
                    className="form-control"
                    placeholder="Enter Name"
                  />
                </div>
                {nameError.length > 0 && (
                  <span style={{ color: "red" }}>{nameError}</span>
                )}
                {/* <Field
                                    name="name"
                                    type="text"
                                    maxLength={20}
                                    component={renderField}
                                    label="NAME"
                                    placeholder="Enter Name"
                                /> */}
              </div>
              <div className="form-group">
                <label>DATE OF BIRTH</label>
                <div className="input_content">
                  {/* <input type="text" className="form-control form-control-two" placeholder="Select Date Of Birth" /> */}
                  <DatePicker
                    selected={startDate}
                    onChange={handleDateChange}
                    value={startDate}
                    maxDate={new Date(2006, 11, 24, 10, 33, 30, 0)}
                    relativeSize={true}
                    showYearDropdown
                    autoComplete="off"
                    placeholderText="DD/MM/YY"
                    onKeyDown={(e: { preventDefault: () => any }) =>
                      e.preventDefault()
                    }
                    className="form-control form-control-two"
                  />
                  <img src={date_image} alt="date_image" />
                  {showDOBError && (
                    <span style={{ color: "red" }}>
                      Please select date of birth.
                    </span>
                  )}
                </div>
              </div>
              <div className="form-group">
                <label>LOCATION</label>
                <div className="input_content">
                  <GooglePlacesAutocomplete
                    onSelect={(e: any) => handleChangeadd(e)}
                    inputClassName="form-control"
                    initialValue={location}
                  />
                  {/* <input type="text" value={location} onChange={onLocationChanged} className="form-control form-control-two" placeholder="Enter Location" /> */}
                  {/* 
                                    <Field
                                        name="location"
                                        type="text"
                                        component={renderField}
                                        label="LOCATION"
                                        placeholder="Enter Location"
                                    /> */}
                  <img
                    src={location_image}
                    onClick={() => get_current_location()}
                    alt="location_image"
                  />
                </div>
                {locationError.length > 0 && (
                  <span style={{ color: "red" }}>{locationError}</span>
                )}
              </div>
              <div className="form-group">
                <label>CATEGORY</label>

                <div className="selectnew category">
                  <Multiselect
                    options={catArray}
                    selectedValues={selectedcatArray}
                    displayValue="name"
                    // onSelect={(e) => {
                    // 	setCatError(false);
                    // 	setCategories(categories.concat(e));
                    // 	let ids: any = [];
                    // 	ids = e.map((item, index) => {
                    // 		return (ids[index] = item._id);
                    // 	});
                    // 	setCategories(ids);
                    // 	setSubcateascat(e, 'add');
                    // }}
                    // onRemove={(e) => {
                    // 	setCatError(false);
                    // 	let ids: any = [];
                    // 	ids = e.map((item, index) => {
                    // 		return (ids[index] = item._id);
                    // 	});
                    // 	setCategories(categories.filter((item) => item == ids));
                    // 	setSubcateascat(e, 'remove');
                    // }}
                    onSelect={(e) => {
                      const element = document.querySelector(
                        ".category #multiselectContainerReact"
                      );
                      if (e.length > 0) {
                        var has_active =
                          element != null &&
                          element.classList.contains("has_data");
                        if (has_active == false) {
                          element != null && element.classList.add("has_data");
                        }
                      } else {
                        element != null && element.classList.remove("has_data");
                      }
                      setCatError(false);
                      // setCategories(categories.concat(e))
                      let ids: any = [];
                      ids = e.map((item, index) => {
                        return (ids[index] = item._id);
                      });
                      console.log("ids", ids);
                      setCategories(ids);
                      setCatsubarray(ids);
                      setSubcateascat(e, "add");
                    }}
                    onRemove={(e) => {
                      const element = document.querySelector(
                        ".category #multiselectContainerReact"
                      );
                      if (e.length > 0) {
                        var has_active =
                          element != null &&
                          element.classList.contains("has_data");
                        if (has_active == false) {
                          element != null && element.classList.add("has_data");
                        }
                      } else {
                        element != null && element.classList.remove("has_data");
                      }
                      setCatError(false);
                      let ids: any = [];
                      ids = e.map((item, index) => {
                        return (ids[index] = item._id);
                      });
                      setCategories(categories.filter((item) => item == ids));
                      setCategories(ids);
                      setSubcateascat(e, "remove");

                      setSubCategories([]);
                      setsubCatidsArray([]);
                      setsubCatidsArray2([]);
                    }}
                    closeIcon="cancel"
                    style={{
                      chips: {
                        background: "#f16a6e",
                        borderRadius: 20,
                      },
                      searchBox: {
                        borderRadius: 5,
                        border: "inherit",
                        padding: 5,
                        minHeight: 22,
                        position: "relative",
                        background: "#f4f4f4",
                        height: 48,
                        fontSize: 18,
                        backgroundImage: `url(${select_arrow})`,
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "95%",
                      },
                    }}
                  />
                  {showCatError && (
                    <span style={{ color: "red" }}>
                      Categories field can't be empty.
                    </span>
                  )}
                </div>
              </div>
              <div className="form-group">
                <label>SUB-CATEGORY</label>

                <div className="selectnew sub_category">
                  <Multiselect
                    options={subcatidsArray}
                    selectedValues={subcatidsArray2}
                    displayValue="name"
                    closeIcon="cancel"
                    groupBy="category_name"
                    onSelect={(e) => {
                      const element = document.querySelector(
                        ".sub_category #multiselectContainerReact"
                      );
                      if (e.length > 0) {
                        var has_active =
                          element != null &&
                          element.classList.contains("has_data");
                        if (has_active == false) {
                          element != null && element.classList.add("has_data");
                        }
                      } else {
                        element != null && element.classList.remove("has_data");
                      }
                      setSubCatError(false);
                      let ids: any = [];
                      ids = e.map((item, index) => {
                        return (ids[index] = item._id);
                      });
                      setSubCategories(ids);
                      setsubcat(e, "add");
                      // setSubCategories(subCategories.concat(e))
                    }}
                    onRemove={(e) => {
                      const element = document.querySelector(
                        ".sub_category #multiselectContainerReact"
                      );
                      if (e.length > 0) {
                        var has_active =
                          element != null &&
                          element.classList.contains("has_data");
                        if (has_active == false) {
                          element != null && element.classList.add("has_data");
                        }
                      } else {
                        element != null && element.classList.remove("has_data");
                      }
                      setSubCatError(false);
                      let ids: any = [];
                      ids = e.map((item, index) => {
                        return (ids[index] = item._id);
                      });
                      setSubCategories(
                        subCategories.filter((item) => item == ids)
                      );
                      setsubcat(e, "remove");
                    }}
                    style={{
                      chips: {
                        background: "#f16a6e",
                        borderRadius: 20,
                      },
                      searchBox: {
                        borderRadius: 5,
                        border: "inherit",
                        padding: 5,
                        minHeight: 22,
                        position: "relative",
                        background: "#f4f4f4",
                        height: 48,
                        fontSize: 18,
                        backgroundImage: `url(${select_arrow})`,
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "95%",
                      },
                    }}
                  />
                  {showSubCatError && (
                    <span style={{ color: "red" }}>
                      Sub Categories field can't be empty.
                    </span>
                  )}
                </div>
              </div>
              <div className="form-group">
                <label>BIO</label>
                <div className="">
                  <textarea
                    onChange={onBioChanged}
                    value={bio}
                    placeholder="Write Something About You"
                  ></textarea>
                  {/* <Field
                                        name="bio"
                                        type="text"
                                        maxLength={200}
                                        component={renderTextareaField}
                                        label="BIO"
                                        placeholder="Write Something About You"
                                    /> */}
                </div>
                {bioError.length > 0 && (
                  <span style={{ color: "red" }}>{bioError}</span>
                )}
              </div>

              <div className="next_bottom">
                <div className="main_btn">
                  <button
                    type="submit"
                    data-toggle="modal"
                    data-target="#provider-upload-document"
                    data-dismiss="modal"
                  >
                    Next
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal>

      {/* Create Provider Upload Document Modal */}
      <Modal
        ariaHideApp={false}
        isOpen={uploaddocModal}
        onRequestClose={closeModal}
        className={"modal-content  new_modal_content small_modal"}
        contentLabel="Example Modal"
        style={customStyles1}
      >
        <div className="modal-header">
          <h4 className="modal-title">
            Upload Document
            <span>
              <i
                className="fa fa-times-circle-o"
                onClick={closeModal}
                aria-hidden="true"
                data-dismiss="modal"
              ></i>
            </span>{" "}
          </h4>
        </div>
        <div className="modal-body">
          <div className="input_form_box form_input_box input-upload">
            <form
              onSubmit={handleSubmit((val: any) =>
                submit(val, "upload_documents")
              )}
              autoComplete={"off"}
            >
              <div className="form-group">
                <div className="input_content upload_content">
                  <input
                    contentEditable={false}
                    type="text"
                    className="form-control"
                    placeholder="Upload document"
                    value={
                      docGovt != "" && (docGovt as any).name == undefined
                        ? docGovt
                        : (docGovt as any).name
                        ? (docGovt as any).name
                        : ""
                    }
                  />
                  <div className="upload_file-btn-box">
                    <div className="upload-btn-wrapper">
                      <button className="btn">UPLOAD</button>
                      <input
                        type="file"
                        accept="application/pdf"
                        name="myfile"
                        onChange={onGovtDocUpload}
                      />
                    </div>
                  </div>
                </div>
                {showDocGovtError && (
                  <span style={{ color: "red" }}>
                    Please upload Id or Document.
                  </span>
                )}
              </div>
              <p className="validation">
                Please upload your verified Government Id or Document.
              </p>
              <div className="form-group">
                <div className="input_content upload_content">
                  <input
                    contentEditable={false}
                    type="text"
                    className="form-control"
                    placeholder="Upload document"
                    value={
                      docProfile != "" && (docProfile as any).name == undefined
                        ? docProfile
                        : (docProfile as any).name
                        ? (docProfile as any).name
                        : ""
                    }
                  />
                  <div className="upload_file-btn-box">
                    <div className="upload-btn-wrapper">
                      <button className="btn">UPLOAD</button>
                      <input
                        type="file"
                        accept="application/pdf"
                        name="myfile"
                        onChange={onProfileDocUpload}
                      />
                    </div>
                  </div>
                </div>
                {showDocProfileError && (
                  <span style={{ color: "red" }}>
                    Please upload Id or Document.
                  </span>
                )}
              </div>
              <p className="validation">
                Please upload your other document related to your profile.
              </p>
              {userworkerdocumentcatArray.length > 0 &&
              workerdocumentArray2.length == 0
                ? localStorage.getItem("user") &&
                  JSON.parse(localStorage.getItem("user") as string).categories
                    .length > 0 &&
                  JSON.parse(
                    localStorage.getItem("user") as string
                  ).categories.map((item: any, index: any) => {
                    console.log("item", item.name);
                    return (
                      <div key={index}>
                        <div className="form-group">
                          <div className="input_content upload_content">
                            <input
                              contentEditable={false}
                              type="text"
                              className="form-control"
                              placeholder="Upload document"
                              value={
                                userworkerdocumentcatArray.length > 0 &&
                                workerdocumentArray[index] == undefined &&
                                userworkerdocumentcatArray[index] !==
                                  undefined &&
                                (userworkerdocumentcatArray[index] as any)
                                  .url !== undefined
                                  ? (userworkerdocumentcatArray[index] as any)
                                      .url
                                  : workerdocumentArray[index] !== undefined &&
                                    (workerdocumentArray[index] as any).name
                                  ? (workerdocumentArray[index] as any).name
                                  : ""
                              }
                            />
                            <div className="upload_file-btn-box">
                              <div className="upload-btn-wrapper">
                                <button className="btn">UPLOAD</button>
                                <input
                                  type="file"
                                  accept="application/pdf"
                                  name={item.name}
                                  id={item.category_id}
                                  onChange={onPlumberDocUpload}
                                />
                              </div>
                            </div>
                          </div>
                          {showDocPlumberError && (
                            <span style={{ color: "red" }}>
                              Please upload Id or Document.
                            </span>
                          )}
                        </div>
                        <p className="validation">
                          Please upload your other document related to your{" "}
                          {item.name} Id.
                        </p>
                      </div>
                    );
                  })
                : localStorage.getItem("user") &&
                  JSON.parse(localStorage.getItem("user") as string).categories
                    .length > 0 &&
                  selCategoryAry.map((item: any, index: any) => {
                    console.log("item----", item);
                    return (
                      <div key={index}>
                        <div className="form-group">
                          <div className="input_content upload_content">
                            {/* <input contentEditable={false} type="text" className="form-control" placeholder="Upload document" value={workerdocumentArray2.length > 0 && workerdocumentArray2[index] != undefined ? ((workerdocumentArray2[index] as any).id) == item.category_id ? (workerdocumentArray2[index] as any).file.name : "" : ""} /> */}
                            <input
                              contentEditable={false}
                              type="text"
                              className="form-control"
                              placeholder="Upload document"
                              value={
                                (localStorage.getItem(
                                  item.category_id
                                ) as string)
                                  ? (localStorage.getItem(
                                      item.category_id
                                    ) as string)
                                  : ""
                              }
                            />

                            <div className="upload_file-btn-box">
                              <div className="upload-btn-wrapper">
                                <button className="btn">UPLOAD</button>
                                <input
                                  type="file"
                                  accept="application/pdf"
                                  name={item.name}
                                  id={item.category_id}
                                  onChange={onPlumberDocUpload}
                                />
                              </div>
                            </div>
                          </div>
                          {item.is_error && (
                            <span style={{ color: "red" }}>
                              Please upload Id or Document.
                            </span>
                          )}
                        </div>
                        {/* here is this */}
                        <p className="validation">
                          Please upload your other document related to your{" "}
                          {item.name} Id.
                        </p>
                      </div>
                    );
                  })}

              {/* {localStorage.getItem('user') && JSON.parse(localStorage.getItem('user') as string).categories.length>0 && JSON.parse(localStorage.getItem('user') as string).categories.map((item: any, index: any) => { 
                              
                                 return(
                            <div key={index}>
                               
                            <div className="form-group">
                                <div className="input_content upload_content">
                                 
                                    <input contentEditable={false} type="text" className="form-control" placeholder="Upload document" value={localStorage.getItem(item.category_id) as string ? localStorage.getItem(item.category_id) as string:""}  />

                                    <div className="upload_file-btn-box">
                                        <div className="upload-btn-wrapper">
                                            <button className="btn">UPLOAD</button>
                                            <input type="file" accept="application/pdf" name={item.name} id={item.category_id} onChange={onPlumberDocUpload} />
                                        </div>
                                    </div>
                                </div>
                                {showDocPlumberError && <span style={{ color: "red" }}>Please upload Id or Document.</span>}
                            </div>
                            <p className="validation">Please upload your other document related to your {item.name} Id.</p>
                            </div>
                                 )
                            })
                            } */}
              <div className="row next_bottom">
                <div className="col-md-6">
                  <div className="main_btn border_button">
                    <a
                      data-toggle="modal"
                      onClick={SetProfileModal}
                      data-target="#provider-create-profile"
                      data-dismiss="modal"
                    >
                      Back
                    </a>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="main_btn ">
                    {/* here */}
                    <button
                      data-toggle="modal"
                      type="submit"
                      data-target="#provider-add-bank-details"
                      data-dismiss="modal"
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal>

      {/* Create Provider Welcome Modal */}
      <Modal
        ariaHideApp={false}
        isOpen={providerwelcomeModal}
        onRequestClose={closeModal}
        className={"modal-content  new_modal_content small_modal"}
        contentLabel="Example Modal"
        style={customStyles1}
      >
        <div className="modal-body">
          <div className="welcome_heading">
            <h1>Welcome,</h1>
            <h3>
              {localStorage.getItem("user") &&
                JSON.parse(localStorage.getItem("user") as string).name}
            </h3>
          </div>
          <div className="file_upload_box">
            <div className="row">
              <div className="col-md-12">
                <div className="upload-btn-wrapper">
                  {profiledata == "" ? (
                    <img src={upload_image} alt="upload-image" />
                  ) : (
                    <img src={profiledata} alt="upload-image" />
                  )}
                  <span>Upload Profile Picture</span>
                  {showCustProfileImageError && (
                    <span style={{ color: "red" }}>
                      Please upload profile image.
                    </span>
                  )}
                  <input
                    type="file"
                    name="myfile"
                    onChange={(e: any) => _handleProfileImageChange(e)}
                    accept="image/jpeg, image/png"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="main-check">
            <label className="chek_box_label">
              Before you join you need to accept our
              <a
                href={`${config.apiBaseUrl}/terms/TERMS_Conditions.html`}
                target="_blank"
              >
                Terms
              </a>{" "}
              and{" "}
              <a
                href={`${config.apiBaseUrl}/privacy/privacy_policy.html`}
                target="_blank"
              >
                Privacy Policy
              </a>
              <input
                type="checkbox"
                defaultChecked={isTermsAndConditionsAccepted}
                onChange={onTermsCheckedChange}
              />
              <span className="checkmark"></span>
            </label>
            {showTermsError && (
              <span style={{ color: "red" }}>
                Please accept our Terms and Privacy Policy.
              </span>
            )}
          </div>
          <div className="row next_bottom">
            <div className="col-md-6">
              <div className="main_btn border_button">
                <a
                  data-toggle="modal"
                  onClick={SetBankAccountModal}
                  data-target="#provider-add-bank-details"
                  data-dismiss="modal"
                >
                  Back
                </a>
              </div>
            </div>
            <div className="col-md-6">
              <div className="main_btn ">
                <button
                  data-toggle="modal"
                  data-target="#provider-profile-review"
                  data-dismiss="modal"
                  onClick={() => submit("", "create_provider_profile4")}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      {/* Create Provider Bank Account Modal */}
      <Modal
        ariaHideApp={false}
        isOpen={bankaccountModal}
        onRequestClose={closeModal}
        className={"modal-content  new_modal_content "}
        contentLabel="Example Modal"
        style={customStyles1}
      >
        <div className="modal-header">
          <h4 className="modal-title">
            Add Bank Details
            <span>
              <i
                className="fa fa-times-circle-o"
                onClick={closeModal}
                aria-hidden="true"
                data-dismiss="modal"
              ></i>
            </span>{" "}
          </h4>
        </div>
        <div className="modal-body">
          <div className="input_form_box form_input_box">
            <form
              onSubmit={handleSubmit((val: any) =>
                submit(val, "add_provider_bank_details")
              )}
              autoComplete={"off"}
            >
              <div className="form-group">
                <label>Bank NAME</label>
                <div className="input_content">
                  {/* <Field
                                        name="accountName"
                                        type="text"
                                        maxLength={20}
                                        component={renderField}
                                        label="BANK NAME"
                                        placeholder="Enter Account Holder Name"
                                    /> */}
                  <input
                    type="text"
                    value={accountName}
                    onChange={onAccountNameChanged}
                    className="form-control"
                    placeholder="Enter Account Holder Name"
                  />
                </div>
                {accountNameError.length > 0 && (
                  <span style={{ color: "red" }}>{accountNameError}</span>
                )}
              </div>
              <div className="form-group">
                <label>ACCOUNT NUMBER</label>
                <div className="input_content">
                  <input
                    type="text"
                    value={accountNumber}
                    onChange={onAccountNumberChanged}
                    className="form-control"
                    placeholder="Enter Account Number"
                  />
                  {/* <Field
                                        name="accountNumber"
                                        type="text"
                                        maxLength={20}
                                        component={renderField}
                                        label="ACCOUNT NUMBER"
                                        placeholder="Enter Account Number"
                                    /> */}
                </div>
                {accountNumberError.length > 0 && (
                  <span style={{ color: "red" }}>{accountNumberError}</span>
                )}
              </div>
              <div className="form-group">
                <label>Branch Name</label>
                <div className="input_content">
                  <input
                    type="text"
                    onChange={OnBranchNameChanged}
                    value={branchName}
                    maxLength={30}
                    className="form-control"
                    placeholder="Enter Branch Name"
                  />
                </div>
                {branchNameError.length > 0 && (
                  <span style={{ color: "red" }}>{branchNameError}</span>
                )}
              </div>

              <div className="form-group">
                <label>ROUTING NUMBER</label>
                <div className="input_content">
                  <input
                    type="text"
                    onChange={onRoutingChanged}
                    value={routingNumber}
                    className="form-control"
                    placeholder="Enter Routing Number"
                  />
                  {/* <Field
                                        name="routingNumber"
                                        type="number"
                                        maxLength={30}
                                        component={renderField}
                                        label="ROUTING NUMBER"
                                        placeholder="Enter Routing Number"
                                    /> */}
                </div>
                {routingNumberError.length > 0 && (
                  <span style={{ color: "red" }}>{routingNumberError}</span>
                )}
              </div>
              <div className="row next_bottom">
                <div className="col-md-6">
                  <div className="main_btn border_button">
                    <a
                      data-toggle="modal"
                      onClick={SetUploaddocModal}
                      data-target="#provider-upload-document"
                      data-dismiss="modal"
                    >
                      Back
                    </a>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="main_btn ">
                    <button
                      type="submit"
                      data-toggle="modal"
                      data-target="#provider-add-profile"
                      data-dismiss="modal"
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal>
      {/* Create Provider Profile Review Modal */}
      <Modal
        ariaHideApp={false}
        isOpen={profilereview}
        onRequestClose={closeModal}
        className={"modal-content new_modal_content"}
        contentLabel="Example Modal"
        style={customStyles1}
      >
        <div className="modal-body">
          <div className="profile-review-content text-center">
            <img
              src={require("../assets/images/profile-under-review.png")}
              alt="profile-under-review"
            />
            <h1>Your profile is under review.</h1>
            <p>
              Your profile information submitted to admin, please wait until the
              profile verification.
            </p>
          </div>

          <div className="row next_bottom">
            <div className="col-md-12">
              <div className="main_btn ">
                <a onClick={closeModal}>Ok</a>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        ariaHideApp={false}
        isOpen={profilereviewcust}
        onRequestClose={closeModal}
        className={"modal-content new_modal_content"}
        contentLabel="Example Modal"
        style={customStyles1}
      >
        <div className="modal-body">
          <div className="profile-review-content text-center">
            <img
              src={require("../assets/images/profile-under-review.png")}
              alt="profile-under-review"
            />
            {/* <h1>Your profile is under review.</h1> */}
            <p>
              Please verify your profile by clicking on the link sent to your
              register email
            </p>
          </div>

          <div className="row next_bottom">
            <div className="col-md-12">
              <div className="main_btn ">
                <a onClick={() => update_user_details()}>Ok</a>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <div className="loader-box" style={loaderStyle}>
        <div className="loader-image">
          <img src={loader_image} />
        </div>
      </div>
    </div>
  );
};

export default reduxForm({
  form: "loginForm", // a unique identifier for this form
})(Home);
