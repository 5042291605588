import React from "react";

const TenderLogo = require("../../assets/images/home_header_logo.png");

function BackUpImage({ height, width }: { height: string; width: string }) {
  return (
    <div
      style={{
        height: height,
        width: width,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#7aa2f3",
        borderRadius: "7.5%",
      }}
    >
      <img src={TenderLogo} alt="profile" />
    </div>
  );
}

export default BackUpImage;
