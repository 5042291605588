import { authHeader } from "../_helpers";
import { config } from "../config/config";
import axios from "axios";
export const userService = {
  login,
  socialLogin,
  register,
  logout,
  userdetail,
  getAll,
  updateProfile,
  changePassword,
  getUsers,
  updateuserStatus,
  forgotPassword,
  getCategories,
  getSubCategories,
  addBankDetails,
  updateProfileimage,
  customer_home,
  provider_profile,
  my_profile,
  uploadGallery,
  deletegallery,
  getBankDetails,
  client_my_active_jobs,
  create_post,
  card_list,
  save_card,
  getNotification,
  deleteCard,
  getFavourites,
  favMarkUnMark,
  helpsupports,
  deleteBank,
  getTransactions,
  getUnreadNotification,
  downloadInvoice,
  userReviews,
  handleError,
  other_user_detail,
  getClientSecret,
  saveCard,
  verifyAccount,
  customerPriceDetail,
  markReadNotification,
};
var configheaders: any = {
  headers: authHeader(),
};

let unique_device_id = localStorage.getItem("unique_device_id");
if (unique_device_id == undefined) {
  generate_unique_device_id();
}
function generate_unique_device_id() {
  console.log("generate_unique_device_id");
  let tmp = Math.random().toString(36).slice(2);
  unique_device_id = tmp;
  localStorage.setItem("unique_device_id", tmp);
}

function login(params: any) {
  let url = `${config.apiUrl}/users/login`;
  // let url = 'http://192.168.2.91:8001/apis/userregister';
  let config1 = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      deviceType: "w",
      appVersion: "1.0",
      "Access-Control-Allow-Origin": "*",
      device: unique_device_id,
    },
  };
  return axios.post(url, params, config1);
}
function socialLogin(params: any) {
  let url = `${config.apiUrl}/users/socialLogin`;
  // let url = 'http://192.168.2.91:8001/apis/userregister';
  let config1 = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      deviceType: "w",
      appVersion: "1.0",
      "Access-Control-Allow-Origin": "*",
      device: unique_device_id,
    },
  };
  return axios.post(url, params, config1);
}
function register(params: any) {
  const proxyurl = "https://cors-anywhere.herokuapp.com/";
  let url = `${config.apiUrl}/users`;
  let config1 = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      deviceType: "w",
      deviceid: "",
      "Access-Control-Allow-Origin": "*",
      device: unique_device_id,
    },
  };
  return axios.post(url, params, config1);
}

function updateProfile(params: any) {
  let url = `${config.apiUrl}/users`;
  console.log(configheaders);
  return axios.put(url, params, configheaders);
}

function updateProfileimage(params: any) {
  let url = `${config.apiUrl}/users`;
  configheaders["headers"]["Content-Type"] = "application/json";
  return axios.put(url, params, configheaders);
}
function deletegallery(params: any) {
  let url = `${config.apiUrl}/users?imageIds=${params}`;
  configheaders["headers"]["Content-Type"] = "application/json";
  return axios.delete(url, configheaders);
}

function getCategories() {
  let url = `${config.apiUrl}/categories`;
  return axios.get(url, configheaders);
}

function getSubCategories(params: any) {
  configheaders["headers"]["Content-Type"] = "application/json";
  let url = `${config.apiUrl}/categories/`;
  return axios.post(url, params, configheaders);
}

function changePassword(params: any) {
  configheaders["headers"]["Content-Type"] =
    "application/x-www-form-urlencoded";
  let url = `${config.apiUrl}/users/change_password`;
  return axios.put(url, params, configheaders);
}

function forgotPassword(params: any) {
  let config1 = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      deviceType: "w",
      deviceid: "",
      "Access-Control-Allow-Origin": "*",
    },
  };
  let url = `${config.apiUrl}/users/forgot_password`;
  return axios.post(url, params, config1);
}

function updateuserStatus(userId: any) {
  let url = `${config.apiUrl}/admin/${userId}/updateStatus`;
  configheaders["headers"]["deviceType"] = "Android";
  return axios.put(url, {}, configheaders);
}

function addBankDetails(data: any) {
  configheaders["headers"]["Content-Type"] =
    "application/x-www-form-urlencoded";

  let url = `${config.apiUrl}/banks`;
  return axios.post(url, data, configheaders);
}

function getBankDetails() {
  let url = `${config.apiUrl}/banks`;
  return axios.get(url, configheaders);
}

function deleteBank(bank_id) {
  let url = `${config.apiUrl}/banks/${bank_id}`;
  configheaders["headers"]["Content-Type"] = "application/json";
  return axios.delete(url, configheaders);
}

function uploadGallery(data: any) {
  let url = `${config.apiUrl}/users/upload_gallery`;
  configheaders["headers"]["Content-Type"] = "application/json";
  return axios.put(url, data, configheaders);
}

function userdetail() {
  let url: any = `${config.apiUrl}/users`;
  return axios.get(url, configheaders);
}
function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("access_token");
}

function getUsers(page: Number, sort: Number, serach: string) {
  let url: any = `${config.apiUrl}/admin/getUsers?page=${page}&role=${sort}&text=${serach}`;
  return axios.get(url, configheaders);
}

function getAll() {
  const requestOptions: any = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(`/users`, requestOptions).then(handleResponse);
}

function handleResponse(response: any) {
  return response.text().then((text: string) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
        window.location.reload();
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}

function customer_home(params: any) {
  let url = `${config.apiUrl}/users/home`;
  configheaders["headers"]["Content-Type"] = "application/json";
  return axios.post(url, params, configheaders);
}
function provider_profile(otheruserId: any, latitude: any, longitude: any) {
  let url =
    `${config.apiUrl}/users/` + otheruserId + "/" + latitude + "/" + longitude;
  return axios.get(url, configheaders);
}

function other_user_detail(user_id: any) {
  let url = `${config.apiUrl}/users/` + user_id + "/" + 0 + "/" + 0;
  return axios.get(url, configheaders);
}

function my_profile() {
  let url = `${config.apiUrl}/users/`;
  return axios.get(url, configheaders);
}

// client api's

function create_post(params: any) {
  let url = `${config.apiUrl}/jobs`;
  configheaders["headers"]["timezone"] = "Asia/kolkata";
  return axios.post(url, params, configheaders);
}

function client_my_active_jobs() {
  let url = `${config.apiUrl}/jobs/active/0`;
  return axios.get(url, configheaders);
}

function card_list() {
  let url = `${config.apiUrl}/cards`;
  return axios.get(url, configheaders);
}

function save_card(params: any) {
  let url = `${config.apiUrl}/cards`;
  configheaders["headers"]["Content-Type"] = "application/json";
  return axios.post(url, params, configheaders);
}

function deleteCard(card_id: any) {
  let url = `${config.apiUrl}/cards/${card_id}`;
  configheaders["headers"]["Content-Type"] = "application/json";
  return axios.delete(url, configheaders);
}

function getNotification() {
  let url = `${config.apiUrl}/notifications`;
  return axios.get(url, configheaders);
}

function getUnreadNotification() {
  let url = `${config.apiUrl}/notifications/unread`;
  return axios.get(url, configheaders);
}

function getFavourites(lat, lng) {
  let url = `${config.apiUrl}/favourites/${lat}/${lng}`;
  return axios.get(url, configheaders);
}

function favMarkUnMark(params: any) {
  let url = `${config.apiUrl}/favourites`;
  configheaders["headers"]["Content-Type"] = "application/json";
  return axios.post(url, params, configheaders);
}

function helpsupports(params: any) {
  let url = `${config.apiUrl}/helpsupports`;
  configheaders["headers"]["Content-Type"] = "application/json";
  return axios.post(url, params, configheaders);
}

function getTransactions(month, year) {
  let url = `${config.apiUrl}/transactions/${month}/${year}`;
  configheaders["headers"]["timezone"] = "Asia/kolkata";
  return axios.get(url, configheaders);
}

function downloadInvoice(transaction_id) {
  let url = `${config.apiUrl}/transactions/${transaction_id}`;
  configheaders["headers"]["timezone"] = "Asia/kolkata";
  return axios.get(url, configheaders);
}

function userReviews(user_id) {
  let url = `${config.apiUrl}/reviews/user/${user_id}`;
  configheaders["headers"]["timezone"] = "Asia/kolkata";
  return axios.get(url, configheaders);
}

function handleError(error: any) {
  console.log("errorrrrrrrrrrrrrrrrrrrrrrrrrrrrr", error);
  // console.log("response",error.response);
  // console.log("response",error.response.status);
  // console.log("response",error.response.data.message);
  if (error.response && error.response.status == 401) {
    alert(error.response.data.message);
    console.log("redirectionnnn");
    localStorage.clear();
    window.location.href = "/";
  } else {
    if (error.name == "NetworkError") {
      alert("Please check you network");
    } else if (error.response) {
      alert(`error: ${error.response.data.message}`);
    } else {
      alert("Handle Error: Something went wrong");
    }
  }
}
function getClientSecret() {
  let url = `${config.apiUrl}/stripe/getclientsecret`;
  configheaders["headers"]["timezone"] = "Asia/kolkata";
  return axios.get(url, configheaders);
}
function saveCard(data: any) {
  let url = `${config.apiUrl}/stripe/savecard/${data}`;
  configheaders["headers"]["timezone"] = "Asia/kolkata";
  return axios.get(url, configheaders);
}
function verifyAccount(token: any) {
  let url = `${config.apiUrl}/users/verify_email/${token}`;
  configheaders["headers"]["timezone"] = "Asia/kolkata";
  return axios.post(url, configheaders);
}

function customerPriceDetail(data: any) {
  console.log("data", data);
  let url = `${config.apiUrl}/stripe/customerPricingDetails/`;
  configheaders["headers"]["Content-Type"] = "application/json";
  return axios.post(url, data, configheaders);
}

function markReadNotification(id: any) {
  let url = `${config.apiUrl}/notifications/read/${id}`;
  return axios.put(url, {}, configheaders);
}

// function getClientSecret() {
//   let url = `http://localhost:5000/api/stripe/getclientsecret`;
//   configheaders["headers"]["timezone"] = "Asia/kolkata";
//   return axios.get(url, configheaders);
// }
// function saveCard(data: any) {
//   let url = `http://localhost:5000/api/stripe/savecard/${data}`;
//   configheaders["headers"]["timezone"] = "Asia/kolkata";
//   return axios.get(url, configheaders);
// }
// function verifyAccount(token: any) {
//   let url = `http://localhost:5000/api/v1/users/verify_email/${token}`;
//   configheaders["headers"]["timezone"] = "Asia/kolkata";
//   return axios.post(url, configheaders);
// }
