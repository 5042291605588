// import firebase from "firebase/app";
// import "firebase/auth";

// const firebaseConfigaccess = {
//     apiKey: "AIzaSyBZ1tRgMSj7NhBa28-kjpUbqYwNpAPtZU4",
//     authDomain: "job-posting-platform.firebaseapp.com",
//     databaseURL: "https://job-posting-platform.firebaseio.com",
//     projectId: "job-posting-platform",
//     storageBucket: "job-posting-platform.appspot.com",
//     messagingSenderId: "686268314302",
//     appId: "1:686268314302:web:f3a99a970bbb9d3b"
//   }
// firebase.initializeApp(firebaseConfigaccess);
// export default firebase;


import firebase from "firebase";
import "firebase/auth";
var firebaseConfig = {
  apiKey: "AIzaSyC7HnBKDuVgP9uvcA4IKQXJkZqvfcxb0tw",
  authDomain: "job-posting-platform.firebaseapp.com",
  databaseURL: "https://job-posting-platform.firebaseio.com",
  projectId: "job-posting-platform",
  storageBucket: "job-posting-platform.appspot.com",
  messagingSenderId: "522963890633",
  appId: "1:522963890633:web:ef6c478a964bcfdfed3818",
  measurementId: "G-E00XB7KNL8"
};
firebase.initializeApp(firebaseConfig);
firebase.auth().signInAnonymously()
export default firebase;
