import React, { useEffect, useState } from "react";
import { userService } from "../../_services";
import { Field, reduxForm, SubmissionError } from "redux-form";
import { Link } from "react-router-dom";
import { config } from "../../config/config";
import Modal from "react-modal";

import { Layout } from "../common/Layout";
import close_image from "../../assets/images/close.png";
import profile_image from "../../assets/images/profile_logo.jpg";
import loader_image from "../../assets/images/loading.gif";

import add_new_left from "../../assets/images/add_new_left.png";
import calender_image from "../../assets/images/calender_image.png";
import left_arrow from "../../assets/images/left_arrow.png";
import right_arrow from "../../assets/images/right_arrow.png";
import { jobsService } from "../../_services";
import Pagination from "react-js-pagination";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import BackUpImage from "../common/BackUpImage";
import { IconButton, Tooltip, withStyles } from "@material-ui/core";
import HelpIcon from "@material-ui/icons/Help";

interface fieldInterface {
  input: any;
  label: string;
  type: string;
  meta: {
    touched: boolean;
    error: string;
  };
}

const CustomTooltip = withStyles({
  tooltip: {
    fontSize: 24, // Set your desired font size here
  },
})(Tooltip);

const ProviderJobs = (props: any) => {
  function goToHiredJobs(id) {
    window.location.href = "job_in_progress/" + id;
  }

  function goToHistoryJobs() {
    window.location.href = "completed_job";
  }
  function goToInvites() {
    window.location.href = "job_invites";
  }

  const [currentpage, setCurrentpage] = useState(0);
  const [bids, setBids] = useState([]);
  const [hiredJobs, setHiredJobs] = useState([]);
  const [historyJobs, setHistoryJobs] = useState([]);
  const [totalbids, setTotalbids] = useState(0);
  const [totalinvitation, setTotalinvitation] = useState(0);
  const [loaderStyle, setLoaderstyle] = useState({ display: "none" });

  useEffect(() => {
    getmyBids(currentpage);
  }, []);

  function getmyBids(page: number) {
    setLoaderstyle({ display: "block" });
    jobsService
      .getMybids(page)
      .then(function (response) {
        setLoaderstyle({ display: "none" });
        setBids(response.data.jobs);
        setTotalinvitation(response.data.invitations);
        setTotalbids(response.data.perPageRecords * response.data.totalPages);
      })
      .catch(function (error) {
        setLoaderstyle({ display: "none" });
        userService.handleError(error);
      });
  }

  function gethiredJobs(page: number) {
    setLoaderstyle({ display: "block" });
    jobsService
      .getHiredJobs(page)
      .then(function (response) {
        setLoaderstyle({ display: "none" });
        console.log("hired jobs", response.data.jobs);
        setHiredJobs(response.data.jobs);
        console.log("get hired jobs", response.data.jobs);
      })
      .catch(function (error) {
        setLoaderstyle({ display: "none" });
        userService.handleError(error);
      });
  }

  function gethistoryJobs(page: number) {
    setLoaderstyle({ display: "block" });
    jobsService
      .getHistoryJobs(page)
      .then(function (response) {
        setLoaderstyle({ display: "none" });
        setHistoryJobs(response.data.jobs);
      })
      .catch(function (error) {
        setLoaderstyle({ display: "none" });
        userService.handleError(error);
      });
  }

  function goToHomeDetail(item: any) {
    window.location.href = "job_details?id=" + item._id;
  }

  function handlePageChange(pagenumber: any, type: string) {
    if (type == "my-bids") {
      setCurrentpage(pagenumber - 1);
      getmyBids(pagenumber - 1);
    }
  }
  function identifyString(s) {
    // Check if the string is only numbers
    if (/^[0-9]+$/.test(s)) {
      return "$";
    }
    // Check if the string is only letters
    else if (/^[A-Za-z]+$/.test(s)) {
      return "";
    }
    // For strings containing both or neither
    else {
      return "";
    }
  }

  const { error, className, handleSubmit, pristine, reset, submitting } = props;
  return (
    <section className="my_job_tab_box">
      <div className="container">
        <div className="tab_heading_content">
          <ul className="nav nav-tabs">
            <li className="active active_list">
              <a onClick={() => getmyBids(0)} data-toggle="tab" href="#active">
                Bids
              </a>
            </li>
            <li className="upcoming_list">
              <a
                onClick={() => gethiredJobs(0)}
                data-toggle="tab"
                href="#upcoming"
              >
                In-Progress
              </a>
            </li>
            <li className="past-list">
              <a
                onClick={() => gethistoryJobs(0)}
                data-toggle="tab"
                href="#past"
              >
                History
              </a>
            </li>
            <div className="invites-button">
              <a className="invites button_borderless" onClick={goToInvites}>
                <i className="fa fa-envelope-open" aria-hidden="true"></i>
                Invites {totalinvitation}
              </a>
            </div>
            <CustomTooltip title="Only Active bids will appear below">
              <IconButton>
                <HelpIcon style={{ fontSize: 24 }} />
              </IconButton>
            </CustomTooltip>
          </ul>

          <div className="tab-content">
            <div id="active" className="tab-pane fade in active">
              <div className="active_tab_content">
                <div
                  className="my_job_content my-job-content-two"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <ul>
                    {bids.length == 0 && (
                      <li
                        className="tab_content_box"
                        style={{ display: "flex" }}
                      >
                        <h1>No Bid Found</h1>
                      </li>
                    )}

                    {bids.length > 0 &&
                      bids.map((item: any, index1) => {
                        console.log("item", item);
                        return (
                          <li
                            className="tab_content_box"
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            {item.job_images.length > 0 ? (
                              <div
                                style={{
                                  borderRadius: "20px",
                                  backgroundColor: "rgba(0,0,0,0)",
                                }}
                              >
                                <div
                                  style={{
                                    height: "200px",
                                    width: "300px",
                                    borderRadius: "7.5%",
                                    backgroundColor: "#7aa2f3",
                                    overflow: "hidden",
                                  }}
                                >
                                  <img
                                    src={item.job_images[0].job_image}
                                    alt="slider_image.png"
                                    style={{ width: "100%" }}
                                  />
                                </div>
                              </div>
                            ) : (
                              <BackUpImage height="200" width="300" />
                            )}
                            <a
                              onClick={() => goToHomeDetail(item)}
                              style={{ textDecoration: "none" }}
                            >
                              <div style={{ width: "100%" }}>
                                <h5 style={{ paddingLeft: "16px" }}>
                                  <span
                                    style={{
                                      fontWeight: 800,
                                      fontSize: "1.4em",
                                      paddingTop: "10px",
                                    }}
                                  >
                                    {" "}
                                    {/* {item.title} */}
                                    {(item as any).title
                                      ? (item as any).title.slice(0, 80) +
                                        ((item as any).title.length > 80
                                          ? "..."
                                          : "")
                                      : ""}
                                  </span>
                                  <br />
                                  <span
                                    style={{
                                      fontWeight: 800,
                                      fontSize: "1.1em",
                                      color: "#7AA2F3",
                                    }}
                                  >
                                    {item.category.name}
                                  </span>
                                  <br />

                                  <span style={{ fontSize: "1.1em" }}>
                                    <span style={{ fontWeight: 800 }}>
                                      Start Date:
                                    </span>
                                    {item.start_date} @{item.start_time}
                                  </span>
                                  <br />
                                  <span style={{ fontSize: "1.1em" }}>
                                    <span style={{ fontWeight: 800 }}>
                                      Posted:
                                    </span>
                                    {item.time_ago}
                                  </span>
                                  <br />
                                  <span style={{ fontSize: "1.1em" }}>
                                    <span style={{ fontWeight: 800 }}>
                                      Budget:
                                    </span>
                                    <span className="color_blue_text">
                                      {" "}
                                      {identifyString(item.budget)}
                                      {item.budget}
                                    </span>
                                  </span>
                                  <br />
                                  <span
                                    style={{
                                      fontWeight: 800,
                                      fontSize: "1.1em",

                                      color: "#585858",
                                      textDecoration: "none",
                                    }}
                                  >
                                    Description:{" "}
                                  </span>
                                  <br />
                                  <span>{item.description}</span>
                                  <br />
                                </h5>
                              </div>
                            </a>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </div>
              {bids.length > 0 && (
                <div className="pagination_box">
                  <Pagination
                    activePage={
                      currentpage == 0 ? currentpage + 1 : currentpage + 1
                    }
                    itemsCountPerPage={20}
                    totalItemsCount={totalbids}
                    pageRangeDisplayed={5}
                    onChange={(e: any) => handlePageChange(e, "my-bids")}
                  />
                </div>
              )}
            </div>
            <div id="upcoming" className="tab-pane fade">
              <div className="my_job_content">
                <ul>
                  {hiredJobs.length == 0 && (
                    <li className="tab_content_box">
                      <h1>No Jobs Found</h1>
                    </li>
                  )}
                  {hiredJobs.length > 0 &&
                    hiredJobs.map((job: any, index) => {
                      return (
                        <>
                          <li
                            className="tab_content_box"
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            {job.job_images.length > 0 ? (
                              <div
                                style={{
                                  borderRadius: "20px",
                                  backgroundColor: "rgba(0,0,0,0)",
                                }}
                              >
                                <div
                                  style={{
                                    height: "200px",
                                    width: "300px",
                                    borderRadius: "7.5%",
                                    backgroundColor: "#7aa2f3",
                                    overflow: "hidden",
                                  }}
                                >
                                  <img
                                    src={job.job_images[0].job_image}
                                    style={{ width: "100%" }}
                                  />
                                </div>
                              </div>
                            ) : (
                              <BackUpImage height="200" width="300" />
                            )}
                            <a
                              onClick={() => goToHiredJobs((job as any)._id)}
                              style={{ textDecoration: "none" }}
                            >
                              <div style={{ width: "100%" }}>
                                <h5 style={{ paddingLeft: "16px" }}>
                                  <span
                                    style={{
                                      fontWeight: 800,
                                      fontSize: "1.4em",
                                      paddingTop: "10px",
                                    }}
                                  >
                                    {" "}
                                    {/* {job.title} */}
                                    {(job as any).title
                                      ? (job as any).title.slice(0, 80) +
                                        ((job as any).title.length > 80
                                          ? "..."
                                          : "")
                                      : ""}
                                  </span>
                                  <span className="In-progress">
                                    {(job as any).job_status === "in_progress"
                                      ? "In-progress"
                                      : "Waiting to start"}
                                  </span>
                                  <br />
                                  <span
                                    style={{
                                      fontWeight: 800,
                                      fontSize: "1.1em",
                                      color: "#7AA2F3",
                                    }}
                                  >
                                    {job.category.name}
                                  </span>
                                  <br />

                                  <span style={{ fontSize: "1.1em" }}>
                                    <span style={{ fontWeight: 800 }}>
                                      Start Date:
                                    </span>
                                    {job.start_date} @{job.start_time}
                                  </span>
                                  <br />
                                  <span style={{ fontSize: "1.1em" }}>
                                    <span style={{ fontWeight: 800 }}>
                                      Posted:
                                    </span>
                                    {job.time_ago}
                                  </span>
                                  <br />
                                  <span style={{ fontSize: "1.1em" }}>
                                    <span style={{ fontWeight: 800 }}>
                                      Budget:
                                    </span>
                                    <span className="color_blue_text">
                                      {" "}
                                      {identifyString(job.budget)}
                                      {job.budget}
                                    </span>
                                  </span>
                                  <br />
                                  <span
                                    style={{
                                      fontWeight: 800,
                                      fontSize: "1.1em",

                                      color: "#585858",
                                      textDecoration: "none",
                                    }}
                                  >
                                    Description:{" "}
                                  </span>
                                  <br />
                                  <span>{job.description}</span>
                                  <br />
                                </h5>
                              </div>
                            </a>
                          </li>
                        </>
                      );
                    })}
                </ul>
              </div>
            </div>
            <div id="past" className="tab-pane fade">
              <div className="my_job_content">
                <ul>
                  {historyJobs.length == 0 && (
                    <li className="tab_content_box">
                      <h1>No Jobs Found</h1>
                    </li>
                  )}
                  {historyJobs.length > 0 &&
                    historyJobs.map((job: any, index) => {
                      return (
                        <>
                          <li
                            className="tab_content_box"
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            {job.job_images.length > 0 ? (
                              <div
                                style={{
                                  borderRadius: "20px",
                                  backgroundColor: "rgba(0,0,0,0)",
                                }}
                              >
                                <div
                                  style={{
                                    height: "200px",
                                    width: "300px",
                                    borderRadius: "7.5%",
                                    backgroundColor: "#7aa2f3",
                                    overflow: "hidden",
                                  }}
                                >
                                  <img
                                    src={job.job_images[0].job_image}
                                    alt="slider_image.png"
                                    style={{ width: "100%" }}
                                  />
                                </div>
                              </div>
                            ) : (
                              <BackUpImage height="200" width="300" />
                            )}
                            <a
                              onClick={() => goToHiredJobs((job as any)._id)}
                              style={{ textDecoration: "none" }}
                            >
                              <div style={{ width: "100%" }}>
                                <h5 style={{ paddingLeft: "16px" }}>
                                  <span
                                    style={{
                                      fontWeight: 800,
                                      fontSize: "1.4em",
                                      paddingTop: "10px",
                                    }}
                                  >
                                    {" "}
                                    {/* {job.title} */}
                                    {(job as any).title
                                      ? (job as any).title.slice(0, 80) +
                                        ((job as any).title.length > 80
                                          ? "..."
                                          : "")
                                      : ""}
                                  </span>
                                  {(job as any).status == "CAN" ? (
                                    <span className="In-progress comleted">
                                      Cancelled
                                    </span>
                                  ) : (
                                    <span className="In-progress comleted">
                                      Completed
                                    </span>
                                  )}
                                  <br />
                                  <span
                                    style={{
                                      fontWeight: 800,
                                      fontSize: "1.1em",
                                      color: "#7AA2F3",
                                    }}
                                  >
                                    {job.category.name}
                                  </span>
                                  <br />

                                  <span style={{ fontSize: "1.1em" }}>
                                    <span style={{ fontWeight: 800 }}>
                                      Start Date:
                                    </span>
                                    {job.start_date} @{job.start_time}
                                  </span>
                                  <br />
                                  <span style={{ fontSize: "1.1em" }}>
                                    <span style={{ fontWeight: 800 }}>
                                      Posted:
                                    </span>
                                    {job.time_ago}
                                  </span>
                                  <br />
                                  <span style={{ fontSize: "1.1em" }}>
                                    <span style={{ fontWeight: 800 }}>
                                      Budget:
                                    </span>
                                    <span className="color_blue_text">
                                      {" "}
                                      {identifyString(job.budget)}
                                      {job.budget}
                                    </span>
                                  </span>
                                  <br />
                                  <span
                                    style={{
                                      fontWeight: 800,
                                      fontSize: "1.1em",

                                      color: "#585858",
                                      textDecoration: "none",
                                    }}
                                  >
                                    Description:{" "}
                                  </span>
                                  <br />
                                  <span>{job.description}</span>
                                  <br />
                                </h5>
                              </div>
                            </a>
                          </li>
                        </>
                      );
                    })}
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="pagination_box">
                    <nav aria-label="Page navigation example">
                        <ul className="pagination">
                            <li className="page-job"><a className="page-link" href="#"><img src={left_arrow} alt="left_arrow" /></a></li>
                            <li className="page-item"><a className="page-link" href="#">1</a></li>
                            <li className="page-item active"><a className="page-link" href="#">2</a></li>
                            <li className="page-item"><a className="page-link" href="#">3</a></li>
                            <li className="page-item"><a className="page-link" href="#">4</a></li>
                            <li className="page-item"><a className="page-link" href="#">5</a></li>
                            <li className="page-item"><a className="page-link" href="#"><img src={right_arrow} alt="right_arrow" /></a></li>
                        </ul>
                    </nav>
                </div> */}
      </div>
      <div className="loader-box" style={loaderStyle}>
        <div className="loader-image">
          <img src={require("../../assets/images/loader.gif")} />
        </div>
      </div>
    </section>
  );
};

export default reduxForm({
  form: "loginForm", // a unique identifier for this form
})(ProviderJobs);
