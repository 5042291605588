import * as React from "react";
import "./App.css";
import firebase from "./firebase.js";
import store from "./store";
import { Provider } from "react-redux";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

//Components
import { Layout } from "./components/common/Layout";
import Home from "./components/Home";
import Clienthome from "./components/client/Clienthome";
import Myjobs from "./components/client/Myjobs";
import Jobdetail from "./components/client/Jobdetail";
import Biddetail from "./components/client/BidDetail";
import ServiceProviderProfile from "./components/client/ServiceProviderProfile";

import ProviderHome from "./components/provider/ProviderHome";
import Chat from "./components/provider/Chat";
import ProviderJobs from "./components/provider/ProviderJobs";

import Messages from "./components/client/messages/Messages";
import Bidmessage from "./components/client/messages/Bidmessage";

import Settings from "./components/provider/Settings";
import Profile from "./components/provider/Profile";
import CompletedJob from "./components/provider/CompletedJob";
import CompletedJobDetails from "./components/provider/CompletedJobDetails";
import JobDetails from "./components/provider/JobDetails";
import JobInProgress from "./components/provider/JobInProgress";
import JobInvitationDetails from "./components/provider/JobInvitationDetails";
import JobInvites from "./components/provider/JobInvites";
import SubmitBid from "./components/provider/SubmitBid";
import Payment from "./components/client/Payment";
import Notifications from "./components/provider/Notifications";
import CusNotifications from "./components/client/Notifications";
import Addjob from "./components/client/Addjob";
import Previewpost from "./components/client/Previewpost";
import Myprofile from "./components/client/Myprofile";
import Inviteuser from "./components/client/Inviteuser";
import LinkedInPopUp from "./components/LinkedInPopUp";
import CustomerJobDetail from "./components/provider/CustomerJobDetail";
import VerifyPassword from "./components/common/VerifyPassword";
import TermsAndConditions from "./components/common/TermsAndConditions";
import PrivacyPolicy from "./components/common/PrivacyPolicy";
// /home/netset/apps/jobpostingreactjs/src/components/provider/CustomerJobDetail.tsx
const access_token = localStorage.getItem("access_token");
const user_role = localStorage.getItem("role");

const is_profile_created = localStorage.getItem("is_profile_created");

const WithLoginHeader = (props: any) => {
  return (
    <Layout type="loggedin" className="">
      {props.children}
    </Layout>
  );
};

const WithHeader = (props: any) => {
  return (
    <Layout type="guest" className="">
      {props.children}
    </Layout>
  );
};

const WithProviderHeader = (props: any) => {
  return (
    <Layout type="provider" className="">
      {props.children}
    </Layout>
  );
};

const App = () => {
  return (
    <Provider store={store}>
      <Router>
        {access_token &&
        user_role === "customer" &&
        is_profile_created == "1" ? (
          <Switch>
            <Route
              exact={true}
              path="/"
              component={() => (
                <WithLoginHeader>{<Clienthome />}</WithLoginHeader>
              )}
            />
            <Route
              exact={true}
              path="/my_jobs"
              component={() => <WithLoginHeader>{<Myjobs />}</WithLoginHeader>}
            />
            <Route
              path="/service_provider_profile/:id/:lat/:lng"
              component={(props: any) => (
                <WithLoginHeader>
                  {<ServiceProviderProfile {...props} />}
                </WithLoginHeader>
              )}
            />
            <Route
              path="/job_detail/:id"
              component={() => (
                <WithLoginHeader>{<Jobdetail />}</WithLoginHeader>
              )}
            />
            <Route
              path="/bid_detail/:id"
              component={() => (
                <WithLoginHeader>{<Biddetail />}</WithLoginHeader>
              )}
            />
            <Route
              path="/job_payment/:id"
              component={() => <WithLoginHeader>{<Payment />}</WithLoginHeader>}
            />

            <Route
              exact={true}
              path="/edit_job/:id"
              component={(props: any) => (
                <WithLoginHeader>{<Addjob {...props} />}</WithLoginHeader>
              )}
            />
            <Route
              exact={true}
              path="/messages"
              component={() => (
                <WithLoginHeader>{<Messages />}</WithLoginHeader>
              )}
            />
            <Route
              exact={true}
              path="/new_message/:job_id/:other_user_id"
              component={(props: any) => (
                <WithLoginHeader>{<Messages {...props} />}</WithLoginHeader>
              )}
            />
            <Route
              exact={true}
              path="/add_job"
              component={() => <WithLoginHeader>{<Addjob />}</WithLoginHeader>}
            />
            <Route
              exact={true}
              path="/previewpost"
              component={() => (
                <WithLoginHeader>{<Previewpost />}</WithLoginHeader>
              )}
            />
            <Route
              exact={true}
              path="/dd"
              component={() => <WithHeader>{<Home />}</WithHeader>}
            />
            <Route
              path="/bid_message/:id/:user_id?"
              component={() => (
                <WithLoginHeader>{<Bidmessage />}</WithLoginHeader>
              )}
            />
            <Route
              path="/my_profile"
              component={() => (
                <WithLoginHeader>{<Myprofile />}</WithLoginHeader>
              )}
            />
            <Route
              path="/invite_user/:job_id"
              component={() => (
                <WithLoginHeader>{<Inviteuser />}</WithLoginHeader>
              )}
            />
            <Route
              exact={true}
              path="/verify_account/:token"
              component={() => <WithHeader>{<VerifyPassword />}</WithHeader>}
            />

            <Route
              exact={true}
              path="/cus_notifications"
              component={() => (
                <WithLoginHeader>{<CusNotifications />}</WithLoginHeader>
              )}
            />
            <Route
              exact={true}
              path="/terms_conditions"
              component={() => (
                <WithLoginHeader>{<TermsAndConditions />}</WithLoginHeader>
              )}
            />
            <Route
              exact={true}
              path="/privacy_policy"
              component={() => (
                <WithLoginHeader>{<PrivacyPolicy />}</WithLoginHeader>
              )}
            />
            <Redirect to="/" />
          </Switch>
        ) : access_token &&
          user_role === "provider" &&
          is_profile_created == "1" ? (
          <Switch>
            <Route
              exact={true}
              path="/"
              component={() => (
                <WithProviderHeader>{<ProviderHome />}</WithProviderHeader>
              )}
            />
            {/* <Route exact={true} path="/provider_home" component={() => <WithProviderHeader>{<ProviderHome />}</WithProviderHeader>} /> */}
            <Route
              exact={true}
              path="/chat"
              component={() => (
                <WithProviderHeader>{<Chat />}</WithProviderHeader>
              )}
            />
            <Route
              exact={true}
              path="/new_message/:job_id/:other_user_id"
              component={(props: any) => (
                <WithProviderHeader>{<Chat {...props} />}</WithProviderHeader>
              )}
            />
            <Route
              exact={true}
              path="/provider_jobs"
              component={() => (
                <WithProviderHeader>{<ProviderJobs />}</WithProviderHeader>
              )}
            />
            <Route
              exact={true}
              path="/settings"
              component={() => (
                <WithProviderHeader>{<Settings />}</WithProviderHeader>
              )}
            />
            <Route
              exact={true}
              path="/profile"
              component={() => (
                <WithProviderHeader>{<Profile />}</WithProviderHeader>
              )}
            />
            <Route
              exact={true}
              path="/completed_job"
              component={() => (
                <WithProviderHeader>{<CompletedJob />}</WithProviderHeader>
              )}
            />
            <Route
              exact={true}
              path="/completed_job_details"
              component={() => (
                <WithProviderHeader>
                  {<CompletedJobDetails />}
                </WithProviderHeader>
              )}
            />
            <Route
              exact={true}
              path="/job_details"
              component={() => (
                <WithProviderHeader>{<JobDetails />}</WithProviderHeader>
              )}
            />
            <Route
              exact={true}
              path="/job_in_progress/:id"
              component={() => (
                <WithProviderHeader>{<JobInProgress />}</WithProviderHeader>
              )}
            />
            <Route
              exact={true}
              path="/job_invitation_details"
              component={() => (
                <WithProviderHeader>
                  {<JobInvitationDetails />}
                </WithProviderHeader>
              )}
            />
            <Route
              exact={true}
              path="/job_invites"
              component={() => (
                <WithProviderHeader>{<JobInvites />}</WithProviderHeader>
              )}
            />
            <Route
              exact={true}
              path="/submit_bid"
              component={() => (
                <WithProviderHeader>{<SubmitBid />}</WithProviderHeader>
              )}
            />
            <Route
              exact={true}
              path="/notifications"
              component={() => (
                <WithProviderHeader>{<Notifications />}</WithProviderHeader>
              )}
            />
            <Route
              exact={true}
              path="/verify_account/:token"
              component={() => <WithHeader>{<VerifyPassword />}</WithHeader>}
            />
            <Route
              exact={true}
              path="/terms_conditions"
              component={() => (
                <WithProviderHeader>
                  {<TermsAndConditions />}
                </WithProviderHeader>
              )}
            />
            <Route
              exact={true}
              path="/privacy_policy/"
              component={() => (
                <WithProviderHeader>{<PrivacyPolicy />}</WithProviderHeader>
              )}
            />
            <Route
              exact={true}
              path="/customerjobdetail/:job_id/:user_id"
              component={() => (
                <WithProviderHeader>{<CustomerJobDetail />}</WithProviderHeader>
              )}
            />
            <Redirect to="/" />
          </Switch>
        ) : access_token ? (
          <Switch>
            <Route
              exact={true}
              path="/"
              component={() => <WithHeader>{<Home />}</WithHeader>}
            />
            <Route
              exact={true}
              path="/verify_account/:token"
              component={() => <WithHeader>{<VerifyPassword />}</WithHeader>}
            />
            <Route
              exact={true}
              path="/terms_conditions"
              component={() => (
                <WithHeader>{<TermsAndConditions />}</WithHeader>
              )}
            />
            <Route
              exact={true}
              path="/privacy_policy"
              component={() => <WithHeader>{<PrivacyPolicy />}</WithHeader>}
            />
            <Redirect to="/" />
          </Switch>
        ) : (
          <Switch>
            <Route
              exact={true}
              path="/"
              component={() => <WithHeader>{<Home />}</WithHeader>}
            />
            <Route
              exact={true}
              path="/verify_account/:token"
              component={() => <WithHeader>{<VerifyPassword />}</WithHeader>}
            />
            <Route
              exact={true}
              path="/terms_conditions"
              component={() => (
                <WithHeader>{<TermsAndConditions />}</WithHeader>
              )}
            />
            <Route
              exact={true}
              path="/privacy_policy"
              component={() => <WithHeader>{<PrivacyPolicy />}</WithHeader>}
            />
            <Route
              exact={true}
              path="/linkedin"
              component={() => <WithHeader>{<LinkedInPopUp />}</WithHeader>}
            />
            <Redirect to="/" />
          </Switch>
        )}
      </Router>
    </Provider>
  );
};

export default App;
